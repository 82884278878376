<div id="demographics" class="registration container">
  <form #demographicsForm="ngForm" [ngClass]="{'form-complete': demographicsForm.valid }" (ngSubmit)="forward(demographicsForm)" novalidate autocomplete="off" appScrollToInvalid [ngClass]="{'form-complete': isFormCompleted}">
    <h1>{{ 'copy.label.DEMOGRAPHICS.heading' | copy | async }}</h1>
    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.DEMOGRAPHICS.subheading' | copy | async }}
        </div>
      </div>

      <article>
        <div class="card-body w-100">
          <mat-form-field class="w-100 required">
            <mat-label class="font-weight-bold" for="shirtSize" aria-labelledby="shirtSize">{{'copy.label.DEMOGRAPHICS.shirtsizelabel' | copy | async}}
              <!-- <i class="fas fa-asterisk required"></i> -->
            </mat-label>
            <mat-select placeholder="{{'copy.label.DEMOGRAPHICS.shirtsizeplaceholder' | copy | async }}" name="shirtSizeValue" id="shirtSizeValue" aria-label="shirtSizeValue" [(ngModel)]="shirtSize.Value" required>
              <mat-option *ngFor="let shirtSize of shirtSizeList" [value]="shirtSize.value">
                {{shirtSize.display}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="w-100 required">
            <label class="w-100 font-weight-bold" for="firstTime" aria-labelledby="firstTime">{{'copy.label.DEMOGRAPHICS.firsttime'|copy|async}}
              <!-- <i class="fas fa-asterisk required"></i> --></label>
            <!-- <app-radio-group></app-radio-group> -->
            <mat-radio-group class="w-100" [(ngModel)]="firstTime.Value" name="firstTimeValue" required>
              <mat-radio-button value="Yes">Yes</mat-radio-button>
              <mat-radio-button value="No">No</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="firstTime.Value === 'Yes'" class="w-100">
              <div class="d-flex justify-content-start">
                <mat-form-field class="w-100">
                  <mat-label class="w-100 font-weight-bold" for="whyAttend" aria-labelledby="whyAttend">{{ 'copy.label.DEMOGRAPHICS.whyAttend' | copy | async }}</mat-label>
                  <textarea matInput class="w-100" name="whyAttend" id="whyAttend" aria-label="whyAttend" [(ngModel)]="whyAttend.Value" id="whyAttend" class="md-textarea form-control"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
           <div class="w-100" *ngIf="bestFeaturesSelections">
            <label class="font-weight-bold">{{'copy.label.DEMOGRAPHICS.bestfeature' | copy | async }}</label>
            <div class="checkbox-group" *ngFor="let bestFeature of BEST_FEATURES_VALUES">
              <div class="agreeCheckbox mt-2">
                <div class="checkboxFlexContainer">
                  <mat-checkbox [(ngModel)]="bestFeaturesSelections[bestFeature.value]" name="{{bestFeature.display}}"  (change)="onChangeBestFeature()" class="example-margin">
                  </mat-checkbox>
                  <label>{{bestFeature.display}}</label>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="otherChecked" class="w-100">
            <mat-form-field class="w-100">
              <mat-label for="otherChecked" aria-labelledby="otherChecked" [ngClass]="{'active' : bestFeatureFreeFormValue}">{{'copy.label.DEMOGRAPHICS.bestfeature' | copy | async }}</mat-label>
              <input matInput name="otherChecked" [(ngModel)]="bestFeatureFreeFormValue" [required]="otherChecked"  type="text" required />
              
            </mat-form-field>
          </div>
          <div class="w-100 mt-3">
            <label class="w-100 font-weight-bold" for="firstTime">{{'copy.label.DEMOGRAPHICS.multiselect' | copy | async }}</label>
            <div class="w-100">
              <div class="d-flex justify-content-between w-100 multiSelect">
                <mat-form-field class="w-40">
                  <mat-label>{{'copy.label.DEMOGRAPHICS.shirtsizeplaceholder' | copy | async }}</mat-label>
                  <mat-select disableRipple>
                    <mat-option *ngFor="let ss of multiSelectValues" value="{{ss}}">{{ss}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-40">
                  <mat-label>{{'copy.label.DEMOGRAPHICS.shirtsizeplaceholder' | copy | async}}</mat-label>
                  <mat-select disableRipple>
                    <mat-option *ngFor="let ss of multiSelectValues" value="{{ss}}">{{ss}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-40">
                  <mat-label>{{'copy.label.DEMOGRAPHICS.shirtsizeplaceholder' | copy | async}}</mat-label>
                  <mat-select disableRipple>
                    <mat-option *ngFor="let ss of multiSelectValues" value="{{ss}}">{{ss}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
    <div class="button_group d-flex">
      <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.goback' | copy | async }}
      </button>
      <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.continue' | copy | async }}
      </button>
      <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
        <i class="fas fa-spinner fa-spin"></i>
      </button>
    </div>
  </form>
</div>
