<div class="contentFullVerticalHeight" id="faqs">
    <div class="container">
        <h2>ATTENDANCE</h2>
        <ngb-accordion class="faqAccordion">
            <ngb-panel
            *ngIf="(attendee.PAXTypeNo === 1 || attendee.PAXTypeNo === 2 || attendee.PAXTypeNo === 3 || attendee.PAXTypeNo === 4 || attendee.PAXTypeNo === 16 || attendee.PAXTypeNo === 17)">
            <ng-template ngbPanelTitle>
                <div class="accordion_dt"><span class="note-span">1, 2, 3, 4, 16, 17 </span>How many LNDM enrollments are permitted per dealer code?</div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div>
                    <p class="p-list-item">A total of two enrollments per dealer code are permitted.</p>
                    <p class="p-list-item"><em><strong>Note: </strong>One primary dealer attendee and one guest (at least 21 years old) is permitted per enrollment.</em></p>
                </div>
            </ng-template>
            </ngb-panel>
    
            <ngb-panel
            *ngIf="(attendee.PAXTypeNo === 1 || attendee.PAXTypeNo === 2 || attendee.PAXTypeNo === 3 || attendee.PAXTypeNo === 4 || attendee.PAXTypeNo === 16 || attendee.PAXTypeNo === 17)">
            <ng-template ngbPanelTitle>
                <div class="accordion_dt"><span class="note-span">1, 2, 3, 4, 16, 17 </span>Can I designate someone else to attend LNDM in my place?</div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div>
                    <p class="p-list-item">Please contact your Area Vehicle Marketing Manager directly regarding substitution requests.</p>
                </div>
            </ng-template>
            </ngb-panel>
    
            <ngb-panel
            *ngIf="(attendee.PAXTypeNo === 1 || attendee.PAXTypeNo === 2 || attendee.PAXTypeNo === 3 || attendee.PAXTypeNo === 4 || attendee.PAXTypeNo === 16 || attendee.PAXTypeNo === 17)">
            <ng-template ngbPanelTitle>
                <div class="accordion_dt"><span class="note-span">1, 2, 3, 4, 16, 17 </span>Can I request additional LNDM enrollments?</div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div>
                    <p class="p-list-item">Please contact your Area Vehicle Marketing Manager directly regarding substitution requests.</p>
                </div>
            </ng-template>
            </ngb-panel>

            <ngb-panel
            *ngIf="(attendee.PAXTypeNo === 1 || attendee.PAXTypeNo === 2 || attendee.PAXTypeNo === 3 || attendee.PAXTypeNo === 4 || attendee.PAXTypeNo === 16 || attendee.PAXTypeNo === 17)">
            <ng-template ngbPanelTitle>
                <div class="accordion_dt"><span class="note-span">1, 2, 3, 4, 16, 17 </span>May I bring a guest?</div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div>
                    <p class="p-list-item">Each dealer enrollment allows for only one guest, who must be at least 21 years old.</p>
                </div>
            </ng-template>
            </ngb-panel>

            <ngb-panel>
            <ng-template ngbPanelTitle>
                <div class="accordion_dt"><span class="note-span">all </span>Are children permitted to attend?</div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div>
                    <p class="p-list-item">Children are not permitted to attend. <strong>(No one under the age of 21 will be permitted to attend.)</strong></p>
                </div>
            </ng-template>
            </ngb-panel>

            <ngb-panel>
            <ng-template ngbPanelTitle>
                <div class="accordion_dt"><span class="note-span">all </span>When is the enrollment deadline?</div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div>
                    <p class="p-list-item">The enrollment deadline is <strong>Friday, August 30, 2024</strong>. <strong>
                    <em>Note: </em>
                    </strong>
                    <em>Cancellations received after <strong>Friday, August 30, 2024,</strong> are nonrefundable.</em></p>
                </div>
            </ng-template>
            </ngb-panel>
        </ngb-accordion>

        <h2>ACCOMMODATIONS</h2>
        <ngb-accordion class="faqAccordion">
            <ngb-panel
            *ngIf="(attendee.PAXTypeNo === 1 || attendee.PAXTypeNo === 2 || attendee.PAXTypeNo === 3 || attendee.PAXTypeNo === 4 || attendee.PAXTypeNo === 16)">
            <ng-template ngbPanelTitle>
                <div class="accordion_dt"><span class="note-span">1, 2, 3, 4, 16 </span>Is the cost of my room covered by Lexus?</div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div>
                    <p class="p-list-item">Hotel packages for LNDM accommodations (October 7-9, 2024) are available for purchase.</p>
                </div>
            </ng-template>
            </ngb-panel>

            <ngb-panel>
            <ng-template ngbPanelTitle>
                <div class="accordion_dt"><span class="note-span">all </span>May I reserve more than one room?</div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div>
                    <p class="p-list-item">One room per registration has been reserved.</p>
                    <p class="p-list-item">To request an additional room, please email <a
                        href="mailto:LexusNationalDealerMeeting@travelhq.com">LexusNationalDealerMeeting@travelhq.com</a>.</p>
                    <p class="p-list-item"><em><strong>Note:</strong> Any additional room charges will be at your personal expense and based on availability.</em></p>
                </div>
            </ng-template>
            </ngb-panel>
            
            <ngb-panel>
            <ng-template ngbPanelTitle>
                <div class="accordion_dt"><span class="note-span">all </span>May I upgrade my room accommodations?</div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div>
                    <p class="p-list-item">Upgrades will be based on hotel availability and will be at your personal expense.
                    </p>
                    <p class="p-list-item">For reservation upgrades, please email 
                        <a href="mailto:LexusNationalDealerMeeting@travelhq.com">LexusNationalDealerMeeting@travelhq.com</a>.</p>
                </div>
            </ng-template>
            </ngb-panel>

            <ngb-panel>
            <ng-template ngbPanelTitle>
                <div class="accordion_dt"><span class="note-span">all </span>May I extend my trip?</div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div>
                    <p class="p-list-item">
                    Yes, extension requests will be based on hotel availability and will be at your personal expense. Please
                    indicate your preferred travel dates during website registration. You may amend your dates by returning to
                    website registration, or contact:
                    </p>
                    <p class="p-list-item">
                        <strong>Lexus Travel Program Headquarters</strong><br />
                        Telephone: <a href="tel:+18003148554">800-314-8554</a> (toll-free) or <a href="+16368270235">636-827-0235</a><br/>
                        (8:00 a.m.-6:00 p.m. CDT, Monday-Friday)<br/>
                        Email: <a href="mailto:LexusNationalDealerMeeting@travelhq.com">LexusNationalDealerMeeting@travelhq.com</a>
                    </p>
                </div>
            </ng-template>
            </ngb-panel>
        </ngb-accordion>

        <h2>CHANGING RESERVATIONS/CANCELLATION POLICIES</h2>
        <ngb-accordion class="faqAccordion">
            <ngb-panel>
                <ng-template ngbPanelTitle>
                    <div class="accordion_dt"><span class="note-span"></span>How do I change/update my reservation once my enrollment is completed?</div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div>
                        <p class="p-list-item">You may return to the website at any time to change/update your registration or email
                            Lexus Travel Program Headquarters at <a
                            href="mailto:LexusNationalDealerMeeting@travelhq.com">LexusNationalDealerMeeting@travelhq.com</a>.</p>
                    </div>
                </ng-template>
            </ngb-panel>

            <ngb-panel
                *ngIf="(attendee.PAXTypeNo === 1 || attendee.PAXTypeNo === 2 || attendee.PAXTypeNo === 3 || attendee.PAXTypeNo === 4 || attendee.PAXTypeNo === 16)">
                <ng-template ngbPanelTitle>
                    <div class="accordion_dt"><span class="note-span">1, 2, 3, 4, 16 </span>What is the deadline date to receive a full refund of my LNDM hotel package price?
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div>
                        <p class="p-list-item">Cancellations received <strong>before/on </strong>Friday, <strong>August 30, 2024,</strong> will be fully refunded less a $75 administration fee.</p>
                        <p class="p-list-item">Cancellations received <strong>after Friday, August 30, 2024, </strong>are non-refundable.</p>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>

        <h2 *ngIf="(attendee.PAXTypeNo === 1 || attendee.PAXTypeNo === 2 || attendee.PAXTypeNo === 3 || attendee.PAXTypeNo === 4 || attendee.PAXTypeNo === 6 || attendee.PAXTypeNo === 7 || attendee.PAXTypeNo === 8 || attendee.PAXTypeNo === 10 || attendee.PAXTypeNo === 11 || attendee.PAXTypeNo === 12 || attendee.PAXTypeNo === 13 || attendee.PAXTypeNo === 14 || attendee.PAXTypeNo === 16 || attendee.PAXTypeNo === 18)">TRAVEL PREPERATIONS</h2>
        <ngb-accordion class="faqAccordion">
            <ngb-panel
                *ngIf="(attendee.PAXTypeNo === 1 || attendee.PAXTypeNo === 2 || attendee.PAXTypeNo === 3 || attendee.PAXTypeNo === 4 || attendee.PAXTypeNo === 16 || attendee.PAXTypeNo === 8)">
                <ng-template ngbPanelTitle>
                    <div class="accordion_dt"><span class="note-span"> 1, 2, 3, 4, 8, 16 </span>Who can assist with my airline reservations?</div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div>
                        <p class="p-list-item">You can book your flights directly with your preferred travel agent or online airline. Alternatively, if you need assistance, please contact:</p>
                        <p class="p-list-item">
                            <strong>Lexus Travel Program Headquarters</strong><br>
                            Telephone: <a href="tel:8003148554">800-314-8554</a> or 
                            <a href="tel:6368270235">636-827-0235</a><br>
                            (8:00 a.m.-6:00 p.m. CDT, Monday-Friday)<br>
                            Email: <a href="mailto:LexusNationalDealerMeeting@travelhq.com">LexusNationalDealerMeeting@travelhq.com</a>
                        </p>
                        <p class="p-list-item">A credit card is required for Lexus Travel Program Headquarters to book your flights.</p>
                        <p class="p-list-item">
                            <span><em><strong>Note:</strong> Booking through Lexus Travel Program Headquarters will incur a
                                non-refundable $50 administration fee per ticket.</em></span>
                        </p>
                    </div>
                </ng-template>
            </ngb-panel>

            <ngb-panel *ngIf="(attendee.PAXTypeNo === 17)">
                <ng-template ngbPanelTitle>
                    <div class="accordion_dt"><span class="note-span">17</span>Who can assist with my airline reservations?</div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div>
                        <p class="p-list-item">Lexus Travel Program Headquarters will begin making airline arrangements for your trip once you've completed the registration process. </p>
                        <p class="p-list-item">
                            <strong>
                            <em>Note:</em>
                            </strong>
                            <em> Your completed travel registration is required to finalize your airline arrangements.</em>
                        </p>
                        <p class="p-list-item">
                            <strong>Tickets and Flight Selections: </strong>Flights will be selected for you based on Lexus' guidelines and in accordance with the program's
                            designated airfares. When available, your flights will be first or business-class. Lexus Travel Program Headquarters will recommend flights 
                            for you based on the best timing and routing, but please be aware that the rules governing the program's designated airfares may stipulate:
                        </p>
                        <ul class="p-list-item">
                            <li>Traveling on a preselected airline.</li>
                            <li>Traveling on a connecting rather than direct or non-stop flight.</li>
                            <li>Traveling to a particular airport.</li>
                        </ul>
                    </div>
                </ng-template>
            </ngb-panel>
    
            <ngb-panel
            *ngIf="(attendee.PAXTypeNo === 1 || attendee.PAXTypeNo === 2 || attendee.PAXTypeNo === 3 || attendee.PAXTypeNo === 4 || attendee.PAXTypeNo === 6 || attendee.PAXTypeNo === 7 || attendee.PAXTypeNo === 8 || attendee.PAXTypeNo === 10 || attendee.PAXTypeNo === 11 || attendee.PAXTypeNo === 12 || attendee.PAXTypeNo === 13 || attendee.PAXTypeNo === 14 || attendee.PAXTypeNo === 16 || attendee.PAXTypeNo === 18)">
                <ng-template ngbPanelTitle>
                    <div class="accordion_dt"><span class="note-span">1, 2, 3, 4, 6, 7, 8, 10, 11, 12, 13, 14, 16, 18</span>From which airports will ground transfers be provided?</div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div>
                        <p class="p-list-item">Round-trip airport transfers will be provided between Nashville International Airport (BNA) and your hotel.</p>
                        <p class="p-list-item">To receive round-trip transportation between Nashville International Airport (BNA) and Lexus group hotels, it is mandatory that 
                            you inform Lexus Travel Program Headquarters of your confirmed flight details <strong>no later than Friday, September 20, 2024</strong>, 
                            by sending an email to <a href="mailto:LexusNationalDealerMeeting@travelhq.com">LexusNationalDealerMeeting@travelhq.com</a>. 
                        </p>
                    </div>
                </ng-template>
            </ngb-panel>

            <ngb-panel *ngIf="(attendee.PAXTypeNo === 17)">
                <ng-template ngbPanelTitle>
                    <div class="accordion_dt"><span class="note-span">17</span>From which airports will ground transfers be provided?</div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div>
                    <p class="p-list-item">Round-trip airport transfers will be provided between Nashville International Airport (BNA) and your hotel.</p>
                    </div>
                </ng-template>
            </ngb-panel>

            <ngb-panel *ngIf="attendee.PAXTypeNo === 9">
                <ng-template ngbPanelTitle>
                    <div class="accordion_dt"><span class="note-span">9 </span>Does Lexus provide airport transfers?</div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div>
                        <p class="p-list-item">Yes, Lexus provides round-trip airport transfers during program arrival and departure dates (Sunday, October 6 and Wednesday, October 9).</p>
                        <p class="p-list-item">To receive round-trip transportation between Nashville International Airport (BNA) and your hotel, 
                            it is mandatory that you inform Lexus Travel Program Headquarters of your confirmed flight details 
                            <strong>no later than Friday, September 20, 2024</strong>, by sending an email to 
                            <a href="mailto:LexusNationalDealerMeeting@travelhq.com">LexusNationalDealerMeeting@travelhq.com</a>.</p>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
  </div>