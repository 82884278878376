<fieldset [ngModelGroup]="fieldName">
  <mat-form-field class="w-100 birthdate">
    <mat-label for="attendeeBirthDate" aria-labelledby="attendeeBirthDate" [ngClass]="{'active' : participant[fieldName]}">{{ label | copy | async }}</mat-label>
    <input
    matInput
    name="attendeeBirthDate__{{participant.$mtcUUID}}"
    id="attendeeBirthDate"
    aria-label="attendeeBirthDate"
    type="text"
    placeholder="MM/DD/YYYY"
    [inputMask]="dateMask"
    [(ngModel)]="tempdate"
    #attendeeBirthDate="ngModel"
    (ngModelChange)="toISOString(tempdate)"
    [appDateValidator]="validateOptions"
    required="isRequired"/>
    <mat-error><app-validation [object]="attendeeBirthDate" isRequired=true [minDate]="minDate" [maxDate]="maxDate" ></app-validation></mat-error>
  </mat-form-field>
</fieldset>
