import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SessionmodalComponent } from '../reusable-components/sessionmodal/sessionmodal.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TimeoutService {
  timeout;

  constructor(
    private authService: AuthenticationService,
    public dialog: MatDialog
  ) {}

  setSessionTimeout() {
    clearTimeout(this.timeout);
    if (this.authService.authtoken) {
      this.authService.validateSessionToken().subscribe((data: any) => {
        this.authService.authtoken = data.token;
        const expiration = data.expiration;
        const currenttime = data.currenttime;
        const expirationtime = expiration - currenttime;
        const expirationwarning = expirationtime - 300000;

        this.timeout = setTimeout(() => {
          const dialogRef = this.open();

          const sub = dialogRef.componentInstance.onExtend.subscribe(() => {
            // do something
            this.setSessionTimeout();
          });
          dialogRef.afterClosed().subscribe(() => {
            sub.unsubscribe();
          });
        }, expirationwarning);
      });
    }
  }
  public open() {
    let dialogRef: MatDialogRef<any>;

    dialogRef = this.dialog.open(SessionmodalComponent, {
      panelClass: 'session-dialog-container',
    });

    return dialogRef;
  }
}
