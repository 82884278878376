import { Injectable } from '@angular/core';
import { windowTime } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { EnvironmentService } from './environment.service';
import { RegistrationService } from './registration.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private configService: ConfigurationService,
    private registrationService: RegistrationService,
    private env: EnvironmentService
  ) { }

  triggerEvent(urlpath, pagetitle){
    let attendee = this.registrationService.getAttendee({});
    this.configService.fetchConfig().then(conf=>{

      window['gtag']('event','page_view', {page_location: urlpath,page_title: pagetitle, "ProgramNo": this.env.getProgramKey(), "Program":conf.programName, "PaxType":attendee.PAXTypeNo });
    });

  }

}
