<div id="login" class="registration container">
  <form name="loginForm" novalidate (ngSubmit)="forward()" autocomplete="off" class="contentFullVerticalHeight halfVerticalHeight">
    <div class="card w-50 ml-auto mr-auto">
      <h1 class="text-center">{{"copy.label.SESSIONTIMEOUT.header"|copy|async}}</h1>
      <div>
        <p class="text-center">{{'copy.html.sessionexpiredtext'|copy|async}}</p>
      </div>
      <div class="button_group d-flex">
        <button type="submit" class="inputSubmit btn btn-primary ml-auto mr-auto">{{"copy.label.SESSIONTIMEOUT.login"|copy|async}}</button>
      </div>
    </div>
  </form>
</div>
