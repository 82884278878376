import { Injectable } from '@angular/core';
import { RegistrationService } from './registration.service';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../services/constants.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CreditcardService {
  cards = [];

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private constantsService: ConstantsService,
    private registrationService: RegistrationService
  ) { }

  /**
         * @function CreditCardService.getCards
         * @description Passes a reference key to the [CreditCardResource.java](../apidocs/com/mtc/regsite/profile/CreditCardResource.html) and gets back the card details.
         */
  getCards() {
    let enrollment = this.registrationService.getEnrollment({});
    if (enrollment.Payment) {
      _.forEach(enrollment.Payment, (payment) =>{
        if (payment.ReferenceKey) {
          const promise = new Promise((resolve, reject) => {
            let url = this.environmentService.getEnvironmentUrl("service/creditcard/" + payment.ReferenceKey);
            this.http.get(url).toPromise().then((response: any) => {

              let carddetails = response.data;
              carddetails.ReferenceKey = payment.ReferenceKey;
              this.storeRVS(carddetails);
              resolve({});
            });
          });
          return promise;
        }
      });
    }
  }



  /**
     * @function CreditCardService.storeRVS
     * @description Stores the card during a return visit.
     * @param card
     */
  storeRVS(card) {
    card.type = this.getCardTypeRVS(card.type);
    card.number = card.number.slice(-4);
    card.masked = "XXXX XXXX XXXX " + card.number;
    card.label = card.type + " ending in " + card.number;
    this.cards.push(card);
  }

  /**
     * @function CreditCardService.getCardTypeRVS
     * @description Gets the card type on a return visit.
     * @param type
     * @returns {String}
     */
  getCardTypeRVS(type) {
    if (type === "AX") {
      return "AMEX";
    } else if (type === "DS") {
      return "DISCOVER";
    } else if (type === "CA") {
      return "MASTERCARD";
    } else if (type === "VI") {
      return "VISA";
    }
  }

  /**
         * @function CreditCardService.getCardType
         * @description Checks the card and returns the card type such as (Discover, Mastercard, etc.)
         * @param ccnum
         * @returns {Boolean|String}
         */
  //TODO add card type: paymentAccountInfo.getPaymentMediaDescription();
  getCardType(ccnum) {
    // AMEX
    if (_.startsWith(ccnum, "34") || _.startsWith(ccnum, "37")) {
      return "AMEX";
    }

    // Discover
    if (_.startsWith(ccnum, "6011")) {
      return "DISCOVER";
    }

    // Mastercard
    if (_.startsWith(ccnum, "51") ||
      _.startsWith(ccnum, "52") ||
      _.startsWith(ccnum, "53") ||
      _.startsWith(ccnum, "54") ||
      _.startsWith(ccnum, "55")) {
      return "MASTERCARD";
    }

    // Visa
    if (_.startsWith(ccnum, "4")) {
      return "VISA";
    }
    return false;
  }

  /**
         * @function CreditCardService.store
         * @description Stores the card.
         * @param card
         */
  store(card) {
    card.type = this.getCardType(card.number);
    card.number = card.number.slice(-4);
    card.masked = "XXXX XXXX XXXX " + card.number;
    card.label = card.type + " ending in " + card.number;
    this.cards.push(card);
  }
}
