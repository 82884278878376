import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CopyService } from 'src/app/services/copy.service';
import { RegistrationService } from 'src/app/services/registration.service';
import * as moment from 'moment';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DateUtilityServiceService } from 'src/app/services/date-utility-service.service';

@Component({
  selector: 'app-hotel-pref',
  templateUrl: './hotel-pref.component.html',
  styleUrls: ['./hotel-pref.component.scss'],
})
export class HotelPrefComponent implements OnInit {
  hotelArrivalDateBegin;
  hotelArrivalDateEnd;
  hotelDepartureDateBegin;
  hotelDepartureDateEnd;
  hotelCheckIn;
  hotelCheckOut;
  enrollment;
  constructor(
    private copyService: CopyService,
    private registrationService: RegistrationService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private dateService: DateUtilityServiceService
  ) {
    this.enrollment = registrationService.Enrollment;
    copyService.getCopyByKey('hotelArrivalDateBegin').then((data) => {
      this.hotelArrivalDateBegin = this.convertToDatePicker(data);
    });
    copyService.getCopyByKey('hotelArrivalDateEnd').then((data) => {
      this.hotelArrivalDateEnd = this.convertToDatePicker(data);
    });
    copyService.getCopyByKey('hotelDepartureDateBegin').then((data) => {
      this.hotelDepartureDateBegin = this.convertToDatePicker(data);
    });
    copyService.getCopyByKey('hotelDepartureDateEnd').then((data) => {
      this.hotelDepartureDateEnd = this.convertToDatePicker(data);
    });

    copyService.getCopyByKey('hotelArrivalDate').then((data) => {
      if (!this.enrollment.HotelCheckInDate) {
        this.enrollment.HotelCheckInDate = data;
      }
      //console.log(this.enrollment.HotelCheckInDate);
      this.hotelCheckIn = this.convertToDatePicker(
        this.enrollment.HotelCheckInDate
      );
      //console.log(this.hotelCheckIn);

    });

    copyService.getCopyByKey('hotelDepartureDate').then((data) => {
      if (!this.enrollment.HotelCheckOutDate) {
        this.enrollment.HotelCheckOutDate = data;
      }
       this.hotelCheckOut = this.convertToDatePicker(
         this.enrollment.HotelCheckOutDate
       );

    });
  }

  convertToDatePicker(datestring) {
    let dateobj = moment.utc(datestring);
    // console.log(dateobj)
    return {
      year: dateobj.year(),
      month: dateobj.month() + 1,
      day: dateobj.date(),
    };

    // return this.ngbDateParserFormatter.parse(datestring);
  }

  convertToRealDate(d) {
    return this.ngbDateParserFormatter.format(d) + 'T12:00:00-0500';;
  }

  updateModel(){
    this.enrollment.HotelCheckInDate = this.convertToRealDate(this.hotelCheckIn);
    this.enrollment.HotelCheckOutDate = this.convertToRealDate(this.hotelCheckOut);
    //console.log(this.enrollment);
  }

  format(date){
    return this.convertToRealDate(date);
  }

  ngOnInit(): void {}
}
