import { Component, Input } from '@angular/core';
import { ListManagerService } from '../../services/list-manager.service';
import { ControlContainer, NgForm } from '@angular/forms';
import { AddressService } from '../../services/address.service';
import { RegistrationService } from '../../services/registration.service';
import { PhoneService } from 'src/app/services/phone.service';
import { VariablesService } from 'src/app/services/variables.service';


@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class AddressComponent {

  @Input() resident: boolean;
  @Input() attendee: any;
  @Input() address: any;
  @Input() isRequired: any;
  @Input() isError: any;
  @Input() groupname: any;

  stateList: any;
  countryList: any;
  provinceList: any;
  tmnaQues;
  isRegComplete;
  varDeskLocation;


  constructor(
    private listService: ListManagerService,
    private addressService: AddressService,
    private registrationService: RegistrationService,
    private phoneService: PhoneService,
    private variablesService: VariablesService

  ) {
    this.listService.getStateList().then(list => {
      this.stateList = list;
    });
    this.listService.getCountryList().then(list => {
      this.countryList = list;
    });
    this.listService.getProvinceList().then(list => {
      this.provinceList = list;
    });

    this.attendee = this.registrationService.getAttendee({});
    this.isRegComplete = this.registrationService.isRegistrationComplete();

    this.varDeskLocation = this.registrationService.getVariable(this.registrationService.getAttendee({}), this.variablesService.VARIABLE.varDeskLocation.ID, {});


    if(this.attendee.PAXTypeNo===6 || this.attendee.PAXTypeNo===10 || this.attendee.PAXTypeNo===11 
      || this.attendee.PAXTypeNo===12 || this.attendee.PAXTypeNo===13){
         this.tmnaQues = true;
   }else{
         this.tmnaQues = false;
   }
  }

  

  addyChange(){
    if(this.attendee.$mtcPrefMailing === 'Yes'){
      this.address.AddressLine1 = "6565 Headquarters Drive";
      this.address.City = "Plano";
      this.address.StateProvince = "TX";
      this.address.Zip = "75024"; 
      this.attendee.$mtcAddyDisabled = true;
    }else{
      this.address.AddressLine1 = "";
      this.address.City = "";
      this.address.StateProvince = "";
      this.address.Zip = "";
      this.attendee.$mtcAddyDisabled = false;
    }
}


  countryChange(){
    this.setCountryOfResidence();
    if(this.address.MailTo){
      this.phoneService.triggeredChange();
    }
  }

  setCountryOfResidence(){
    if(this.resident){
      if(this.attendee.$mtcvarCountryOfRes){
        this.attendee.ResidenceCountryISOCode = this.address.CountryISOCode;
      }
    }
  }

  zipChange(zip) {
    if (this.isZipValidForUSA(zip.viewModel)) {
      this.addressService.getCityState(zip.viewModel).subscribe(data => {
        this.address.City = data.city;
        this.address.StateProvince = data.state;
      });
    }
  }

  isZipValidForUSA(zipCode) {
    if (zipCode && this.address.CountryISOCode==='US' ) {
      return /^[0-9]{5}$/.test(zipCode);
    }
    return false;
  }
}
