<div id="guest-travel" class="registration container">
  <form #guestTravelForm="ngForm" [ngClass]="{'form-complete': guestTravelForm.valid}" name="guestTravelForm"
    (ngSubmit)="forward(guestTravelForm)" novalidate autocomplete="off" appScrollToInvalid [panels]="panels"
    [ngClass]="{'form-complete': isFormCompleted}">
    <h1>{{ 'copy.label.GUESTTRAVEL.heading' | copy | async }}</h1>

    <mat-accordion class="example-headers-align mb-4" multi>
      <mat-expansion-panel *ngFor="let guest of guests; let i= index;" [ngClass]="{'active': guest.$mtcIsSelected}"
          class="mat-expanded" [expanded]="guest.$mtcIsSelected">

          <mat-expansion-panel-header>
            <mat-panel-title>
                <strong>{{getDisplayName(guest)}}</strong>
            </mat-panel-title>
            <mat-panel-description>
                &nbsp;
                <mat-icon></mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <article>

            <div class="w-100 required">
            <label>{{"copy.label.TRAVEL.guestTravel1"|copy|async}} {{getDisplayName(guest)}} {{"copy.label.TRAVEL.guestTravel2"|copy|async}}</label>
            <mat-radio-group class="w-100" [(ngModel)]="guest.TravelType" [disabled]="isRegComplete"
              #traveltype="ngModel" name="traveltype_{{guest.$mtcUUID}}" required>
              <mat-radio-button *ngFor="let op of travelTypes[i]" [value]="op.value" >
                {{op.display}}
              </mat-radio-button>
              <div class="w-100">
                <mat-error><app-validation [object]="traveltype" isRequired=true></app-validation></mat-error>
              </div>
            </mat-radio-group>
            </div>
          </article>
        <div class="w-100" *ngIf="guest.TravelType === Constants.TRAVEL_TYPES.FLY">
          <div class="travelLinksPrimaryAndGuest">
            <div class="w-100" [innerHTML]='"copy.html.AirInformationRT10" | copy | async'></div>
            <p class="w-100"><u><a (click)="openModal('TSAPrivacy', 'Security Requirement', 'Close')"><strong>TSA Privacy Notice</strong></a></u></p>
            <p class="w-100">US Citizens: New standards for state driver’s licenses and identification cards, set by the federal REAL ID Act, will be enforced.</p>
            <p class="w-100"><u><a class="responsibilityLink" (click)="openModal('RealIDAct', 'REAL ID Act', 'Close')"><strong>REAL ID Act</strong></a></u></p>
          </div>
          <mat-form-field class="w-100">
            <mat-label for="Prefix" aria-labelledby="Prefix">
              {{ 'copy.label.FEATURES.NAME.prefixlabel' | copy | async }}</mat-label>
            <mat-select name="Prefix_{{guest.$mtcUUID}}" id="Prefix" aria-label="Prefix" [(ngModel)]="guest.Prefix"
              #Prefix="ngModel" matNativeControl>
              <mat-option *ngFor="let obj of prefixList" [value]="obj.code">{{ obj.name }}</mat-option>
            </mat-select>
            <mat-error><app-validation [object]="Prefix" ></app-validation></mat-error>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label for="FirstName" aria-labelledby="FirstName" [ngClass]="{'active' : guest.FirstName}">
              {{ 'copy.label.FEATURES.NAME.first' | copy | async }}</mat-label>
            <input matInput name="FirstName_{{guest.$mtcUUID}}" id="FirstName" [attr.aria-label]="FirstName" type="text"
               maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="guest.FirstName"
              #FirstName="ngModel" required />
            <mat-error><app-validation [object]="FirstName" isRequired=true pattern="alpha"></app-validation></mat-error>
          </mat-form-field>

          <mat-form-field class="w-100" *ngIf="!guest.NoMiddleNameFlag">
            <mat-label for="MiddleName" aria-labelledby="MiddleName" [ngClass]="{'active' : guest.MiddleName}">
              {{ 'copy.label.FEATURES.NAME.middle' | copy | async }}</mat-label>
            <input matInput name="MiddleName_{{guest.$mtcUUID}}" id="MiddleName" [attr.aria-label]="MiddleName" type="text"
               maxlength="15" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="guest.MiddleName"
              #MiddleName="ngModel" required />
            <mat-error><app-validation [object]="MiddleName" isRequired=true pattern="alpha"></app-validation></mat-error>
            <span *ngIf="isRegComplete">
              {{guest.MiddleName}}
            </span>

          </mat-form-field>
          <div class="agreeCheckbox">
            <div class="checkboxFlexContainer">
              <mat-checkbox name="mtcHasNoMiddleName_{{guest.$mtcUUID}}" [(ngModel)]="guest.NoMiddleNameFlag" *ngIf="!isRegComplete"
              #mtcHasNoMiddleName="ngModel" [required]="!guest.MiddleName">
              </mat-checkbox>
              <label>I do not have a middle name or there is no middle name listed on my identification.</label>
            </div>
          </div>

          <mat-form-field class="w-100">
            <mat-label for="LastName" aria-labelledby="LastName" [ngClass]="{'active' : guest.LastName}">
              {{ 'copy.label.FEATURES.NAME.last' | copy | async }}</mat-label>
            <input matInput name="LastName_{{guest.$mtcUUID}}" id="LastName" [attr.aria-label]="LastName" type="text"
               maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="guest.LastName"
              #LastName="ngModel" required />
            <mat-error><app-validation [object]="LastName" isRequired=true pattern="alpha"></app-validation></mat-error>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label for="Suffix" aria-labelledby="Suffix">{{ 'copy.label.FEATURES.NAME.suffix' | copy | async }}
            </mat-label>
            <mat-select name="Suffix_{{guest.$mtcUUID}}" id="Suffix" aria-label="Suffix" [(ngModel)]="guest.Suffix"
              matNativeControl>
              <mat-option *ngFor="let obj of suffixList" [value]="obj.code">{{ obj.name }}</mat-option>
            </mat-select>
          </mat-form-field>

          <app-date *ngIf="!isChild(guest) && !isRegComplete" [participant]="guest" fieldName="BirthDate" [submitted]="isError"
            label="copy.label.TRAVEL.birthdate" [isRequired]="true" minDate="1901-01-02"
            maxDate="today"></app-date>
          <app-date *ngIf="isChild(guest) && !isRegComplete" [participant]="guest" fieldName="BirthDate" [submitted]="isError"
            label="copy.label.TRAVEL.birthdate" [isRequired]="true" [minDate]="minChildDate"
            maxDate="today"></app-date>

          <div class="w-100 required">
            <label>{{ 'copy.label.FEATURES.GENDER.heading' | copy | async }}</label>
            <div>
              <mat-radio-group class="w-100" [(ngModel)]="guest.Gender" [disabled]="isRegComplete"
                #gender="ngModel" name="gender_{{guest.$mtcUUID}}" required>
                <mat-radio-button *ngFor="let op of genderRadios" [value]="op.value">
                  {{op.display | async }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="w-100">
              <mat-error><app-validation [object]="gender" isRequired=true></app-validation></mat-error>
            </div>
          </div>

          <mat-form-field class="w-100">
            <mat-label for="RedressNumber" aria-labelledby="RedressNumber">
              {{ 'copy.label.FEATURES.TRAVELER.redress' | copy | async }}</mat-label>
            <input matInput name="RedressNumber_{{guest.$mtcUUID}}" id="RedressNumber" [attr.aria-label]="RedressNumber" type="text" pattern="^[\w\s!@\-\_:',.\/?]*"
              class="form-control" maxlength="25" [(ngModel)]="guest.RedressNumber" #RedressNumber="ngModel" />
            <mat-error><app-validation [object]="RedressNumber" isRequired=false pattern="alphanum"></app-validation></mat-error>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label for="KnownTravelerNumber" aria-labelledby="KnownTravelerNumber">
              {{ 'copy.label.FEATURES.TRAVELER.knownnum' | copy | async }}</mat-label>
            <input matInput name="KnownTravelerNumber_{{guest.$mtcUUID}}" id="KnownTravelerNumber"
              [attr.aria-label]="KnownTravelerNumber" type="text" pattern="^[\w\s!@\-\_:',.\/?]*" class="form-control" maxlength="25"
              [(ngModel)]="guest.KnownTravelerNumber" #KnownTravelerNumber="ngModel" />
            <mat-error><app-validation [object]="KnownTravelerNumber" isRequired=false pattern="alphanum"></app-validation></mat-error>
          </mat-form-field>
          <div class="agreeCheckbox">
            <div class="checkboxFlexContainer">
              <mat-checkbox name="otherNumber" [(ngModel)]="guest.$mtcOtherNumberBox" #otherNum="ngModel" (change)="otherNumChange(guest)">
                {{ 'copy.label.FEATURES.TRAVELER.otherNumber' | copy | async }}
              </mat-checkbox>
              <mat-error><app-validation [object]="otherNum" isRequired=false ></app-validation></mat-error>
            </div>
          </div>
          <div class="w-100 required">
            <label class="w-100 required">
              Is {{guest.FirstName || guest.PreferredFirstName}} traveling on the same itinerary as {{attendee.PreferredFirstName|| attendee.FirstName}}?
          </label>
            <mat-radio-group class="w-100" [(ngModel)]="guest.$mtcSameItinerary" required #sameitin="ngModel" name="sameitin_{{guest.$mtcUUID}}">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
              <div class="w-100">
                <mat-error><app-validation  [object]="sameitin" isRequired=true></app-validation></mat-error>
              </div>
            </mat-radio-group>
          </div>
          <div *ngIf="guest.$mtcSameItinerary === false">

            <section class="card">
              <div class="card-header">
                <div class="card-title">
                  {{ 'copy.label.TRAVEL.departHome' | copy | async }}
                </div>
              </div>
              <article>
                <div class="container">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="flightpref">
                        <div class="calendar-section">
                          <div class="calendar-container">
                            <p>
                              <strong>{{"copy.label.FEATURES.FLIGHTPREF.arrival"|copy | async}}</strong>{{groupArrivalDate| date: "fullDate": "-0500"}}
                            </p>
                            <p>
                              <strong>{{"copy.label.FEATURES.FLIGHTPREF.departure"|copy | async}}</strong>{{groupDepartureDate| date: "fullDate": "-0500"}}
                            </p>
                          </div>
                        </div>
                      </div>
                      <ngb-datepicker [firstDayOfWeek]=7 [minDate]="startDate" [maxDate]="endDate" [(ngModel)]="guest.$mtcOutboundDateTime" name="outboundDateTime_{{guest.$mtcUUID}}" (dateSelect)="updateModel()"></ngb-datepicker>
                    </div>
                    <div class="col-md-6">
                      <label>{{"copy.label.FEATURES.FLIGHTPREF.datelabel"|copy|async}}<span
                          class="normal">{{guest.$mtcOutbound.DepartureDateTime| date: "fullDate": "-0500"}}</span></label>
                      <p class="colorGrey marginBottom50"><i>{{"copy.label.TRAVEL.usecalendar" |copy|async}}</i></p>
                      <hr>
                      <app-flight-pref [direction]="'Outbound'" [attendee]="guest" [location]="guest.$mtcOutbound.DepartureCityLocation"></app-flight-pref>
                      <div class="w-100 required">
                        <label>{{ 'copy.label.FEATURES.FLIGHTPREF.timelabel' | copy | async }}</label>
                        <div>
                          <mat-radio-group [(ngModel)]="guest.$mtcOutbound.DepartureTimePreference" name="times_{{guest.$mtcUUID}}" aria-label="times"
                            #times="ngModel" id="times" matNativeControl required>
                            <mat-radio-button *ngFor="let op of travelTimes" [value]="op.code">{{op.name}}
                            </mat-radio-button>
                            <div class="w-100">
                              <mat-error><app-validation [object]="times" isRequired=true></app-validation></mat-error>
                            </div>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </section>

            <section class="card">
              <div class="card-header">
                <div class="card-title">
                  {{ 'copy.label.TRAVEL.departDest' | copy | async }}
                </div>
              </div>
              <article>
                <div class="container">
                  <div class="row">
                    <div class="col-md-6">
                      <ngb-datepicker [firstDayOfWeek]=7 [minDate]="startDateRet" [maxDate]="endDateRet" [(ngModel)]="guest.$mtcReturnDateTime" name="returnDateTime_{{guest.$mtcUUID}}" (dateSelect)="updateModel()"></ngb-datepicker>
                    </div>
                    <div class="col-md-6">
                      <label>{{"copy.label.FEATURES.FLIGHTPREF.datelabel"|copy|async}}<span
                        class="normal">{{guest.$mtcReturn.DepartureDateTime| date: "fullDate": "-0500"}}</span></label>
                    <p class="colorGrey marginBottom50"><i>{{"copy.label.TRAVEL.usecalendar" |copy|async}}</i></p>
                    <hr>
                    <app-flight-pref [direction]="'Return'" [attendee]="guest" [location]="guest.$mtcReturn.DepartureCityLocation"></app-flight-pref>
                      <!-- <mat-form-field class="w-100">
                        <mat-label for="DepartureCityLocation" aria-labelledby="DepartureCityLocation"
                          [ngClass]="{'active' : guest.$mtcReturn.DepartureCityLocation}">
                          {{ 'copy.label.FEATURES.FLIGHTPREF.airportlabel' | copy | async }}</mat-label>
                        <input matInput name="DepartureCityLocation" id="DepartureCityLocation"
                          [attr.aria-label]="DepartureCityLocation" type="text"  maxlength="60"
                          pattern="[a-zA-Z ]+" [(ngModel)]="guest.$mtcReturn.DepartureCityLocation" #DepartureCityLocation="ngModel"
                          required />
                        <mat-error><app-validation [object]="DepartureCityLocation" isRequired=true pattern="alpha"></app-validation></mat-error>
                      </mat-form-field> -->
                      <div class="w-100 required">
                        <label>{{ 'copy.label.FEATURES.FLIGHTPREF.timelabel' | copy | async }}</label>
                        <div>
                          <mat-radio-group [(ngModel)]="guest.$mtcReturn.DepartureTimePreference" name="timesFlightPref_{{guest.$mtcUUID}}"
                            aria-label="timesFlightPref" #timesFlightPref="ngModel" id="timesFlightPref" matNativeControl
                            required>
                            <mat-radio-button *ngFor="let op of travelTimes" [value]="op.code">{{op.name}}
                            </mat-radio-button>
                            <div class="w-100">
                              <mat-error><app-validation [object]="timesFlightPref" isRequired=true></app-validation></mat-error>
                            </div>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </section>
          </div>
          <section class="card">
            <div class="card-header">
              <div class="card-title">
                {{ 'copy.label.TRAVEL.flightprefheading' | copy | async }}
              </div>
            </div>
            <article>
              <div class="w-100 required">
                <label>{{ 'copy.label.FEATURES.SEATPREF.heading' | copy | async }}</label>
                <div>
                  <mat-radio-group class="w-100" [(ngModel)]="guest.InterRowPosition" [disabled]="isRegComplete"
                    #interRow="ngModel" name="interRow_{{guest.$mtcUUID}}" required>
                    <mat-radio-button *ngFor="let op of flightPrefRadios" [value]="op.value">
                      {{op.display | async }}
                    </mat-radio-button>
                    <div class="w-100">
                      <mat-error><app-validation [object]="interRow" isRequired=true></app-validation></mat-error>
                    </div>
                  </mat-radio-group>
                </div>
              </div>
            </article>
          </section>
          <section class="card">
            <div class="card-header">
              <div class="card-title">
                {{ 'copy.label.TRAVEL.ffheading' | copy | async }}
              </div>
            </div>
            <article>
              <div id="frequent-flyer" class="w-100">
                <div *ngFor="let flyer of guest.FrequentFlyer; let x = index">
                  <hr>
                  <mat-form-field class="w-100">
                    <mat-label for="airlineCode" aria-labelledby="airlineCode">
                      {{ 'copy.label.FEATURES.FREQFLY.ffairlinelabel' | copy | async }}
                    </mat-label>
                    <mat-select name="airlineCode{{x}}_{{guest.$mtcUUID}}" id="airlineCode" aria-label="airlineCode"
                      [(ngModel)]="flyer.AirlineCode" #airlineCode="ngModel" required matNativeControl>
                      <mat-option *ngFor="let o of filterAirlines(airlineList, flyer.AirlineCode, guest)" [value]="o.code">{{o.name}}</mat-option>
                    </mat-select>
                    <mat-error><app-validation [object]="airlineCode" isRequired=true></app-validation></mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label for="AccountNo" aria-labelledby="AccountNo" [ngClass]="{'active' : flyer.AccountNo}">
                      {{ 'copy.label.FEATURES.FREQFLY.ffaccountlabel' | copy | async }}</mat-label>
                    <input matInput name="AccountNo{{x}}_{{guest.$mtcUUID}}" id="AccountNo" [attr.aria-label]="AccountNo" type="text"
                      class="form-control" maxlength="17"  [(ngModel)]="flyer.AccountNo"
                      #AccountNo="ngModel" required pattern="^[\w\s!@\-\_:',.\/?]*"/>
                    <mat-error><app-validation [object]="AccountNo" isRequired=true pattern="alphanum"></app-validation></mat-error>
                  </mat-form-field>
                  <div *ngIf="guest.FrequentFlyer.length > 0">
                    <button type="button" class="remove-frequent-flyer btn btn-danger" (click)="removeFrequentFlyer(guest, flyer)">
                      <i class="fas fa-minus-circle"></i> {{"copy.label.FEATURES.FREQFLY.remove"|copy|async}}
                    </button>
                  </div>
                  <hr>
                </div>

                <button type="button" class="add-frequent-flyer btn btn-success" (click)="addFrequentFlyer(guest)">
                  <i class="fas fa-plus-circle"></i> {{"copy.label.FEATURES.FREQFLY.add" | copy | async}}
                </button>
              </div>
            </article>
          </section>

        </div>

        <section class="card" *ngIf="guest.TravelType === Constants.TRAVEL_TYPES.RAIL">
          <div class="card-header">
            <div class="card-title">
              {{ 'copy.label.TRAVEL.departHome' | copy | async }}
            </div>
          </div>
          <article>
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <div class="flightpref">
                    <div class="calendar-section">
                      <div class="calendar-container">
                        <p>
                          <strong>{{"copy.label.FEATURES.FLIGHTPREF.arrival"|copy | async}}</strong>{{groupArrivalDate| date: "fullDate": "-0500"}}
                        </p>
                        <p>
                          <strong>{{"copy.label.FEATURES.FLIGHTPREF.departure"|copy | async}}</strong>{{groupDepartureDate| date: "fullDate": "-0500"}}
                        </p>
                      </div>
                    </div>
                  </div>

                  <ngb-datepicker [firstDayOfWeek]=7 [minDate]="startDate" [maxDate]="endDate" [(ngModel)]="guest.$mtcOutboundDateTime" name="outboundDateTime_{{guest.$mtcUUID}}" (dateSelect)="updateModel()"></ngb-datepicker>

                </div>
                <div class="col-md-6">
                  <label>{{"copy.label.FEATURES.FLIGHTPREF.datelabel"|copy|async}}<span
                      class="normal">{{guest.$mtcOutbound.DepartureDateTime| date: "fullDate": "-0500"}}</span></label>
                  <p class="colorGrey marginBottom50"><i>{{"copy.label.TRAVEL.usecalendar" |copy|async}}</i></p>
                  <hr>
                  <mat-form-field class="w-100">
                    <mat-label for="OutboundDepartureCityLocation" aria-labelledby="DepartureCityLocation"
                      [ngClass]="{'active' : guest.$mtcOutbound.DepartureCityLocation}">
                      {{ 'copy.label.FEATURES.FLIGHTPREF.airportlabel' | copy | async }}</mat-label>
                    <input matInput name="DepartureCityLocation_{{guest.$mtcUUID}}" id="DepartureCityLocation"
                      [attr.aria-label]="DepartureCityLocation" type="text"  maxlength="60"
                      pattern="[a-zA-Z ]+" [(ngModel)]="guest.$mtcOutbound.DepartureCityLocation" #DepartureCityLocation="ngModel"
                      required />
                    <mat-error><app-validation [object]="DepartureCityLocation" isRequired=true pattern="alpha"></app-validation></mat-error>
                  </mat-form-field>
                  <div class="w-100 required">
                    <label>{{ 'copy.label.FEATURES.FLIGHTPREF.timelabel' | copy | async }}</label>
                    <div>
                      <mat-radio-group [(ngModel)]="guest.$mtcOutbound.DepartureTimePreference" name="times_{{guest.$mtcUUID}}" aria-label="times"
                        #times="ngModel" id="times" matNativeControl required>
                        <mat-radio-button *ngFor="let op of travelTimes" [value]="op.code">{{op.name}}
                        </mat-radio-button>
                        <div class="w-100">
                          <mat-error><app-validation [object]="times" isRequired=true></app-validation></mat-error>
                        </div>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>

        <section class="card" *ngIf="guest.TravelType === Constants.TRAVEL_TYPES.RAIL">
          <div class="card-header">
            <div class="card-title">
              {{ 'copy.label.TRAVEL.departDest' | copy | async }}
            </div>
          </div>
          <article>
            <article>
              <div class="container">
                <div class="row">
                  <div class="col-md-6">
                    <ngb-datepicker [firstDayOfWeek]=7 [minDate]="startDateRet" [maxDate]="endDateRet" [(ngModel)]="guest.returnDateTime" name="returnDateTime_{{guest.$mtcUUID}}" (dateSelect)="updateModel()"></ngb-datepicker>
                  </div>
                  <div class="col-md-6">
                    <label>{{"copy.label.FEATURES.FLIGHTPREF.datelabel"|copy|async}}<span
                      class="normal">{{guest.$mtcReturn.DepartureDateTime| date: "fullDate": "-0500"}}</span></label>
                  <p class="colorGrey marginBottom50"><i>{{"copy.label.TRAVEL.usecalendar" |copy|async}}</i></p>
                  <hr>
                    <mat-form-field class="w-100">
                      <mat-label for="DepartureCityLocation" aria-labelledby="DepartureCityLocation"
                        [ngClass]="{'active' : guest.$mtcReturn.DepartureCityLocation}">
                        {{ 'copy.label.FEATURES.FLIGHTPREF.airportlabel' | copy | async }}</mat-label>
                      <input matInput name="ReturnDepartureCityLocation_{{guest.$mtcUUID}}" id="DepartureCityLocation"
                        [attr.aria-label]="DepartureCityLocation" type="text"  maxlength="60"
                        pattern="[a-zA-Z ]+" [(ngModel)]="guest.$mtcReturn.DepartureCityLocation" #DepartureCityLocation="ngModel"
                        required />
                      <mat-error><app-validation [object]="DepartureCityLocation" isRequired=true pattern="alpha"></app-validation></mat-error>
                    </mat-form-field>

                    <div class="w-100 required">
                      <label>{{ 'copy.label.FEATURES.FLIGHTPREF.timelabel' | copy | async }}</label>
                      <div>
                        <mat-radio-group [(ngModel)]="guest.$mtcReturn.DepartureTimePreference" name="timesFlightPref_{{guest.$mtcUUID}}"
                          aria-label="timesFlightPref" #timesFlightPref="ngModel" id="timesFlightPref" matNativeControl
                          required>
                          <mat-radio-button *ngFor="let op of travelTimes" [value]="op.code">{{op.name}}
                          </mat-radio-button>
                          <div class="w-100">
                            <mat-error><app-validation [object]="timesFlightPref" isRequired=true></app-validation></mat-error>
                          </div>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </article>
          </article>
        </section>

        <section class="card" *ngIf="guest.TravelType === Constants.TRAVEL_TYPES.OWN_AIR">
          <div class="card-header">
            <div class="card-title">
              {{ 'copy.label.TRAVEL.ownairHeader' | copy | async }}
            </div>
          </div>
          <article>
            <div [innerHTML]='"copy.html.ownair" | copy | async'></div>
          </article>
        </section>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="button_group d-flex">
      <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()"
        *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.goback' | copy | async }}
      </button>
      <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.continue' | copy | async }}
      </button>
      <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
        <i class="fas fa-spinner fa-spin"></i>
      </button>
    </div>
  </form>
</div>
