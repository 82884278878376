import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { EnrollmentService } from 'src/app/services/enrollment.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {
  enrollment;
  error;
  success;
  token;
  progkey;
  submitting = false;
  submitError = false;
  submitSuccess = false;
  constructor(private registrationService: RegistrationService,
    private auth: AuthenticationService,
    private envService: EnvironmentService,
    private enrollmentService: EnrollmentService) {
    this.enrollment = registrationService.getEnrollment({});
    this.token = this.auth.authtoken;
    this.progkey = this.envService.getProgramKey();
   }

  ngOnInit(): void {
  }

  setModel(event){
    try{
      let parsed = JSON.parse(event.target.value);
      this.registrationService.Enrollment = parsed;
      this.enrollment = this.registrationService.getEnrollment({});
      console.log(parsed.$mtchotelSpecialRequests);
      this.success = true;
    }catch(e){
      this.error=true;
    }
  }
  clear(){
    this.error = false;
    this.success = false;
    this.submitError = false;
    this.submitSuccess = false;
  }

  log(){
    console.log(this.enrollment);
  }

  logCleanse(){
    console.log("token",this.auth.authtoken);
    console.log("progkey",this.envService.getProgramKey());
    console.log(this.registrationService.cleanse(this.enrollment));
  }

  overrideProgramKey(){
    this.envService.overloadprogramkey = this.progkey;
  }

  overrideToken(){
    this.auth.authtoken = this.token;
  }

  submit(){
    this.submitting = true;
    this.clear();
    this.enrollmentService.createEnrollment(this.enrollment).then(data=>{
      this.submitting = false;
      this.submitSuccess = true;
    },err=>{
      this.submitting = false;
      this.submitError = true;
    });
  }
}
