<div id="invitation" class="registration container">
  <form ng-class="{'form-complete': invitationCtrl.isFormCompleted}" name="invitationForm" (ngSubmit)="forward()" novalidate autocomplete="off" appScrollToInvalid [ngClass]="{'form-complete': isFormCompleted}">
    <h1>{{ 'copy.label.INVITATION.header' | copy | async }}</h1>
    <!-- <mtc-alert></mtc-alert> -->
    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.INVITATION.inviteQuestion' | copy | async }}
          <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <div class="card-body">
          <div class="radio-group w-100">
            <div class="w-100 required">
              <mat-radio-group [(ngModel)]="invitation.isAttending" #attending="ngModel" name="attending" id="attending" required>
                <mat-radio-button [value]="true">Yes</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
                <div class="w-100">
                  <mat-error><app-validation [object]="attending" isRequired=true></app-validation></mat-error>
                </div>
              </mat-radio-group>
            </div>
          </div>
        </div>
      </article>
    </section>
    <section class="card" *ngIf="invitation.isAttending === false">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.INVITATION.reasonQuestion' | copy | async }}
          <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <div class="card-body">
          <div class="radio-group w-100">
            <mat-radio-group class="w-100" [(ngModel)]="invitation.declineOption" name="declineOption" #declineOption="ngModel" required>
              <mat-radio-button *ngFor="let op of declineRadios" [value]="op.value">{{op.display|async}}</mat-radio-button>
              <div class="w-100">
                <mat-error><app-validation [object]="declineOption" isRequired=true></app-validation></mat-error>
              </div>
            </mat-radio-group>
          </div>
          <div class="d-flex justify-content-start flex-column w-100" *ngIf="invitation.declineOption === 'other'">
            <mat-form-field class="w-100 mb-0">
              <mat-label for="declineComment" aria-labelledby="declineComment">{{"copy.label.INVITATION.description"|copy |async}}</mat-label>
              <textarea matInput class="w-100" name="declineComment" id="declineComment" aria-label="declineComment" [(ngModel)]="invitation.declineComment" maxlength="225" #declineComment="ngModel" required></textarea>
            </mat-form-field>
            <div class="w-100">
              <mat-error><app-validation [object]="declineComment" isRequired=true pattern="alpha"></app-validation></mat-error>
            </div>
          </div>
        </div>
      </article>
    </section>
    <div class="button_group d-flex">
      <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.goback' | copy | async }}
      </button>
      <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.continue' | copy | async }}
      </button>
      <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
        <i class="fas fa-spinner fa-spin"></i>
      </button>
    </div>
  </form>
</div>
