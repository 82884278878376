<fieldset [ngModelGroup]="groupname">
    <!-- Country -->
    <mat-form-field class="w-100">
        <mat-label for="countryISOCode" aria-labelledby="countryISOCode">
            {{ 'copy.label.FEATURES.ADDRESS.countrylabel' | copy | async }}</mat-label>
        <mat-select name="countryISOCode" id="countryISOCode" aria-label="countryISOCode"
            [(ngModel)]="address.CountryISOCode" #Country="ngModel" [required]="isRequired" matNativeControl (ngModelChange)="countryChange()">
            <mat-option *ngFor="let obj of countryList" [value]="obj.code">
                {{ obj.name }}
            </mat-option>
        </mat-select>
        <app-validation [object]="Country" [isRequired]=isRequired></app-validation>
    </mat-form-field>
    <!-- Resident Country -->
    <div class="w-100 required" *ngIf="resident">
        <mat-label aria-labelledby="varCountryYesNo" for="varCountryYesNo">
            {{ 'copy.label.FEATURES.ADDRESS.countryofres' | copy | async }}</mat-label>
        <mat-radio-group [(ngModel)]="attendee.$mtcvarCountryOfRes" name="varCountryYesNo_{{address.AddressType}}"
            #varCountryYesNo="ngModel" matNativeControl [required]="isRequired"
            (ngModelChange)="setCountryOfResidence()">
            <mat-radio-button value="Yes">Yes</mat-radio-button>
            <mat-radio-button value="No">No</mat-radio-button>
            <div class="w-100">
                <app-validation [object]="varCountryYesNo" [isRequired]=isRequired></app-validation>
            </div>
        </mat-radio-group>
    </div>
    <mat-form-field class="w-100"
        *ngIf="(attendee.$mtcvarCountryOfRes === 'No')">
        <mat-label for="ResidenceCountryISOCode" aria-labelledby="ResidenceCountryISOCode">
            {{ 'copy.label.FEATURES.ADDRESS.countryofreslist' | copy | async }}</mat-label>
        <mat-select name="ResidenceCountryISOCode_{{address.AddressType}}" id="ResidenceCountryISOCode"
            aria-label="ResidenceCountryISOCode" [(ngModel)]="attendee.ResidenceCountryISOCode"
            #ResidenceCountryISOCode="ngModel" [required]="isRequired" matNativeControl>
            <mat-option *ngFor="let obj of countryList" [value]="obj.code">
                {{ obj.name }}
            </mat-option>
        </mat-select>
        <app-validation [object]="ResidenceCountryISOCode" [isRequired]=isRequired></app-validation>
    </mat-form-field>

    <!-- Custom: Is your preferred business mailing address TMNA in Plano, TX? -->
    <div class="w-100 required" *ngIf="tmnaQues">
        <mat-label aria-labelledby="prefMailing" for="prefMailing">
            {{ 'copy.label.FEATURES.ADDRESS.prefMailing' | copy | async }} </mat-label>
        <mat-radio-group [(ngModel)]="attendee.$mtcPrefMailing" name="prefMailing"
            #prefMailing="ngModel" matNativeControl [required]="isRequired" 
            (ngModelChange)="addyChange()">
            <mat-radio-button value="Yes">Yes</mat-radio-button>
            <mat-radio-button value="No">No</mat-radio-button>
            <div class="w-100">
                <app-validation [object]="prefMailing" [isRequired]=isRequired></app-validation>
            </div>
        </mat-radio-group>
    </div>

    <!-- Address Line 1 -->
    <mat-form-field class="w-100 mb-0">
        <mat-label for="AddressLine1" aria-labelledby="AddressLine1" [ngClass]="{'active' : address.AddressLine1}">
            {{ 'copy.label.FEATURES.ADDRESS.line1label' | copy | async }}</mat-label>
        <input matInput name="AddressLine1" id="AddressLine1" [disabled]="attendee.$mtcAddyDisabled" aria-label="AddressLine1" class="form-control" type="text"
            maxlength="40" pattern="^[\w\s!@\-\_\\:',.\/?]*" [(ngModel)]="address.AddressLine1" #addressLine1="ngModel"
            [required]="isRequired" />
        <app-validation [object]="addressLine1" [isRequired]=isRequired pattern="alphanum"></app-validation>
    </mat-form-field>

    <!-- Address Line 2 -->
    <mat-form-field class="w-100 mt-0">
        <mat-label for="AddressLine2" aria-labelledby="AddressLine2" [ngClass]="{'active' : address.AddressLine2}">
            {{ 'copy.label.FEATURES.ADDRESS.line2label' | copy | async }}</mat-label>
        <input matInput name="AddressLine2" id="AddressLine2" [disabled]="attendee.$mtcAddyDisabled" aria-label="AddressLine2" class="form-control" type="text"
            maxlength="40" pattern="^[\w\s!@\-\_\\:',.\/?]*" [(ngModel)]="address.AddressLine2" #addressLine2="ngModel" />
        <app-validation [object]="addressLine2" [isRequired]=false pattern="alphanum"></app-validation>
    </mat-form-field>

    <!-- Zip -->
    <mat-form-field class="w-100" *ngIf="address.CountryISOCode === 'US'" >
        <mat-label for="Zip" aria-labelledby="Zip" [ngClass]="{'active' : address.Zip}">
            {{ 'copy.label.FEATURES.ADDRESS.ziplabel' | copy | async }}</mat-label>
        <input matInput name="Zip" id="Zip" [disabled]="attendee.$mtcAddyDisabled" aria-label="Zip" class="form-control" type="text" maxlength="14"
            pattern="^[0-9]{5}$" [(ngModel)]="address.Zip" #zip="ngModel" [required]="isRequired"
            (ngModelChange)="zipChange(zip)" />
        <app-validation [object]="zip" [isRequired]=isRequired pattern="uszip"></app-validation>
    </mat-form-field>
    <mat-form-field class="w-100" *ngIf="address.CountryISOCode === 'CA'" >
        <mat-label for="Zip" aria-labelledby="Zip" [ngClass]="{'active' : address.Zip}">
            {{ 'copy.label.FEATURES.ADDRESS.ziplabel' | copy | async }}</mat-label>
        <input matInput name="Zip" id="Zip" aria-label="Zip" class="form-control" type="text" maxlength="14"
            pattern="^([a-zA-Z][0-9][a-zA-Z]){1}[ ]{0,1}([0-9][a-zA-Z][0-9]){1}$" [(ngModel)]="address.Zip" #zip="ngModel" [required]="isRequired"
            (ngModelChange)="zipChange(zip)" />
        <app-validation [object]="zip" [isRequired]=isRequired pattern="cazip"></app-validation>
    </mat-form-field>

    <mat-form-field class="w-100" *ngIf="address.CountryISOCode !== 'CA' && address.CountryISOCode !== 'US'">
        <mat-label for="Zip" aria-labelledby="Zip" [ngClass]="{'active' : address.Zip}">
            {{ 'copy.label.FEATURES.ADDRESS.ziplabel' | copy | async }}</mat-label>
        <input matInput name="Zip" id="Zip" aria-label="Zip" class="form-control" type="text" maxlength="14"
        pattern="^[\w\s!@\-\_\\:',.\/?]*" [(ngModel)]="address.Zip" #zip="ngModel" (ngModelChange)="zipChange(zip)" />
        <app-validation [object]="zip" pattern="integer"></app-validation>
    </mat-form-field>

    <!-- City -->
    <mat-form-field class="w-100">
        <mat-label for="City" aria-labelledby="City" [ngClass]="{'active' : address.City}">
            {{ 'copy.label.FEATURES.ADDRESS.citylabel' | copy | async }}</mat-label>
        <input matInput name="City" id="City" [disabled]="attendee.$mtcAddyDisabled" aria-label="City" class="form-control" type="text" maxlength="25"
        pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="address.City" #city="ngModel" [required]="isRequired" />
        <app-validation [object]="city" [isRequired]=isRequired pattern="alpha"></app-validation>
    </mat-form-field>

    <!-- State -->
    <div *ngIf="address.CountryISOCode === 'US'">
        <mat-form-field class="w-100 required">
            <mat-label for="state" aria-labelledby="state">
                {{ 'copy.label.FEATURES.ADDRESS.USstatelabel' | copy | async }}</mat-label>
            <mat-select name="state" [disabled]="attendee.$mtcAddyDisabled" [(ngModel)]="address.StateProvince" #stateProvince="ngModel"
                [required]="isRequired" matNativeControl>
                <mat-option *ngFor="let obj of stateList" [value]="obj.code">
                    {{obj.name}}</mat-option>
            </mat-select>
            <app-validation [object]="stateProvince" [isRequired]=isRequired></app-validation>
        </mat-form-field>
    </div>
    <div *ngIf="address.CountryISOCode === 'CA'">
        <mat-form-field class="w-100 required">
            <mat-label for="state" aria-labelledby="state">
                {{ 'copy.label.FEATURES.ADDRESS.CAstatelabel' | copy | async }}</mat-label>
            <mat-select name="state" [(ngModel)]="address.StateProvince" #stateProvince="ngModel"
                [required]="isRequired" matNativeControl>
                <mat-option *ngFor="let obj of provinceList" [value]="obj.code">
                    {{obj.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <app-validation [object]="stateProvince" [isRequired]=isRequired></app-validation>
    </div>
    <mat-form-field class="w-100" *ngIf="address.CountryISOCode !== 'CA' && address.CountryISOCode !== 'US'">
        <mat-label for="State" aria-labelledby="State" [ngClass]="{'active' : address.StateProvince}">
            {{ 'copy.label.FEATURES.ADDRESS.OTHstatelabel' | copy | async }}</mat-label>
        <input matInput name="State" id="State" aria-label="State" class="form-control" type="text" maxlength="25"
        pattern="^[\w\s!@\-\_\\:',.\/?]*" [(ngModel)]="address.StateProvince" #stateProvince="ngModel"
             />

        <app-validation [object]="stateProvince" pattern="alphanum"></app-validation>
    </mat-form-field>

    <div *ngIf="attendee.$mtcPrefMailing === 'Yes'">
        <mat-form-field class="w-100" >
            <mat-label for="deskLocation" aria-labelledby="deskLocation">Provide your desk location <i>(Example: E1-4B-12h)</i></mat-label>
            <input matInput name="deskLocation" required [(ngModel)]="varDeskLocation.Value" class="form-control deskLocation" type="text" maxlength="10"/>
        </mat-form-field>
    </div>

</fieldset>

