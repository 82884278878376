<mat-form-field *ngIf="!isinternational()" class="w-100">
  <mat-label
    (for)="groupname"
    (aria-labelledby)="groupname">{{ label | copy | async }}</mat-label>
  <fieldset  [ngModelGroup]="groupname">
    <div class="w-100" >
      <input
        matInput
        name="natPhoneNo"
        id="natPhoneNo"
        type="text"
        [inputMask]="phoneMask"
        placeholder="(___)___-____"
        [(ngModel)]="phone.$mtcPhoneNoMasked"
        #natPhone
        #natPhoneNo="ngModel"         
        (change)="maskConvert()"
        minlength="10"
        [required]="isRequired === 'true'"
      />
    </div>
  </fieldset>
  <mat-error><app-validation  [object]="natPhoneNo" isRequired="true" pattern="phonenum"></app-validation></mat-error>
</mat-form-field>
<div class="intlphone" *ngIf="isinternational()">
  <label>{{ label | copy | async }}</label>
<mat-grid-list [cols]="breakpoint" (window:resize)="calculateSize($event)" rowHeight="110px">
  <mat-grid-tile colspan="1" class="form-tile">
    <mat-form-field >
      <fieldset [ngModelGroup]="groupname">
          <input
          matInput
          name="phonecode"
          id="phonecode"
          [attr.aria-label]="phonecode"

          type="tel"
          size="5"
          maxlength="4"
          [(ngModel)]="phone.CountryCode"
          [required]="isRequired === 'true'"
          #phonecode="ngModel"
          pattern="[0-9]+"
          placeholder="Country Code"
        />
      </fieldset>
      <mat-error><app-validation  [object]="phonecode" isRequired="true" pattern="integer"></app-validation></mat-error>
    </mat-form-field>
  </mat-grid-tile>
  <mat-grid-tile colspan="1" class="form-tile">
    <mat-form-field >
      <fieldset [ngModelGroup]="groupname">
          <input
              matInput
              name="phonearea"
              id="phonearea"
              [attr.aria-label]="phonearea"

              type="tel"
              size="5"
              maxlength="5"
              [(ngModel)]="phone.AreaCityCode"
              #phonearea="ngModel"
              pattern="[0-9]+"
              placeholder="Area Code"
            />
      </fieldset>
      <mat-error><app-validation  [object]="phonearea" isRequired="false" pattern="integer"></app-validation></mat-error>
    </mat-form-field>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="phonesize" class="form-tile">
    <mat-form-field >
      <fieldset [ngModelGroup]="groupname">
        <input
            matInput
            name="phoneno"
            id="phoneno"
            [attr.aria-label]="phoneno"

            type="tel"
            maxlength="20"
            [(ngModel)]="phone.PhoneNo"
            [required]="isRequired === 'true'"
            #phoneno="ngModel"
            pattern="[0-9]+"
            placeholder="Phone Number"
          />
        </fieldset>
        <mat-error><app-validation  [object]="phoneno" isRequired="true" pattern="integer"></app-validation></mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>

</div>
