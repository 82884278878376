<div id="outer_container" *ngIf="retrieved">
  <app-ie-not-supported></app-ie-not-supported>
  <div id="header" class="header">
    <app-banner></app-banner>
  </div>
  <div *ngIf="isDev()" class="stickyButton">
    <button mat-raised-button (click)="showCopy()">Show Copy</button>
  </div>
  <!--[if IE]>
    <div class="col-md-12">
      <h1>Did you know that your Internet Explorer is out of date?</h1>
      <p>
        To get the best possible experience using our website we recommend that
        you upgrade to a newer version or try another web browser. A list of the
        most popular web browsers we support can be found below.
      </p>
      <h2>Just click on the the icons to get to the download page</h2>
      <article>
        <ul class="redir-list">
          <li class="col-md-3 col-sm-12 text-center">
            <a
              href="https://www.google.com/chrome/browser/"
              class="browser-icon text-center"
            >
              <img src="./images/browsers/chrome_256x256.png" />
            </a>
          </li>
          <li class="col-md-3 col-sm-12 text-center">
            <a
              href="https://www.mozilla.org/en-US/firefox/new/"
              class="browser-icon text-center"
            >
              <img src="./images/browsers/firefox_256x256.png" />
            </a>
          </li>
          <li class="col-md-3 col-sm-12 text-center">
            <a
              href="http://windows.microsoft.com/en-us/internet-explorer/download-ie"
              class="browser-icon text-center"
            >
              <img src="./images/browsers/internet-explorer_256x256.png" />
            </a>
          </li>
          <li class="col-md-3 col-sm-12 text-center">
            <a
              href="http://support.apple.com/downloads/#safari"
              class="browser-icon text-center"
            >
              <img src="./images/browsers/safari_256x256.png" />
            </a>
          </li>
        </ul>
        <p>
          If you are unable to upgrade or use an alternate browser, please
          <a href="service/iecontactus">contact us</a>.
        </p>
      </article>
    </div>
  <![endif]-->
  <div class="navContainer" ui-view="navigation"></div>
  <div class="container-fluid p-0">
    <div id="progress-cart-container">
      <div ui-view="progress"></div>
      <div ui-view="cart"></div>
    </div>
    <div ui-view="roundProgress"></div>
  </div>
  <div class="container-fluid p-0">
    <main>
    <div class="pageTemplateContainer">
      <router-outlet></router-outlet>
    </div>
    </main>
  </div>
</div>
<app-scroll-to-top></app-scroll-to-top>
<app-footer *ngIf="retrieved"></app-footer>
