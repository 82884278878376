<!-- <div class="form-inline">
    <div class="form-group">
      <label>all items are approved</label>
      <input  [value]="true" type="radio" [ngModel]="isApproved">
    </div>

    <div class="form-group">
        <label>none of items are approved</label>
        <input  [value]="false" type="radio"  [ngModel]="isApproved">
    </div>

  </div> -->

<div>
<input id="Yes" type="radio" value="Yes" name="yesNo" formControlName="yesNo">
<label for="Yes">Yes</label>
</div>

<div>
<input id="No" type="radio" value="No" name="yesNo" formControlName="yesNo">
<label for="No">No</label>
</div>

<!-- <div *ngIf="g.yesNo.touched && g.yesNo.invalid" class="alert alert-danger">
<div *ngIf="g.yesNo.errors.required">Name is required.</div>
</div> -->