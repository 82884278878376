import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class PersonalPreferenceService {

    constructor(private Constants: ConstantsService) { }

    /**
      * @function PersonalPreferenceService.isNeedingDietaryConsiderations
      * @description Returns boolean based on needing dietary considerations or not.
      * @param mealPreference
      * @returns {Boolean}
      */
    isNeedingDietaryConsiderations(mealPreference) {
        return mealPreference && (mealPreference === this.Constants.DIETS.OTHER || mealPreference === this.Constants.DIETS.ALLERGY);
    }

    /**
      * @function PersonalPreferenceService.isPersonalConsiderationsVisible
      * @description Determines if personal considerations are visible.
      * @param types
      * @returns {Boolean}
      */
    isPersonalConsiderationsVisible(types) {
        return types && (types.indexOf(this.Constants.DISABILITIES.OTHER) >= 0);
    }

    /**
      * @function PersonalPreferenceService.commitConsiderationsChanges
      * @description Commits the consideration changes, or resets them if no preferences are given but they are selected.
      * @param attendeez
      */
    commitConsiderationsChanges(attendeez) {
        if (!attendeez) {
            return;
        }

        //if (angular.isArray(attendeez)) {
        if (Array.isArray(attendeez)) {
            attendeez.map((e)=> {
                this.commitConsiderationsChanges(e);
            });
            return;
        }

        if (attendeez.$mtcHasConsiderations === false) {
            attendeez.DisabilityType = [];
            attendeez.PersonalConsiderations = undefined;
            attendeez.DietaryNeed = undefined;
            attendeez.DietaryConsiderations = undefined;
        }
        else { // set $mtcHasConsiderations to false if attendee answers 'Y' but select no meal preference/personal consideration
            if (!this.hasConsiderations(attendeez)) {
                attendeez.$mtcHasConsiderations = false;
            }

            if (attendeez.$mtcHasConsiderations) {
                if (attendeez.DisabilityType.length > 0) {
                    if (!(_.includes(attendeez.DisabilityType, this.Constants.DISABILITIES.OTHER))) {
                        attendeez.PersonalConsiderations = undefined;
                    }
                }
                else {
                    attendeez.PersonalConsiderations = undefined;
                }
                if (typeof attendeez.DietaryNeed === "undefined") {
                    attendeez.DietaryConsiderations = undefined;
                }
                else if (!(_.includes(attendeez.DietaryNeed, this.Constants.DIETS.OTHER)) && !(_.includes(attendeez.DietaryNeed, this.Constants.DIETS.ALLERGY))) {
                    attendeez.DietaryConsiderations = undefined;
                }
            }

        }

    }

    /**
      * @function PersonalPreferenceService.hasConsiderations
      * @description Returns whether attendee contains any considerations.
      * @param attendee
      * @returns {Boolean}
      */
    hasConsiderations(attendee) {
        //if (!attendee || angular.isUndefined(attendee.$mtcHasConsiderations)) {
        if (!attendee || attendee.$mtcHasConsiderations === undefined) {
            return undefined;
        }
        if (!attendee.$mtcHasConsiderations) {
            return false;
        }
        if (attendee.$mtcHasConsiderations) {
            return (attendee.DisabilityType && attendee.DisabilityType.length > 0) ||
                (attendee.DietaryNeed && attendee.DietaryNeed.length > 0);
        }
    }
}