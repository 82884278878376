import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DateUtilityServiceService } from './date-utility-service.service';
import * as l33t from 'l33teral';

declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class CopyService {

  showcopy = false;
  constructor(private configurationService: ConfigurationService,  private dateService: DateUtilityServiceService) {
   }

  getCopyByKey(value){
    const promise = new Promise((resolve, reject)=>{
      this.configurationService.fetchConfig().then((data: any) => {
        const config = data;
        let prefix = '';
        if(this.showcopy){
          prefix = value + ' - ';
        }
        resolve(prefix +this.getCopyByKeyAndConfig(value, config));
      });
    });
    return promise;
  }

  getCopyByKeyAndConfig(value, config) {
        const myLeet = l33t(config);
        const ret = myLeet.tap(value.trim(), 'couldnt find ' + value);
        // TODO Check attendee group

        let content = ret;
        // console.log(value + ' ' + content);
        const tokensMatch = /\{\{([A-Za-z0-9\s\|]*)\}\}/;
        // pick out the nested interpolation
        if(content !== undefined){
          content = "" + ret;
        let tokens = content.match(tokensMatch);
        // while there's at least one match and the content still contains an interpolation bracket
        while (tokens && content.indexOf('{') > -1) {
            // check to see if it uses filter
            if(tokens[1].indexOf('|') === -1) {
              // simple replace
              // replace the first token and begin recursion. Pass along the args in case the chain terminates at one
              let retrieved = this.getCopyByKeyAndConfig(tokens[1], config);
              var date = this.dateService.createMTCZoneDateTime(retrieved);
              // if that worked
              if (date) {
                  //retrieved is now formatted as a date
                  retrieved = date.format("dddd, MMMM D, YYYY");;
              }

              content = content.replace(tokens[0], retrieved);
              // check for tokens again and loop
              tokens = content.match(tokensMatch);
            } else {
              const filterArray = tokens[1].split('|');

              let retrieved = this.getCopyByKeyAndConfig(filterArray[0], config);
              for(let i = 1 ; i < filterArray.length; i++) {
                retrieved = this.applyFilter(retrieved, filterArray[i]);
              }

              content = content.replace(tokens[0], retrieved);
              // check for tokens again and loop
              tokens = content.match(tokensMatch);
            }

        }
      } else {
         console.log('couldnt find ' + value);
         return "";
      }
        // const subfilterMatch = /\{\{([^{}]*) ?\|+ ?([^{}]*)\}\}/; //matches if we have values with filters like {{fee | currency}}
        // let subfilter = content.match(subfilterMatch);
        // // if there was a filter
        // if (subfilter) {
        //   const subsubfilterMatch = /([^{}]*) ?\:+ ?([^{}]*)/; // matches if we have values with filters like {{fee | currency}}
        //   // filter the stuff. Syntax is usually {{ value | filter }} so we have to flip the regex results
        //   // this is again where you go learn regex to understand why
        //   // this apparently needed to loop to catch multiple subfiltered tokens
        //   while (subfilter && content.indexOf('{') > -1) {
        //       // replace the first token and begin recursion. Pass along the args in case the chain terminates at one
        //       const subsubfilter = subfilter[2].match(subsubfilterMatch);
        //       if (subsubfilter) {
        //           // filter with formatter
        //           if (subsubfilter[1]==='date'){
        //             // check for tokens again and loop
        //             content = content.replace(subfilter[0], $filter(subsubfilter[1])(this.getContent(data, subfilter[1], args), subsubfilter[2], '-0500'));

        //           } else {
        //             content = content.replace(subfilter[0], $filter(subsubfilter[1])(this.getContent(data, subfilter[1], args), subsubfilter[2]));
        //           }
        //           subfilter = content.match(subfilterMatch);
        //       } else {
        //           // simple filter
        //           if (subfilter[2]==='date'){
        //               content = content.replace(subfilter[0], $filter(subfilter[2])(this.getContent(data, subfilter[1], args), '-0500'));
        //           } else {
        //           content = content.replace(subfilter[0], $filter(subfilter[2])(this.getContent(data, subfilter[1], args)));
        //           }
        //           subfilter = content.match(subfilterMatch);

        //       }

        //   }
        // }
        return content;

  }

  applyFilter(content: string, filter: string ) {
    filter = filter.trim();
    filter = filter.toLowerCase();

    if (filter === 'lowercase') {
      content = content.toLowerCase();
    } else {
      content = '(cant apply filter "' + filter + '" to "' + content + '")';
    }

    return content;
  }
}
