import { Component, OnInit, Input } from '@angular/core';
import { RegistrationService } from 'src/app/services/registration.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { PaymentService } from 'src/app/services/payment.service';
import { CollectPaymentComponent } from 'src/app/reusable-components/collect-payment/collect-payment.component';
import { CreditcardService } from 'src/app/services/creditcard.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-credit-card-display',
  templateUrl: './credit-card-display.component.html',
  styleUrls: ['./credit-card-display.component.scss']
})
export class CreditCardDisplayComponent implements OnInit {
  @Input() cardType: string;
  @Input() ccOnly: boolean;
  collectOtherPayment:boolean;
  payment;
  mediatypes;
  subscription;
  cards;
  cardused;
  constructor(private registrationService: RegistrationService,
    private configurationService: ConfigurationService,
    private paymentService: PaymentService,
    private creditCardService: CreditcardService) {
      this.cards = this.creditCardService.cards;
    }

  ngOnInit(): void {
    this.payment = this.registrationService.getCreditCard(this.registrationService.getPayment([]), this.cardType, {Category:this.cardType});
   // console.log(this.cards);
    this.cardused = _.filter(this.cards, {ReferenceKey: this.payment.ReferenceKey})[0];
  }

}
