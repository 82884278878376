import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { RegistrationService } from 'src/app/services/registration.service';

import { ModalService } from 'src/app/services/modal.service';


@Component({
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  enrollment;
  output = {
    config: "",
    enrollment:"",
    programKey:"",
    baseURL:"",
    actEndpoint:"",
    hideLimits:false,
    hideHeader:false,
    enforceUniqueTopic:false,
    moreinfo:'hideshow',
    multimode: true,
    variableConfig:[
      {
        "TopicCode":"GOLFONE",
        "SeqNo":1,
        "Display": true,
        "Required":true,
        "Type":"Dropdown",
        "Values":["None", "Men's Lefthanded","Men's Righthanded","Women's Lefthanded","Women's Righthanded"]
      },
      {
        "TopicCode":"GOLFONE",
        "SeqNo":2,
        "Display":true,
        "Required":true,
        "Type":"Textbox"
      }
    ]
  };
  ready=false;
  componentready=false;
  isFormCompleted;
  isSubmitting;
  isError;
  constructor(
    private registrationService: RegistrationService,
    private configService: ConfigurationService,
    private environmentService: EnvironmentService,
    private navigationService: NavigationService,
    private modalService: ModalService
  ) {
    this.enrollment = this.registrationService.getEnrollment({});
    this.output.enrollment = this.enrollment;
    this.configService.fetchConfig().then( conf =>{
      this.output.config = conf;
      this.output.programKey = this.environmentService.getProgramKey();
      this.output.baseURL = this.environmentService.getEnvironmentUrl("");
      this.output.actEndpoint = "service/webactivity";
      this.ready=true;
      console.log(this.output.multimode);
    })


  }

  openItin(){
    //this.modalService.openItinModal();
  }

  handleUpdates(event){
    this.componentready=true;
    this.isError = event.detail.error;
    console.log(event);
    console.log(this.enrollment);
  }

  ngOnInit(): void {
  }

  forward(form) {
    this.isSubmitting = true;
    this.isFormCompleted = true;
    if (form.valid && !this.isError ) {
      this.navigationService.forward();
    } else {
      this.isSubmitting = false;
    }
  }

  back() {
    this.navigationService.back();
  }

}
