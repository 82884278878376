import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'src/app/services/registration.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  attendee;
  constructor(private registrationService: RegistrationService) {
    this.attendee = this.registrationService.getAttendee({});
  }

  ngOnInit(): void {
  }

}
