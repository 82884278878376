<div [ngModelGroup]="direction">
    <mat-form-field class="w-100">
        <mat-label for="DepartureCityLocation" aria-labelledby="DepartureCityLocation">
            {{ 'copy.label.FEATURES.FLIGHTPREF.airportlabel' | copy | async }}</mat-label>
        <input matInput [matAutocomplete]="auto" name="DepartureCityLocation" #DepartureCityLocation="ngModel"
            [(ngModel)]="location"
            (ngModelChange)="doFilter(location)"  required>
        <mat-error><app-validation [object]="DepartureCityLocation" isRequired=true pattern="alpha"></app-validation></mat-error>
    </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete" class="w-100">
        <mat-option *ngFor="let l of airportLocations" [value]="l.airport">
            {{l.airport}} - {{l.crscode}} - {{l.state}}
        </mat-option>
    </mat-autocomplete>

</div>