import { Injectable } from '@angular/core';
import { RegistrationService } from './registration.service';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { CreditcardService } from './creditcard.service';

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {
  constructor(
    private registrationService: RegistrationService,
    private authService: AuthenticationService,
    private creditCardService: CreditcardService,
    private router: Router
  ) {}

  SITE_KEY = window.location.hostname + ':' + window.location.pathname;
  ENROLLMENT_KEY = this.SITE_KEY + ':enrollment';
  CARD_KEY = this.SITE_KEY + ':card';
  PROFILE_KEY = this.SITE_KEY + ':profile';

  store() {
    const enrollment = this.registrationService.getEnrollment({});
    if (this.authService.authtoken && enrollment) {
      enrollment.$mtcSessionToken = this.authService.authtoken;
    }
    try {
      if (enrollment) {
        sessionStorage.setItem(
          this.ENROLLMENT_KEY,
          JSON.stringify(this.registrationService.cleanseStorage(enrollment))
        );
      }
      if (this.creditCardService.cards) {
        sessionStorage.setItem(
          this.CARD_KEY,
          JSON.stringify(this.creditCardService.cards)
        );
      }
      // if (ProfileService.profile) {
      //   sessionStorage.setItem(
      //     PROFILE_KEY,
      //     JSON.stringify(ProfileService.profile)
      //   );
      // }
    } catch (e) {
      // persistance unavailable
      // const rejection = {
      //   config: { onError: Constants.ALERT_POLICY.LOCALSTORAGE }
      // };
      // $rootScope.$broadcast('MTC:EVENT:HTTP:RESPONSE_ERROR', rejection);
    }
  }

  retrieve(url) {
    const enrollment = sessionStorage.getItem(this.ENROLLMENT_KEY);
    // const cards = sessionStorage.getItem(CARD_KEY);
    // const profile = sessionStorage.getItem(PROFILE_KEY);
    let error = false;
    console.log("router url",url,this.router.url);
    if (
      url !== '/login' &&
      url.indexOf('/load')==-1  &&
      url !== '/oauth' &&
      url !== '/onsite' &&
      url !== '/instructions' &&
      url !== '/privacy-notice'
    ) {
      if (enrollment && enrollment !== 'undefined') {
        this.registrationService.Enrollment = JSON.parse(enrollment);
        // console.log(this.registrationService.Enrollment);
        if (this.registrationService.Enrollment.$mtcSessionToken) {
          let cards = sessionStorage.getItem(this.CARD_KEY);
          if (cards) {
            this.creditCardService.cards = cards ? JSON.parse(cards) : [];
          }
          // if (profile) {
          //   ProfileService.profile = profile ? JSON.parse(profile) : {};
          // }
          this.authService.setAuthToken(this.registrationService.Enrollment.$mtcSessionToken);
          // if (this.registrationService.getEnrollment({}).Language) {
          //   $http.defaults.headers.common.language = this.registrationService.getEnrollment(
          //     {}
          //   ).Language;
          // }
          this.authService.validateSessionToken().subscribe(
            (data:any) => {
              this.authService.authtoken = data.token;
              // console.log(data);
            },
            () => {
              console.log("persist error");
              error = true;
              this.clear();
              this.router.navigate(['/timeout']);
            }
          );
        } else {
          error = true;
        }
      } else {
        error = true;
      }

      if (error) {
        this.clear();
        this.router.navigate(['/login']);
      }
    } else {
      this.clear();
    }
  }

  clear() {
    this.registrationService.Enrollment = undefined;
    sessionStorage.removeItem(this.ENROLLMENT_KEY);

    this.creditCardService.cards = [];
    sessionStorage.removeItem(this.CARD_KEY);
    // ProfileService.profile = {};
    // sessionStorage.removeItem(PROFILE_KEY);

    // $http.defaults.headers.common['MTC-Session-Token'] = '';
  }

  testStorage() {
    try {
      sessionStorage.setItem('localStorage', '1');
      sessionStorage.removeItem('localStorage');
    } catch (e) {
     //  persistance unavailable
      // const rejection = {
      //   config: { onError: Constants.ALERT_POLICY.LOCALSTORAGE }
      // };
      // $rootScope.$broadcast('MTC:EVENT:HTTP:RESPONSE_ERROR', rejection);
    }
  }
}
