import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { RegistrationService } from './registration.service';
import { ConstantsService } from './constants.service';
import { VariablesService } from './variables.service';
import * as _ from 'lodash';
import { AuthenticationService } from './authentication.service';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class HotelService {
  authToken;

  serviceurl = this.environmentService.getEnvironmentUrl(
    'service/hotel'
  );

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private registrationService: RegistrationService,
    private Constants: ConstantsService,
    private varService: VariablesService,
    private authService: AuthenticationService
  ) {}


  hotelStay = this.registrationService.getHotel([]);

  getHotelList(){

    var cleanedData = this.registrationService.cleanse(this.registrationService.getEnrollment({}));
   //  cleaned data
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(this.serviceurl, cleanedData)
        .subscribe((data: any) => {
          resolve(data);
        });
    });
    return promise;
  }
  getNonContiguousHotelNights( availableNights, selectedNights ) {
    var nonContiguousNights = [];
    availableNights.forEach(( obj ) => {

        var hoteldates = _.map( _.filter( selectedNights, { Hotel: obj.webHotelGroupID } ), "Date" );
        hoteldates.sort();

        var d1;
        var d2;

        var foundNonContiguousDate = false;
        hoteldates.forEach(( date ) => {
            if( !d1 ) {
                d1 = moment.utc(date, "YYYY-MM-DD HH:mm:ss.SSS+Z").toDate();
            } else {
                d2 = moment.utc(date, "YYYY-MM-DD HH:mm:ss.SSS+Z").toDate();
                d1.setDate( d1.getDate() + 1 );
                if( d1.getTime() !== d2.getTime() ) {
                    foundNonContiguousDate = true;
                }
                d1 = moment.utc(date, "YYYY-MM-DD HH:mm:ss.SSS+Z").toDate();
            }
        } );
        if( foundNonContiguousDate ) {
            nonContiguousNights.push( obj.supplierName );
        }
    } );
    return nonContiguousNights;
  }

  getHotelDescriptionUrl(webHotelGroupDesc){
    return "copy.html.Hotel" + webHotelGroupDesc.replace(/[\s]/g, "").replace(/[\-]/g, "");
  }

}
