import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EnrollmentService } from 'src/app/services/enrollment.service';

import { EnvironmentService } from 'src/app/services/environment.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { ConstantsService } from '../../services/constants.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  editElement: any;
  classExists: any;
  output:any = {
    test:"test"
  };
  $mtcUsingProfile;
  attendee;
  programInfo: any;
  theStartDate;
  theEndDate;


  valuechange(vals){
    console.log(vals.detail);
  }

  constructor(
    private registrationService: RegistrationService,
    private navigationService: NavigationService,
    private environmentService: EnvironmentService,
    public Constants: ConstantsService,
    private configurationService: ConfigurationService,
    private authService: AuthenticationService,
    private el: ElementRef,
    private renderer: Renderer2) {
    this.attendee = this.registrationService.getAttendee({});
    let enrollment = this.registrationService.getEnrollment({});
    this.output.enrollment = enrollment;
    this.output.dataurl = this.environmentService.getEnvironmentUrl('');
    this.output.serviceurl = this.environmentService.getProfileService();
    this.output.programkey = this.environmentService.getProgramKey();
    this.output.websiteKey = "EdwardJonesTap";
    this.output.status = "register";
    this.output.readonly = false;


window.onload = function() {
    this.el.nativeElement = (document.getElementById('editProfile'));
     console.log(this.el.nativeElement);
   }
    // this.el.nativeElement = this.editElement;
    //this.classExists = (this.el.nativeElement.classList.contains('stateEdit') == true);
    //console.log(this.classExists);
   }

  ngOnInit(): void {
    this.configurationService.fetchConfig().then((data: any) => {
        this.programInfo = data;
        this.theStartDate = moment.utc(this.programInfo.StartDate+"-0500", "YYYY-MM-DD HH:mm:ss+Z").toDate();
        this.theEndDate = moment.utc(this.programInfo.EndDate+"-0500", "YYYY-MM-DD HH:mm:ss+Z").toDate();

      });

    }


  handleUpdates(event){
    this.attendee.$mtcUsingProfile = 'NO';
    this.navigationService.forward();
  }
  truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num);
  }

  prefill(event){
    this.attendee.$mtcnbc = event.detail;
    let attendee = this.attendee;
    let profile = event.detail.profile;
    let enrollment = this.output.enrollment;
    //this.attendee.FirstName = event.detail.profile.Attendee[0].Name.FirstName;
    if(event.detail && event.detail.profile && event.detail.profile.Attendee){
      if (event.detail.profile.Attendee[0].Name.Prefix) {
        this.attendee.Prefix = event.detail.profile.Attendee[0].Name.Prefix;
      }
      if(profile.Attendee[0].AttendeeProfileUniqueId)
      {
        attendee.AuthenticatedUserProfile = profile.Attendee[0].AttendeeProfileUniqueId;
      }
      if (profile.Attendee[0].Name.PreferredFirstName) {
          attendee.PreferredFirstName = this.truncateString(profile.Attendee[0].Name.PreferredFirstName,25);
      }
      if (profile.Attendee[0].Name.PreferredLastName) {
          attendee.PreferredLastName = this.truncateString(profile.Attendee[0].Name.PreferredLastName,25);
      }
      if (profile.Attendee[0].Name.Suffix) {
          attendee.Suffix = profile.Attendee[0].Name.Suffix;
      }
      if (profile.Attendee[0].Name.ProfessionalPPs) {
          //attendee.ProfessionalLetters = profile.Attendee[0].Name.ProfessionalPPs;
      }



      if (profile.Attendee[0].Gender) {
          attendee.Gender = profile.Attendee[0].Gender;
      }

      if (profile.Attendee[0].Phone && profile.Attendee[0].Phone.length > 0) {
          _.forEach(profile.Attendee[0].Phone, (pho) => {
              if (pho.AreaCityCode && pho.PhoneNo) {
                  if (pho.PhoneType === "MOBILE") {
                      var mobile = this.registrationService.getPhone(this.attendee, "MOBILE", {});
                      mobile.CountryCode = pho.CountryCode ? pho.CountryCode : "01";
                      mobile.AreaCityCode = pho.AreaCityCode;
                      mobile.PhoneNo = pho.PhoneNo;
                  }
              }
          });
      }
      attendee.FrequentFlyer = [];
      if (profile.Attendee[0].TravelInfo) {
          if (profile.Attendee[0].TravelInfo.RedressNumber) {
              attendee.RedressNumber = this.truncateString(profile.Attendee[0].TravelInfo.RedressNumber,13);
          }
          if (profile.Attendee[0].TravelInfo.KnownTravelerNumber) {
              attendee.KnownTravelerNumber = this.truncateString(profile.Attendee[0].TravelInfo.KnownTravelerNumber,25);
          }
          if (profile.Attendee[0].TravelInfo.AirInterRowPosition) {
              attendee.InterRowPosition = profile.Attendee[0].TravelInfo.AirInterRowPosition;
          }

          if (profile.Attendee[0].TravelInfo.Passport && profile.Attendee[0].TravelInfo.Passport[0]) {
              attendee.$mtcProvidePassport = true;
              var passport = this.registrationService.getTravelDocument(attendee, this.Constants.DOCUMENT_TYPES.PASSPORT, {});
              if (this.programInfo.PassportFlag === 'Y')
              {
                  if (profile.Attendee[0].TravelInfo.Passport[0].DocumentNo) {
                      passport.DocumentNo = this.truncateString(profile.Attendee[0].TravelInfo.Passport[0].DocumentNo,30);
                  }
                  if (profile.Attendee[0].TravelInfo.Passport[0].IssueDateTime) {
                      passport.IssueDateTime = profile.Attendee[0].TravelInfo.Passport[0].IssueDateTime;
                  }
                  if (profile.Attendee[0].TravelInfo.Passport[0].IssuancePlace) {
                      passport.IssuanceInfo = this.truncateString(profile.Attendee[0].TravelInfo.Passport[0].IssuancePlace,30);
                  }
                  if (profile.Attendee[0].TravelInfo.Passport[0].ExpirationDateTime) {
                      passport.ExpirationDateTime = profile.Attendee[0].TravelInfo.Passport[0].ExpirationDateTime;
                  }
                  if (profile.Attendee[0].TravelInfo.Passport[0].CitizenshipCountryISOCode) {
                      attendee.CitizenshipCountryISOCode = profile.Attendee[0].TravelInfo.Passport[0].CitizenshipCountryISOCode;
                  }
                  if (profile.Attendee[0].TravelInfo.Passport[0].BirthCountryISOCode) {
                      passport.BirthCountryISOCode = profile.Attendee[0].TravelInfo.Passport[0].BirthCountryISOCode;
                  }

              } else
              {
                  if (profile.Attendee[0].TravelInfo.Passport[0].ExpirationDateTime) {
                      passport.ExpirationDateTime = profile.Attendee[0].TravelInfo.Passport[0].ExpirationDateTime;
                  }
              }
          }

          if (profile.Attendee[0].TravelInfo.FrequentFlyer) {

              _.forEach(profile.Attendee[0].TravelInfo.FrequentFlyer, (freq) => {
                  if (freq) {
                      if (freq.AirlineCode && freq.AccountNo) {
                        freq.AccountNo = this.truncateString(freq.AccountNo, 17);
                        let index = _.findIndex(attendee.FrequentFlyer, {AirlineCode:freq.AirlineCode});
                        if(index == -1){
                            attendee.FrequentFlyer.push(freq);
                        }else{
                          attendee.FrequentFlyer[index].AccountNo = freq.AccountNo;
                        }
                      }
                  }
              });
          }
      }


      if (profile.Attendee[0].DietaryPersonalNeeds && (profile.Attendee[0].DietaryPersonalNeeds.DietaryConsiderations || profile.Attendee[0].DietaryPersonalNeeds.DietaryNeed || (profile.Attendee[0].DietaryPersonalNeeds.DisabilityType && profile.Attendee[0].DietaryPersonalNeeds.DisabilityType.length > 0) || profile.Attendee[0].DietaryPersonalNeeds.PersonalConsiderations)) {
          attendee.$mtcHasConsiderations = true;
          if (profile.Attendee[0].DietaryPersonalNeeds.DietaryConsiderations) {
              attendee.DietaryConsiderations = this.truncateString(profile.Attendee[0].DietaryPersonalNeeds.DietaryConsiderations,255);
            }
          if (profile.Attendee[0].DietaryPersonalNeeds.DietaryNeed) {
              attendee.DietaryNeed = profile.Attendee[0].DietaryPersonalNeeds.DietaryNeed;
          }
          if (profile.Attendee[0].DietaryPersonalNeeds.DisabilityType && (profile.Attendee[0].DietaryPersonalNeeds.DisabilityType !== "" || profile.Attendee[0].DietaryPersonalNeeds.DisabilityType !== " ")) {
              attendee.DisabilityType = profile.Attendee[0].DietaryPersonalNeeds.DisabilityType;
              console.log(profile.Attendee[0].DietaryPersonalNeeds.DisabilityType);
          }
          if (profile.Attendee[0].DietaryPersonalNeeds.PersonalConsiderations) {
              attendee.PersonalConsiderations = this.truncateString(profile.Attendee[0].DietaryPersonalNeeds.PersonalConsiderations,255);
          }
      } else {
        attendee.$mtcHasConsiderations = false;
      }
      if (profile.TravelPreference) {
          if (profile.TravelPreference.AirPreference) {
              var outbound = this.registrationService.getPAXAirPreference(this.attendee, this.Constants.AIRPREF_DIRECTIONS.OUTBOUND, {});
              if (profile.TravelPreference.AirPreference.PreferredDepartingAirport) {
                  outbound.DepartureCityLocation = profile.TravelPreference.AirPreference.PreferredDepartingAirport;
              }
              if (profile.TravelPreference.AirPreference.PreferredDepartingAirportCode) {
                  outbound.DepartureAirportCode = profile.TravelPreference.AirPreference.PreferredDepartingAirportCode;
              }

              if (profile.TravelPreference.AirPreference.AlternateDepartingAirport) {
                  outbound.$mtcaltAir = profile.TravelPreference.AirPreference.AlternateDepartingAirport;
              }
              if (profile.TravelPreference.AirPreference.AirSpecialRequests !== undefined && profile.TravelPreference.AirPreference.AirSpecialRequests)
              {
                  //if (profile.TravelPreference.AirPreference.AirSpecialRequests.length > 150)
                 // {
                 //     if (profile.TravelPreference.AirPreference.AirSpecialRequests) {
                 //         enrollment.AirSpecialRequests = profile.TravelPreference.AirPreference.AirSpecialRequests.substring(0, 150);
                 //     }
                 // } else
                 // {
                      if (profile.TravelPreference.AirPreference.AirSpecialRequests) {
                          enrollment.AirSpecialRequests = this.truncateString(profile.TravelPreference.AirPreference.AirSpecialRequests,150);
                      }
                 // }
              }
          }
          if (profile.TravelPreference.HotelSpecialRequests !== undefined && profile.TravelPreference.HotelSpecialRequests)
          {
              //if (profile.TravelPreference.HotelSpecialRequests.length > 150)
             // {
             //     enrollment.HotelSpecialRequests = profile.TravelPreference.HotelSpecialRequests.substring(0, 150);
             // } else
             // {
                  enrollment.HotelSpecialRequests = this.truncateString(profile.TravelPreference.HotelSpecialRequests, 100);
             // }
          }


      }
      if (profile.Attendee[0].Address) {
          _.forEach(profile.Attendee[0].Address, (address) =>{
              if (profile.Attendee[0].MailTo)
              {
                  if (profile.Attendee[0].MailTo === 'HOME')
                  {
                      if (address.AddressType) {
                          if (address.AddressType === 'HOME')
                          {
                            this.registrationService.update(this.registrationService.getAddress(attendee, address.AddressType,{}), {
                                  $mtcMailToHome: true
                              });
                          } else
                          {
                            this.registrationService.update(this.registrationService.getAddress(attendee, address.AddressType,{}), {
                                  $mtcMailToBus: false
                              });
                          }
                      }
                  } else
                  {
                      if (address.AddressType) {
                        this.registrationService.update(this.registrationService.getAddress(attendee, address.AddressType,{}), {
                              $mtcMailToHome: false,
                              $mtcMailToBus: true
                          });
                      }

                  }
              }

          });
      }

      if (profile.AdminContact && profile.AdminContact.AdminContactName) {
          enrollment.$mtcHasAdminContact = true;
          var admin = this.registrationService.getAdminContact({});
          if (profile.AdminContact.AdminContactName) {
              admin.AdminContactName = profile.AdminContact.AdminContactName;
          }
          if (profile.AdminContact.AdminContactEmailAddress) {
              admin.AdminContactEmailAddress = profile.AdminContact.AdminContactEmailAddress;
          }

          if (!admin.AdminContactPhone || admin.AdminContactPhone.length === 0) {
              admin.AdminContactPhone = [{}];
          }
          var phone = profile.AdminContact.Phone;
          if (phone && phone.length > 0) {
              admin.AdminContactPhone[0].PhoneType = "WORK";
              if (phone[0].CountryCode) {
                  admin.AdminContactPhone[0].CountryCode = phone[0].CountryCode;
              }
              if (phone[0].AreaCityCode) {
                  admin.AdminContactPhone[0].AreaCityCode = phone[0].AreaCityCode;
              }
              if (phone[0].PhoneNo) {
                  admin.AdminContactPhone[0].PhoneNo = phone[0].PhoneNo;
              }
          }
      } else {
          enrollment.$mtcHasAdminContact = false;
      }
        this.attendee.$mtcUsingProfile = 'YES';
    }
    this.navigationService.forward();
  }

}
