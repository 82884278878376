import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, FormGroup, ValidationErrors, FormControl } from '@angular/forms';

@Directive({
  selector: '[expirationDate]',
  providers: [{provide: NG_VALIDATORS, useExisting: ExpirationValidatorDirective, multi: true}]
})
export class ExpirationValidatorDirective implements Validator {
  date = undefined;

  constructor() { }

  validate(form: FormGroup): any {
    let error = null;

    const month = form.get('expirationdate.month');
    const year = form.get('expirationdate.year');
    if (month && year) {
      let date;
      var selectedDate = new Date();
      selectedDate.setMonth(month.value-1);
      selectedDate.setFullYear(year.value);
      //selectedDate.setDate(1);

      date = "" + selectedDate;
      if(selectedDate.getFullYear() > 0 && selectedDate < new Date()){
        error = "Card is expired";
        form.get('expirationdate.month')?.setErrors({expired:true});
        form.get('expirationdate.year')?.setErrors({expired:true});
      }else{
        error = null;
        form.get('expirationdate.month')?.updateValueAndValidity({onlySelf: true, emitEvent: false});
        form.get('expirationdate.year')?.updateValueAndValidity({onlySelf: true, emitEvent: false});
        form.get('expirationdate')?.updateValueAndValidity({onlySelf: true, emitEvent: false});
      }
    }

    const message = {
      'expirationDate': {
        'message': error
      }
    };

    return error ? message : null;
  }
}

