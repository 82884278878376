<div id="activity" class="registration container">
  <form
    #activityForm="ngForm"
    name="activityForm"
    (ngSubmit)="forward(activityForm)"
    novalidate
    appScrollToInvalid
    [ngClass]="{ 'form-complete': isFormCompleted }"
  >
    <!-- <h1>{{ 'copy.label.ATTENDEE.header' | copy | async }}</h1> -->

    <activity-comp
      *ngIf="ready"
      [inputJson]="output"
      (activitybooked)="handleUpdates($event)"
    ></activity-comp>
    <div class="stickyButtonAgenda">
        <a href="assets/agenda.tab.html" target="_blank" matTooltip="Open Agenda"><i class="far fa-calendar-alt"></i><p>Agenda</p></a>
    </div>
    <div *ngIf="ready && componentready" class="button_group d-flex">
      <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.goback' | copy | async }}
      </button>
      <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.continue' | copy | async }}
      </button>
      <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
        <i class="fas fa-spinner fa-spin"></i>
      </button>
    </div>
  </form>
</div>
