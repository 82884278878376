import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../../../services/registration.service';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {
	active = 1;
	attendee;
	attendeeWaveNo;

  constructor(
  	private registrationService: RegistrationService
  	) {

  	this.attendee = this.registrationService.getAttendee({});
  	this.attendeeWaveNo = this.registrationService.getAttendee({}).WaveNo;
  	//console.log(this.attendeeWaveNo == 1);

  	 }

  ngOnInit(): void {
  }

}
