import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { AuthenticationService } from './services/authentication.service';
import { EnvironmentService } from './services/environment.service';

@Injectable()
export class THQHttpInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthenticationService,
    private envService: EnvironmentService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {


    let thqReq = req.clone({
      headers: req.headers.set('Cache-Control', 'no-cache'),
    });

    thqReq = thqReq.clone({
      headers: thqReq.headers.set('If-Modified-Since', '0'),
    });
    // Get the auth token from the service.
    const authToken = this.auth.authtoken;

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    if (authToken) {
      thqReq = thqReq.clone({
        headers: thqReq.headers.set('MTC-Session-Token', authToken),
      });
    }

    // add environment token
    const programKey = this.envService.getProgramKey();
    thqReq = thqReq.clone({
      headers: thqReq.headers.set('ProgramKey', programKey),
    });
    // send cloned request with header to the next handler.
    return next.handle(thqReq);
  }
}
