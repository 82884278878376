<div id="hotelbooking" class="registration container">
  <form #hotelbookingForm="ngForm" [ngClass]="{'form-complete': hotelbookingForm.valid}" name="hotelbookingForm" (ngSubmit)="forward(hotelbookingForm)" novalidate autocomplete="off" appScrollToInvalid [ngClass]="{'form-complete': isFormCompleted}">
    <h1>{{ 'copy.label.HOTEL.heading' | copy | async }}</h1>

    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.HOTEL.label' | copy | async }}
          <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <mat-radio-group [(ngModel)]="enrollment.HotelNeeded" name="hotelNeeded" #hotelNeeded="ngModel" required (ngModelChange)="clearRequests()">
          <mat-radio-button [value]="true" [checked]="enrollment.HotelNeeded === true">Yes</mat-radio-button>
          <mat-radio-button [value]="false" [checked]="enrollment.HotelNeeded === false">No</mat-radio-button>
          <div class="w-100">
            <mat-error><app-validation  [object]="hotelNeeded" isRequired=true ></app-validation></mat-error>
          </div>
        </mat-radio-group>
      </article>
    </section>
    <section class="card" *ngIf="enrollment.HotelNeeded === true">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.HOTEL.hotels' | copy | async }}
          <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <div class="hotel-table" *ngIf="hotelList">

          <!--div class="w-100" [innerHTML]="'copy.html.hotelinfoPackage'|copy|async"></div-->
          <div class="roomdetails" *ngFor="let hotelobj of hotelList.roomTypeList">
            <div class="allhotelinfo">
              <div class="hotelinfo">
                <div *ngIf="hotelobj.firstFourSeasons"><h3><u>Four Seasons Hotel Nashville Packages</u></h3></div>
                <div *ngIf="hotelobj.firstOneHotel"><h3><u>1 Hotel Nashville Packages</u></h3></div>
                <br>
                <div [innerHTML]='hotelobj.verbiage'></div>
                <div class="clearLeft">
                    <div class="hotelprices" *ngFor="let hotelPackage of hotelobj.packages">
                      <ul>
                        <li>
                         Price: {{hotelPackage.price| currency}}
                        </li>
                      </ul>
                      <!--p>{{hotelPackage.alttext}}</p-->
                      <input type="button" *ngIf="isselected(hotelPackage, hotelobj.webHotelGroupID)" class="btn btn-success" value="Selected"/>
                      <input type="button" *ngIf="!isselected(hotelPackage, hotelobj.webHotelGroupID) && isHotelAvailableOnDates(hotelobj, hotelPackage)" class="btn btn-primary" (click)='addNights(hotelPackage, hotelobj.webHotelGroupID)' value="Book This Package"/>
                      <input type="button" *ngIf="!isselected(hotelPackage, hotelobj.webHotelGroupID) && !isHotelAvailableOnDates(hotelobj, hotelPackage)" class="btn btn-danger" (click)='addNights(hotelPackage, hotelobj.webHotelGroupID)' value="Sold Out"/>
                  </div>
                </div>
                <hr ng-if="!lasthotel"/>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

        <section class="card" *ngIf="enrollment.HotelNeeded === true">
          <div class="card-header">
            <div class="card-title">
              {{ 'copy.label.HOTEL.roominfo' | copy | async }}
            </div>
          </div>
          <article> 
            <!--div class="radio-group">
              <mat-radio-group [(ngModel)]="attendee.HotelSmoking" name="smoking" >
                <mat-radio-button [value]="true" [checked]="attendee.HotelSmoking === true">Smoking</mat-radio-button>
                <mat-radio-button [value]="false" [checked]="attendee.HotelSmoking === false">Non-Smoking</mat-radio-button>
              </mat-radio-group>
            </div-->
            <!-- <div>
              {{ 'copy.label.HOTEL.special' |copy |async}}
              <textarea name="hotelSpecialRequests" [(ngModel)]="enrollment.HotelSpecialRequests" maxlength="225"></textarea>
            </div> -->
            <div class="d-flex justify-content-start w-100">
              <mat-form-field class="w-100">
                <mat-label class="w-100" for="DietaryNeedOther" aria-labelledby="DietaryNeedOther">{{ 'copy.label.HOTEL.special' |copy |async}}</mat-label>
                <textarea matInput class="w-100" name="hotelSpecialRequests" [(ngModel)]="enrollment.HotelSpecialRequests" maxlength="225" pattern="^[\w\s!@\-\_:&',.\/?]*"></textarea>
              </mat-form-field>
            </div>
          </article>
      </section>

      <section class="card" *ngIf="enrollment.HotelNeeded === false">
        <div class="card-header">
          <div class="card-title">
            {{ 'copy.label.DEMOGRAPHICS.nohotellabel' | copy | async }}
            <i class="fas fa-asterisk required"></i>
          </div>
        </div>
        <article>
          <mat-radio-group [(ngModel)]="enrollment.HotelSpecialRequests" name="noHotelReason" #noHotelReason="ngModel" required>
            <mat-radio-button *ngFor="let op of noHotelRadios" [value]="op.value">{{op.display}}</mat-radio-button>
            <div class="w-100">
              <mat-error><app-validation  [object]="noHotelReason" isRequired=true ></app-validation></mat-error>
            </div>
          </mat-radio-group>

        </article>
      </section>

      <div class="button_group d-flex">
        <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()" *ngIf="!isSubmitting">
          {{ 'copy.label.GLOBAL.goback' | copy | async }}
        </button>
        <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
          {{ 'copy.label.GLOBAL.continue' | copy | async }}
        </button>
        <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
          <i class="fas fa-spinner fa-spin"></i>
        </button>
      </div>
    </form>
  </div>
