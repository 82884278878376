<form name="cardCollectionForm" id="cardCollectionForm" #cardCollectionForm="ngForm" [ngClass]="{'form-complete': isFormCompleted}" expirationDate>
  <header class="modal-header">
    <h1 class="modal-title">{{"copy.label.CARDCOLLECT.heading"|copy|async}}</h1>
    <div class="close-container" (click)="onNoClick()">
      <div class="leftright"></div>
      <div class="rightleft"></div>
    </div>
    <!-- <button class="btn btn-outline-light closeButton" (click)="onNoClick()">X</button> -->
  </header>

  <mat-dialog-content class="modal-body overflow-auto">

    <mat-accordion>
      <!-- #docregion basic-panel -->
      <!-- #docregion hide-toggle -->
      <mat-expansion-panel hideToggle>
        <!-- #enddocregion hide-toggle -->
        <mat-expansion-panel-header class="bg-white">
          <mat-panel-title class="secureMessage">
            <i class="fab fa-expeditedssl"></i> <p><strong>{{"copy.label.CARDCOLLECT.secure"|copy|async}}</strong></p>
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>{{"copy.label.CARDCOLLECT.securemessage"|copy|async}}</p>
      </mat-expansion-panel>
      <!-- #enddocregion basic-panel -->

    </mat-accordion>

    <!-- <h4 id="collectionlink" (click)="isSecureExpanded=!isSecureExpanded"><i class="fas fa-lock"></i>{{"copy.label.CARDCOLLECT.secure"|copy|async}}</h4>

        <div *ngIf="isSecureExpanded">{{"copy.label.CARDCOLLECT.securemessage"|copy|async}}</div> -->
    <div id="card-container">
      <img src="assets/images/visa.png" />
      <img src="assets/images/amex.png" />
      <img src="assets/images/mastercard.png" />
      <img src="assets/images/discover.png" />
    </div>

    <mat-form-field class="w-100">
      <mat-label for="name" aria-labelledby="name">{{ 'copy.label.CARDCOLLECT.name' | copy | async }}</mat-label>
      <input matInput name="name" id="name" [attr.aria-label]="name" type="text"  maxlength="60"
        [(ngModel)]="card.name" #name="ngModel" alphanum required />
      <mat-error><app-validation [object]="name" isRequired=true pattern="alphanum"></app-validation></mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label for="cardNumber" aria-labelledby="name">{{ 'copy.label.CARDCOLLECT.number' | copy | async }}
      </mat-label>
      <input matInput name="cardNumber" id="cardNumber" [attr.aria-label]="cardNumber" type="text"
         maxlength="25" [(ngModel)]="card.number" #cardNumber="ngModel" integer appLuhnCheck required />
      <mat-error><app-validation [object]="cardNumber" isRequired=true pattern="integer"></app-validation></mat-error>
    </mat-form-field>

    <app-credit-card-date [card]="card"></app-credit-card-date>

    <app-credit-card-address *ngIf="config.cardaddress" [card]="card"></app-credit-card-address>

  </mat-dialog-content>
  <footer id="modal-footer">
    <div class="button_group d-flex flex-row justify-content-between">
      <button data-dismiss="modal" (click)="onNoClick()"
        class="btn btn-outline-primary">{{"copy.label.GLOBAL.cancel"|copy|async}}</button>
      <button mat-button cdkfocusinitial (click)="ok(cardCollectionForm)" *ngIf="!isSubmitting"
        class="btn btn-primary ml-auto text-white">{{"copy.label.GLOBAL.next"|copy|async}}</button>
        <button class="btn btn-primary ml-auto" *ngIf="isSubmitting">
          <i class="fas fa-spinner fa-spin"></i>
        </button>
    </div>
  </footer>
</form>
<!-- <footer id="modal-footer">
    <div class="button_group d-flex flex-row justify-content-between">
      <button mat-button data-dismiss="modal" (click)="onNoClick()" class="btn btn-default">{{"copy.label.GLOBAL.cancel"|copy|async}}</button>
    </div>
  </footer> -->
