<div id="summary" class="registration container">
  <form #summaryForm="ngForm" name="summaryForm" (ngSubmit)="forward(summaryForm)" novalidate appScrollToInvalid [ngClass]="{'form-complete': isFormCompleted }" *ngIf="!errorMsg">
    <div class="card emptybody" *ngIf="!errorMsg && !successMessage && isRegComplete">
      <div class="card-title">
        <div class="summaryHeaderAndPrint">
          <h1>{{ 'copy.label.SUMMARY.heading' | copy | async }}</h1>
          <mat-icon class="printButton" (click)="printPage();" aria-hidden="false" aria-label="Example home icon">print</mat-icon>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="errorMsg || successMessage || !isRegComplete">
      <div class="card-title">
        <div class="summaryHeaderAndPrint">
          <h1>{{ 'copy.label.SUMMARY.heading' | copy | async }}</h1>
          <mat-icon class="printButton" (click)="printPage();" aria-hidden="false" aria-label="Example home icon">print</mat-icon>
        </div>
      </div>
      <div class="card-body" >
        <!-- Top Section -->
        <div class="w-100" *ngIf="errorMsg" type="danger">
          <strong>
            <span class="fas fa-stack">
              <i class="fas fa-exclamation fa-stack-1x"></i>
              <i class="far fa-circle fa-stack-2x"></i></span> {{errorMsg}}</strong>
        </div>
        <div class="w-100" *ngIf="successMessage">
          <div class="w-100" [innerHTML]='"copy.html.rvsSuccessMessage"|copy|async'></div>
        </div>
        <section *ngIf="!isRegComplete">
          <h2>{{ 'copy.label.SUMMARY.review' | copy | async }}</h2>
          <article name="legal" class="summaryLinks">
            <div class="agreeCheckbox">
              <div class="checkboxFlexContainer">
                <mat-checkbox name="termsAndConditions" [(ngModel)]="enrollment.AgreedToTermsAndConditions" #termsAndConditions="ngModel" required></mat-checkbox>
                <!-- Placing the text inside the mat-checkbox will make it so the checkbox will be checked when links are clicked --><label class="required">
                  I agree to the Maritz <a tabindex="0" (click)="openModal('termsOfUse', 'About This Site', 'Close');" (keydown.enter)="openModal('termsOfUse', 'About This Site', 'Close');">Terms of Use</a> and
                  the <a tabindex="0" (click)="openModal('ResponsibilityStatement', 'Responsibility Statement', 'Close');" (keydown.enter)="openModal('ResponsibilityStatement', 'Responsibility Statement', 'Close');">{{ 'copy.label.SUMMARY.responsibilitylink' | copy | async }}</a></label>
              </div>
              <div class="w-100">
                <small class="text-danger"><app-validation [object]="termsAndConditions" isRequired=true></app-validation></small>
              </div>
            </div>
            <div *ngIf="isGDPR">
              <div class="agreeCheckbox">
                <div class="checkboxFlexContainer">
                  <mat-checkbox name="privacyPolicy" [(ngModel)]="enrollment.PrivacyPolicy"></mat-checkbox>
                  <!-- Placing the text inside the mat-checkbox will make it so the checkbox will be checked when links are clicked -->
                  <label>I agree to the <a routerLink="/privacy-notice" target="_blank">Lexus Privacy Notice</a>. I
                    understand that Maritz may collect and process my personal information on behalf of {{'clientName' |copy |async}}.</label>
                </div>
              </div>
            </div>
            <div class="agreeCheckbox">
              <div class="checkboxFlexContainer">
                <mat-checkbox name="rememberme" [(ngModel)]="rememberme" #RememberMe="ngModel"></mat-checkbox>
                <label>{{"copy.label.SUMMARY.rememberme"|copy|async}}
                  <a tabindex="0" matTooltip="{{'copy.label.SUMMARY.remembermequestion'|copy|async}}">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </a></label>
              </div>
            </div>
          </article>
        </section>
      </div>
    </div>
    <!-- Attendee Page -->
    <mat-expansion-panel *ngIf="siteFlowContains('attendee')" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{attendee.$mtcDisplayName}}
          <a class="edit-link" (click)="editAttendee()">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-badge></app-badge>
      <!--app-summary-display [ngmodel]="this.attendee.BirthDate| date:'longDate'">
        {{'copy.label.TRAVEL.birthdate' | copy | async }}</app-summary-display-->
      <app-addresssummary *ngIf="workAddress.AddressLine1 !== undefined" [address]="workAddress">
        {{ 'copy.label.FEATURES.ADDRESS.business' | copy | async }}</app-addresssummary>
      <app-addresssummary *ngIf="homeAddress.AddressLine1 !== undefined" [address]="homeAddress">
        {{ 'copy.label.FEATURES.ADDRESS.home' | copy | async }}</app-addresssummary>
      <app-summary-display [ngmodel]="emailAddress.EmailAddress">{{ 'copy.label.FEATURES.EMAIL.label' | copy | async }}
      </app-summary-display>
      <app-phonesummary *ngIf="workPhone.PhoneNo !== '' && workPhone.PhoneNo !== undefined" [phone]="workPhone">
        {{ 'copy.label.ATTENDEE.CONTACT.bizphonelabel' | copy | async }}</app-phonesummary>
      <app-phonesummary *ngIf="mobilePhone.PhoneNo !== '' && mobilePhone.PhoneNo !== undefined" [phone]="mobilePhone">
        {{ 'copy.label.ATTENDEE.CONTACT.cellphonelabel' | copy | async }}</app-phonesummary>
      <app-phonesummary *ngIf="homePhone.PhoneNo !== '' && homePhone.PhoneNo !== undefined" [phone]="homePhone">
        {{ 'copy.label.ATTENDEE.CONTACT.homephonelabel' | copy | async }}</app-phonesummary>
      <app-summary-display [ngmodel]="attendee.EmergencyContact.EmergencyContactName">
        {{ 'copy.label.FEATURES.EMERGENCY.namelabel' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="attendee.EmergencyContact.EmergencyContactRelation">
        {{ 'copy.label.FEATURES.EMERGENCY.relationshiplabel' | copy | async }}</app-summary-display>
      <app-phonesummary *ngIf="emerContactMainPhone.PhoneNo !== '' && emerContactMainPhone.PhoneNo !== undefined" [phone]="emerContactMainPhone">{{ 'copy.label.FEATURES.EMERGENCY.phonelabel' | copy | async }}
      </app-phonesummary>
      <app-phonesummary *ngIf="emerContactAltPhone.PhoneNo !== '' && emerContactAltPhone.PhoneNo !== undefined" [phone]="emerContactAltPhone">{{ 'copy.label.FEATURES.EMERGENCY.altphonelabel' | copy | async }}
      </app-phonesummary>
      <!-- Dietary Considerations -->
      <!-- <div class="mainAttendeeConsiderations" *ngIf="!attendee.$mtcHasConsiderations">
        <div *ngIf="!attendee.$mtcHasConsiderations" class="form-summary">
          <label class="col-md-6 col-xs-12">{{ 'copy.label.ATTENDEE.DIETARY.header' | copy | async }}</label>
          <span class="form-summary-item col-md-6 col-xs-12">{{ 'copy.label.FEATURES.PERSONAL.nopersonal' | copy | async }}</span>
        </div>
      </div> -->
      <div class="mainAttendeeConsiderations" >
        <div *ngIf="attendee.DietaryNeed" class="form-summary">
          <label class="col-md-6 col-xs-12">{{ 'copy.label.FEATURES.DIETARY.label' | copy | async }}</label>
          <span class="form-summary-item col-md-6 col-xs-12" *ngIf="!attendee.DietaryConsiderations">{{dietaryNeedDisplay}}</span>
          <span class="form-summary-item col-md-6 col-xs-12" *ngIf="attendee.DietaryConsiderations">{{dietaryNeedDisplay}} - {{attendee.DietaryConsiderations}} </span>
        </div>
        
        <app-summary-display [ngmodel]="personalConsiderationsDisplay" *ngIf="personalConsiderationsDisplay">
          {{ 'copy.label.FEATURES.PERSONAL.ADAsummary' | copy | async }}</app-summary-display>
      </div>



      <div *ngIf="enrollment.$mtcHasAdminContact">
        <app-summary-display [ngmodel]="adminContact.AdminContactName">
          {{ 'copy.label.FEATURES.ADMIN.namelabel' | copy | async }}</app-summary-display>
        <app-phonesummary *ngIf="enrollment.$mtcHasAdminContact" [phone]="adminContact.AdminContactPhone[0]">{{ 'copy.label.FEATURES.ADMIN.phonelabel' | copy | async }}
        </app-phonesummary>
        <app-summary-display [ngmodel]="adminContact.AdminContactEmailAddress">
          {{ 'copy.label.FEATURES.ADMIN.email' | copy | async }}</app-summary-display>
      </div>
    </mat-expansion-panel>
    <!-- Guest Page -->
    <div *ngIf="siteFlowContains('guest')">
      <mat-expansion-panel *ngIf="!guests.length" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Guest
            <a class="edit-link" (click)="editGuest(undefined)">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="form-summary">
          <label class="col-md-6 col-xs-12">{{"copy.label.SUMMARY.noguest"|copy|async}}</label>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngFor="let guest of guests" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{guest.$mtcDisplayName}}
            <a class="edit-link" (click)="editGuest(guest)">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-summary-display [ngmodel]="guest.PreferredFirstName" *ngIf="guest.PreferredFirstName">
          {{ 'copy.label.FEATURES.NAME.preferredfirst' | copy | async }}</app-summary-display>
        <app-summary-display [ngmodel]="guest.PreferredLastName" *ngIf="guest.PreferredLastName">
          {{ 'copy.label.FEATURES.NAME.preferredlast' | copy | async }}</app-summary-display>
        <!--app-summary-display [ngmodel]="getGuestTypeDisplay(guest)">
          {{ 'copy.label.FEATURES.GUESTTYPE.heading' | copy | async }}</app-summary-display-->
        <!-- <div class="guestConsiderations">
          <div *ngIf="!guest.$mtcHasConsiderations" class="form-summary">
            <label class="col-md-6 col-xs-12">{{ 'copy.label.ATTENDEE.DIETARY.header' | copy | async }}</label>
            <span class="form-summary-item col-md-6 col-xs-12">{{ 'copy.label.FEATURES.PERSONAL.nopersonal' | copy | async }}</span>
          </div>
        </div> -->
        <div class="guestConsiderations">
          <div *ngIf="guest.DietaryNeed" class="form-summary">
            <label class="col-md-6 col-xs-12">{{ 'copy.label.FEATURES.DIETARY.label' | copy | async }}</label>
            <span class="form-summary-item col-md-6 col-xs-12" *ngIf="!guest.DietaryConsiderations">{{getGuestDiet(guest)}}</span>
            <span class="form-summary-item col-md-6 col-xs-12" *ngIf="guest.DietaryConsiderations">{{getGuestDiet(guest)}} - {{guest.DietaryConsiderations}} </span>
          </div>
          <app-summary-display [ngmodel]="getGuestADAPersonal(guest)" *ngIf="guest.DisabilityType !== undefined">
            {{ 'copy.label.FEATURES.PERSONAL.ADAsummary' | copy | async }}</app-summary-display>
        </div>
      </mat-expansion-panel>

    </div>
    <!-- Demographics Page -->
    <mat-expansion-panel *ngIf="siteFlowContains('demographics')" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Demographics
          <a class="edit-link" (click)="editDemographics()">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!--app-summary-display [ngmodel]="shirtSize.Value" *ngIf="shirtSize.Value">
        {{'copy.label.DEMOGRAPHICS.shirtsizelabel' | copy | async}}</app-summary-display>
      <app-summary-display [ngmodel]="firstTime.Value" *ngIf="firstTime.Value">
        {{'copy.label.DEMOGRAPHICS.firsttime'|copy|async}}</app-summary-display>
      <app-summary-display [ngmodel]="whyAttend.Value" *ngIf="whyAttend.Value">
        {{'copy.label.DEMOGRAPHICS.whyAttend' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="bestFeatures.Value" *ngIf="bestFeatures.Value">
        {{'copy.label.DEMOGRAPHICS.bestfeature' | copy | async }}</app-summary-display-->
    </mat-expansion-panel>
    <!-- Hotel Page -->
    <mat-expansion-panel *ngIf="siteFlowContains('hotel') || siteFlowContains('hotelpackage')" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Hotel
          <a class="edit-link" *ngIf="!isRegComplete" (click)="editHotel()">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
          <a class="edit-link" *ngIf="isRegComplete" (click)="editHotel()">{{ 'copy.label.SUMMARY.editlocked'| copy | async }}</a>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <span class="form-summary-item" *ngIf="!enrollment.HotelNeeded">{{ 'copy.label.HOTEL.nohotel'| copy | async }}</span>
      <div *ngIf="siteFlowContains('hotelpackage') && enrollment.HotelNeeded && hotelsLoaded">
        <div *ngFor="let hotelobj of getHotelsWithStays()">
          <span class="form-summary-item">
            <label class="summaryHeader"><strong>{{hotelobj.supplierName}}</strong></label>
            <div class="form-summary">
              <span class="form-summary-item">
                <div class="form-summary-line" *ngFor="let date of getNightsAtHotel(hotelobj.webHotelGroupID)">
                  <strong>{{date| date:'fullDate':"-0500"}} - {{getStatus(hotelobj, date)}}</strong>
                </div>
              </span>
            </div>
          </span>
        </div>
      </div>
      <div *ngIf="siteFlowContains('hotelbooking') && enrollment.HotelNeeded && hotelsLoaded">
        <div *ngFor="let hotelobj of getHotelsWithStays()">
          <span class="form-summary-item">
            <label class="summaryHeader"><strong>{{hotelobj.supplierName}}</strong></label>
            <div class="form-summary">
              <span class="form-summary-item">
                <div class="form-summary-line" *ngFor="let date of getNightsAtHotel(hotelobj.webHotelGroupID)">
                  <strong>{{date| date:'fullDate':"-0500"}} - {{getStatus(hotelobj, date)}}</strong>
                </div>
              </span>
            </div>
          </span>
        </div>
      </div>
      <div *ngIf="siteFlowContains('hotel') && enrollment.HotelNeeded">
        <app-summary-display [ngmodel]="this.enrollment.HotelCheckInDate| date:'fullDate'">
          {{'copy.label.FEATURES.HOTELPREF.checkin' | copy | async }}</app-summary-display>
        <app-summary-display [ngmodel]="this.enrollment.HotelCheckOutDate| date:'fullDate'">
          {{'copy.label.FEATURES.HOTELPREF.checkout' | copy | async }}</app-summary-display>
      </div>
      <!--app-summary-display *ngIf="hotelSmoking===true">{{'copy.label.HOTEL.smoking' | copy | async }}
      </app-summary-display>
      <app-summary-display *ngIf="hotelSmoking===false">{{'copy.label.HOTEL.nonsmoking' | copy | async }}
      </app-summary-display-->
      <app-summary-display [ngmodel]="enrollment.HotelSpecialRequests" *ngIf="enrollment.HotelSpecialRequests">Special Requests</app-summary-display>
    </mat-expansion-panel>
    <!-- Travel Page -->
    <mat-expansion-panel *ngIf="siteFlowContains('travel')" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Travel Request - {{attendee.$mtcDisplayName}} <a class="edit-link" *ngIf="!isRegComplete" (click)="editTravel()">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
          <a class="edit-link" *ngIf="isRegComplete" (click)="editTravel()">{{ 'copy.label.SUMMARY.editlocked'| copy | async }}</a>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!--div class="form-summary" *ngIf="attendee.TravelType === this.Constants.TRAVEL_TYPES.PRE_REG">
        <label class="col-md-6 col-xs-12">{{ 'copy.label.SUMMARY.alreadybooked'| copy | async }}</label>
      </div--> 
      <!--div class="form-summary" *ngIf="attendee.TravelType === this.Constants.TRAVEL_TYPES.OWN_AIR">
        <label class="col-md-6 col-xs-12">{{ 'copy.html.ownair'| copy | async }}</label>
      </div-->
   
      <div class="form-summary" *ngIf="attendee.TravelType === this.Constants.TRAVEL_TYPES.DRIVE">
        <label class="col-md-6 col-xs-12">{{ 'copy.label.SUMMARY.drive'| copy | async }}</label>
      </div>

      
      <div *ngIf="attendee.TravelType === this.Constants.TRAVEL_TYPES.FLY && isRegComplete" [innerHTML]='"copy.html.RvsMtcAir" | copy | async'></div>


      <app-flightsummary *ngIf="attendee.TravelType === this.Constants.TRAVEL_TYPES.FLY && !isRegComplete" [attendee]="attendee" [enrollment]="enrollment" [traveltype]="'FLY'"></app-flightsummary>
      <app-flightsummary *ngIf="attendee.TravelType === this.Constants.TRAVEL_TYPES.RAIL && !isRegComplete" [attendee]="attendee" [enrollment]="enrollment" [traveltype]="'RAIL'"></app-flightsummary>
      
      <app-summary-display *ngIf="varArrAirportTransportation.Value" [ngmodel]="varArrAirportTransportation.Value">
        {{ 'copy.label.TRAVEL.arrivalTransportation' | copy | async }}</app-summary-display>

        <app-summary-display *ngIf="varArrAirportTransportation.Value === 'Yes' && varArrAirportSelection.Value" [ngmodel]="varArrAirportSelection.Value">
          {{ 'copy.label.TRAVEL.airport' | copy | async }}</app-summary-display> 
    
        <app-summary-display *ngIf="varArrAirportSelection && varArrAirportSelection.Value === 'Private - FBO'" [ngmodel]="varArrivalTransferCode.Value">
        Arrival Airport Code</app-summary-display>


       <app-summary-display *ngIf="varDepartAirportTransportation.Value" [ngmodel]="varDepartAirportTransportation.Value">
          {{ 'copy.label.TRAVEL.departTransportation' | copy | async }}</app-summary-display>

         <app-summary-display *ngIf="varDepartAirportTransportation.Value === 'Yes' && varDepartAirportSelection.Value" [ngmodel]="varDepartAirportSelection.Value">
          {{ 'copy.label.TRAVEL.departairport' | copy | async }}</app-summary-display> 
          
          <app-summary-display *ngIf="varDepartAirportSelection && varDepartAirportSelection.Value === 'Private - FBO'" [ngmodel]="varDepartTransferCode.Value">
           Departure Airport Code</app-summary-display>
          
          <app-summary-display *ngIf="varHotelDatesMatch.Value" [ngmodel]="varHotelDatesMatch.Value">
            {{ 'copy.label.TRAVEL.matchDates' | copy | async }}</app-summary-display>


    </mat-expansion-panel>
    <div *ngIf="siteFlowContains('guest-travel') && attendee.TravelType === this.Constants.TRAVEL_TYPES.FLY">
      <mat-expansion-panel [expanded]="true" *ngFor="let guest of guests">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Travel Request - {{guest.$mtcDisplayName}} <a class="edit-link" *ngIf="!isRegComplete" (click)="editGuestTravel(guest)">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
            <a class="edit-link" *ngIf="isRegComplete" (click)="editGuestTravel(guest)">{{ 'copy.label.SUMMARY.editlocked'| copy | async }}</a>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="form-summary-item" *ngIf="guest.TravelType === this.Constants.TRAVEL_TYPES.PRE_REG">{{ 'copy.label.SUMMARY.alreadybooked'| copy | async }}</span>
        <span class="form-summary-item" *ngIf="guest.TravelType === this.Constants.TRAVEL_TYPES.DRIVE">{{ 'copy.label.SUMMARY.drive'| copy | async }}</span>
        <!--div *ngIf="guest.TravelType === this.Constants.TRAVEL_TYPES.OWN_AIR" 
        [innerHTML]='"copy.html.ownair" | copy | async'></div-->
        <div *ngIf="guest.TravelType === this.Constants.TRAVEL_TYPES.FLY && isRegComplete" [innerHTML]='"copy.html.RvsMtcAir" | copy | async'></div>
        <app-flightsummary *ngIf="guest.TravelType === this.Constants.TRAVEL_TYPES.FLY && !isRegComplete" [attendee]="guest" [enrollment]="enrollment" [traveltype]="'FLY'"></app-flightsummary>
        <app-flightsummary *ngIf="guest.TravelType === this.Constants.TRAVEL_TYPES.RAIL && !isRegComplete" [attendee]="guest" [enrollment]="enrollment" [traveltype]="'RAIL'"></app-flightsummary>
     
      </mat-expansion-panel>
    </div>
    <div *ngIf="siteFlowContains('payment') && !isRegComplete && showpayment">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"copy.label.PAYMENT.heading"|copy|async}} <a class="edit-link" *ngIf="!isRegComplete" (click)="editPayment()">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!--div *ngIf="(!charges.Conference || charges.Conference === 0 || charges.Conference === 0.0)">
          <div class="form-summary">
            <span class="form-summary-item w-100"><span class="form-summary-line">
                <h3 class="w-100">{{ 'copy.label.PAYMENT.FEESRT10.guarantee' | copy |async }}</h3>
              </span></span> 

          
            <div *ngIf="conferencePayment.MediaType === 'CREDITCARD'">
              <app-credit-card-display *ngIf="conferencePayment && conferencecard" cardType="Conference"></app-credit-card-display>
            </div>
          </div>
        </div-->
        <div *ngIf="charges.Conference > 0">
          <div class="form-summary">
            <span class="form-summary-item w-100"><span class="form-summary-line">
                <h3 class="w-100">{{ 'copy.label.PAYMENT.FEESRT10.conference' | copy |async }} {{charges.Conference | currency}} USD</h3>
              </span></span>
            <!--div *ngIf="conferencePayment.MediaType === 'CHECK'">
              <div [innerHTML]='"copy.html.Check"|copy|async'></div>
            </div-->
            <!--div *ngIf="conferencePayment.MediaType === 'WIRETRANSFER'">
              <div [innerHTML]='"copy.html.WireTransfer"|copy|async'></div>
            </div-->
            <!--div *ngIf="conferencePayment.MediaType === 'CHECK' || conferencePayment.MediaType === 'WIRETRANSFER'">
              <div *ngIf="guaranteecard.ReferenceKey">
                <h3 class="w-100">{{"copy.label.PAYMENT.FEESRT10.guarantee"|copy|async}}</h3>
                <app-credit-card-display cardType="Guarantee"></app-credit-card-display>
              </div>
            </div-->
            <div *ngIf="conferencePayment.MediaType === 'CREDITCARD'">
              <app-credit-card-display *ngIf="conferencePayment && conferencecard" cardType="Conference"></app-credit-card-display>
            </div>
          </div>
        </div>
        <div *ngIf="charges.Hotel > 0">
          <div class="form-summary">
            <span class="form-summary-item w-100"><span class="form-summary-line">
                <h3 class="w-100">{{"copy.label.PAYMENT.FEESRT10.hotel"|copy|async}} {{charges.Hotel | currency}} USD</h3>
              </span></span>
          
            <div *ngIf="hotelPayment.MediaType === 'CREDITCARD'">
              <app-credit-card-display *ngIf="hotelPayment && hotelcard" cardType="Hotel"></app-credit-card-display>
            </div>

          </div>
        </div>
        <div class="form-summary" *ngIf="programInfo.hotelGuarantee && (charges.Hotel === 0 || !charges.Hotel ) && enrollment.HotelNeeded">
          <h3 class="w-100">{{"copy.label.HOTEL.guarantee"|copy|async}}</h3>
          <app-credit-card-display *ngIf="hotelPayment && hotelcard" cardType="Hotel"></app-credit-card-display>
        </div>
      
        <!--div *ngIf="charges.Guest > 0">
          <div class="form-summary">
            <span class="form-summary-item w-100"><span class="form-summary-line">
                <h3 class="w-100">{{"copy.label.PAYMENT.FEESRT10.guest"|copy|async}} {{charges.Guest | currency}} USD</h3>
              </span></span>
            <div *ngIf="guestPayment.MediaType === 'CHECK'">
              <div [innerHTML]='"copy.html.Check"|copy|async'></div>
            </div>
            <div *ngIf="guestPayment.MediaType === 'WIRETRANSFER'">
              <div [innerHTML]='"copy.html.WireTransfer"|copy|async'></div>
            </div>
            <div *ngIf="guestPayment.MediaType === 'CHECK' || guestPayment.MediaType === 'WIRETRANSFER'">
              <div *ngIf="guaranteecard.ReferenceKey">
                <h3 class="w-100">{{"copy.label.PAYMENT.FEESRT10.guarantee"|copy|async}}</h3>
                <app-credit-card-display cardType="Guarantee"></app-credit-card-display>
              </div>
            </div>
            <div *ngIf="guestPayment.MediaType === 'CREDITCARD'">
              <app-credit-card-display *ngIf="guestPayment && guestcard" cardType="Guest"></app-credit-card-display>
            </div>
          </div>
        </div-->
      </mat-expansion-panel>
    </div>

    <!--div *ngIf="siteFlowContains('activity')">

    <mat-expansion-panel [expanded]="true" >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Activity Selection
          <a class="edit-link" (click)="editSessions()" *ngIf="!isRegComplete">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
          <a class="edit-link" *ngIf="isRegComplete" (click)="editSessions()">{{ 'copy.label.SUMMARY.editlocked'| copy | async }}</a>
        </mat-panel-title>
       

      </mat-expansion-panel-header>
      <activity-summary
      *ngIf="ready"
      [inputJson]="output"
      >
      </activity-summary>
    </mat-expansion-panel>
    </div-->

    <div class="button_group d-flex">
      <button id="forward-button" type="submit" class="inputSubmit btn btn-primary ml-auto" *ngIf="!isSubmitting && !isRegComplete">
        Submit
      </button>
      <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
        <i class="fas fa-spinner fa-spin"></i>
      </button>
    </div>
  </form>
  <div *ngIf="errorMsg">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{"copy.label.SUMMARY.heading"|copy|async}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <h2>{{"copy.label.SUMMARY.error"|copy|async}}</h2>
      <p>{{"copy.label.SUMMARY.errordetails"|copy|async}}</p>
      <p *ngIf="'travelHQ800Num'|copy|async">
        {{"copy.label.CONTACTUS.phone"|copy |async}} {{'travelHQ800Num' |copy |async}}
      </p>
      <p *ngIf="'travelHQIntlNum'|copy|async">
        {{"copy.label.CONTACTUS.phone"|copy|async}} {{'travelHQIntlNum'|copy|async}}
      </p>
      <p>{{"copy.label.CONTACTUS.email2"|copy|async}} <a href="mailto:{{'travelHQEmail'|copy|async}}">{{'travelHQEmail'|copy|async}}</a>
      </p>
      <p>{{"copy.label.CONTACTUS.hours"|copy|async}} {{'programSupportHours'|copy|async}}</p>
      <button (click)="errorMsg=undefined">Dismiss</button>
      <!-- <pre>REMOVE THIS BEFORE PROD: <br/>{{enrollment | json}}</pre> -->
    </mat-expansion-panel>
  </div>
</div>
