<!-- Regular pretrip content -->
<!-- <div class="contentFullVerticalHeight halfVerticalHeight">
    <div class="container">
        <div [innerHTML]='"copy.html.PretripTravel.PretripTravelCopy"|copy|async'></div>
    </div>
</div> -->

<!-- FIB content -->
<div class="contentFullVerticalHeight halfVerticalHeight">
    <div class="container">
        <div [innerHTML]='"copy.html.PretripTravel.PretripTravelCopy"|copy|async'></div>
        <div [innerHTML]='"copy.html.PretripTravel.PretripTravelCommonCopy"|copy|async'></div>
    </div>
</div>