import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class OnetimepasswordService {

  constructor(private env: EnvironmentService, private http: HttpClient) { }

  registerOTP(email){
    let url = this.env.getEnvironmentUrl('service/registerotp');
    return this.http.post(url,{email:email});
    //receive back OTP id, censored email and phone
  }

  selectMethod(method:String, guid:String){
    let url = this.env.getEnvironmentUrl('service/sendotp/'+method+'/'+guid);
    return this.http.get(url);
    //send via selected method
  }

  validateOTP(pass:String, key:String, method:String, guid:String){
    let url = this.env.getEnvironmentUrl('service/validateotp/'+method+'/'+guid);
    let data = {'OneTimePassword':pass,'ApplicationSessionID':key};
    return this.http.post(url, data)
  }


}
