import { Component, OnInit, Inject } from '@angular/core';
import { CopyService } from '../../services/copy.service';
import { RegistrationService } from '../../services/registration.service';
import { ConfigurationService } from '../../services/configuration.service';
import { EnvironmentService } from '../../services/environment.service';
import { ConstantsService } from '../../services/constants.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-forgot-confirmation',
  templateUrl: './forgot-confirmation.component.html'
})

export class ForgotConfirmationComponent implements OnInit {
  confirmationLookUp: any;
  isFormCompleted: boolean = false;
  isSubmitting: boolean = false;
  success: boolean = false;
  isError: boolean =false;

  constructor(
    private environmentService: EnvironmentService,
    public dialogRef: MatDialogRef<ForgotConfirmationComponent>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {

    this.confirmationLookUp = {
      lastName: "",
      email: ""
    };
  }

  ok(form) {
    this.isError = false;
    this.isFormCompleted = true;
    console.log(form);
    if (form.valid) {
      const serviceurl = this.environmentService.getEnvironmentUrl(
        'service/forgot-confirmation-no'
      );
      this.http.post(serviceurl, this.confirmationLookUp).subscribe(() => {
        this.success = true;
      }, () => {
        this.isError = true;
      });
    } else {
      this.isSubmitting = false;
    }

  };
}
