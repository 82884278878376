 <header class="modal-header">
  <h1 class="modal-title">Registration Fees</h1>
  <div class="close-container" (click)="onNoClick('&nbsp;')">
      <div class="leftright"></div>
      <div class="rightleft"></div>
  </div>
</header>
<form id="cart">
  <mat-dialog-content class="modal-body overflow-auto" >

      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>

    <table mat-table *ngIf="!isLoadingResults" [dataSource]="fees" class="mat-elevation-z8 cartTable">
      <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef> Line Item </th>
        <td mat-cell *matCellDef="let element"> {{element.desc}} </td>
        <td mat-footer-cell *matFooterCellDef> Total </td>
      </ng-container>
      <ng-container matColumnDef="cost">
        <th mat-header-cell *matHeaderCellDef> Price </th>
        <td mat-cell *matCellDef="let element"> {{element.cost |currency}} </td>
        <td mat-footer-cell *matFooterCellDef> {{total |currency}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: displayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  <!-- <table *ngIf="fees">
    <thead>
      <tr>
        <th>Item</th>
        <th>Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let fee of fees">
        <td>{{fee.description}}
          <span *ngIf="fee.quantity > 1"> (x{{fee.quantity}} @ {{fee.price| currency}})</span>
      </td>
      <td>
          <span >{{fee.price * fee.quantity| currency}}<br/></span>
      </td>
      </tr>
      <tr>
        <th>Total</th>
        <th>{{total| currency}}</th>
    </tr>
    </tbody>
  </table> -->
  </mat-dialog-content>


  <footer id="modal-footer">
    <div class="button_group d-flex flex-row justify-content-between">
      <button data-dismiss="modal" (click)="onNoClick('&nbsp;')" class="btn btn-outline-primary">{{"copy.label.GLOBAL.cancel"|copy|async}}</button>
    </div>
  </footer>
</form>
