import { Pipe, PipeTransform } from '@angular/core';
import { ContentService } from './content.service';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'content'
})
export class ContentPipe implements PipeTransform {

  constructor(private contentService: ContentService, private sanitized: DomSanitizer) { }

  transform(value: string) {
    return this.contentService.getContentByValue(value).then((res:string) => {
      console.log(res);
      return this.sanitized.bypassSecurityTrustHtml(res);
    });

  }

}
