import { Directive, Self, HostListener } from '@angular/core';
import {ControlContainer} from '@angular/forms';

@Directive({
  selector: 'form'
})
export class MarkAllAsTouchedDirective {
  @HostListener('submit')
  public onSubmit(): void {
    // console.log("on submit", this.container);
    this.container.control.markAllAsTouched();
  }

  constructor(@Self() private container: ControlContainer) {}

}
