import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConstantsService } from 'src/app/services/constants.service';
import { LineitemService } from 'src/app/services/lineitem.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
fees = [];
total;
displayedColumns: string[] = ['desc', 'cost'];
isLoadingResults = true;

  constructor(public dialogRef: MatDialogRef<CartComponent>,
    public lineItemService: LineitemService,
    public constantsService: ConstantsService,
    private cp: CurrencyPipe) { }

  ngOnInit(): void {
    this.lineItemService.getAllLineItems().subscribe({
      next: data => {
        data;
        this.total = 0;
        data.forEach((f)=>{
          let desc = f.description;
          if(f.quantity > 1){
            desc += " (x" + f.quantity + " @ " + this.cp.transform(f.price) + ")";
          }
          let cost = f.price * f.quantity;

          this.fees.push({"desc":desc, "cost":cost})
          this.total += f.price * f.quantity;


        });
        this.isLoadingResults = false;

        },
          error: error => {
            this.constantsService.ALERT_POLICY.CONTACT_SUPPORT
          }
    })
  }

  onNoClick(action): void {
    this.dialogRef.close(action);
  }

}
