<!-- FIB activities (plain text) -->
<!-- <div class="contentFullVerticalHeight halfVerticalHeight">
    <div class="container">
        <div [innerHTML]='"copy.html.FIBActivity.FIBActivityCopy"|copy|async'></div>
    </div>
</div> -->

<!-- FIB activities cards -->
<div class="contentFullVerticalHeight halfVerticalHeight">
    <div class="container">
        <div [innerHTML]='"copy.html.FIBActivity.ActivityCards"|copy|async'></div>
    </div>
</div>