<div class="modal-content-wrapper">
  <div class="modal-header">
    <h1 class="modal-title">{{ 'copy.label.SESSIONMODULE.title' | copy | async }}</h1>
  </div>
  <mat-dialog-content class="modal-body overflow-auto">
    <mat-card>
      <p *ngIf="!isRegComplete">{{ 'copy.label.SESSIONMODULE.message' | copy | async }}</p>
      <p *ngIf="isRegComplete">{{ 'copy.label.SESSIONMODULE.rvsmessage' | copy | async }}</p>
    </mat-card>
    <footer class="modal-footer">
      <button mat-button data-dismiss="modal" id="cancel-button" type="button" class="btn btn-default" (click)="logout()">
        {{ 'copy.label.SESSIONMODULE.cancel' | copy | async }}
      </button>
      <button *ngIf="!timesUp" id="cancel-button" type="button" class="btn btn-primary" (click)="refresh()">
        {{ 'copy.label.SESSIONMODULE.confirm' | copy | async }}
      </button>
    </footer>
  </mat-dialog-content>
</div>
