<div id="travel" class="registration container">
  <form #travelForm="ngForm" [ngClass]="{'form-complete': travelForm.valid}" name="travelForm" (ngSubmit)="forward(travelForm)" novalidate autocomplete="off" appScrollToInvalid [ngClass]="{'form-complete': isFormCompleted}">
    <h1>{{ 'copy.label.TRAVEL.heading' | copy | async }}</h1>
    <section class="card" *ngIf="airQues" >
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.TRAVEL.label' | copy | async }}
          <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <mat-radio-group class="w-100" [(ngModel)]="attendee.TravelType" [disabled]="isRegComplete" #traveltype="ngModel" name="traveltype" required (ngModelChange)="clearRequests()">
          <mat-radio-button *ngFor="let op of travelTypes" [value]="op.value">
            {{op.display | async }}
          </mat-radio-button>
          <div class="w-100">
            <mat-error><app-validation [object]="traveltype" isRequired=true></app-validation></mat-error>
          </div>
        </mat-radio-group>
      </article>
    </section>
    <section class="card" *ngIf= "attendee.TravelType === Constants.TRAVEL_TYPES.OWN_AIR || attendee.TravelType === Constants.TRAVEL_TYPES.FLY">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.TRAVEL.arrivalTransportation' | copy | async }}
          <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <mat-radio-group class="w-100" [(ngModel)]="varArrAirportTransportation.Value" [disabled]="isRegComplete" #arrtransportation="ngModel" name="arrtransportation" required>
          <mat-radio-button *ngFor="let op of yesNoArrival" [value]="op.value">
            {{op.display }}
          </mat-radio-button>
          <div class="w-100">
            <app-validation [object]="arrtransportation" isRequired=true></app-validation>
          </div>
        </mat-radio-group>

        <mat-form-field class="w-100" *ngIf="varArrAirportTransportation.Value === 'Yes' && attendee.TravelType === Constants.TRAVEL_TYPES.OWN_AIR">
          <mat-label for="arrivalairport" aria-labelledby="arrivalairport">{{ 'copy.label.TRAVEL.airport' | copy | async }}</mat-label>
          <mat-select name="arrivalairport" [(ngModel)]="varArrAirportSelection.Value" required>
            <mat-option *ngFor="let obj of transportList" [value]="obj.value">{{ obj.display }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100" *ngIf="varArrAirportTransportation.Value === 'Yes' && varArrAirportSelection.Value==='Private - FBO'">
          <mat-label  aria-labelledby="ArrivalTransfer">{{ 'copy.label.TRAVEL.airportCode' | copy | async }}</mat-label>
          <input matInput name="ArrivalTransfer" id="ArrivalTransfer" type="text" class="form-control" maxlength="75" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="varArrivalTransferCode.Value" required />
        </mat-form-field>

      </article>
    </section>

    <section class="card" *ngIf= "attendee.TravelType === Constants.TRAVEL_TYPES.OWN_AIR || attendee.TravelType === Constants.TRAVEL_TYPES.FLY">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.TRAVEL.departTransportation' | copy | async }}
          <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <mat-radio-group class="w-100" [(ngModel)]="varDepartAirportTransportation.Value" [disabled]="isRegComplete" #departtransportation="ngModel" name="departtransportation" required>
          <mat-radio-button *ngFor="let op of yesNoDepart" [value]="op.value">
            {{op.display }}
          </mat-radio-button>
          <div class="w-100">
            <app-validation [object]="departtransportation" isRequired=true></app-validation>
          </div>
        </mat-radio-group>

        <mat-form-field class="w-100" *ngIf="varDepartAirportTransportation.Value === 'Yes' && attendee.TravelType === Constants.TRAVEL_TYPES.OWN_AIR">
          <mat-label for="departairport" aria-labelledby="departairport">{{ 'copy.label.TRAVEL.departairport' | copy | async }}</mat-label>
          <mat-select name="departairport" [(ngModel)]="varDepartAirportSelection.Value" required>
            <mat-option *ngFor="let obj of transportList" [value]="obj.value">{{ obj.display }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100" *ngIf="varDepartAirportTransportation.Value === 'Yes' && varDepartAirportSelection.Value==='Private - FBO'">
          <mat-label  aria-labelledby="DepartTransfer">{{ 'copy.label.TRAVEL.airportCode' | copy | async }}</mat-label>
          <input matInput name="DepartTransfer" id="DepartTransfer" type="text" class="form-control" maxlength="75" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="varDepartTransferCode.Value" required />
        </mat-form-field>

      </article>
    </section>

    

    <div class="w-100" *ngIf="attendee.TravelType === Constants.TRAVEL_TYPES.FLY">
      <section class="card">
        <article>
          <div class="w-100" [innerHTML]='"copy.html.AirReservations" | copy | async'></div>
        </article>
      </section>
      <section class="card">
        <div class="card-header">
          <div class="card-title">
            {{"Air Request "}}
            <span *ngIf="attendee.PreferredFirstName === undefined && attendee.FirstName !== undefined">{{" - " + attendee.FirstName}}</span>
            <span *ngIf="attendee.PreferredFirstName !== undefined && attendee.FirstName === undefined">{{" - " + attendee.PreferredFirstName}}</span>
            <span *ngIf="attendee.PreferredFirstName !== undefined && attendee.FirstName !== undefined">{{" - " + attendee.PreferredFirstName}}</span>
          </div>
        </div>
        <article class="p-4">
          <div class="travelLinksPrimaryAndGuest">
            <div [innerHTML]='"copy.html.AirInformationRT10" | copy | async'></div>
            <p><u><a tabindex="0" (click)="openModal('TSAPrivacy', 'Security Requirement', 'Close')" (keydown.enter)="openModal('TSAPrivacy', 'Security Requirement', 'Close')"><strong>TSA Privacy Notice</strong></a></u></p>
            <p>US Citizens: New standards for state driver’s licenses and identification cards, set by the federal REAL ID Act, will be enforced.</p>
            <p><u><a tabindex="0"class="responsibilityLink" (click)="openModal('RealIDAct', 'REAL ID Act', 'Close')" (keydown.enter)="openModal('RealIDAct', 'REAL ID Act', 'Close')"><strong>REAL ID Act</strong></a></u></p>
          </div>
          <div class="w-100">
            <mat-form-field class="w-100">
              <mat-label for="Prefix" aria-labelledby="Prefix">
                {{ 'copy.label.FEATURES.NAME.prefixlabel' | copy | async }}</mat-label>
              <mat-select name="Prefix" id="Prefix" aria-label="Prefix" [(ngModel)]="attendee.Prefix" #Prefix="ngModel"  matNativeControl>
                <mat-option *ngFor="let obj of prefixList" [value]="obj.code">{{ obj.name }}</mat-option>
              </mat-select>
              <mat-error><app-validation [object]="Prefix"></app-validation></mat-error>
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label for="FirstName" aria-labelledby="FirstName" [ngClass]="{'active' : attendee.FirstName}">
                {{ 'copy.label.FEATURES.NAME.first' | copy | async }}</mat-label>
              <input matInput name="FirstName" id="FirstName" [attr.aria-label]="FirstName" type="text"  maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="attendee.FirstName" #FirstName="ngModel" required />
              <mat-error><app-validation [object]="FirstName" isRequired=true pattern="alpha"></app-validation></mat-error>
            </mat-form-field>
            <mat-form-field class="w-100" *ngIf="!attendee.NoMiddleNameFlag">
              <mat-label for="MiddleName" aria-labelledby="MiddleName" [ngClass]="{'active' : attendee.MiddleName}">
                {{ 'copy.label.FEATURES.NAME.middle' | copy | async }}</mat-label>
              <input matInput name="MiddleName" id="MiddleName" [attr.aria-label]="MiddleName" type="text"  maxlength="15" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="attendee.MiddleName" #MiddleName="ngModel" required />
              <mat-error><app-validation [object]="MiddleName" isRequired=true pattern="alpha"></app-validation></mat-error>
              <span *ngIf="isRegComplete">
                {{attendee.MiddleName}}
              </span>
            </mat-form-field>
            <div class="agreeCheckbox mb-2">
              <div class="checkboxFlexContainer" *ngIf="!isRegComplete && !attendee.MiddleName">
                <mat-checkbox name="mtcHasNoMiddleName" [(ngModel)]="attendee.NoMiddleNameFlag" *ngIf="!isRegComplete && !attendee.MiddleName" #mtcHasNoMiddleName="ngModel" required="true">
                  <!-- {{ 'copy.label.FEATURES.NAME.nomiddle' | copy | async }} -->
                </mat-checkbox>
                <label>I do not have a middle name or there is no middle name listed on my identification.</label>
              </div>
            </div>
            <div class="agreeCheckbox mb-2" *ngIf="!isRegComplete && attendee.MiddleName">
              <div class="checkboxFlexContainer">
                <mat-checkbox name="mtcHasNoMiddleName" [(ngModel)]="attendee.NoMiddleNameFlag" *ngIf="!isRegComplete && attendee.MiddleName" #mtcHasNoMiddleName="ngModel" required="false">
                  <!-- {{ 'copy.label.FEATURES.NAME.nomiddle' | copy | async }} -->
                </mat-checkbox>
                <label>I do not have a middle name or there is no middle name listed on my identification.</label>
              </div>
            </div>
            <mat-form-field class="w-100">
              <mat-label for="LastName" aria-labelledby="LastName" [ngClass]="{'active' : attendee.LastName}">
                {{ 'copy.label.FEATURES.NAME.last' | copy | async }}</mat-label>
              <input matInput name="LastName" id="LastName" [attr.aria-label]="LastName" type="text"  maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="attendee.LastName" #LastName="ngModel" required />
              <mat-error><app-validation [object]="LastName" isRequired=true pattern="alpha"></app-validation></mat-error>
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label for="Suffix" aria-labelledby="Suffix">{{ 'copy.label.FEATURES.NAME.suffix' | copy | async }}
              </mat-label>
              <mat-select name="Suffix" id="Suffix" aria-label="Suffix" [(ngModel)]="attendee.Suffix" matNativeControl>
                <mat-option *ngFor="let obj of suffixList" [value]="obj.code">{{ obj.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <app-date [participant]="attendee" fieldName="BirthDate" [submitted]="isError" label="copy.label.TRAVEL.birthdate" [isRequired]="true" minDate="1901-01-02"
            maxDate="today"></app-date>
            <div class="w-100 required">
              <label>{{ 'copy.label.FEATURES.GENDER.heading' | copy | async }}</label>
              <div>
                <mat-radio-group class="w-100" [(ngModel)]="attendee.Gender" [disabled]="isRegComplete" #gender="ngModel" name="gender" required>
                  <mat-radio-button *ngFor="let op of genderRadios" [value]="op.value">
                    {{op.display | async }}
                  </mat-radio-button>
                  <div class="w-100">
                    <mat-error><app-validation [object]="gender" isRequired=true></app-validation></mat-error>
                  </div>
                </mat-radio-group>
              </div>
            </div>
            <mat-form-field class="w-100">
              <mat-label for="RedressNumber" aria-labelledby="RedressNumber">
                {{ 'copy.label.FEATURES.TRAVELER.redress' | copy | async }}</mat-label>
              <input matInput name="RedressNumber" id="RedressNumber" [attr.aria-label]="RedressNumber" type="text" pattern="^[\w\s!@\-\_:',.\/?]*"  maxlength="13" [(ngModel)]="attendee.RedressNumber" #RedressNumber="ngModel" />
              <mat-error><app-validation [object]="RedressNumber" isRequired=false pattern="alphanum"></app-validation></mat-error>
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label for="KnownTravelerNumber" aria-labelledby="KnownTravelerNumber">
                {{ 'copy.label.FEATURES.TRAVELER.knownnum' | copy | async }}</mat-label>
              <input matInput name="KnownTravelerNumber" id="KnownTravelerNumber" [attr.aria-label]="KnownTravelerNumber" type="text" pattern="^[\w\s!@\-\_:',.\/?]*"  maxlength="25" [(ngModel)]="attendee.KnownTravelerNumber" #KnownTravelerNumber="ngModel" />
              <mat-error><app-validation [object]="KnownTravelerNumber" isRequired=false pattern="alphanum"></app-validation></mat-error>
            </mat-form-field>
            <div class="agreeCheckbox">
              <div class="checkboxFlexContainer">
                <mat-checkbox name="otherNumber" [(ngModel)]="otherNumberBox" #otherNum="ngModel" (change)="otherNumChange()">
                  {{ 'copy.label.FEATURES.TRAVELER.otherNumber' | copy | async }}
                </mat-checkbox>
                <mat-error><app-validation [object]="otherNum" isRequired=false ></app-validation></mat-error>
              </div>
            </div>
          </div>
        </article>
      </section>

      <section class="card">
        <div class="card-header">
          <div class="card-title">
            {{ 'copy.label.TRAVEL.departHome' | copy | async }}
          </div>
        </div>
        <article>
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="flightpref">
                  <div class="calendar-section">
                    <div class="calendar-container">
                      <p>
                        <strong>{{"copy.label.FEATURES.FLIGHTPREF.arrival"|copy | async}}</strong> {{groupArrivalDate| date: "fullDate": "-0500"}}
                      </p>
                      <p>
                        <strong>{{"copy.label.FEATURES.FLIGHTPREF.departure"|copy | async}}</strong> {{groupDepartureDate| date: "fullDate": "-0500"}}
                      </p>
                    </div>
                  </div>
                </div>
                <ngb-datepicker 
                [firstDayOfWeek]=7 
                [minDate]="startDate" 
                [maxDate]="endDate" 
                [(ngModel)]="outboundDateTime" 
                name="outboundDateTime" 
                (dateSelect)="updateModel()"
                [startDate]="outboundDateTime"
                ></ngb-datepicker>
              </div>
              <div class="col-md-6">
                <label>{{"copy.label.FEATURES.FLIGHTPREF.datelabel"|copy|async}}<span class="normal">{{attendee.$mtcOutbound.DepartureDateTime| date: "fullDate": "-0500"}}</span></label>
                <p class="colorGrey marginBottom50"><i>{{"copy.label.TRAVEL.usecalendar" |copy|async}}</i></p>
                <hr>
                <app-flight-pref [direction]="'Outbound'" [attendee]="attendee" [location]="attendee.$mtcOutbound.DepartureCityLocation"></app-flight-pref>
                <div class="w-100 required">
                  <label>{{ 'copy.label.FEATURES.FLIGHTPREF.timelabel' | copy | async }}</label>
                  <div>
                    <mat-radio-group [(ngModel)]="attendee.$mtcOutbound.DepartureTimePreference" name="times" aria-label="times" #times="ngModel" id="times" matNativeControl required>
                      <mat-radio-button *ngFor="let op of travelTimes" [value]="op.code">{{op.name}}
                      </mat-radio-button>
                      <div class="w-100">
                        <mat-error><app-validation [object]="times" isRequired=true></app-validation></mat-error>
                      </div>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
      <section class="card">
        <div class="card-header">
          <div class="card-title">
            {{ 'copy.label.TRAVEL.departDest' | copy | async }}
          </div>
        </div>
        <article>
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <ngb-datepicker 
                [firstDayOfWeek]=7 
                [minDate]="startDateRet" 
                [maxDate]="endDateRet" 
                [(ngModel)]="returnDateTime"
                 name="returnDateTime" 
                 (dateSelect)="updateModel()"
                 [startDate]="returnDateTime"></ngb-datepicker>
              </div>
              <div class="col-md-6">
                <label>{{"copy.label.FEATURES.FLIGHTPREF.datelabel"|copy|async}}<span class="normal">{{attendee.$mtcReturn.DepartureDateTime| date: "fullDate": "-0500"}}</span></label>
                <p class="colorGrey marginBottom50"><i>{{"copy.label.TRAVEL.usecalendar" |copy|async}}</i></p>
                <hr>
                <app-flight-pref [direction]="'Return'" [attendee]="attendee" [location]="attendee.$mtcReturn.DepartureCityLocation"></app-flight-pref>
                <!-- <mat-form-field class="w-100">
                  <mat-label for="DepartureCityLocation" aria-labelledby="DepartureCityLocation"
                    [ngClass]="{'active' : attendee.$mtcReturn.DepartureCityLocation}">
                    {{ 'copy.label.FEATURES.FLIGHTPREF.airportlabel' | copy | async }}</mat-label>
                  <input matInput name="DepartureCityLocation" id="DepartureCityLocation"
                    [attr.aria-label]="DepartureCityLocation" type="text"  maxlength="60"
                    pattern="[a-zA-Z ]+" [(ngModel)]="attendee.$mtcReturn.DepartureCityLocation" #DepartureCityLocation="ngModel"
                    required />
                  <mat-error><app-validation [object]="DepartureCityLocation" isRequired=true pattern="alpha"></app-validation></mat-error>
                </mat-form-field> -->
                <div class="w-100 required">
                  <label>{{ 'copy.label.FEATURES.FLIGHTPREF.timelabel' | copy | async }}</label>
                  <div>
                    <mat-radio-group [(ngModel)]="attendee.$mtcReturn.DepartureTimePreference" name="timesFlightPref" aria-label="timesFlightPref" #timesFlightPref="ngModel" id="timesFlightPref" matNativeControl required>
                      <mat-radio-button *ngFor="let op of travelTimes" [value]="op.code">{{op.name}}
                      </mat-radio-button>
                      <div class="w-100">
                        <mat-error><app-validation [object]="timesFlightPref" isRequired=true></app-validation></mat-error>
                      </div>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
      <section class="card">
        <div class="card-header">
          <div class="card-title">
            {{ 'copy.label.TRAVEL.flightprefheading' | copy | async }}
          </div>
        </div>
        <article>
          <div class="w-100 required">
            <label>{{ 'copy.label.FEATURES.SEATPREF.heading' | copy | async }}</label>
            <div>
              <mat-radio-group class="w-100" [(ngModel)]="attendee.InterRowPosition" [disabled]="isRegComplete" #interRow="ngModel" name="interRow" required>
                <mat-radio-button *ngFor="let op of flightPrefRadios" [value]="op.value">
                  {{op.display | async }}
                </mat-radio-button>
                <div class="w-100">
                  <mat-error><app-validation [object]="interRow" isRequired=true></app-validation></mat-error>
                </div>
              </mat-radio-group>
            </div>
          </div>
          <div class="w-100">
            <mat-form-field class="w-100">
              <mat-label>{{ 'copy.label.TRAVEL.specialrequest' | copy | async }}</mat-label>
              <textarea matInput class="w-100 form-control" name="AirSpecialRequests" id="AirSpecialRequests" aria-label="AirSpecialRequests" [(ngModel)]="enrollment.AirSpecialRequests" #AirSpecialRequests="ngModel" maxlength="225"  pattern="^[\w\s!@\-\_:&',.\/?]*"></textarea>
              <mat-error><app-validation [object]="AirSpecialRequests" isRequired=true pattern="alphanum"></app-validation></mat-error>
            </mat-form-field>
          </div>
&nbsp;
        <div class="w-100 required">
            <label>{{ 'copy.label.TRAVEL.matchDates' | copy | async }}</label>
            <mat-radio-group class="w-100" [(ngModel)]="varHotelDatesMatch.Value" [disabled]="isRegComplete" #matchDates="ngModel" name="matchDates" required >
              <mat-radio-button *ngFor="let op of matchList" [value]="op.value">
                {{op.display}}
              </mat-radio-button>
              <div class="w-100">
                <app-validation [object]="matchDates" isRequired=true></app-validation>
              </div>
            </mat-radio-group>
          </div>

        </article>
      </section>
      <section class="card">
        <div class="card-header">
          <div class="card-title">
            {{ 'copy.label.TRAVEL.ffheading' | copy | async }}
          </div>
        </div>
        <article>
          <div id="frequent-flyer" class="w-100">
            <div *ngFor="let flyer of attendee.FrequentFlyer; let x = index">
              <hr>
              <mat-form-field class="w-100">
                <mat-label for="airlineCode" aria-labelledby="airlineCode">
                  {{ 'copy.label.FEATURES.FREQFLY.ffairlinelabel' | copy | async }}
                </mat-label>
                <mat-select name="airlineCode{{x}}" id="airlineCode" aria-label="airlineCode" [(ngModel)]="flyer.AirlineCode" #airlineCode="ngModel" required matNativeControl>
                  <mat-option *ngFor="let o of filterAirlines(airlineList, flyer.AirlineCode)" [value]="o.code">{{o.name}}</mat-option>
                </mat-select>
                <mat-error><app-validation [object]="airlineCode" isRequired=true></app-validation></mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label for="AccountNo" aria-labelledby="AccountNo" [ngClass]="{'active' : flyer.AccountNo}">
                  {{ 'copy.label.FEATURES.FREQFLY.ffaccountlabel' | copy | async }}</mat-label>
                <input matInput name="AccountNo{{x}}" id="AccountNo" [attr.aria-label]="AccountNo" type="text"  maxlength="17" [(ngModel)]="flyer.AccountNo" #AccountNo="ngModel" required pattern="^[\w\s!@\-\_:',.\/?]*" />
                <mat-error><app-validation [object]="AccountNo" isRequired=true pattern="alphanum"></app-validation></mat-error>
              </mat-form-field>
              <div *ngIf="attendee.FrequentFlyer.length > 0">
                <button type="button" class="remove-frequent-flyer btn btn-danger" (click)="removeFrequentFlyer(flyer)">
                  <i class="fas fa-minus-circle"></i> {{"copy.label.FEATURES.FREQFLY.remove"|copy|async}}
                </button>
              </div>
              <hr>
            </div>
            <button type="button" class="add-frequent-flyer btn btn-success" (click)="addFrequentFlyer()">
              <i class="fas fa-plus-circle"></i> {{"copy.label.FEATURES.FREQFLY.add" | copy | async}}
            </button>
          </div>
        </article>
      </section>
    </div>
    
    <!--section class="card" *ngIf="attendee.TravelType === Constants.TRAVEL_TYPES.OWN_AIR">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.TRAVEL.ownairHeader' | copy | async }}
        </div>
      </div>
      <article>
        <div [innerHTML]='"copy.html.ownair" | copy | async'></div>
      </article>
    </section-->
    <div class="button_group d-flex">
      <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.goback' | copy | async }}
      </button>
      <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.continue' | copy | async }}
      </button>
      <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
        <i class="fas fa-spinner fa-spin"></i>
      </button>
    </div>
  </form>
</div>
