import { Component, OnInit, Input } from '@angular/core';
import { RegistrationService } from 'src/app/services/registration.service';
import { ControlContainer, NgForm } from '@angular/forms';


@Component({
  selector: 'app-credit-card-date',
  templateUrl: './credit-card-date.component.html',
  styleUrls: ['./credit-card-date.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CreditCardDateComponent implements OnInit {
  @Input() card:any;
  expirationYears = [];
  expirationMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  date = undefined;
  invaliddate = false;

  constructor() {
   }

  ngOnInit(): void {
    let thisyear = new Date().getFullYear();
    for (let i = thisyear; i <= thisyear + 8; i++) {
      this.expirationYears.push(i);
    }
  }

  displayMonth(m) {
    let temp = "" + m;
    if (temp.length === 1) {
      temp = "0" + temp;
    }
    return temp;
  }

  calcDate(){
    if (this.card.expMonth && this.card.expYear) {
      var selectedDate = new Date();
      selectedDate.setMonth(this.card.expMonth - 1);
      selectedDate.setFullYear(this.card.expYear);
      selectedDate.setDate(1);

      this.date = "" + selectedDate;
      if(selectedDate < new Date()){
        this.invaliddate=true;
      }else{
        this.invaliddate=false;
      }
    } else {
      this.date = undefined;
      this.invaliddate=false;
    }
  }
} 
