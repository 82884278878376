<!-- <h1 mat-dialog-title>{{"copy.label.CONTACTUS.title"|copy|async}}</h1>
 -->
<header class="modal-header">
  <h1 class="modal-title">{{"copy.label.CONTACTUS.title"|copy|async}}</h1>
  <div class="close-container" (click)="onNoClick('&nbsp;')">
      <div class="leftright"></div>
      <div class="rightleft"></div>
    </div>
  <!-- <button class="btn btn-outline-light closeButton"  (click)="onNoClick()">X</button> -->
</header>
<form name="contactForm" #contactForm="ngForm" [ngClass]="{'form-complete': isFormCompleted}" *ngIf="!success">
  <mat-dialog-content class="modal-body overflow-auto" *ngIf="!contactSent">
    <mat-form-field class="w-100">
      <mat-label for="firstName" aria-labelledby="firstName" [ngClass]="{'active' : contact.firstName}">{{ 'copy.label.CONTACTUS.first' | copy | async }}</mat-label>
      <input matInput name="firstName" id="firstName" [attr.aria-label]="firstName" type="text"  maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="contact.firstName" #firstName="ngModel" required />
      <mat-error><app-validation  [object]="firstName" isRequired=true pattern="alpha"></app-validation></mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label for="lastName" aria-labelledby="lastName" [ngClass]="{'active' : contact.lastName}">{{ 'copy.label.CONTACTUS.last' | copy | async }}</mat-label>
      <input matInput name="lastName" id="lastName" [attr.aria-label]="lastName" type="text"  maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="contact.lastName" #lastName="ngModel" required />
      <mat-error><app-validation  [object]="lastName" isRequired=true pattern="alpha"></app-validation></mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label for="email" aria-labelledby="email" [ngClass]="{'active' : contact.email}">{{ 'copy.label.CONTACTUS.email' | copy | async }}</mat-label>
      <input matInput type="text" name="email" id="email" aria-label="email"  maxlength="100" [(ngModel)]="contact.email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$" #email="ngModel" required />
      <mat-error><app-validation  [object]="email" isRequired=true pattern="email"></app-validation></mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label for="inquiry" aria-labelledby="inquiry">{{ 'copy.label.CONTACTUS.inquiry' | copy | async }}
      </mat-label>
      <mat-select name="inquiry" id="inquiry" aria-label="inquiry" [(ngModel)]="contact.inquiry" #inquiry="ngModel" required matNativeControl>
        <mat-option *ngFor="let o of options" [value]="o.value">{{o.translationId | async}}</mat-option>
      </mat-select>
      <mat-error><app-validation  [object]="inquiry" isRequired=true></app-validation></mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label for="message" aria-labelledby="message">{{ 'copy.label.CONTACTUS.message' | copy | async }}
      </mat-label>
      <textarea matInput class="w-100" name="message" id="message" aria-label="message" [(ngModel)]="contact.message" maxlength="225" #message="ngModel" required></textarea>
      <mat-error><app-validation  [object]="message" isRequired=true></app-validation></mat-error>
    </mat-form-field>

    <div class="">
      <button type="button" class="btn btn-link p-0 mb-2" (click)="toggleOtherMethodsDisplay()">{{"copy.label.CONTACTUS.contact"|copy|async}}</button>
      <div *ngIf="isOtherExpanded">
        <p>{{travelHQName}}</p>
        <p *ngIf="phone0">
          {{"copy.label.CONTACTUS.phone"|copy |async}} {{phone0}}
        </p>
        <p *ngIf="phone1">
          {{"copy.label.CONTACTUS.phone"|copy|async}} {{phone1}}
        </p>
        <p>{{"copy.label.CONTACTUS.email2"|copy|async}} <a href="mailto:{{emailInfo}}">{{emailInfo}}</a>
        </p>
        <p>{{"copy.label.CONTACTUS.hours"|copy|async}} {{hours}}</p>
      </div>
    </div>
  </mat-dialog-content>
  <footer *ngIf="!contactSent" id="modal-footer">
    <div class="button_group d-flex">
      <button (click)="reset(contactForm)" class="btn btn-outline-light resetBtn">{{"copy.label.GLOBAL.reset"|copy|async}}</button>
      <button data-dismiss="modal" (click)="onNoClick('&nbsp;')" class="btn btn-outline-light cancelBtn">{{"copy.label.GLOBAL.cancel"|copy|async}}</button>
      <button cdkfocusinitial (click)="ok(contactForm)" class="btn btn-outline-light sendBtn">{{"copy.label.GLOBAL.send"|copy|async}}</button>
    </div>
  </footer>
</form>
<mat-dialog-content class="modal-body" *ngIf="contactSent">
  <p class="w-100">{{"copy.label.CONTACTUS.sent"|copy|async}}</p><br />
  <p class="w-100">{{travelHQName}}</p>
  <div class="w-100">
    <p class="w-100" *ngIf="phone0">
      {{"copy.label.CONTACTUS.phone"|copy |async}} {{phone0}}
    </p>
    <p class="w-100" *ngIf="phone1">
      {{"copy.label.CONTACTUS.phone"|copy|async}} {{phone1}}
    </p>
    <p class="w-100">{{"copy.label.CONTACTUS.email2"|copy|async}} <a href="mailto:{{emailInfo}}">{{emailInfo}}</a>
    </p>
    <p class="w-100">{{"copy.label.CONTACTUS.hours"|copy|async}} {{hours}}</p>
  </div>
</mat-dialog-content>
<!-- <footer id="modal-footer">
  <div class="button_group d-flex">
    <button mat-button data-dismiss="modal" (click)="onNoClick('&nbsp;')" class="btn btn-default">{{"copy.label.GLOBAL.cancel"|copy|async}}</button>
  </div>
</footer> -->
