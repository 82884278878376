<div id="hotelbooking" class="registration container">
  <form #hotelbookingForm="ngForm" [ngClass]="{'form-complete': hotelbookingForm.valid}" name="hotelbookingForm" (ngSubmit)="forward(hotelbookingForm)" novalidate autocomplete="off" appScrollToInvalid [ngClass]="{'form-complete': isFormCompleted}">
    <h1>{{ 'copy.label.HOTEL.heading' | copy | async }}</h1>

    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.HOTEL.label' | copy | async }}
          <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <mat-radio-group [(ngModel)]="enrollment.HotelNeeded" name="hotelNeeded" #hotelNeeded="ngModel" required (ngModelChange)="clearRequests()">
          <mat-radio-button [value]="true" [checked]="enrollment.HotelNeeded === true">Yes</mat-radio-button>
          <mat-radio-button [value]="false" [checked]="enrollment.HotelNeeded === false">No</mat-radio-button>
          <div class="w-100">
            <mat-error><app-validation  [object]="hotelNeeded" isRequired=true ></app-validation></mat-error>
          </div>
        </mat-radio-group>
      </article>
    </section>
    <section class="card" *ngIf="enrollment.HotelNeeded === true">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.HOTEL.hotels' | copy | async }}
          <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <div class="error ng-invalid" *ngFor="let error of getNonContiguousHotels()">
          <strong><span class="fas fa-stack"><i class="fas fa-exclamation fa-stack-1x"></i><i class="fas fa-circle-thin fa-stack-2x"></i></span> Rooms selected not contiguous for {{error}}. </strong>
        </div>
        <div class="error ng-invalid" [hidden]="!noHotel">
          <strong><span class="fas fa-stack"><i class="fas fa-exclamation fa-stack-1x"></i><i class="fas fa-circle-thin fa-stack-2x"></i></span>Please select at least one hotel room.</strong>
        </div>
        <div class="table-responsive">
          <table class="table hotelTable" *ngIf="hotelList != undefined">
            <thead>
              <tr>
              <th></th>
              <th *ngIf="this.preextend > 0" class="extension extension-header" [attr.colspan]="preextend">Pre Extension</th>
              <th *ngIf="this.groupDays > 0" class="eventDates" [attr.colspan]="groupDays + 1">Event Dates</th>
              <th *ngIf="this.postextend > 0" class="extension extension-header" [attr.colspan]="postextend">Post Extension</th>
              </tr>
            </thead>

            <tbody *ngFor="let hotelobj of hotelList.roomTypeList">
              <tr>
                <th class="hotelDetails"><h2>{{hotelobj.supplierName}}</h2>
                  <!-- <a class="desktopDetails" ng-if="!hotelobj.displaySection" (click)="hotelCtrl.showSection(hotelobj)">{{"copy.label.HOTEL.BOOKING.showdetails"|copy|async}}</a>
                  <a class="desktopDetails" ng-if="hotelobj.displaySection" (click)="hotelCtrl.showSection(hotelobj)">{{"copy.label.HOTEL.BOOKING.hidedetails"|copy|async}}</a> -->
                  <p><a class="mobileDetails" (click)="showModal(hotelobj)">{{"copy.label.HOTEL.BOOKING.mobiledetails"|copy|async}}</a></p>
                </th>
                <th *ngFor="let n of dateRange"><span class="day">{{n|date:"EEE": "-0500"}}</span><span class="date">{{n| date:"MMM d": "-0500"}}</span></th>
              </tr>
              <tr>
                <td></td>
                <td *ngFor="let n of hotelobj.roomNightsListWithRate" [ngClass]="{'prepost': n.groupDateFlag!=='GRP'}">
                  <div class="d-flex justify-content-center flex-column align-items-center" *ngIf="isHotelAvailableOnDate(hotelobj, n.blockDate) && !n.isBookedAsPending">
                    <!-- <input type="checkbox" [checked]="isChecked(n.blockDate, hotelobj.webHotelGroupID)" (click)="addNight(n.blockDate, hotelobj.webHotelGroupID)"> -->
                    <mat-checkbox style="position: relative; z-index: 100;"  [checked]="isChecked(n.blockDate, hotelobj.webHotelGroupID)" (change)="addNight(n.blockDate, hotelobj.webHotelGroupID)">

                </mat-checkbox>
                    <div class="hotel_desc" *ngIf="n.nightNetRate !== 0">{{n.nightNetRate| currency}} </div>
                    <div class="hotel_desc" *ngIf="n.netRateAltText">{{n.netRateAltText}} </div>
                  </div>
                  <div class="d-flex justify-content-center flex-column align-items-center" *ngIf="isHotelAvailableOnDate(hotelobj, n.blockDate) && n.isBookedAsPending">
                    <!-- <input type="checkbox" [checked]="isChecked(n.blockDate, hotelobj.webHotelGroupID)" (click)="addNight(n.blockDate, hotelobj.webHotelGroupID)"> -->
                    <mat-checkbox style="position: relative; z-index: 100;" [checked]="isChecked(n.blockDate, hotelobj.webHotelGroupID)" (change)="addNight(n.blockDate, hotelobj.webHotelGroupID)">

                </mat-checkbox>
                    <div class="hotel_desc" *ngIf="n.nightNetRate !== 0">{{n.nightNetRate| currency}} </div>
                    <div class="hotel_desc" *ngIf="n.netRateAltText">{{n.netRateAltText}} </div>
                  </div>
                  <div class="d-flex justify-content-center flex-column align-items-center" *ngIf="!isHotelAvailableOnDate(hotelobj, n.blockDate)">
                    <span class="material-icons">
                      clear
                    </span>
                    <div class="hotel_desc">{{"copy.label.HOTEL.BOOKING.notavail"|copy|async}} </div>
                  </div>
                </td>
              </tr>
              <tr>&nbsp;&nbsp;</tr>
            </tbody>
          </table>
        </div>
      </article>
    </section>
    <section class="card" *ngIf="enrollment.HotelNeeded === true">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.HOTEL.roominfo' | copy | async }}
        </div>
      </div>
      <article>
        {{ 'copy.label.HOTEL.roompref' |copy |async}}
        <div class="radio-group">
          <mat-radio-group [(ngModel)]="attendee.HotelSmoking" name="smoking" >
            <mat-radio-button [value]="true" [checked]="attendee.HotelSmoking === true">Smoking</mat-radio-button>
            <mat-radio-button [value]="false" [checked]="attendee.HotelSmoking === false">Non-Smoking</mat-radio-button>
          </mat-radio-group>
        </div>
        <!-- <div>
          {{ 'copy.label.HOTEL.special' |copy |async}}
          <textarea name="hotelSpecialRequests" [(ngModel)]="enrollment.HotelSpecialRequests" maxlength="225"></textarea>
        </div> -->
        <div class="d-flex justify-content-start w-100">
          <mat-form-field class="w-100">
            <mat-label class="w-100" for="DietaryNeedOther" aria-labelledby="DietaryNeedOther">{{ 'copy.label.HOTEL.special' |copy |async}}</mat-label>
            <textarea matInput class="w-100" name="hotelSpecialRequests" [(ngModel)]="enrollment.HotelSpecialRequests" maxlength="225" pattern="^[\w\s!@\-\_:&',.\/?]*"></textarea>
          </mat-form-field>
        </div>
      </article>
  </section>

    <section class="card" *ngIf="enrollment.HotelNeeded === false">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.DEMOGRAPHICS.nohotellabel' | copy | async }}
          <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <mat-radio-group [(ngModel)]="enrollment.HotelSpecialRequests" name="noHotelReason" #noHotelReason="ngModel" required>
          <mat-radio-button *ngFor="let op of noHotelRadios" [value]="op.value">{{op.display}}</mat-radio-button>
          <div class="w-100">
            <mat-error><app-validation  [object]="noHotelReason" isRequired=true ></app-validation></mat-error>
          </div>
        </mat-radio-group>

      </article>
    </section>
    <div class="button_group d-flex">
      <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.goback' | copy | async }}
      </button>
      <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.continue' | copy | async }}
      </button>
      <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
        <i class="fas fa-spinner fa-spin"></i>
      </button>
    </div>
  </form>
</div>
