<div id="invitation" class="registration container">
    <h1>Decline Acknowledgement</h1>
    <section class="card">
        <div class="card-header">
            <div class="card-title ml-0">
                Response Submitted Successfully 
            </div>
        </div>
        <div class="card-body">
            <p class="w-100">Thank you for responding to our invitation to attend the {{programName}}. We are sorry you are not able to join us.</p>
            <p class="w-100">If you have questions, or decide you would like to attend, please contact {{travelHQName}} at {{phone}} or via email at <a href="mailto:{{email}}">{{email}}</a>.
            </p>
        </div>
    </section>
</div>