import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { CapacitysessionService } from '../../services/capacitysession.service';
import { RegistrationService } from '../../services/registration.service';

@Component({
  templateUrl: './selection.component.html'
})
export class SelectionComponent implements OnInit {
  isSubmitting = false;
  programOptionRadios1 =[];
  programOptionRadios2 =[];
  programOptionRadios3 =[];
  attendee;
  constructor(private navigationService: NavigationService,
    private capacityService: CapacitysessionService,
    private registrationService: RegistrationService
  ) {

    this.capacityService.getCapacity(1, "Wave 1", [1], "Wave 1: September 29 - October 07, 2023",this.programOptionRadios1);
    this.capacityService.getCapacity(2, "Wave 2", [2], "Wave 2: October 06-14, 2023", this.programOptionRadios2);
    this.capacityService.getCapacity(3, "Wave 3", [3], "Wave 3: October 13-21, 2023", this.programOptionRadios3);
    this.attendee = this.registrationService.getAttendee({});

  }

  ngOnInit(): void {}


  forward() {
    this.isSubmitting = true;
    if(this.attendee.$mtcCapacitySet){
      this.capacityService.updateCapacity(this.attendee.$mtcWave.capacityName).then(() => {
        this.navigationService.forward();
      });
    }else{
      this.capacityService.insertCapacity(this.attendee.$mtcWave.capacityName).then(() => {
        this.attendee.$mtcCapacitySet = true;
        this.navigationService.forward();
      });
    }
  }

  radioChange(event){
    console.log(event.value);
    this.attendee.$mtcWave = event.value;
    this.attendee.WaveNo = event.value.value;

  }

  back() {
    this.navigationService.back();
  }
}
