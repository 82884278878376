<div id="arrivaldate">
  <h3>
    {{ 'copy.label.FEATURES.HOTELPREF.checkin' | copy | async }}:
    <span class="normal">{{ format(hotelCheckIn) | date: "fullDate"}}</span>
  </h3>
  <ngb-datepicker
    [firstDayOfWeek]=7
    [(ngModel)]="hotelCheckIn"
    [minDate]="hotelArrivalDateBegin"
    [maxDate]="hotelArrivalDateEnd"
    (dateSelect)="updateModel()"
    [startDate]="hotelCheckIn"
  ></ngb-datepicker>
</div>

<div id="departuredate">
  <h3>
    {{ 'copy.label.FEATURES.HOTELPREF.checkout' | copy | async }}:
    <span class="normal">{{ format(hotelCheckOut) | date: "fullDate"}}</span>
  </h3>
  <ngb-datepicker
    [firstDayOfWeek]=7
    [(ngModel)]="hotelCheckOut"
    [minDate]="hotelDepartureDateBegin"
    [maxDate]="hotelDepartureDateEnd"
    (dateSelect)="updateModel()"
    [startDate]="hotelCheckOut"
  ></ngb-datepicker>
</div>

<!-- <button (click)="show = !show">show/hide</button> -->
