<div class="summaryCardDisplay">
  <div class="cardImage">
    <img src="assets/images/visa.png" *ngIf="cardused.type === 'VISA'" />
    <img src="assets/images/amex.png" *ngIf="cardused.type === 'AMEX'" />
    <img src="assets/images/mastercard.png" *ngIf="cardused.type === 'MASTERCARD'" />
    <img src="assets/images/discover.png" *ngIf="cardused.type === 'DISCOVER'" />
  </div>
  <div class="cardInfo">
    <label>Name on Card: </label>
    <span>{{cardused.name}}</span>
  </div>
  <div class="cardInfo">
    <label>Card Number: </label>
    <span>{{cardused.masked}}</span>
  </div>
  <div class="cardInfo">
    <label>Expiration Date: </label>
    <span>{{cardused.expMonth}}/{{cardused.expYear}}</span>
  </div>
  <div class="cardInfo" *ngIf="cardused.zip">
    <label>Billing Zip Code: </label>
    <span>{{cardused.zip}}</span>
  </div>
</div>
