import { Component, OnInit, Input } from '@angular/core';
import { ListManagerService } from '../../../services/list-manager.service';
import { element } from 'protractor';

@Component({
  selector: 'app-addresssummary',
  templateUrl: './addresssummary.component.html'
})
export class AddresssummaryComponent implements OnInit {

  @Input() address: any;

  countryList: any;
  selectedCountry: any;

  constructor(private listService: ListManagerService) { }

  ngOnInit(): void {
    this.listService.getCountryList().then(list => {
      this.countryList = list;
      this.countryList.forEach(element => {
        if (element.code === this.address.CountryISOCode) {
          this.selectedCountry = element.name;
        }
      })
    });
  }

}
