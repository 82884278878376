<div id="profile" class="page container" >
  <div class="card profileCard">
    <div class="card-header">
      <div class="card-title">
        Travel Profile
      </div>
    </div>
    <mtc-profile [inputJson]="output" (skip)="handleUpdates($event)" (prefill)="prefill($event)"></mtc-profile>
    <!-- <mtc-component></mtc-component> -->
    <!-- <mtc-basecomponent></mtc-basecomponent> -->
  </div>
</div>
