import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { AttendeeGroupService } from './attendee-group.service';
import { group } from '@angular/animations';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  config: any;
  attendeeGroupConfig: any;
  promise;
  inprogress:boolean = false;
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private attendeeGroupService: AttendeeGroupService
  ) {}



  fetchConfig(recalculate?:boolean) {
    if (this.promise && this.inprogress) {
      return this.promise;
    } else {
      this.inprogress = true;
      this.promise = new Promise((resolve, reject) => {
        if (this.config) {
          if(recalculate){
            this.applyAttendeeGroups().then(() => {
              this.inprogress = false;
              resolve(this.attendeeGroupConfig);
            });
          }else{
            this.inprogress = false;
            resolve(this.attendeeGroupConfig);
          }

        } else {
          const serviceurl = this.environmentService.getEnvironmentUrl(
            'service/configuration'
          );
          this.http.get(serviceurl).subscribe((data) => {
            this.config = data;
            this.applyAttendeeGroups().then(() => {
              this.inprogress = false;
              resolve(this.attendeeGroupConfig);
            });
          });
        }
      });
      return this.promise;
    }
  }


  applyAttendeeGroups() {
    let promise = new Promise((resolve, reject) => {
      this.attendeeGroupService
        .getAttendeeGroups()
        .then((groups: Array<any>) => {
          let descList = ['default'];
          groups.forEach((group) => {
            descList.push(group.description);
          });
          var filteredResult = this.traverse(this.config, descList);
          this.attendeeGroupConfig = filteredResult;
          resolve(this.attendeeGroupConfig);
        });
    });
    return promise;
  }

  traverse(config: Array<any>, groupArray) {
    const result = {};
    for (const key of Object.keys(config)) {
      if (Array.isArray(config[key]) && config[key][0].hasOwnProperty('val')) {
        let lowestSeq = 999;
        let value;
        config[key].forEach((configval) => {
          // tslint:disable-next-line: radix
          const seqno = parseInt(configval.seqno) ? parseInt(configval.seqno) : 998;
          if (
            configval.attendeegroup &&
            _.includes(groupArray, configval.attendeegroup) &&
            seqno < lowestSeq
          ) {
            lowestSeq = seqno;
            value = configval.val;
          }
        });
        result[key] = value;
      } else if (typeof config[key] === 'object') {
        result[key] = this.traverse(config[key], groupArray);
      }
    }
    return result;
  }
}
