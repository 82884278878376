import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  Enrollment;

  constructor(private Constants: ConstantsService) { }

  makeGuestTravelTypeMatchPrimary(enrollment) {
    if (
      enrollment &&
      enrollment.Participant &&
      enrollment.Participant.length > 1
    ) {
      const travelType = _.result(
        _.find(enrollment.Participant, { MainPAX: true }),
        'TravelType'
      );
      _.each(_.filter(enrollment.Participant, { MainPAX: false }),  (
        guest
      )=> {
        if (
          guest.TravelType === undefined &&
          (travelType === 'OWNAIR' ||
            travelType === 'DRIVE' ||
            travelType === 'FLY' ||
            travelType === 'RAIL')
        ) {
          guest.TravelType = travelType;
        }
      });
    }
    return enrollment;
  }

  cleanseStorage(object) {
    const objectcopy = _.cloneDeep(object);
    this.makeGuestTravelTypeMatchPrimary(objectcopy);
    return this.Ripper()
      .using('$mtcForm')
      .using('$$')
      .clean(objectcopy);
  }

  cleanse(object) {
    const objectcopy = _.cloneDeep(object);
    this.makeGuestTravelTypeMatchPrimary(objectcopy);
    //                this.convertTravelTypePrereg(objectcopy);
    const substitute = this.Substitutions();
    return this.Ripper()
      .using(substitute.specialChars)
      .using(substitute.paxVariableData)
      .using(substitute.paxPhone)
      .using(substitute.paxEmail)
      .using(substitute.paxAddress)
      .using(substitute.paxEmergencyContactPhone)
      .using(substitute.paxTravelDocument)
      .using(substitute.paxAirPreference)
      .using(substitute.adminContactPhone)
      .using(substitute.paxTypeNo)
      .using(substitute.creditCard)
      .using('$$') //TODO: do I need to add mtcID to this list? -kb
      .using('$mtc')
      .using('enrollmentNo')
      .using('programNo')
      .using('webSecurityLoginID')
      .using(substitute.nullcheck)
      .clean(objectcopy);
  }

  Ripper() {
    function compose(second, first) {
      if (second === undefined) {
        return first;
      }
      if (first === undefined) {
        return second;
      }
      return (key, value) => {
        const result = first(key, value);
        return result !== undefined ? second(key, result) : undefined;
      };
    }

    return {
      replacer: undefined,
      using(f) {
        if (f === undefined) {
          this.replacer = compose(this.replacer, undefined);
        } else if (typeof f === 'function') {
          this.replacer = compose(this.replacer, f);
        } else if (typeof f === 'string') {
          this.replacer = compose(this.replacer, (key, value) => {
            return key.toString().indexOf(f) === 0 ? undefined : value;
          });
        } else if (typeof f.test === 'function') {
          this.replacer = compose(this.replacer, (key, value) => {
            return f.test(key.toString()) ? undefined : value;
          });
        } else {
          this.replacer = compose(this.replacer, (key, value) => {
            return key === undefined ? undefined : value;
          });
        }
        return this;
      },
      clean(target) {
        const s = JSON.stringify(target, this.replacer);
        return s === undefined ? undefined : JSON.parse(s);
      }
    };
  }

  Substitutions() {
    return {
      /**
       * @function RegistrationService.paxVariableData
       * @description See the cleanse function.
       * @see RegistrationService.cleanse
       */
      paxVariableData: function paxVariableData(key, value) {
        if (!(key === 'PAXVariableData' && Array.isArray(value))) {
          return value;
        }

        return _.filter(value, (element) => {
          if (!element.Value) {
            element.Value = ''; //needed for RVS values to be wiped out.
          }
          return typeof element.WebSequenceNo !== 'undefined';
        });
      },
      /**
       * @function RegistrationService.paxAirPreference
       * @description See the cleanse function.
       */
      paxAirPreference: function paxAirPreference(key, value) {
        if (!(key === 'PAXAirPreference' && Array.isArray(value))) {
          return value;
        }

        return _.filter(value, (element) => {
          return (
            typeof element.DirectionType === 'string' &&
            typeof element.SequenceNo !== 'undefined'
          );
        });
      },
      /**
       * @function RegistrationService.paxEmail
       * @description See the cleanse function.
       */
      paxEmail: function paxEmail(key, value) {
        if (!(key === 'Email' && Array.isArray(value))) {
          return value;
        }

        return _.filter(value, (element) => {
          return (
            typeof element.EmailAddress === 'string' &&
            typeof element.EmailType !== 'undefined'
          );
        });
      },
      /**
       * @function RegistrationService.paxTypeNo
       * @description See the cleanse function.
       */
      paxTypeNo: function paxTypeNo(key, value) {
        if (key !== 'PAXTypeNo' || typeof value !== 'number') {
          return value;
        }

        return value;
      },
      /**
       * @function RegistrationService.paxPhone
       * @description See the cleanse function.
       */
      paxPhone: function paxPhone(key, value) {
        if (!(key === 'Phone' && Array.isArray(value))) {
          return value;
        }

        return _.filter(value, (element) => {
          return (
            typeof element.PhoneNo === 'string' && element.PhoneNo !== '' &&
            typeof element.PhoneType !== 'undefined' &&
            element.PhoneNo.length > 0
          );
        });
      },
      /**
       * @function RegistrationService.paxAddress
       * @description See the cleanse function.
       */
      paxAddress: function paxAddress(key, value) {
        if (!(key === 'Address' && Array.isArray(value))) {
          return value;
        }

        return _.filter(value, (element) => {
          //element.AddressLine1 is not required by the XSD, if the site needs only a country to go in this can be removed
          return (
            typeof element.AddressLine1 === 'string' && element.AddressLine1 !== '' &&
            typeof element.CountryISOCode === 'string' &&
            typeof element.AddressType !== 'undefined' &&
            ((element.CountryISOCode !== 'US' && element.CountryISOCode !== 'CA')
              || ( typeof element.Zip === 'string' && element.Zip !== ''
              && typeof element.City === 'string' && element.City !== ''
              && typeof element.StateProvince === 'string' && element.StateProvince !== '')
            )
          );
        });
      },
      /**
       * @function RegistrationService.paxEmergencyContactPhone
       * @description See the cleanse function.
       */
      paxEmergencyContactPhone: function paxEmergencyContactPhone(key, value) {
        if (!(key === 'EmergencyContactPhone' && Array.isArray(value))) {
          return value;
        }

        return _.filter(value, (element) => {
          return (
            typeof element.PhoneNo === 'string' && element.PhoneNo !== '' &&
            typeof element.ContactTime !== 'undefined' &&
            element.PhoneNo.length > 0
          );
        });
      },
      /**
       * @function RegistrationService.paxTravelDocument
       * @description See the cleanse function.
       */
      paxTravelDocument: function paxTravelDocument(key, value) {
        if (!(key === 'TravelDocument' && Array.isArray(value))) {
          return value;
        }

        return _.filter(value, (element) => {
          return (
            typeof element.DocumentNo === 'string' &&
            typeof element.DocumentType !== 'undefined'
          );
        });
      },
      /**
       * @function RegistrationService.adminContactPhone
       * @description See the cleanse function.
       */
      adminContactPhone: function adminContactPhone(key, value) {
        if (!(key === 'AdminContactPhone' && Array.isArray(value))) {
          return value;
        }

        return _.filter(value, (element) => {
          return (
            typeof element.PhoneNo === 'string' &&
            typeof element.PhoneType !== 'undefined' &&
            element.PhoneNo.length > 0
          );
        });
      },
      /**
       * @function RegistrationService.creditCard
       * @description See the cleanse function.
       */
      creditCard: function creditCard(key, value) {
        if (!(key === 'CreditCard' && Array.isArray(value))) {
          return value;
        }

        return _.filter(value, (element) => {
          return (
            typeof element.ReferenceKey === 'string' &&
            typeof element.Category !== 'undefined'
          );
        });
      },
      /**
       * @function RegistrationService.specialChars
       * @description See the cleanse function.
       */
      specialChars: function specialChars(key, value) {
        if (typeof value !== 'string') {
          return value;
        }
        //TODO date stuff
        // if (DateUtilityService.getMTCZoneDateTimeString(value)) {
        //   return value;
        // }
        return value.replace(/[^\w\s!@\-\(\)&\_\\:',.\/?]/gi, '');        
      },
      nullcheck: function nullcheck(key, value) {
        if (value !== null) {
          return value;
        }
        return undefined;
      }
    };
  }

  isSaneEnumerationValue(enumeration, value) {
    if (!enumeration) {
      return true;
    }
    if (!value) {
      return false;
    }
    if (Array.isArray(value)) {
      const recursive = this;
      return _.reduce(
        value,
        (a, v) => {
          return a && recursive.isSaneEnumerationValue(enumeration, v);
        },
        true
      );
    }

    const enumValues = _.values(enumeration);
    if (!_.isObject(value)) {
      return _.includes(enumValues, value);
    } else {
      return _.find(enumValues, value);
    }
  }

  saneValue(enumeration, value) {
    if (this.isSaneEnumerationValue(enumeration, value)) {
      return value;
    }
    const enumDisplayVal = enumeration
      ? _.keys(enumeration).join("', '")
      : 'invalid enum';
    throw new Error(
      "Value '" + value + "' expected to be among '" + enumDisplayVal + "'"
    );
  }

  sanePropertyValue(enumeration, object, key) {
    if (object && key) {
      let value = object[key];
      value = !value || this.saneValue(enumeration, value);
      return object;
    }
    return undefined;
  }

  find(collection, selector) {
    const key = _.keys(selector)[0];
    if (selector && selector[key]) {
      return _.find(collection, selector);
    }
    return collection && collection.length > 0 ? collection[0] : undefined;
  }

  locate(collection, selector, enumeration, defaults) {
    const key = _.keys(selector)[0];
    const item = this.find(
      collection,
      this.sanePropertyValue(enumeration, selector, key)
    );
    if (item) {
      return this.sanePropertyValue(enumeration, item, key);
    }
    const defaultValue =
      defaults && _.keys(defaults).length === 0 ? selector : defaults;
    if (defaultValue) {
      return this.sanePropertyValue(
        enumeration,
        this.insert(collection, selector, defaultValue),
        key
      );
    }
    return undefined;
  }

  insert(collection, mixin, defaults) {
    if (!defaults) {
      return undefined;
    }

    if (Array.isArray(defaults)) {
      defaults.forEach((element) => {
        collection.push(_.merge(element, mixin));
      });
      return defaults;
    }

    const v = _.merge(defaults, mixin);
    collection.push(v);
    return v;
  }

  getEnrollment(defaults) {
    if (!this.Enrollment) {
      this.Enrollment = defaults;
    }
    return this.Enrollment;
  }

  getParticipant(defaults) {
    const e = this.getEnrollment({ Participant: [] });
    if (!e.Participant) {
      e.Participant = [];
      this.insert(e.Participant, {}, defaults);
    }
    return e.Participant;
  }

  getAttendee(defaults) {
    const e = this.getEnrollment({
      Participant: []
    });
    if (!e.Participant) {
      e.Participant = [];
    }
    const find = _.find(e.Participant, {
      MainPAX: true
    });
    return (
      find ||
      this.insert(
        e.Participant,
        {
          MainPAX: true,
          FrequentFlyer: []
        },
        defaults
      )
    );
  }

  getVariable(attendee, seqno, defaults) {
    if (!attendee) {
      return undefined;
    }
    if (!seqno) {
      return undefined;
    }
    if (!attendee.PAXVariableData) {
      attendee.PAXVariableData = [];
    }

    return this.locate(
      attendee.PAXVariableData,
      {
        WebSequenceNo: seqno
      },
      undefined,
      defaults
    );
  }

  getAdminContact(defaults) {
    const e = this.getEnrollment({});
    if (!e.AdminContact) {
      e.AdminContact = defaults;
    }
    if (defaults) {
      if (!e.AdminContact.AdminContactPhone) {
        e.AdminContact.AdminContactPhone = [];
      }
      this.locate(
        e.AdminContact.AdminContactPhone,
        {
          PhoneType: this.Constants.PHONES.WORK
        },
        this.Constants.PHONES,
        {}
      );
    }
    return e.AdminContact;
  }

  getGuests(defaults) {
    const e = this.getEnrollment({
      Participant: []
    });
    const find = _.filter(e.Participant, {
      MainPAX: false
    });
    if (!find || find.length > 0) {
      return find;
    }
    return _.filter(e.Participant, {
      MainPAX: false
    });
  }

  addGuests(guests) {
    const e = this.getEnrollment({
      Participant: []
    });
    this.insert(
      e.Participant,
      {
        MainPAX: false,
        FrequentFlyer: []
      },
      guests
    );
  }

  getAddress(attendee, type, defaults) {
    if (!attendee) {
      return undefined;
    }
    if (!attendee.Address) {
      attendee.Address = [];
    }
    return this.locate(
      attendee.Address,
      {
        AddressType: type
      },
      this.Constants.ADDRESSES,
      defaults
    );
  }

  getDisabilities(attendee, defaults) {
    if (!attendee) {
      return undefined;
    }
    if (!attendee.DisabilityType) {
      attendee.DisabilityType = [];
      if (!defaults) {
        return undefined;
      }
      attendee.DisabilityType.push(
        this.saneValue(this.Constants.DISABILITIES, defaults)
      );
    }
    return attendee.DisabilityType;
  }

  getTravelDocument(attendee, type, defaults) {
    if (!attendee) {
      return undefined;
    }
    if (!attendee.TravelDocument) {
      attendee.TravelDocument = [];
    }
    return this.locate(
      attendee.TravelDocument,
      {
        DocumentType: type
      },
      this.Constants.DOCUMENT_TYPES,
      defaults
    );
  }

  getPAXAirPreference(attendee, type, defaults) {
    if (!attendee) {
      return undefined;
    }
    if (!attendee.PAXAirPreference) {
      attendee.PAXAirPreference = [];
    }
    const seqn = attendee.PAXAirPreference.reduce((accum, elem) => {
      if (typeof elem.SequenceNo === 'number' && accum < elem.SequenceNo) {
        return elem.SequenceNo;
      }
      return accum;
    }, 0);
    return this.locate(
      attendee.PAXAirPreference,
      {
        DirectionType: type,
        SequenceNo: seqn
      },
      this.Constants.AIRPREF_DIRECTIONS,
      defaults
    );
  }

  getFrequentFlyer(attendee) {
    if (!attendee) {
      return undefined;
    }
    if (!attendee.FrequentFlyer) {
      attendee.FrequentFlyer = [];
    }
    return attendee.FrequentFlyer;
  }

  getOwnAir(participant) {
    if (!participant.PAXOwnAir) {
      participant.PAXOwnAir = [];
    }
    return participant.PAXOwnAir;
  }

  getPhone(attendee, type, defaults) {
    if (!attendee) {
      return undefined;
    }
    if (!attendee.Phone) {
      attendee.Phone = [];
    }
    const phone = this.locate(
      attendee.Phone,
      {
        PhoneType: type
      },
      this.Constants.PHONES,
      defaults
    );
    if (phone) {
      if (!phone.PhoneNo) {
        phone.PhoneNo = '';
      }
      if (!phone.AreaCityCode) {
        phone.AreaCityCode = '';
      }
    }
    return phone;
  }

  getEmail(attendee, type, defaults) {
    if (!attendee) {
      return undefined;
    }
    if (!attendee.Email) {
      attendee.Email = [];
    }
    return this.locate(
      attendee.Email,
      {
        EmailType: type
      },
      this.Constants.EMAILS,
      defaults
    );
  }

  getEmergencyContactPhone(attendee, type, defaults) {
    const selector = {
      ContactTime: type
    };
    if (!attendee.EmergencyContact) {
      if (!defaults) {
        return undefined;
      }
      attendee.EmergencyContact = {
        EmergencyContactPhone: []
      };
    }

    attendee.EmergencyContact.EmergencyContactPhone =
      attendee.EmergencyContact.EmergencyContactPhone || [];

    const phone = this.locate(
      attendee.EmergencyContact.EmergencyContactPhone,
      selector,
      this.Constants.PHONE_TIMES,
      defaults
    );
    if (phone) {
      if (!phone.PhoneNo) {
        phone.PhoneNo = '';
      }
      if (!phone.AreaCityCode) {
        phone.AreaCityCode = '';
      }
    }

    return phone;
  }

  getPayment(defaults) {
    const e = this.getEnrollment({});
    if (!e.Payment) {
      e.Payment = defaults;
    }
    return e.Payment;
  }

  getCreditCard(payment, type, defaults) {
    if (!payment) {
      payment = [];
    }
    return this.locate(
      payment,
      {
        Category: type
      },
      undefined,
      defaults
    );
  }

  getPaymentMedia() {
    let payment = this.getPayment([]);
    if (!payment) {
      payment = [];
    }
    const mediaTypes = [];
    for (const element of payment) {
      if (element.MediaType) {
        mediaTypes.push(element.MediaType);
      }
    }
    return mediaTypes;
  }

  getHotel(defaults) {
    const e = this.getEnrollment({});
    if (!e.WebHotelBooking) {
      e.WebHotelBooking = defaults;
    }
    return e.WebHotelBooking;
  }

  getRoommate(defaults) {
    const e = this.getEnrollment({});
    if (!e.Roommate) {
      e.Roommate = defaults;
    }
    return e.Roommate;
  }

  update(object, updates) {
    return _.mergeWith(object, updates, (left, right) => {
      if (!right || right.toString().trim().length <= 0) {
        return left;
      }
      return right;
    });
  }

  isRegistrationComplete() {
    return (
      this.getEnrollment({ Participant: [] }).$mtcRegistrationComplete || false
    );
  }

  /**
   * @function RegistrationService.removeGuests
   * @description Removes guests from the enrollment.
   */
  removeGuests(guests) {
    let e = this.getEnrollment({
      Participant: []
    });
    if (Array.isArray(guests)) {
      guests.forEach((element) => {
        _.remove(e.Participant, element);
      });
    } else {
      _.remove(e.Participant, guests);
    }
  }
}
