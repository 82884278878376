<div id="cancellation" class="registration container">
    <form name="cancelForm" #cancelForm="ngForm" [ngClass]="{'form-complete': isFormCompleted}"
        (ngSubmit)="forward(cancelForm.valid)" novalidate autocomplete="off" appScrollToInvalid [ngClass]="{'form-complete': isFormCompleted}">

        <h1>{{ 'copy.label.CANCELLATION.title' | copy | async }}</h1>

        <p class="w-100">
            <strong>{{"copy.label.CANCELLATION.pleasenote"|copy|async}}</strong>
            {{ 'copy.label.CANCELLATION.note' | copy | async }}
        </p>

        <section class="card">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.CANCELLATION.attendeeinfo' | copy | async }}
                </div>
            </div>
            <article class="p-4">
                <p><strong>{{ 'copy.label.FEATURES.NAME.first' | copy | async }}:</strong> {{attendee.FirstName}}</p>
                <p><strong>{{ 'copy.label.FEATURES.NAME.last' | copy | async }}:</strong> {{attendee.LastName}}</p>
                <p><strong>{{ 'copy.label.FEATURES.EMAIL.label' | copy | async }}:</strong> {{EmailAddress}}</p>
            </article>
        </section>

        <section class="card">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.CANCELLATION.policy' | copy | async }}
                </div>
            </div>
            <article class="p-4">
                <div [innerHTML]='"copy.html.CancelPolicy" | copy | async'></div>
            </article>
        </section>

        <!--section class="card">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.CANCELLATION.hotelinfo' | copy | async }}
                </div>
            </div>
            <article class="p-4">
                <div [innerHTML]='"copy.html.CancelHotelPolicy" | copy | async'></div>
            </article>
        </section-->

        <section class="card">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.CANCELLATION.addinfo' | copy | async }}
                </div>
            </div>
            <article class="p-4">
                <div class="w-100">
                    <div class="d-flex justify-content-start">
                      <mat-form-field class="w-100">
                        <mat-label for="cancelReason">{{ 'copy.label.CANCELLATION.reason' | copy | async }}</mat-label>
                        <textarea matInput class="w-100" name="cancelReason" id="cancelReason" aria-label="cancelReason" [(ngModel)]="Cancel.reason" #cancelReason="ngModel" maxlength="225"  required pattern="^[\w\s!@\-\_:',.\/?]*"></textarea>
                        <mat-error><app-validation  [object]="cancelReason" isRequired=true pattern="alphanum"></app-validation></mat-error>
                      </mat-form-field>
                    </div>
                </div>
            </article>
        </section>
        <div class="button_group d-flex flex-row justify-content-between">
            <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()"
                *ngIf="!isSubmitting">
                {{ 'copy.label.GLOBAL.goback' | copy | async }}
            </button>
            <button id="forward-button" type="submit" class="inputSubmit btn btn-primary"
                *ngIf="!isSubmitting">
                {{ 'copy.label.GLOBAL.continue' | copy | async }}
            </button>
            <button class="inputSubmit btn btn-primary" *ngIf="isSubmitting">
                <i class="fas fa-spinner fa-spin"></i>
            </button>
        </div>
    </form>
</div>
