<div class="modal-content-wrapper">
  <header class="modal-header">
    <h1 class="modal-title">{{ modalData.title }}</h1>
    <div tabindex="0" class="close-container" (click)="closeModal('&nbsp;')" (keydown.enter)="closeModal('&nbsp;')">
      <div class="leftright"></div>
      <div class="rightleft"></div>
    </div>
  </header>
  <mat-dialog-content class="modal-body bg-white overflow-hidden">
    <iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe>
  </mat-dialog-content>
  <footer id="modal-footer" class="modal-footer w-100">
    <!-- <div class="w-100 button_group d-flex flex-row justify-content-between">
      <button id="modal-action-button-external" class="btn btn-primary float-right" (click)="actionFunction(modalData.actionButtonText)">
        {{ modalData.actionButtonText }}
      </button>
    </div> -->
  </footer>
</div>
