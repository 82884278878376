import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { CreditcardService } from 'src/app/services/creditcard.service';
import { HttpClient } from '@angular/common/http';
import { PaymentService } from 'src/app/services/payment.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorComponent } from '../error/error.component';
import { LogService } from 'src/app/services/log.service';



@Component({
  selector: 'app-card-collection',
  templateUrl: './card-collection.component.html',
  styleUrls: ['./card-collection.component.scss']
})
export class CardCollectionComponent implements OnInit {
  reset;
  isSecureExpanded;
  isFormCompleted;
  isSubmitting;
  log = {error:(string:any)=>{}};
  card: any = { name:'', number: '', zip:'', ReferenceKey: String };
  attendee;
  cardSaveError;
  config;
  constructor(public dialogRef: MatDialogRef<CardCollectionComponent>,
    private configurationService: ConfigurationService,
    private registrationService: RegistrationService,
    private creditCardService: CreditcardService,
    private http: HttpClient,
    private paymentService: PaymentService,
    private snackBar: MatSnackBar,
    private errorDialog: MatDialog,
    private logService: LogService
  ) {
    this.attendee = this.registrationService.getAttendee({});
    this.configurationService.fetchConfig().then((config)=>{
      this.config = config
    });
  }

  ngOnInit(): void {
    if (this.attendee.FirstName && this.attendee.LastName) {
      this.card.name = this.attendee.FirstName + " " + this.attendee.LastName;
    }

    this.reset =  (form) => {
      // this.contact = {};
      form.controls.email.value = "";
      form.controls.email.markAsPristine();
      form.controls.firstName.markAsPristine();
      form.controls.lastName.markAsPristine();
      form.controls.inquiry.markAsPristine();
      form.controls.message.markAsPristine();
      this.isFormCompleted = false;
      // this.isOtherExpanded = false;
      // this.isError = false;
    }

  }

  //private
  /**
   * @function CardCollectionController.catchError
   * @description On catchError do not submit.
   */
  catchError(status, config) {
    this.log.error("Registration -- " + config.programName + " -- Insert Credit Card Error: " + status);
    this.isSubmitting = false;
  };

  /**
   *@function CardCollectionController.storeReferenceKey
   *@description Stores reference key from credit card submittion.
   */
  storeReferenceKey(response) {
    this.card.ReferenceKey = response.guid;
    this.creditCardService.store(Object.assign(this.card, {}));
    this.paymentService.cardEntered(response.guid);
    this.dialogRef.close(response.guid);
  };

  /**
   * @function CardCollectionController.postCreditCard
   * @description Posts credit card information and stores on success and also on error.
   */
  postCreditCard() {

      //this.log = mtcLogService.buildFor(config.programName);
      let secureURL = this.config.secureURL;
      let cardcopy:any = {...this.card};

      // need to do this as self.card.expMonth is 1 based and cardcollection is 0 based to avoid it slide by 1 month in Impact
      cardcopy.expMonth--;
      this.http.post(secureURL + "/cardcollection/service/creditcard", cardcopy).subscribe((response) => {
        this.storeReferenceKey(response);
        //console.log(response);
        this.cardSaveError = false;
      }, (error)=>{
        this.cardSaveError = true;
        this.isSubmitting = false;
        this.logService.errorcc("Error Saving Card","cardcollection").subscribe(response=>{
          console.log("Card Error");
        });
        let dialogRef: MatDialogRef<ErrorComponent> = this.errorDialog.open(ErrorComponent, {});

      });
  };

  onNoClick(): void {
    this.dialogRef.close();
  }
  toggleSecureMessage() {
    this.isSecureExpanded = !this.isSecureExpanded;
  }
  ok(form) {
    this.isFormCompleted = true;
    //console.log(this.card);
    if (form.valid && this.card.expMonth) {
      this.isSubmitting = true;
      this.postCreditCard();
    }

  }
}
