import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule  } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import {MatDialogModule} from '@angular/material/dialog';

import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { MdePopoverModule } from '@material-extended/mde';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CopyPipe } from './services/copy.pipe';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputMaskModule } from '@ngneat/input-mask';
import { BnNgIdleService } from 'bn-ng-idle'; /* For Timeout */

/* Page Components */
import { LoginComponent } from './page-components/login/login.component';
import { LoadComponent } from './page-components/load/load.component';
import { DeclineComponent } from './page-components/decline/decline.component';
import { SelectionComponent } from './page-components/selection/selection.component';
import { AttendeeComponent } from './page-components/attendee/attendee.component';
import { GuestComponent } from './page-components/guest/guest.component';
import { DemographicsComponent } from './page-components/demographics/demographics.component';
import { HotelBookingComponent } from './page-components/hotel-booking/hotel-booking.component';
import { SummaryComponent } from './page-components/summary/summary.component';
import { AcknowledgementComponent } from './page-components/acknowledgement/acknowledgement.component';
import { CancellationComponent } from './page-components/cancellation/cancellation.component';
import { InvitationComponent } from './page-components/invitation/invitation.component';
import { TimeoutComponent } from './page-components/timeout/timeout.component';
import { TravelComponent } from './page-components/travel/travel.component';
import { GuestTravelComponent } from './page-components/guest-travel/guest-travel.component';
import { TestPageComponent } from './page-components/testpage/testpage.component';
import { OauthComponent } from './page-components/oauth/oauth.component';
import { InstructionsComponent } from './page-components/instructions/instructions.component';


/* Reusable Components */
import { RadioGroupComponent } from './reusable-components/radio-group/radio-group.component';
import { ContactusComponent } from './reusable-components/contactus/contactus.component';
import { AddressComponent } from './reusable-components/address/address.component';
import { ValidationComponent } from './reusable-components/validation/validation.component';
import { PhoneComponent } from './reusable-components/phone/phone.component';
import { LogoutComponent } from './reusable-components/logout/logout.component';
import { BannerComponent } from './reusable-components/banner/banner.component';
import { FooterComponent } from './reusable-components/footer/footer.component';
import { ModalComponent } from './reusable-components/modal/modal.component';
import { ModalExternalComponent } from './reusable-components/modal-external/modal-external.component';
import { SessionmodalComponent } from './reusable-components/sessionmodal/sessionmodal.component';
import { BadgeComponent } from './reusable-components/summary/badge/badge.component';
import { ForgotConfirmationComponent } from './reusable-components/forgot-confirmation/forgot-confirmation.component';
import { AddresssummaryComponent } from './reusable-components/summary/addresssummary/addresssummary.component';
import { PhonesummaryComponent } from './reusable-components/summary/phonesummary/phonesummary.component';
import { SummaryDisplayComponent } from './reusable-components/summary/summary-display/summary-display.component';
import { THQHttpInterceptor } from './THQHttpInterceptor';
import { ScrollToInvalidDirective } from './reusable-components/scroll-to-invalid/scroll-to-invalid.directive';
import { DateComponent } from './reusable-components/date/date.component';
import { DateValidatorDirective } from './reusable-components/date-validator.directive';
import { HomeComponent } from './page-components/pretrip/home/home.component';
import { HotelComponent } from './page-components/hotel/hotel.component';
import { HotelPrefComponent } from './reusable-components/hotel-pref/hotel-pref.component';
import { FlightPrefComponent } from './reusable-components/flight-pref/flight-pref.component';
import { FlightsummaryComponent } from './reusable-components/summary/flightsummary/flightsummary.component';
import { ReplacementComponent } from './page-components/replacement/replacement.component';
import { PaymentComponent } from './page-components/payment/payment.component';
import { CollectPaymentComponent } from './reusable-components/collect-payment/collect-payment.component';
import { CreditCardComponent } from './reusable-components/credit-card/credit-card.component';
import { CardCollectionComponent } from './reusable-components/card-collection/card-collection.component';
import { CreditCardDateComponent } from './reusable-components/credit-card-date/credit-card-date.component';
import { CartComponent } from './reusable-components/cart/cart.component';
import { CurrencyPipe } from '@angular/common';
import { PreventPasteDirective } from './reusable-components/prevent-paste.directive';
import { HotelPackageComponent } from './page-components/hotel-package/hotel-package.component';
import { LuhnCheckDirective } from './reusable-components/luhn-check.directive';
import { CreditCardDisplayComponent } from './reusable-components/credit-card-display/credit-card-display.component';
import { ActivitiesPretripComponent } from './page-components/pretrip/activities-pretrip/activities-pretrip.component';
import { AgendaComponent } from './page-components/pretrip/agenda/agenda.component';
import { DestinationComponent } from './page-components/pretrip/destination/destination.component';
import { FaqComponent } from './page-components/pretrip/faq/faq.component';
import { HomepageComponent } from './page-components/pretrip/homepage/homepage.component';
import { HotelPretripComponent } from './page-components/pretrip/hotel-pretrip/hotel-pretrip.component';
import { OnePageComponent } from './page-components/pretrip/one-page/one-page.component';
import { SpeakersComponent } from './page-components/pretrip/speakers/speakers.component';
import { SponsorsComponent } from './page-components/pretrip/sponsors/sponsors.component';
import { TravelPretripComponent } from './page-components/pretrip/travel-pretrip/travel-pretrip.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { SafeHtmlPipe } from './services/safe-html.pipe';
import { ProfileComponent } from './page-components/profile/profile.component';
import { ActivityComponent } from './page-components/activity/activity.component';
import { FordattendeeComponent } from './reusable-components/fordattendee/fordattendee.component';
import { GeneralComponent } from './page-components/general/general.component';
import { ScrollToTopComponent } from './reusable-components/scroll-to-top/scroll-to-top.component';
import { HotelDetailsComponent } from './reusable-components/hotel-details/hotel-details.component';
import { UniqueemailDirective } from './reusable-components/uniqueemail.directive';
import { ConfigComponent } from './page-components/config/config.component';
import { CreditCardAddressComponent } from './reusable-components/credit-card-address/credit-card-address.component';
import { ErrorComponent } from './reusable-components/error/error.component';
import { ExpirationValidatorDirective } from './reusable-components/expiration-validator.directive';
import { IeNotSupportedComponent } from './reusable-components/ie-not-supported/ie-not-supported.component';
import { MarkAllAsTouchedDirective } from './reusable-components/mark-all-as-touched.directive';
import { OnsiteComponent } from './page-components/onsite/onsite.component';
import { EmailComponent } from './reusable-components/email/email.component';
import { EmailmatchDirective } from './reusable-components/emailmatch.directive';
import { ContentPipe } from './services/content.pipe';
import { OtpComponent } from './reusable-components/otp/otp.component';
import { PrivacyNoticeComponent } from './page-components/privacy-notice/privacy-notice.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoadComponent,
    DeclineComponent,
    SelectionComponent,
    GeneralComponent,
    AttendeeComponent,
    DemographicsComponent,
    HotelBookingComponent,
    SummaryComponent,
    AcknowledgementComponent,
    CancellationComponent,
    InvitationComponent,
    LogoutComponent,
    BannerComponent,
    FooterComponent,
    CopyPipe,
    PhoneComponent,
    AddressComponent,
    RadioGroupComponent,
    ContactusComponent,
    ValidationComponent,
    ModalComponent,
    ModalExternalComponent,
    SessionmodalComponent,
    BadgeComponent,
    ForgotConfirmationComponent,
    TimeoutComponent,
    AddresssummaryComponent,
    PhonesummaryComponent,
    SummaryDisplayComponent,
    ScrollToInvalidDirective,
    DateComponent,
    DateValidatorDirective,
    HomeComponent,
    GuestComponent,
    TravelComponent,
    HotelComponent,
    HotelPrefComponent,
    GuestTravelComponent,
    FlightPrefComponent,
    FlightsummaryComponent,
    ReplacementComponent,
    PaymentComponent,
    CollectPaymentComponent,
    CreditCardComponent,
    CardCollectionComponent,
    CreditCardDateComponent,
    CartComponent,
    PreventPasteDirective,
    HotelPackageComponent,
    LuhnCheckDirective,
    CreditCardDisplayComponent,
    ActivitiesPretripComponent,
    AgendaComponent,
    DestinationComponent,
    FaqComponent,
    HomepageComponent,
    HotelPretripComponent,
    OnePageComponent,
    SpeakersComponent,
    SponsorsComponent,
    TravelPretripComponent,
    SafeHtmlPipe,
    ProfileComponent,
    ActivityComponent,
    TestPageComponent,
    OauthComponent,
    InstructionsComponent,
    FordattendeeComponent,
    ScrollToTopComponent,
    HotelDetailsComponent,
    UniqueemailDirective,
    ConfigComponent,
    CreditCardAddressComponent,
    ErrorComponent,
    ExpirationValidatorDirective,
    IeNotSupportedComponent,
    MarkAllAsTouchedDirective,
    OnsiteComponent,
    EmailComponent,
    EmailmatchDirective,
    ContentPipe,
    OtpComponent,
    PrivacyNoticeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    MatExpansionModule,
    MatButtonModule,
    MatLegacyDialogModule,
    MatDialogModule,
    MatFormFieldModule,
    MDBBootstrapModule.forRoot(),
    MatIconModule,
    NgbModule,
    // MdePopoverModule,
    MatToolbarModule,
    MatCardModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatStepperModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatTooltipModule,
    MatSnackBarModule,
    InputMaskModule
  ],
  exports: [
    CopyPipe
  ],
  providers: [BnNgIdleService,
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: THQHttpInterceptor, multi: true }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
