import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ModalComponent } from '../../reusable-components/modal/modal.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { CopyService } from '../../services/copy.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  content: any;
  urlSafe: SafeResourceUrl;

  constructor(
    public modalService: ModalService,
    private matDialog: MatDialog
  ) {}

  openModal(copy, title, actionButtonText) {
    this.modalService.openModal(copy, title, actionButtonText);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = "modal-component-terms";
    //console.log(dialogConfig.data.actionButtonText);
  }

  openModalExternal(url, title, actionButtonText) {
    this.modalService.openModalExternal(url, title, actionButtonText);
  }

  ngOnInit(): void {}

  cookieEnter(){
    document.getElementById('cookieSettings').click();
  }
}
