import { Component, OnInit, Input } from '@angular/core';
import { CreditcardService } from 'src/app/services/creditcard.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CardCollectionComponent } from '../card-collection/card-collection.component';
import { RegistrationService } from 'src/app/services/registration.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {
  @Input() payment:any;

  cards = [];
  ReferenceKey;
  subscription;
  constructor(private creditCardService: CreditcardService,
    public dialog: MatDialog,
    private paymentService: PaymentService) {
      this.subscription = this.paymentService.emitter.subscribe({
        next: (event) => {
            if(!this.payment.ReferenceKey && event.guid){
              this.payment.ReferenceKey = event.guid;
            }
        }
    })
    }

  ngOnInit(): void {
    this.cards = this.creditCardService.cards;
    if (!this.payment.ReferenceKey && this.cards && this.cards[0]) {
      this.payment.ReferenceKey = this.cards[0].ReferenceKey;
    }
  }

  open() {
    const dialogRef = this.dialog.open(CardCollectionComponent, {
			disableClose: true,
			data: { row: null }
		});

		dialogRef.afterClosed().subscribe(result => {
      this.cards = this.creditCardService.cards;
      if (result) {
        this.payment.ReferenceKey = result;
      }
		});
  }

}
