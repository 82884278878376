import { Injectable } from '@angular/core';
import { RegistrationService } from './registration.service';
import { EnvironmentService } from './environment.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { ConstantsService } from './constants.service';
import { PersistenceService } from './persistence.service';


@Injectable({
  providedIn: 'root'
})
export class CapacitysessionService {

  constructor(
    private registrationService: RegistrationService,
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private authService: AuthenticationService,
    private constantsService: ConstantsService,
    private persistenceService: PersistenceService,
    private authenticationService: AuthenticationService
  ) {}

  serviceUrl = new EnvironmentService().getEnvironmentUrl('service/capacitysession');
  getHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        ProgramKey: '450282A8-FD21-4D41-B2D5-4DEEC4C75DD7',
        'MTC-Session-Token': this.authService.authtoken,
      }),
    };
  }
  getCapacity(wave, name, encorValue, displayScreen, array) {
    const promise = new Promise((resolve, reject) => {
      const url = this.serviceUrl + "/" + wave+"/"+name;
      this.http
        .get(url)
        .subscribe((data: any) => {
          console.log(data);
          if(data && (data.Actual < data.Allowable)){
            array.push({value: encorValue,display: displayScreen, disabled: false, capacityName:name});
          }else{
            array.push({ value: encorValue,display: displayScreen + " - Sold Out", disabled: true, capacityName:name});
          }
          resolve(data);
        });
    });
    return promise;
  }


  updateCapacity(name) {
    const promise = new Promise((resolve, reject) => {
      //const cleanedData = this.registrationService.cleanse(data);
      const url = this.serviceUrl + "/" + name;
      this.http
        .post(url, name)
        .subscribe((data: any) => {
          resolve(data);
        });
    });
    return promise;
  }

  insertCapacity(name) {
    const promise = new Promise((resolve, reject) => {
      const url = this.serviceUrl + "/" + name;
      this.http
        .put(url, name)
        .subscribe((data: any) => {
          resolve(data);
        });
    });
    return promise;
  }

  deleteCapacity() {
    const promise = new Promise((resolve, reject) => {
      const url = this.serviceUrl;
      this.http
        .delete(url)
        .subscribe((data: any) => {
          resolve(data);
        });
    });
    return promise;
  }


}
