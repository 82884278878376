import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ModalComponent } from '../../reusable-components/modal/modal.component';
import { AuthenticationService } from '../../services/authentication.service';
import { PersistenceService } from '../../services/persistence.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { RegistrationService } from 'src/app/services/registration.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  constructor(
    private matDialog: MatDialog,
    private authService: AuthenticationService,
    private http: HttpClient,
    private persistenceService: PersistenceService,
    private router: Router,
    private registrationService: RegistrationService
  ) {
   }

   isUserLoggedIn(){
      return this.authService.authtoken !== undefined;
   }

  openModal() {
    const dialogConfig = new MatDialogConfig();


    dialogConfig.disableClose = false;
    dialogConfig.id = "modal-component-logout";
    //dialogConfig.copy = "copy.label.LOGOUTMODULE.message";
    // dialogConfig.title = "Terms Of Use"
    dialogConfig.data = {
      name: "logout",
      title: "Are you sure you want to logout?",
      actionButtonText: "Logout",
    }
    if(this.registrationService.isRegistrationComplete()){
      dialogConfig.data.copy = 'copy.label.LOGOUTMODULE.rvsmessage'
    }else{
      dialogConfig.data.copy = 'copy.label.LOGOUTMODULE.message'
    }



    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
    modalDialog.afterClosed().subscribe((data) => {
      if(data === 'Logout'){
       // logging out
        this.logout();
      }
    });

  }
  logout() {
   // in logout function
    this.authService.invalidateSessionToken().then(() => {
     // after invalidateSessionToken
        //this.http.defaults.headers.common["MTC-Session-Token"] = undefined;
        this.persistenceService.clear();
        this.router.navigate(['/login']);
      });
  };

  ngOnInit(): void {
  }
}
