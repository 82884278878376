<div id="test" class="registration container">
  <form #testForm="ngForm" name="testForm" (ngSubmit)="forward(testForm)" novalidate appScrollToInvalid [ngClass]="{'form-complete': isFormCompleted}">
    <h1>Test Page</h1>
    <section class="card">
      <div class="card-header">
        <div class="card-title">
          Please select an attendee type: <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <div class="w-100 required">
          <div>
            <mat-radio-group class="w-100" [(ngModel)]="attendee.PAXTypeNo" 
              #attendeetypetest="ngModel" name="attendeetypetest" required>
              <mat-radio-button *ngFor="let op of testPageRadios" [value]="op.value">
                {{op.display}}
              </mat-radio-button>
              <div>
                <mat-error><app-validation [object]="attendeetypetest" isRequired=true></app-validation></mat-error>
              </div>
            </mat-radio-group>
          </div> 
        </div>        
  </article>
</section>
<div class="button_group d-flex">
  <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()" *ngIf="!isSubmitting">
    {{ 'copy.label.GLOBAL.goback' | copy | async }}
  </button>
  <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
    {{ 'copy.label.GLOBAL.continue' | copy | async }}
  </button>
  <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
    <i class="fas fa-spinner fa-spin"></i>
  </button>
</div>
</form>
</div>
