import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../../services/registration.service';
import { ConfigurationService } from '../../services/configuration.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ContactusComponent } from 'src/app/reusable-components/contactus/contactus.component';
import { NavigationService } from '../../services/navigation.service';

@Component({
  templateUrl: './general.component.html'
})
export class GeneralComponent implements OnInit {
  isSubmitting = false;
  programInfo: any;
  enrollment: any;
  contact: any;
  phone0: any;
  phone1: any;
  email: any;
  hours: any;
  returnLogin: any;
  isFormCompleted = false;
  attendee;
  pax;


  constructor(
    private registrationService: RegistrationService,
    private configurationService: ConfigurationService,
    private navigationService: NavigationService,
    public dialog: MatDialog) {
      this.attendee = this.registrationService.getAttendee({});
      this.pax = this.attendee.PAXTypeNo;
  
    this.enrollment = this.registrationService.getEnrollment({});
  }

  ngOnInit(): void {
    this.configurationService.fetchConfig().then((data: any) => {
      this.programInfo = data;
      if (this.programInfo !== undefined) {
        this.returnLogin = this.programInfo.returnLogin;
        this.phone0 = this.programInfo.travelHQ800Num;
        this.phone1 = this.programInfo.travelHQIntlNum;
        this.email = this.programInfo.travelHQEmail;
        this.hours = this.programInfo.programSupportHours;
      }
    });
  }

  openContactModal(): void {
    let dialogRef = this.dialog.open(ContactusComponent, { panelClass: 'forgot-dialog-container' });
  }

  forward(form) {
    this.isSubmitting = true;
    this.isFormCompleted = true;
      this.navigationService.forward();
  }

  back() {
    this.navigationService.back();
  }  

}