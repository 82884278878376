import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ModalComponent } from '../reusable-components/modal/modal.component';
import { CopyService } from './copy.service';
import { ModalExternalComponent } from '../reusable-components/modal-external/modal-external.component';

declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private copyService: CopyService,
    public matDialog: MatDialog
  ) {}


  openModal(copy, title, actionButtonText) {

    const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.id = "modal-component";
      dialogConfig.data = {
        title: title,
        copy: "copy.html."+copy,
        actionButtonText: actionButtonText
      };
      dialogConfig.ariaLabel = title;
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  openModalExternal(url, title, actionButtonText) {
    const dialogConfigExternal = new MatDialogConfig();
    dialogConfigExternal.disableClose = false;
    dialogConfigExternal.id = "modal-component";
    dialogConfigExternal.data = {
      title: title,
      url: url,
      actionButtonText: actionButtonText
    }
    const modalExternalDialog = this.matDialog.open(ModalExternalComponent, dialogConfigExternal);
  }


}

