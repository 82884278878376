import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ModalService } from '../../services/modal.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal',
  templateUrl: './modal-external.component.html'
})
export class ModalExternalComponent implements OnInit {
  urlSafe;
  constructor(
    public dialogRef: MatDialogRef<ModalExternalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public sanitizer: DomSanitizer
  ) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(modalData.url);
  }

  ngOnInit() {}

  actionFunction(action) {
    this.closeModal(action);
  }

  closeModal(action) {
    this.dialogRef.close(action);
  }
}
