import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  baseUrl = '';
  constructor() {}
  sitename = 'lexusndm24/';
  overloadprogramkey;

  getEnvironmentUrl(service) {
    // By default the $http service will call relative to your current location.
    // You would only need to modify this service if your angular client
    // should call a different domain than the one it was loaded from.

    const env = window.location.hostname;

    if (env.indexOf('localhost') >= 0) {
      //uxui
      this.baseUrl = 'http://local.travelhq.com/events/';
      //this.baseUrl = 'https://clientservice-dev.travelhq.com/events/';
    } else if (env.indexOf('local') >= 0) {
      this.baseUrl = 'http://local.travelhq.com/events/';
      //this.baseUrl = 'https://clientservice-dev.travelhq.com/events/';
    } else if (env.indexOf('dev') >= 0) {
      this.baseUrl = 'https://clientservice-dev.travelhq.com/events/';
    } else if (env.indexOf('test') >= 0) {
      this.baseUrl = 'https://clientservice-test.travelhq.com/events/';
    } else if (env.indexOf('content') >= 0) {
      this.baseUrl = 'https://clientservice.travelhq.com/events/';
    }
    let url = this.baseUrl + this.sitename;

    if(service){
      url += service;
    }
    return url;
  }

  getProgramKey() {
    const env = window.location.hostname;
    let key = '';
    if(this.overloadprogramkey){
      key = this.overloadprogramkey;
    } else if (env.indexOf('local') >= 0 || env.indexOf('dev') >= 0) {
      //old dev key = '450282A8-FD21-4D41-B2D5-4DEEC4C75DD7';
      key = '00F8F7C2-B134-4BD0-B7DE-A9675E8CB8D0';
    } else if (env.indexOf('test') >= 0) {
      key = '4CED62F7-5E01-4BEF-A580-8E9268D6315B';
    } else if (env.indexOf('content') >= 0) {
      key = 'F86DEDC1-635C-4360-9D41-452759550A44';
    }
    return key;
  }

  getEnv() {
    const env = window.location.hostname;

    if (env.indexOf('local') >= 0 || env.indexOf('dev') >= 0) {
      return 'dev';
    } else if (env.indexOf('test') >= 0) {
      return 'test';
    } else {
      return 'prod';
    }
  }
  getProfileService() {
    const env = window.location.hostname;
    let baseurl = '';
    if (env.indexOf('local') >= 0 || env.indexOf('dev') >= 0) {
      baseurl = 'https://profile-dev.travelhq.com/service/attendeeprofilemanagementrest';
    } else if (env.indexOf('test') >= 0) {
      baseurl = 'https://profile-test.travelhq.com/service/attendeeprofilemanagementrest';
    } else if (env.indexOf('content') >= 0) {
      baseurl = 'https://profile.travelhq.com/service/attendeeprofilemanagementrest';
    }
    return baseurl;
  }

  getContentUrl(contentstatus,version){
    const env = window.location.hostname;
    let tag = 'registration';
    let programid = 116020;
    let baseurl = 'https://assets.prod.travelhq.com/sitefiles/program/';
    // if (env.indexOf('local') >= 0 || env.indexOf('dev') >= 0) {
    //   baseurl = 'https://assets.dev.travelhq.com/sitefiles/program/';
    // } else if (env.indexOf('test') >= 0) {
    //   baseurl = 'https://assets.test.travelhq.com/sitefiles/program/';
    // }
    let url = baseurl +programid +'/' + tag +'/'+ contentstatus + '/' + version + '.json';
    if(version=='next'){
      // if (env.indexOf('local') >= 0 ){
      //   url = 'http://local.travelhq.com/contentservice/content/'+ programid + '/' + tag;
      //   // url = 'https://content-service.dev.travelhq.com/contentservice/content/'+ programid + '/' + tag;
      // }else if(env.indexOf('dev') >= 0) {
      //   url = 'https://content-service.dev.travelhq.com/contentservice/content/'+ programid + '/' + tag;
      // } else if (env.indexOf('test') >= 0) {
      //   url = 'https://content-service.test.travelhq.com/contentservice/content/'+ programid + '/' + tag;
      // }else{
        url = 'https://content-service.prod.travelhq.com/contentservice/content/'+ programid + '/' + tag;
      // }
        
    }
    return url


  }
}
