import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { CopyService } from '../../services/copy.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  templateUrl: './invitation.component.html'
})
export class InvitationComponent implements OnInit {

  constructor(
    private navigationService: NavigationService,
    private copyService: CopyService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  isSubmitting = false;
  isError = false;
  isFormCompleted = false;
  invitation = {
    isAttending: undefined,
    declineOption: undefined,
    declineComment: undefined
  };

  declineRadios = [
    {
      value: 'Business Conflict',
      display: this.copyService.getCopyByKey(
        'copy.label.INVITATION.declineReason1'
      )
    },
    {
      value: 'Personal Conflict',
      display: this.copyService.getCopyByKey(
        'copy.label.INVITATION.declineReason2'
      )
    },
    {
      value: 'other',
      display: this.copyService.getCopyByKey(
        'copy.label.INVITATION.declineReason3'
      )
    }
  ];

  ngOnInit(): void {}

  forward() {
    this.isSubmitting = true;
    this.isFormCompleted = true;

    if (this.invitation.isAttending) {
      this.navigationService.forward();
    } else if (this.invitation.isAttending === false) {
      if(this.invitation.declineOption === undefined)
      {
        this.isError = true;
        this.isSubmitting = false;
      }
      else{
          let declinereason = this.invitation.declineOption;
          if (this.invitation.declineOption === 'other' && this.invitation.declineComment === undefined) {
            this.isError = true;
            this.isSubmitting = false;
          }
          else{
            if (this.invitation.declineOption === 'other')
            {
              declinereason = this.invitation.declineComment;
            }
             this.authenticationService
            .updateProfile('CANCELLED', declinereason)
            .subscribe(() => {
            this.router.navigate(['decline']);
          });
        }
      }
    }
    else {
      this.isError = true;
      this.isSubmitting = false;
    }
  }

  back() {
    this.navigationService.back();
  }
}
