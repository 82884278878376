import { Injectable } from '@angular/core';
import { RegistrationService } from './registration.service';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AttendeeGroupService {
  constructor(
    private registrationService: RegistrationService,
    private environmentService: EnvironmentService,
    private http: HttpClient
  ) {}

  getAttendeeGroups() {
    var cleanedData = this.registrationService.cleanse(
      this.registrationService.getEnrollment({})
    );

    const serviceurl = this.environmentService.getEnvironmentUrl(
      'service/attendeegroup'
    );
    let promise = new Promise((resolve, reject) => {
      this.http.post(serviceurl, cleanedData).subscribe((data) => {
          resolve(data);
      });
    });
    return promise;
  }
}
