import { Pipe, PipeTransform} from '@angular/core';
import { CopyService } from './copy.service';


@Pipe({
  name: 'copy'
})
export class CopyPipe implements PipeTransform {
  copyValue;
  constructor(private copyService: CopyService) { }

  transform(value: string) {
    return this.copyService.getCopyByKey(value).then((res) => {
      return res;
    });

  }

}
