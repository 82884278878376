<div *ngIf="collectOtherPayment"  provide-parent-form>
    <div ngModelGroup="eoqihpod">
    <mat-form-field class="w-100">
        <mat-label for="payment" aria-labelledby="payment">
            {{ 'copy.label.PAYMENT.type' | copy | async }}</mat-label>
        <mat-select name="MediaType" id="MediaType" aria-label="payment"
            [(ngModel)]="payment.MediaType" #MediaType="ngModel" required matNativeControl (ngModelChange)="selected()">
            <mat-option *ngFor="let type of mediatypes" [value]="type.value" >
                {{ type.display|copy|async }}
            </mat-option>
        </mat-select>
        <mat-error><app-validation [object]="MediaType" isRequired=true></app-validation></mat-error>
    </mat-form-field>
    <div *ngIf="payment.MediaType == 'CREDITCARD'">
        <div [innerHTML]='"copy.html.CreditCard" | copy | async'></div>
    </div>
    <div *ngIf="payment.MediaType == 'CHECK'">
        <div [innerHTML]='"copy.html.Check" | copy | async'></div>
    </div>
    <div *ngIf="payment.MediaType == 'WIRETRANSFER'">
        <div [innerHTML]='"copy.html.WireTransfer" | copy | async'></div>
    </div>
</div></div>
<div *ngIf="!collectOtherPayment || payment.MediaType == 'CREDITCARD'">
    <app-credit-card [payment]="payment"></app-credit-card>
</div>
