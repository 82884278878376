<div>
  <mat-label>{{ 'copy.label.CARDCOLLECT.date' | copy | async }}</mat-label>
</div>
<div class="creditCardDate" provide-parent-form>
  <div ngModelGroup="expirationdate">
    <mat-form-field appearance="outline" class="left">
      <mat-label>Month</mat-label>
      <mat-select name="month" id="month" aria-label="month" [(ngModel)]="card.expMonth" #month="ngModel" required matNativeControl (ngModelChange)="calcDate()">
        <mat-option *ngFor="let month of expirationMonths" [value]="month">{{ displayMonth(month) }}</mat-option>
      </mat-select>
      <mat-error><app-validation [object]="month" isRequired=true></app-validation></mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="right">
      <mat-label>Year</mat-label>
      <mat-select name="year" id="year" aria-label="year" [(ngModel)]="card.expYear" #year="ngModel" required matNativeControl (ngModelChange)="calcDate()">
        <mat-option *ngFor="let year of expirationYears" [value]="year">{{ year }}</mat-option>
      </mat-select>
      <mat-error><app-validation [object]="year" isRequired=true></app-validation></mat-error>
    </mat-form-field>
  </div>
</div>
<!-- <div *ngIf="this.invaliddate" class="thq-error-message">
  <div>
    <small class="text-danger">This Card is Expired</small>
  </div>
</div> -->