import { Component } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { RegistrationService } from '../../services/registration.service';
import { ConstantsService } from '../../services/constants.service';
import { EnvironmentService } from '../../services/environment.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  templateUrl: './cancellation.component.html'
})
export class CancellationComponent {
  isFormCompleted: boolean;
  attendee: any;
  EmailAddress: any;
  isError = false;
  Cancel = {
    reason:""
  };
  isSubmitting: boolean;



  constructor(
    private http: HttpClient,
    private navigationService: NavigationService,
    private registrationService: RegistrationService,
    private constantsService: ConstantsService,
    private router: Router,
    private environmentService: EnvironmentService) {

    this.attendee = this.registrationService.getAttendee({});
    this.EmailAddress = this.registrationService.getEmail(this.attendee, this.constantsService.EMAILS.BUSINESS, {}).EmailAddress;

  }

  /**
    * @function back
    * @description Uses Angular router service to go to summary page.
    */
  back() {
    this.router.navigate(['/summary']);
  }

  /**
    * @function forward
    * @description Checks if form is valid then posts cancel reason and go to summary page.
    */
  forward(valid: any) {
    this.isFormCompleted = true;
    this.isSubmitting = true;
    //this.navigationService.forward();
    if (valid) {

      const serviceurl = this.environmentService.getEnvironmentUrl(
        'service/enrollment/cancel'
      );

      this.http.post(serviceurl, this.Cancel).subscribe( () =>{
        this.registrationService.getEnrollment({}).$mtcUpdateSuccess = true;
        this.router.navigate(['/summary']);
      }, () => {
        this.isSubmitting = false;
      });

    }else{
      this.isError = true;
      this.isSubmitting = false;
    }
  }

}
