import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DateUtilityServiceService {
  constructor() {}

  dateDiff(first, second) {
    var date1: any = new Date(first);
    var date2: any = new Date(second);
    var diffDays: any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
    return diffDays;
  }
  calculateDateRange(startDate, endDate) {
    var d1 = moment.utc(startDate, 'YYYY-MM-DD HH:mm:ss.SSS+Z');
    var d2 = moment.utc(endDate, 'YYYY-MM-DD HH:mm:ss.SSS+Z');

    var input = [];
    var duration = moment.duration(d2.diff(d1)).asDays();
    for (var i = 0; i <= duration; i++) {
      input.push(_.cloneDeep(d1.toDate()));
      d1.add(1, 'd');
    }
    return input;
  }
  createMTCZoneDateTime(date) {
    return this.createMoment(date);
  }
  createMoment(date) {
    if (date) {
      if (moment.isMoment(date)) {
        return date;
      } else {
        if (
          moment(
            date,
            [
              moment.ISO_8601,
              'YYYY-MM-DD',
              'YYYY-MM-DD HH:mm:ssZ',
              'YYYY-MM-DD HH:mm:ss.SSSZ',
            ],
            true
          ).isValid()
        ) {
          return moment
            .utc(date, 'YYYY-MM-DD HH:mm:ss.SSS+Z')
            .tz('America/Chicago');
        } else if (
          moment(
            date,
            [
              'MM/DD/YYYY h:mm',
              'MM/DD/YYYY hh:mm',
              'MM/DD/YYYY hh:mm a',
              'MM/DD/YYYY',
            ],
            true
          ).isValid()
        ) {
          return moment.tz(date, 'MM/DD/YYYY h:mm', 'America/Chicago');
        } else {
          return undefined;
        }
      }
    } else {
      return undefined;
    }
  }
}
