import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  constructor() {}

  EMAILS = {
    BUSINESS: 'BUSINESS',
    PERSONAL: 'PERSONAL',
    TRAVELER: 'TRVLARR',
    SUPERVISOR: 'SUPERVSR'
  };
  ADDRESSES = {
    BUSINESS: 'WORK',
    HOME: 'HOME'
  };
  PHONES = {
    WORK: 'WORK',
    HOME: 'HOME',
    MOBILE: 'MOBILE'
  };
  PHONE_TIMES = {
    DAY_PHONE: 'DTPHONE',
    EVENING_PHONE: 'NTPHONE',
    ALTERNATE_PHONE: 'ALTPHONE'
  };
  DISABILITIES = {
    DIABETIC: 'DIABETIC',
    HEARING_IMPAIRED: 'HRNGIMPD',
    OTHER: 'OTHER',
    VISUALLY_IMPAIRED: 'VISLIMPD',
    WHEEL_CHAIR: 'WHLCHAIR'
  };
  DIETS = {
    ALLERGY: 'FOODALLE',
    LOW_FAT: 'LWFTLWCL',
    LOW_SODIUM: 'LWSODIUM',
    NO_LOW_CARB: 'NOLWCARB',
    KOSHER: 'KOSHER',
    HALAL: 'HALAL',
    VEGAN: 'VEGAN',
    VEGETARIAN: 'VEGTRIAN',
    GLUTEN_FREE: 'GLUTFREE',
    OTHER: 'OTHER'
  };
  TRAVEL_TYPES = {
    FLY: 'FLY',
    OWN_AIR: 'OWNAIR',
    PRE_REG: 'PREREG',
    DRIVE: 'DRIVE',
    RAIL: 'RAIL'
  };
  DOCUMENT_TYPES = {
    PASSPORT: 'PASSPORT',
    VISA: 'VISA',
    DRVRLIC: 'DRVRLIC',
    BTHCERT: 'BTHCERT',
    ARCARD: 'ARCARD',
    CITCERT: 'CITCERT',
    NATNLID: 'NATNLID'
  };
  AIRPREF_DIRECTIONS = {
    OUTBOUND: 'O',
    RETURN: 'R'
  };
  REQUESTED_SEAT_TYPES = {
    WINDOW: 'W',
    AISLE: 'A',
    NOPREF: 'NOPREF'
  };

  ALERT_POLICY = {
    IGNORE_ERROR: 100,
    WAIT_TRY_AGAIN: 200,
    REFRESH_TRY_AGAIN: 300,
    CONTACT_SUPPORT: 400,
    UNAUTHORIZED: 500,
    LOCALSTORAGE: 600
  };
}
