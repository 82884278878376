import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { EnrollmentService } from '../../services/enrollment.service';
import { ListManagerService } from 'src/app/services/list-manager.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { MatAccordion } from '@angular/material/expansion';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { GuestService } from 'src/app/services/guest.service';
import { PersonalPreferenceService } from 'src/app/services/personal-preference.service';
import { ConstantsService } from 'src/app/services/constants.service';
import * as _ from 'lodash';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ContactusComponent } from 'src/app/reusable-components/contactus/contactus.component';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html'
})
export class GuestComponent implements OnInit {
  enrollment;
  isSubmitting = false;
  isFormCompleted = false;
  isRegComplete: any;
  guests: any;
  maxGuests: any;
  programInfo: any;
  attendee: any;
  selectedGuest: any;
  isError: any;
  toBeDeleted: any;
  forms = [];
  hasGuestsAttending;
  DisabilityType;
  personalConsiderationsList;
  mealList;
  isNeedingDietaryConsiderations;
  suffixList;
  prefixList;
  guestTypes;
  isDeleting;
  minChildDate;
  @ViewChildren(MatExpansionPanel) panels: QueryList<ElementRef>;

  constructor(
    private navigationService: NavigationService,
    public registrationService: RegistrationService,
    private listService: ListManagerService,
    private enrollmentService: EnrollmentService,
    private configurationService: ConfigurationService,
    private guestService: GuestService,
    private personalPreferenceService: PersonalPreferenceService,
    private constantsService: ConstantsService,
    public dialog: MatDialog,
  ) {

    this.enrollment = this.registrationService.getEnrollment({});
    this.isRegComplete = this.registrationService.isRegistrationComplete();
    this.attendee = this.registrationService.getAttendee({});
    this.hasGuestsAttending = this.attendee.$mtcHasGuestsAttending;
    this.guests = this.registrationService.getGuests({});
    this.listService.getCommonPrefixList().then(list => {
      this.prefixList = list;
    });
    this.listService.getMealPreferenceList().then(list => {
      this.mealList = list;
    });
    this.listService.getDisabilityList().then(list => {
      this.personalConsiderationsList = list;
    });
    this.listService.getSuffixList().then(list => {
      this.suffixList = list;
    });

  }

  ngOnInit(): void {
    this.configurationService.fetchConfig().then((data: any) => {
      this.programInfo = data;
      this.maxGuests = this.programInfo.maxGuests;
    });
    this.guestService.getGuestTypesList().then((gTypes: any) => {
      this.guestTypes = gTypes;
    });
    this.isNeedingDietaryConsiderations = this.personalPreferenceService.isNeedingDietaryConsiderations;
    if (this.guests.length > 0) {
      if (!this.areAnyGuestsSelected()) {
        this.selectGuest(_.first(this.guests));
      }
    }
    if (this.guests.length > 0) {
      this.attendee.$mtcHasGuestsAttending = true;
      this.guests.forEach(guest => {
        if(!guest.$mtcUUID){
          guest.$mtcUUID = Math.random();
        }
      });
    }
    if (this.guests.length === 0) {
    this.addGuest({});
    }

    this.guestService.getChildMinDate().then(date=>{
     // console.log(date);
      this.minChildDate = date;
    });

  }

  isChild(pax){
    return this.guestService.isChild(pax);
  }
  includes(arr, val) {
    return _.includes(arr, val);
  }

  considerationUpdate(consideration, guest) {
    let justadded = false;

    if (guest.DisabilityType === undefined) {
      guest.DisabilityType = [];
      guest.DisabilityType = this.registrationService.getDisabilities(guest, "");
    }

    if (!_.includes(guest.DisabilityType, consideration.code)) {
      guest.DisabilityType.push(consideration.code);
      justadded = true;
    }
    if (justadded === false && _.includes(guest.DisabilityType, consideration.code)) {
      let index = guest.DisabilityType.indexOf(consideration.code);
      if (index !== -1) {
        guest.DisabilityType.splice(index, 1);
      }
    }
  }

  /**
   * @function isAddGuestEnabled
   * @description Returns if guests is less then the max amount of guests.
   */
  isAddGuestEnabled() {
    return this.guests.length < this.maxGuests;
  }

  /**
   * @function addGuest
   * @description Sets if the attendee has guests attending to true then adds a new guest to the [RegistrationService](./app.RegistrationService.html)
   */
  addGuest(guest) {
    this.isFormCompleted = false;
    guest.$mtcUUID = Math.random();
    this.guests.push(guest);
    this.registrationService.addGuests(guest);
    this.selectGuest(guest);
  }

  /**
   * @function selectGuest
   * @description Used for selecting a specific guest on the page.
   */
  selectGuest(guest) {
    this.guestService.selectGuest(guest);
    // _.each(this.guests, (item) => {
    //   let consideration = this.personalPreferenceService.hasConsiderations(item);
    //   if (item.$mtcHasConsiderations === undefined) {
    //     item.$mtcHasConsiderations = consideration;
    //   }
    // });
    this.selectedGuest = guest;
    return guest;
  }

  /**
   * @function getDisplayName
   * @description Returns the Preferred name for the guest or returns false.
   * @returns {String|Boolean}
   */
  getDisplayName(guest) {
    if (guest) {
      return guest.PreferredFirstName || guest.FirstName || "Guest";
    } else {
      return false;
    }
  }

  forward(form) {
    this.isSubmitting = true;
    this.isFormCompleted = true;
    if (form.valid || form.disabled) {
      if (!this.attendee.$mtcHasGuestsAttending) {
        this.registrationService.removeGuests(this.guests);
      }
      this.guests.forEach((guest) => {
        guest.$mtcIsSelected = false;
      });
      if (this.guests.length > 0) {
        this.guests[0].$mtcIsSelected = true;
      }
      this.navigationService.forward();
    } else {
      this.isError = true;
      this.isSubmitting = false;
    }
  }

  back() {
    // this.personalPreferenceService.commitConsiderationsChanges(this.guests);
    if (!this.attendee.$mtcHasGuestsAttending) {
      this.registrationService.removeGuests(this.guests);
    }
    this.navigationService.back();
  }

  isItemSelected(item) {
    return item.$mtcIsSelected;
  }

  /**
   * @function areAnyGuestsSelected
   */
  areAnyGuestsSelected() {
    return _.some(this.guests, this.isItemSelected);
  }

  /**
   * @function isRemoveGuestEnabled
   * @description Checks if guest removal is enabled on a guest.
   */
  isRemoveGuestEnabled() {
    let filtered = _.filter(this.guests,  (guest) => {
      return !guest.$mtcDeleting;
    });
    return filtered.length > 1;
  }

  /**
   * @function removeGuest
   * @description Removes a guest from the using [RegistrationService.removeGuests()](./app.RegistrationService.html)
   */
  removeGuest(guest) {
    this.isDeleting = true;
    setTimeout(() => {
      this.isDeleting = false;
    }, 500);
    this.toBeDeleted = guest;
    _.remove(this.guests, guest);
    _.remove(this.forms, guest);
    this.registrationService.removeGuests(guest);
    let last = _.last(this.guests);
    this.selectGuest(last === guest ? this.guests[this.guests.length - 1] : last);
  }

  openContact(){
    this.dialog.open(ContactusComponent, { panelClass: 'forgot-dialog-container' });
  }

}
