<section class="card" >
    <div class="card-header">
      <div class="card-title">
        Employment
      </div>
    </div>
    <article>
      <div class="card-body w-100">
       <mat-form-field class="w-100" >
          <mat-label for="BusinessName" aria-labelledby="BusinessName" [ngClass]="{'active' : attendee.BusinessName}">Dealership Name</mat-label>
          <input matInput name="BusinessName" [disabled]=true id="BusinessName" [attr.aria-label]="BusinessName" type="text"  maxlength="25" pattern="^[\w\s!@\-\_:',.\/?]*" [(ngModel)]="attendee.BusinessName" #BusinessName="ngModel" required />
          <mat-error><app-validation  [object]="BusinessName" isRequired=true pattern="alphanum"></app-validation></mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" >
          <mat-label for="varPartsAccountNum" aria-labelledby="varPartsAccountNum" [ngClass]="{'active' : varPartsAccountNum}">P&A Code</mat-label>
          <input matInput name="varPartsAccountNum"  [disabled]=true id="varPartsAccountNum" [attr.aria-label]="varPartsAccountNum" type="text"  maxlength="60" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="varPartsAccountNum.Value"/>
          <mat-error><app-validation [object]="varPartsAccountNum" isRequired=true pattern="alpha"></app-validation></mat-error>
        </mat-form-field>
      </div>
    </article>
   </section>
