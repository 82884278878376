<div provide-parent-form>
  <div ngModelGroup="cardAddress">
    <mat-form-field appearance="fill"  class="w-100" *ngIf="prefilladd">
      <mat-label>Prefill Billing Address</mat-label>
      <mat-select name="address" id="address" aria-label="address" [(ngModel)]="prefill"
      #add="ngModel" required matNativeControl (ngModelChange)="prefillAddress()">
        <mat-option *ngFor="let add of addresslist" [value]="add" >{{getDisplay(add)}}</mat-option>
      </mat-select>
      <mat-error><app-validation [object]="add" isRequired=true></app-validation></mat-error>
    </mat-form-field>

    <div>
      <!-- card.countryCode=""; -->
      <mat-form-field class="w-100">
        <mat-label for="countryISOCode" aria-labelledby="countryISOCode">
            {{ 'copy.label.FEATURES.ADDRESS.countrylabel' | copy | async }}</mat-label>
        <mat-select name="countryISOCode" id="countryISOCode" aria-label="countryISOCode"
            [(ngModel)]="card.countryCode" #Country="ngModel" [required]=true matNativeControl >
            <mat-option *ngFor="let obj of countryList" [value]="obj.code">
                {{ obj.name }}
            </mat-option>
        </mat-select>
        <mat-error><app-validation [object]="Country" [isRequired]=true></app-validation></mat-error>
    </mat-form-field>
      <!-- card.address=""; -->
      <mat-form-field class="w-100 mb-0">
        <mat-label for="AddressLine1" aria-labelledby="AddressLine1" [ngClass]="{'active' : card.AddressLine1}">
            {{ 'copy.label.FEATURES.ADDRESS.line1label' | copy | async }}</mat-label>
        <input matInput name="AddressLine1" id="AddressLine1" aria-label="AddressLine1" class="form-control" type="text"
            maxlength="40" pattern="^[\w\s!@\-\_:',.\/?]*" [(ngModel)]="card.address" #addressLine1="ngModel"
            [required]=true />
        <mat-error><app-validation [object]="addressLine1" [isRequired]=true pattern="alphanum"></app-validation></mat-error>
    </mat-form-field>
      <!-- card.zip=""; -->
      <mat-form-field class="w-100" *ngIf="card.countryCode === 'US'" >
        <mat-label for="Zip" aria-labelledby="Zip" [ngClass]="{'active' : card.Zip}">
            {{ 'copy.label.FEATURES.ADDRESS.ziplabel' | copy | async }}</mat-label>
        <input matInput name="Zip" id="Zip" aria-label="Zip"  type="text" maxlength="14"
            pattern="^[0-9]{5}$" [(ngModel)]="card.zip" #zip="ngModel" [required]=true
            (ngModelChange)="zipChange(zip)" />
        <mat-error><app-validation [object]="zip" [isRequired]=true pattern="uszip"></app-validation></mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" *ngIf="card.countryCode === 'CA'" >
        <mat-label for="Zip" aria-labelledby="Zip" [ngClass]="{'active' : card.Zip}">
            {{ 'copy.label.FEATURES.ADDRESS.ziplabel' | copy | async }}</mat-label>
        <input matInput name="Zip" id="Zip" aria-label="Zip"  type="text" maxlength="14"
            pattern="^([a-zA-Z][0-9][a-zA-Z]){1}[ ]{0,1}([0-9][a-zA-Z][0-9]){1}$" [(ngModel)]="card.zip" #zip="ngModel" [required]=true
            (ngModelChange)="zipChange(zip)" />
        <mat-error><app-validation [object]="zip" [isRequired]=true pattern="cazip"></app-validation></mat-error>
    </mat-form-field>

    <mat-form-field class="w-100" *ngIf="card.countryCode !== 'CA' && card.countryCode !== 'US'">
        <mat-label for="Zip" aria-labelledby="Zip" [ngClass]="{'active' : card.Zip}">
            {{ 'copy.label.FEATURES.ADDRESS.ziplabel' | copy | async }}</mat-label>
        <input matInput name="Zip" id="Zip" aria-label="Zip" class="form-control" type="text" maxlength="14"
        pattern="^[\w\s!@\-\_:',.\/?]*" [(ngModel)]="card.zip" #zip="ngModel" (ngModelChange)="zipChange(zip)" />
        <mat-error><app-validation [object]="zip" pattern="integer"></app-validation></mat-error>
    </mat-form-field>
      <!-- card.city=""; -->
      <mat-form-field class="w-100">
        <mat-label for="City" aria-labelledby="City" [ngClass]="{'active' : card.City}">
            {{ 'copy.label.FEATURES.ADDRESS.citylabel' | copy | async }}</mat-label>
        <input matInput name="City" id="City" aria-label="City"  type="text" maxlength="25"
        pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="card.city" #city="ngModel" [required]=true />
        <mat-error><app-validation [object]="city" [isRequired]=true pattern="alpha"></app-validation></mat-error>
    </mat-form-field>
      <!-- card.state=""; -->
      <div *ngIf="card.countryCode === 'US'">
        <mat-form-field class="w-100 required">
            <mat-label for="state" aria-labelledby="state">
                {{ 'copy.label.FEATURES.ADDRESS.USstatelabel' | copy | async }}</mat-label>
            <mat-select name="state" [(ngModel)]="card.state" #stateProvince="ngModel"
                [required]=true matNativeControl>
                <mat-option *ngFor="let obj of stateList" [value]="obj.code">
                    {{obj.name}}</mat-option>
            </mat-select>
            <mat-error><app-validation [object]="stateProvince" [isRequired]=true></app-validation></mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="card.countryCode === 'CA'">
        <mat-form-field class="w-100 required">
            <mat-label for="state" aria-labelledby="state">
                {{ 'copy.label.FEATURES.ADDRESS.CAstatelabel' | copy | async }}</mat-label>
            <mat-select name="state" [(ngModel)]="card.state" #stateProvince="ngModel"
                [required]=true matNativeControl>
                <mat-option *ngFor="let obj of provinceList" [value]="obj.code">
                    {{obj.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-error><app-validation [object]="stateProvince" [isRequired]=true></app-validation></mat-error>
    </div>
    <mat-form-field class="w-100" *ngIf="card.countryCode !== 'CA' && card.countryCode !== 'US'">
        <mat-label for="State" aria-labelledby="State" [ngClass]="{'active' : card.StateProvince}">
            {{ 'copy.label.FEATURES.ADDRESS.OTHstatelabel' | copy | async }}</mat-label>
        <input matInput name="State" id="State" aria-label="State" class="form-control" type="text" maxlength="25"
        pattern="^[\w\s!@\-\_:',.\/?]*" [(ngModel)]="card.state" #stateProvince="ngModel"
            />

        <mat-error><app-validation [object]="stateProvince" pattern="alphanum"></app-validation></mat-error>
    </mat-form-field>

    </div>
  </div>
</div>
