import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm, ValidatorFn, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { DateValidatorDirective } from '../date-validator.directive';
import * as moment from 'moment-timezone';
import { createMask } from '@ngneat/input-mask';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
  providers: [{provide: NG_VALIDATORS, useExisting: DateValidatorDirective, multi: true}],
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {

  @Input() participant: any;
  @Input() label: any;
  @Input() fieldName: string;
  @Input() submitted: boolean;
  @Input() isRequired: boolean;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Output() childEvent = new EventEmitter();

  tempdate;
  validateOptions = {
    minDate:undefined,
    maxDate:undefined
  };

  dateMask: any = createMask<Date>({
    alias: 'datetime',
    inputFormat:'mm/dd/yyyy'
  });

  constructor() {
  }

  ngOnInit(): void {
    if(this.participant && this.participant[this.fieldName]){
      this.tempdate = this.getMTCZoneDateString(this.participant[this.fieldName]);
    } else {
      this.tempdate = '';
    }

    this.setValidateOptions();
  }

  toISOString(date) {
    this.setValidateOptions();
    if (this.tempdate) {
      const dateObj = this.createMoment(this.tempdate)
      if (dateObj && dateObj.isValid()) {
        dateObj.hour(12);
        this.participant[this.fieldName] = dateObj.format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      }
    }

    this.childEvent.emit();
  }

  setValidateOptions(){
    if(this.minDate){
      if(this.minDate === "today"){
        this.validateOptions.minDate = new Date();
      }else{
        this.validateOptions.minDate = this.createMoment(this.minDate);
      }
    }
    if(this.maxDate){
      if(this.maxDate === "today"){
        this.validateOptions.maxDate = new Date();
      }else{
        this.validateOptions.maxDate = this.createMoment( this.maxDate);
      }
    }
  }

  createMoment(date) {
      if (date) {
          if (moment.isMoment(date)) {
              return date;
          } else {
              if (moment(date, [moment.ISO_8601, 'YYYY-MM-DD', 'YYYY-M-D', 'YYYY-MM-DD HH:mm:ssZ', 'YYYY-MM-DD HH:mm:ss.SSSZ'], true).isValid()) {
                  return moment.utc(date, 'YYYY-MM-DD HH:mm:ss.SSS+Z').tz('America/Chicago');
              } else if (moment(date, ['MM/DD/YYYY h:mm', 'MM/DD/YYYY hh:mm', 'MM/DD/YYYY hh:mm a', 'MM/DD/YYYY'], true).isValid()) {
                  return moment.tz(date, 'MM/DD/YYYY h:mm', 'America/Chicago');
              } else {
                  return undefined;
              }
          }
      } else {
          return undefined;
      }
  }

  getMTCZoneDateString(date) {
      const d = this.createMoment(date);
      if (d) {
          return d.tz('America/Chicago').format('MM/DD/YYYY');
      }
      return undefined;
  };

}
