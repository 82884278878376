import { Injectable } from '@angular/core';
import { RegistrationService } from './registration.service';
import * as _ from 'lodash';
import { ConfigurationService } from './configuration.service';
import { DateUtilityServiceService } from './date-utility-service.service';

@Injectable({
  providedIn: 'root'
})
export class GuestService {
  guestTypes = [];

  constructor(private registrationService: RegistrationService,
    private configService: ConfigurationService,
    private dateService: DateUtilityServiceService) {
    this.getGuestTypesList();
  }

  /**
    * @function GuestService.getGuestTypesList
    * @description Returns the guest types list.
    */
  getGuestTypesList() {
    const promise = new Promise((resolve, reject) => {
      this.configService.fetchConfig().then((config) => {
        this.guestTypes = config.paxTypeNo;
        if (config.error) {
          reject(config.error);
        } else {
          resolve(this.guestTypes);
        }
      });
    });
    return promise;
  }

  /**
   * @function GuestService.selectGuest
   * @description Sets $mtcIsSelected to the guest passed in.
   * @param guest
   */
  selectGuest(guest) {
    let guests = this.registrationService.getGuests([]);
    _.each(guests,  (existing) => {
      existing.$mtcIsSelected = (existing === guest);
    });
  }

  /**
    * @function GuestService.isChild
    * @description Returns whether guest is a child.
    * @param participant
    * @returns {Boolean}
    */
  isChild(participant) {

    if (!participant || !participant.PAXTypeNo) {
      return false;
    }
    let res = _.filter(this.guestTypes, { "value": participant.PAXTypeNo, "display": "Child" }).length !== 0;
    return res;
  }

  getChildMinDate(){
    const promise = new Promise((resolve, reject) => {
      this.configService.fetchConfig().then((config) => {
        let confStartDate = this.dateService.createMoment(config.arrivalDate).toDate();
        let date = '1901-01-02';
        if(config.guestAge){
          date = ''+(confStartDate.getFullYear() - config.guestAge) + '-' +confStartDate.getMonth()+'-' +confStartDate.getDate();
        }
        resolve(date);
      });
    });
    return promise;
  }

  /**
   * @function GuestService.isSpouseSelected
   * @description Returns if guest is a spouse.
   * @param participant
   * @returns {Boolean}
   */
  isSpouseSelected(participant) {
    if (participant && participant.PAXTypeNo) {
      let res = _.filter(this.guestTypes, { "value": participant.PAXTypeNo, "display": "Spouse" }).length !== 0;
      if (res) {
        return !res;
      }
    }
    let guests = this.registrationService.getGuests([]);
    let isselected = false;
    _.each(guests, (guest) => {
      if (guest && guest.PAXTypeNo) {
        let temp = _.filter(this.guestTypes, { "value": guest.PAXTypeNo, "display": "Spouse" }).length !== 0;
        if (temp) {
          isselected = true;
        }
      }
    });
    return isselected;
  }

  /**
   * @function GuestService.getTypeDisplay
   * @description Sets defualt val and returns default val or returns guest type.
   * @param participant
   */
  getTypeDisplay(participant) {
    let defaultVal = "None";
    if (!participant) {
      return defaultVal;
    }
    let type = _.find(this.guestTypes, { value: participant.PAXTypeNo });
    return type ? type.display : defaultVal;
  }
}
