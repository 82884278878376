
<div *ngIf="traveltype === 'FLY'">

    <div class="form-summary">
      <label  class="col-md-6 col-xs-12">{{'copy.label.TRAVEL.birthdate'| copy | async}}</label>
        <span class="form-summary-item col-md-6 col-xs-12" *ngIf="attendee.BirthDate">{{attendee.BirthDate| date: "longDate": "-0500"}}</span>
    </div>
    <app-summary-display [ngmodel]="displaygender" *ngIf="displaygender">{{ 'copy.label.FEATURES.GENDER.heading' | copy | async }}</app-summary-display>
    <app-summary-display [ngmodel]="attendee.RedressNumber" *ngIf="attendee.RedressNumber">{{ 'copy.label.FEATURES.TRAVELER.redress' | copy | async }}</app-summary-display>
    <app-summary-display [ngmodel]="attendee.KnownTravelerNumber" *ngIf="attendee.KnownTravelerNumber">{{ 'copy.label.FEATURES.TRAVELER.knownnum' | copy | async }}</app-summary-display>
    <app-summary-display [ngmodel]="otherknowntraveler" *ngIf="otherknowntraveler ==='Yes'">{{ 'copy.label.FEATURES.TRAVELER.otherNumberSummary' | copy | async }}</app-summary-display>

    <div *ngIf="attendee.$mtcProvidePassport && attendee.TravelDocument">
        <div class="summaryHeader"><strong>{{'copy.label.FEATURES.PASSPORT.heading'| copy | async}}</strong></div>
        <app-summary-display [ngmodel]="attendee.TravelDocument[0].DocumentNo" *ngIf="attendee.TravelDocument[0].DocumentNo">{{ 'copy.label.FEATURES.PASSPORT.numberlabel' | copy | async }}</app-summary-display>
        <div class="form-summary">
            <label class="col-md-6 col-xs-12">{{'copy.label.FEATURES.PASSPORT.dateofissuelabel'| copy | async}}</label>
            <span class="form-summary-item col-md-6 col-xs-12" *ngIf="attendee.TravelDocument[0].IssueDateTime">{{attendee.TravelDocument[0].IssueDateTime| date: "longDate": "-0500"}}</span>
        </div>
        <app-summary-display [ngmodel]="attendee.TravelDocument[0].IssuanceInfo" *ngIf="attendee.TravelDocument[0].IssuanceInfo">{{ 'copy.label.FEATURES.PASSPORT.placeofissuelabel' | copy | async }}</app-summary-display>
        <div class="form-summary">
            <label class="col-md-6 col-xs-12">{{'copy.label.FEATURES.PASSPORT.dateofexplabel'| copy | async}}</label>
            <span class="form-summary-item col-md-6 col-xs-12" *ngIf="attendee.TravelDocument[0].ExpirationDateTime">{{attendee.TravelDocument[0].ExpirationDateTime| date: "longDate": "-0500"}}</span>
        </div>
        <app-summary-display [ngmodel]="displayissuingcountry" *ngIf="displayissuingcountry">{{ 'copy.label.FEATURES.PASSPORT.countrylabel' | copy | async }}</app-summary-display>
        <app-summary-display [ngmodel]="displaybirthcountry" *ngIf="displaybirthcountry">{{ 'copy.label.FEATURES.PASSPORT.placeofbirthlabel' | copy | async }}</app-summary-display>
    </div>
    <div *ngIf="!attendee.$mtcSameItinerary">
      <div>
        <div class="summaryHeader"><strong>{{'copy.label.TRAVEL.departHome'| copy | async}}</strong></div>
        <app-summary-display [ngmodel]="attendee.$mtcOutbound.DepartureCityLocation" *ngIf="attendee.$mtcOutbound.DepartureCityLocation">{{ 'copy.label.FEATURES.FLIGHTPREF.airportlabel' | copy | async }}</app-summary-display>
        <div class="form-summary">
            <label class="col-md-6 col-xs-12">{{'copy.label.FEATURES.FLIGHTPREF.datelabel'| copy | async}}</label>
            <span class="form-summary-item col-md-6 col-xs-12" *ngIf="attendee.$mtcOutbound.DepartureDateTime">{{attendee.$mtcOutbound.DepartureDateTime| date: "longDate": "-0500"}}</span>
        </div>
        <app-summary-display [ngmodel]="outboundtimepref" *ngIf="outboundtimepref">{{ 'copy.label.FEATURES.FLIGHTPREF.timelabel' | copy | async }}</app-summary-display>
      </div>
      <div>
        <div class="summaryHeader"><strong>{{'copy.label.TRAVEL.departDest'| copy | async}}</strong></div>
        <app-summary-display [ngmodel]="attendee.$mtcReturn.DepartureCityLocation" *ngIf="attendee.$mtcReturn.DepartureCityLocation">{{ 'copy.label.FEATURES.FLIGHTPREF.airportlabel' | copy | async }}</app-summary-display>
        <div class="form-summary">
            <label class="col-md-6 col-xs-12">{{'copy.label.FEATURES.FLIGHTPREF.datelabel'| copy | async}}</label>
            <span class="form-summary-item col-md-6 col-xs-12" *ngIf="attendee.$mtcReturn.DepartureDateTime">{{attendee.$mtcReturn.DepartureDateTime| date: "longDate": "-0500"}}</span>
        </div>
        <app-summary-display [ngmodel]="returntimepref" *ngIf="returntimepref">{{ 'copy.label.FEATURES.FLIGHTPREF.timelabel' | copy | async }}</app-summary-display>
      </div>
    </div>
    <div *ngIf="attendee.InterRowPosition">
        <app-summary-display [ngmodel]="seatposition" *ngIf="seatposition">{{ 'copy.label.FEATURES.SEATPREF.heading' | copy | async }}</app-summary-display>
    </div>
    <!--div *ngIf="attendee.MainPAX && enrollment.AirSpecialRequests">
        <div class=" form-summary">
            <label class="col-md-6 col-xs-12">{{'copy.label.TRAVEL.specialrequest'| copy | async}}</label><br/>
            <span class="form-summary-item" *ngIf="enrollment.AirSpecialRequests">{{enrollment.AirSpecialRequests}}</span>
        </div>
    </div-->

    <app-summary-display [ngmodel]="enrollment.AirSpecialRequests" *ngIf="attendee.MainPAX && enrollment.AirSpecialRequests ">{{'copy.label.TRAVEL.specialrequest'| copy | async}}</app-summary-display>
       

    <div *ngFor="let flyer of attendee.FrequentFlyer">
        <app-summary-display [ngmodel]="filterairlinecode(flyer.AirlineCode)" *ngIf="flyer.AirlineCode">{{ 'copy.label.FEATURES.FREQFLY.ffairlinelabel' | copy | async }}</app-summary-display>
        <app-summary-display [ngmodel]="flyer.AccountNo" *ngIf="flyer.AccountNo">{{ 'copy.label.FEATURES.FREQFLY.ffaccountlabel' | copy | async }}</app-summary-display>
    </div>
</div>
<div *ngIf="traveltype === 'RAIL'">
    <p class=" form-summary-item col-md-6 col-xs-12" *ngIf="attendee.TravelType === this.Constants.TRAVEL_TYPES.RAIL">{{ 'copy.label.SUMMARY.travelType'| copy | async }}: {{ 'copy.label.SUMMARY.rail'| copy | async }}</p>
    <p>{{'copy.label.TRAVEL.departHome'| copy | async}}</p>
    <app-summary-display [ngmodel]="attendee.$mtcOutbound.DepartureCityLocation" *ngIf="attendee.$mtcOutbound.DepartureCityLocation">{{ 'copy.label.FEATURES.FLIGHTPREF.airportlabel' | copy | async }}</app-summary-display>
    <div class=" form-summary">
        <label class="col-md-6 col-xs-12">{{'copy.label.FEATURES.FLIGHTPREF.datelabel'| copy | async}}</label>
        <span class="form-summary-item col-md-6 col-xs-12" *ngIf="attendee.$mtcOutbound.DepartureDateTime">{{attendee.$mtcOutbound.DepartureDateTime| date: "longDate": "-0500"}}</span>
    </div>
    <app-summary-display [ngmodel]="outboundtimepref" *ngIf="outboundtimepref">{{ 'copy.label.FEATURES.FLIGHTPREF.timelabel' | copy | async }}</app-summary-display>
    <p>{{'copy.label.TRAVEL.departDest'| copy | async}}</p>
    <app-summary-display [ngmodel]="attendee.$mtcReturn.DepartureCityLocation" *ngIf="attendee.$mtcReturn.DepartureCityLocation">{{ 'copy.label.FEATURES.FLIGHTPREF.airportlabel' | copy | async }}</app-summary-display>
    <div class=" form-summary">
        <label class="col-md-6 col-xs-12">{{'copy.label.FEATURES.FLIGHTPREF.datelabel'| copy | async}}</label>
        <span class="form-summary-item col-md-6 col-xs-12" *ngIf="attendee.$mtcReturn.DepartureDateTime">{{attendee.$mtcReturn.DepartureDateTime| date: "longDate": "-0500"}}</span>
    </div>
    <app-summary-display [ngmodel]="returntimepref" *ngIf="returntimepref">{{ 'copy.label.FEATURES.FLIGHTPREF.timelabel' | copy | async }}</app-summary-display>
</div>
