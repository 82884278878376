import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ModalService } from '../../services/modal.service';
import { CopyService } from '../../services/copy.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit {

  constructor(
    private copyService: CopyService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
  
  }

  ngOnInit() {}

  actionFunction(action) {
    this.closeModal(action);
  }

  closeModal(action) {
    this.dialogRef.close(action);
  }

}