<div id="login" class="page container">
  <form name="loginForm" novalidate (ngSubmit)="forward()" autocomplete="off" [ngClass]="{'form-complete': isFormCompleted}">
    <h1>{{"copy.label.LOGIN.header"|copy|async}}</h1>
    <!-- <mtc-alert></mtc-alert> -->
    <div *ngIf="errorMsg" type="danger">
      <strong>
        <span class="fas fa-stack">
          <i class="fas fa-exclamation fa-stack-1x"></i>
          <i class="far fa-circle fa-stack-2x"></i>
        </span>
        {{errorMsg}}
      </strong>
    </div>
    <div class="content col-md-12">
      <div class="row">
        <div class="d-flex loginFlexContainer justify-content-between">
          <div [innerHTML]='"copy.html.logintext"|copy|async' class="login-info"></div>
          <div class="login-forms">
            <div class="card">
              <div class="card-header">
                <div class="card-title">{{"copy.label.LOGIN.newlogin.header"|copy|async}}</div>
              </div>
              <div class="card-body">
                <mat-form-field class="w-100">
                  <mat-label for="login-new-input" aria-labelledby="login-new-input" [ngClass]="{'active' : loginID}">{{"copy.label.LOGIN.newlogin.label"|copy|async}}</mat-label>
                  <input matInput  type="text" name="login-new-input" id="login-new-input" aria-label="login-new-input" [(ngModel)]="loginID" alphanum />
                </mat-form-field>
                <span class="help-block"><a tabindex="0" (click)="openModal('LoginRegHelp', 'Registration Help', 'Close')" (keydown.enter)="openModal('LoginRegHelp', 'Registration Help', 'Close')">{{"copy.label.LOGIN.newlogin.help"|copy|async}}</a></span>
                <mat-form-field class="w-100" ng-if="loginCtrl.programInfo.regCode">
                  <mat-label [ngClass]="{'active' : regcode}" for="regcode" aria-labelledby="regcode">{{"copy.label.LOGIN.regcode.label"|copy|async}}</mat-label>
                  <input matInput  type="text" name="regcode" id="regcode" aria-label="regcode" [(ngModel)]="regcode" alphanum />
                </mat-form-field>
                <div class="button_group d-flex">
                  <button *ngIf="!isSubmitting" type="submit" aria-label="Submit" class="forward btn btn-primary">{{'copy.label.LOGIN.newlogin.button'|copy|async}}</button>
                  <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
                    <i class="fas fa-spinner fa-spin"></i>
                  </button>
                  <button aria-label="Decline" class="back btn btn-outline-primary" type="button" (click)="declineButtonClick( )" *ngIf="!isSubmitting">Decline</button>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title">{{"copy.label.LOGIN.oldlogin.header"|copy|async}}</div>
              </div>
              <div class="card-body">
                <mat-form-field class="w-100">
                  <mat-label [ngClass]="{'active' : confirmationNo}" class="control-label">{{"copy.label.LOGIN.oldlogin.label"|copy|async}}</mat-label>
                  <!-- <i popover-placement="top" uib-popover="Your unique Confirmation Code was included in the confirmation email you received when submitting your original registration. If you no longer have that email, or you do not know your confirmation code, please use the Forgot Confirmation Code link provided on this page." class="fas fa-info-circle"></i> -->
                  <input matInput  type="text" name="login-old-conf" [(ngModel)]="confirmationNo" alphanum />
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label [ngClass]="{'active' : emailAddress}" class="control-label">{{"copy.label.LOGIN.oldlogin.email"|copy|async}}</mat-label>
                  <!-- <i popover-placement="top" uib-popover="Please make sure you enter the email address you provided during your initial registration. The system will not be able to log you in if you use an email address that was not entered as your primary email address during your initial registration." class="fas fa-info-circle"></i> -->
                  <input matInput  type="text" name="login-old-email" [(ngModel)]="emailAddress" alphanum />
                </mat-form-field>
                <span class="help-block">
                  <!-- <a ng-click="loginCtrl.openForgotConf()">{{"copy.label.LOGIN.oldlogin.forgot"|copy|async}}</a> -->
                  <a tabindex="0" (click)="openForgotConf()" (keydown.enter)="openForgotConf()">{{"copy.label.LOGIN.oldlogin.forgot"|copy|async}}</a>
                </span>
                <div class="agreeCheckbox">
                  <div class="checkboxFlexContainer">
                    <mat-checkbox name="rememberme" [(ngModel)]="rememberme" #RememberMe="ngModel"></mat-checkbox>
                    <label>{{"copy.label.SUMMARY.rememberme"|copy|async}}
                    <a tabindex="0" matTooltip="{{'copy.label.SUMMARY.remembermequestion'|copy|async}}">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                    </a></label>
                  </div>
                </div>
                <div class="button_group d-flex">
                  <button type="submit" class="forward btn btn-primary" *ngIf="!isSubmitting"> {{'copy.label.LOGIN.oldlogin.button'|copy|async}} </button>
                  <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
                    <i class="fas fa-spinner fa-spin"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
