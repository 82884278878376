import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { NavigationService } from '../../services/navigation.service';
import { EnrollmentService } from '../../services/enrollment.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ForgotConfirmationComponent } from '../../reusable-components/forgot-confirmation/forgot-confirmation.component';
import { ModalService } from 'src/app/services/modal.service';
import { HttpClient } from '@angular/common/http';
import { PersistenceService } from '../../services/persistence.service';
import { Router } from '@angular/router';
import { RememberService } from 'src/app/services/remember.service';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentService } from '../../services/environment.service';

@Component({
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.scss']
})
export class OauthComponent implements OnInit {
  loginID;
  regcode;
  oa;
  theToken = false;
  confirmationNo;
  emailAddress;
  errorMsg;
  cookieLogin;
  firstname;
  lastname;
  employeetype;
  userIDCookie;
  TrackFocusDirective;
  isFormCompleted = false;
  isSubmitting = true;
  rememberme=false;
  foundcookie=false;
  constructor(
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private enrollmentService: EnrollmentService,
    public dialog: MatDialog,
    public modalService: ModalService,
    private persistenceService: PersistenceService,
    private router: Router,
    private rememberService: RememberService,
    private route: ActivatedRoute,
    private environmentService: EnvironmentService,
    private http: HttpClient
  ) {
    //this.persistenceService.clear();

   }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
        console.log('routeparams',params);

      if(params["code"])
      {

      const serviceurl = this.environmentService.getEnvironmentUrl(
        'service/oauth/?code=' + params["code"] + "&client-request-id=" +  params["client-request-id"] );
      this.http.get(serviceurl).subscribe((data) => {
        this.oa = data;
        this.cookieLogin = this.oa.fordSiteCode;
        this.firstname = this.oa.givenName;
        this.lastname = this.oa.sn; //THIS HAS CHANGED
        this.employeetype = this.oa.fordJobRole;
        if(this.employeetype === '' || !this.employeetype )
        {
          this.employeetype = this.oa.employeeType; //HAS THIS CHANGED???
        }

        this.userIDCookie = this.oa.sub;
        if(this.employeetype === "f" || this.employeetype === "a" || this.employeetype === "omsc" || this.employeetype === "F" || this.employeetype === "A" || this.employeetype === "OMSC" || this.employeetype === "J" || this.employeetype === "j" || this.employeetype === "M" || this.employeetype === "m" || this.employeetype === "W" || this.employeetype === "w")
        {
            //USED WHEN NOT LOADING UNIQUE IDS FOR EMPLOYEES
            this.loginID = this.employeetype;
            
            //USED WHEN WE ARE LOADING EMPLOYEE IDS AND ONLY ONES LOADED CAN ATTEND
            this.loginID = this.userIDCookie;
        }
        else
        {
          //USED FOR DEALER LOGIN; ALWAYS NEEDED
          this.loginID = this.cookieLogin;
        }

        this.forward();

      });

      }else{
        this.isSubmitting = false;
        this.errorMsg = "Login not set.";

      }
    });

  }

  forward() {
    this.persistenceService.testStorage();
    this.persistenceService.clear();
    const creds: any = {};
    creds.username = this.loginID;
    creds.password = 'password';
    if (this.loginID) {
      this.authenticationService.oauthlogin(creds, this.userIDCookie).then(() => {
        // promise resolved
        this.navigationService.forward();
      }, (err) => {
        this.isSubmitting =false;
        this.errorMsg = err;
      });
    } else {
      this.isSubmitting =false;
      this.errorMsg = "Login not set.";
    }

  }

}
