<div class="modal-content-wrapper">
  <header class="modal-header">
    <h1 class="modal-title">{{ modalData.title }}</h1>
    <div tabindex="0" class="close-container" (click)="closeModal('&nbsp;')" (keydown.enter)="closeModal('&nbsp;')">
      <div class="leftright"></div>
      <div class="rightleft"></div>
    </div>
  </header>
  <mat-dialog-content class="modal-body">
    <div [innerHTML]='modalData.copy|copy|async'></div>
  </mat-dialog-content>
  <footer id="modal-footer">
    <div class="button_group d-flex flex-row justify-content-between">
      <button id="modal-action-button" class="btn btn-light"  (click)="actionFunction(modalData.actionButtonText)">
        {{ modalData.actionButtonText }}
      </button>
    </div>
  </footer>
</div>
