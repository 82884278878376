import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  public emitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  mediaTypeSelected(mediaType){
    this.emitter.emit({"MediaType": mediaType});
  }

  cardEntered(guid){
    this.emitter.emit({"guid": guid});
  }
}
