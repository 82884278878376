import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class RememberService {

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private cookieService: CookieService
  ) { }

  getCookie(){
    // const url = this.environmentService.getEnvironmentUrl('/service/remember');

    // return this.http.get<any>(url);
    let member = this.cookieService.get('remember');
    if(member) {
      return JSON.parse(member);
    } else {
      return undefined;
    }
  }

  setCookie(confcode: string, email: string){
    // const url = this.environmentService.getEnvironmentUrl('/service/remember');

    // let data = {
    //   confcode: confcode,
    //   email: email
    // };

    // return this.http.post<any>(url, data);
    let loc = window.location.toString();
    console.log(loc);
    let path = loc.substring(window.origin.length,loc.indexOf('#/'));
    console.log(path);
    let member = {confcode: confcode, email: email};
    this.cookieService.set('remember', JSON.stringify(member), {
      expires: 100,
      path: path,
      secure: true,
      sameSite: 'Lax'
    });
  }

  deleteCookie(){
    // const url = this.environmentService.getEnvironmentUrl('/service/remember');

    // return this.http.delete<any>(url);
    this.cookieService.delete('remember','/');
  }
}
