import { Component, OnInit } from '@angular/core';
import { CopyService } from '../../services/copy.service';
import { RegistrationService } from '../../services/registration.service';
import { ConfigurationService } from '../../services/configuration.service';
import { EnvironmentService } from '../../services/environment.service';
import { ConstantsService } from '../../services/constants.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/authentication.service';


@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html'
})

export class ContactusComponent implements OnInit {
  programInfo: any;
  response: any;
  options: any;
  config: any;
  attendee: any;
  attendeeEmail: any;
  isOtherExpanded: boolean;
  inq: any;
  contact: any;
  ok: any;
  success: any;
  reset: any;
  close: any;
  isFormCompleted: any;
  contactInfo: any;
  phone0: any;
  phone1: any;
  emailInfo: any;
  hours: any;
  isError: any;
  contactSent: boolean = false;
  travelHQName: string[];
  isSubmitting;

  constructor(
    private copyService: CopyService,
    private registrationService: RegistrationService,
    private configurationService: ConfigurationService,
    private environmentService: EnvironmentService,
    private constantsService: ConstantsService,
    private http: HttpClient,
    private authService: AuthenticationService,
    public dialogRef: MatDialogRef<ContactusComponent>) {
    this.attendee = this.registrationService.getAttendee({});
    this.attendeeEmail = this.registrationService.getEmail(this.attendee, undefined, {});

  }

  getHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        ProgramKey: '450282A8-FD21-4D41-B2D5-4DEEC4C75DD7',
        'MTC-Session-Token': this.authService.authtoken,
      }),
    };
  }

  ngOnInit(): void {

    this.options = [ 
      {
        value: 'Add/Cancel Guest',
        translationId: this.copyService.getCopyByKey(
          'copy.label.CONTACTUS.guest'
        )
      },
      {
        value: 'Agenda',
        translationId: this.copyService.getCopyByKey(
          'copy.label.CONTACTUS.agenda'
        )
      },
      {
        value: 'Air Reservations',
        translationId: this.copyService.getCopyByKey(
          'copy.label.CONTACTUS.air'
        )
      },
      {
        value: 'Billing',
        translationId: this.copyService.getCopyByKey(
          'copy.label.CONTACTUS.billing'
        )
      },
      {
        value: 'Cancellation',
        translationId: this.copyService.getCopyByKey(
          'copy.label.CONTACTUS.cancellation'
        )
      },
      {
        value: 'Hotel Reservations',
        translationId: this.copyService.getCopyByKey(
          'copy.label.CONTACTUS.hotel'
        )
      }, 
      {
        value: 'General Question/Other',
        translationId: this.copyService.getCopyByKey(
          'copy.label.CONTACTUS.question'
        )
      } 
    ];

    this.configurationService.fetchConfig().then((data: any) => {
      this.programInfo = data;
      if (this.programInfo !== undefined) {
        this.phone0 = this.programInfo.travelHQ800Num;
        this.phone1 = this.programInfo.travelHQIntlNum;
        this.emailInfo = this.programInfo.travelHQEmail;
        this.hours = this.programInfo.programSupportHours;
        this.travelHQName = this.programInfo.travelHQName;
      }
    });

    if (this.inq) {
      this.contact = {
        firstName: this.attendee.FirstName,
        lastName: this.attendee.LastName,
        email: this.attendeeEmail.EmailAddress,
        inquiry: this.inq,
        enrollment: this.registrationService.cleanse(this.registrationService.getEnrollment({}))
      };
    } else {
      this.contact = {
        firstName: this.attendee.FirstName,
        lastName: this.attendee.LastName,
        email: this.attendeeEmail.EmailAddress,
        enrollment: this.registrationService.cleanse(this.registrationService.getEnrollment({}))
      };
    }

    this.ok =  (form) => {
      this.isFormCompleted = true;
      this.isSubmitting = true;
      if (form.valid) {
        const serviceurl = this.environmentService.getEnvironmentUrl(
          'service/contact-us'
        );

        this.http.post(serviceurl, this.contact, this.getHeaders()).subscribe(() => {
          this.contactSent = true;
        });

      } else {
        this.isError = true;
        this.isSubmitting = false;
      }
    };


    this.reset = (form) => {
      this.contact = {};
      form.controls.email.value = "";
      form.controls.email.markAsPristine();
      form.controls.firstName.markAsPristine();
      form.controls.lastName.markAsPristine();
      form.controls.inquiry.markAsPristine();
      form.controls.message.markAsPristine();
      this.isFormCompleted = false;
      this.isOtherExpanded = false;
      this.isError = false;
    }
  }
  toggleOtherMethodsDisplay() {
    this.isOtherExpanded = !this.isOtherExpanded;
  }
  onNoClick(action): void {
    this.dialogRef.close(action);
  }

}
