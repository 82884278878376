import { Component, OnInit} from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { RegistrationService } from '../../services/registration.service';
import { ListManagerService } from '../../services/list-manager.service';
import { EnrollmentService } from '../../services/enrollment.service';
import { VariablesService } from '../../services/variables.service';
import * as _ from 'lodash';

@Component({
  templateUrl: './testpage.component.html'
})
export class TestPageComponent implements OnInit {
  isSubmitting = false;
  isError = false;
  attendee;
  pax;
  prefixList;
  suffixList;
  cdsid;
  noncompid;
  workPhone;
  testPageRadios
  attendeetypetest
  homePhone;
  mobilePhone;
  workAddress;
  homeAddress;
  emailAddress;
  adminContact;
  emerContact;
  emerContactMainPhone;
  emerContactAltPhone;
  DisabilityType;
  mealList;
  personalConsiderationsList;
  $mtcverifyEmailAddress;
  $mtcMailToAddress;
  $mtcHasConsiderations;
  isRegComplete;
  enrollment;
  answer;
  isFormCompleted = false;

  clickedCountry;
  childEventClicked(data) {
    this.clickedCountry = data;
  }


  constructor(
    private navigationService: NavigationService,
    private registrationService: RegistrationService,
    private listService: ListManagerService,
    private enrollmentService: EnrollmentService,
    private variablesService: VariablesService
  ) {

    this.enrollment = this.registrationService.getEnrollment({});
    this.isRegComplete = this.registrationService.isRegistrationComplete();
    this.testPageRadios = [
        {value: 1, display: "Dealer"},
        {value: 5, display: "Company"}
    ];
  

    this.attendee = this.registrationService.getAttendee({});
    this.pax = this.attendee.PAXTypeNo;

  }

  ngOnInit(): void { 


  }

  includes(arr, val){
    return _.includes(arr, val);
  }






  forward(form) {
    this.isSubmitting = true;
    this.isFormCompleted = true;
 
    if (form.valid ) {
      this.navigationService.forward();
    } else {
      this.isError = true;
      this.isSubmitting = false;

    }
  }

  back() {
    this.navigationService.back();
  }
}
