import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../../services/registration.service';
import { ConfigurationService } from '../../services/configuration.service'; 
import { ContactusComponent } from 'src/app/reusable-components/contactus/contactus.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  templateUrl: './acknowledgement.component.html'
})
export class AcknowledgementComponent implements OnInit {
  programInfo: any;
  enrollment: any;
  contact: any;
  phone0: any;
  phone1: any;
  email: any;
  hours: any;
  attendee;
  returnLogin: any;

  constructor(
    private registrationService: RegistrationService,
    private configurationService: ConfigurationService,
    public dialog: MatDialog) {
      this.enrollment = this.registrationService.getEnrollment({});
      this.attendee = this.registrationService.getAttendee({});
  
  }

  ngOnInit(): void {
    this.configurationService.fetchConfig().then((data: any) => {
      this.programInfo = data;
      if (this.programInfo !== undefined) {
        this.returnLogin = this.programInfo.returnLogin;
        this.phone0 = this.programInfo.travelHQ800Num;
        this.phone1 = this.programInfo.travelHQIntlNum;
        this.email = this.programInfo.travelHQEmail;
        this.hours = this.programInfo.programSupportHours;
      }
    });
  }

  openContactModal(): void {
    let dialogRef = this.dialog.open(ContactusComponent, { panelClass: 'forgot-dialog-container' });
  }

}
