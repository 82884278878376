<div id="attendee" class="registration container">
  <form #attendeeForm="ngForm" name="attendeeForm" (ngSubmit)="forward(attendeeForm)" novalidate appScrollToInvalid [ngClass]="{'form-complete': isFormCompleted}">
    <h1>{{ 'copy.label.ATTENDEE.header' | copy | async }}</h1>
    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.ATTENDEE.PERSONAL.header' | copy | async }}
        </div>
      </div>
      <article>
        <div class="card-body w-100">
          <mat-form-field class="prefixInput">
            <mat-label for="Prefix">{{ 'copy.label.FEATURES.NAME.prefixlabel' | copy | async }}</mat-label>
            <mat-select name="Prefix" [disabled]="isRegComplete" id="Prefix" aria-label="Prefix" [(ngModel)]="attendee.Prefix" #Prefix="ngModel" matNativeControl>
              <mat-option *ngFor="let obj of prefixList" [value]="obj.code">{{ obj.name }}</mat-option>
            </mat-select>
            <mat-error><app-validation  [object]="Prefix" isRequired=true></app-validation></mat-error>
          </mat-form-field>

          <mat-form-field class="firstNameInput">
            <mat-label for="FirstName" [ngClass]="{'active' : attendee.FirstName}">{{ 'copy.label.FEATURES.NAME.first' | copy | async }}</mat-label>
            <input  matInput name="FirstName" [disabled]="isRegComplete" id="FirstName" [attr.aria-label]="FirstName" type="text"  maxlength="25" pattern="^[A-Za-z\s\-\_',.]*$" [(ngModel)]="attendee.FirstName" #FirstName="ngModel" required />
            <mat-error><app-validation  [object]="FirstName" isRequired=true pattern="alpha"></app-validation></mat-error>
          </mat-form-field>

          <mat-form-field class="lastNameInput">
            <mat-label for="LastName" [ngClass]="{'active' : attendee.LastName}">{{ 'copy.label.FEATURES.NAME.last' | copy | async }}</mat-label>
            <input matInput name="LastName" [disabled]="isRegComplete" id="LastName" [attr.aria-label]="LastName" type="text"  maxlength="25" pattern="^[A-Za-z\s\-\_,'.]*$" [(ngModel)]="attendee.LastName" #LastName="ngModel" required />
            <mat-error><app-validation  [object]="LastName" isRequired=true pattern="alpha"></app-validation></mat-error>
          </mat-form-field>

          <mat-form-field class="suffixInput">
            <mat-label for="Suffix">{{ 'copy.label.FEATURES.NAME.suffix' | copy | async }}</mat-label>
            <mat-select name="Suffix" [disabled]="isRegComplete" id="Suffix" aria-label="Suffix" [(ngModel)]="attendee.Suffix" matNativeControl>
              <mat-option *ngFor="let obj of suffixList" [value]="obj.code">{{ obj.name }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="ProfessionalLetters">
            <mat-label for="ProfessionalLetters" aria-labelledby="ProfessionalLetters" [ngClass]="{'active' : attendee.ProfessionalLetters}">{{ 'copy.label.FEATURES.NAME.proletterslabel' | copy | async }}</mat-label>
            <input  matInput name="ProfessionalLetters" [disabled]="isRegComplete" id="ProfessionalLetters" [attr.aria-label]="ProfessionalLetters" type="text" class="form-control" maxlength="20" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="attendee.ProfessionalLetters" #ProfessionalLetters="ngModel" />
            <app-validation  [object]="ProfessionalLetters" isRequired=false pattern="alpha"></app-validation>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label for="PreferredFirst" aria-labelledby="PreferredFirst" [ngClass]="{'active' : attendee.PreferredFirstName}">{{ 'copy.label.FEATURES.NAME.preferredfirst' | copy | async }}</mat-label>
            <input matInput name="PreferredFirst" id="PreferredFirst" aria-label="PreferredFirst" type="text" class="form-control" maxlength="25" pattern="^[A-Za-z\s\-\_,'.]*$" [(ngModel)]="attendee.PreferredFirstName" #PreferredFirst="ngModel" required />
            <app-validation  [object]="PreferredFirst" isRequired=true pattern="alpha"></app-validation>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label for="PreferredLast" aria-labelledby="PreferredLast" [ngClass]="{'active' : attendee.PreferredLastName}">{{ 'copy.label.FEATURES.NAME.preferredlast' | copy | async }}</mat-label>
            <input matInput name="PreferredLast" id="PreferredLast" [attr.aria-label]="PreferredLast" type="text" class="form-control" maxlength="25" pattern="^[A-Za-z\s\-\_,'.]*$" [(ngModel)]="attendee.PreferredLastName" #PreferredLast="ngModel" required />
            <app-validation  [object]="PreferredLast" isRequired=true pattern="alpha"></app-validation>
          </mat-form-field>


          <div class="w-100" *ngIf="(attendee.PAXTypeNo === 99 || attendee.PAXTypeNo === 1 || attendee.PAXTypeNo === 2 || attendee.PAXTypeNo === 3 || attendee.PAXTypeNo === 4) && !isRegComplete">
            <mat-form-field class="w-100">
              <mat-label for="PAXTypeNo" aria-labelledby="PAXTypeNo">{{ 'copy.label.ATTENDEE.attendeetype' | copy | async }}</mat-label>
              <mat-select name="PAXTypeNo" [(ngModel)]="attendee.$mtcPAXTypeNo" required>
                <mat-option *ngFor="let obj of attendeetypeList" [value]="obj.value">{{ obj.display }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100" *ngIf="attendee.$mtcPAXTypeNo === 4">
              <mat-label for="JobTitle" aria-labelledby="JobTitle" [ngClass]="{'active' : attendee.JobTitle}">{{ 'copy.label.ATTENDEE.otherattendeetype' | copy | async }}</mat-label>
              <input matInput name="JobTitle" id="JobTitle" [attr.aria-label]="JobTitle" type="text" class="form-control" maxlength="75" pattern="^[\w\s!()&+/@\-\_\\:',.\/?]*" [(ngModel)]="attendee.JobTitle" #JobTitle="ngModel" required />
              <app-validation  [object]="JobTitle" isRequired=true pattern="alphanum"></app-validation>
            </mat-form-field>

          </div>

          <mat-form-field class="w-100" *ngIf="attendee.$mtcPAXTypeNo === 1 || attendee.PAXTypeNo === 8 || attendee.PAXTypeNo === 9 || attendee.PAXTypeNo === 14 || attendee.PAXTypeNo === 15 || attendee.PAXTypeNo === 18">
            <mat-label for="BusinessName" aria-labelledby="BusinessName" [ngClass]="{'active' : attendee.BusinessName}">{{ 'copy.label.FEATURES.COMPANY.label' | copy | async }}</mat-label>
            <input matInput name="BusinessName" id="BusinessName" [attr.aria-label]="BusinessName" type="text" class="form-control" maxlength="50" pattern="^[\w\s!&@\-\_\\:',.\/?]*" [(ngModel)]="attendee.BusinessName" #BusinessName="ngModel" required />
            <app-validation  [object]="BusinessName" isRequired=true pattern="alphanum"></app-validation>
          </mat-form-field>

          <mat-form-field class="w-100" *ngIf="attendee.PAXTypeNo === 9">
            <mat-label for="JobTitle" aria-labelledby="JobTitle" [ngClass]="{'active' : attendee.JobTitle}">{{ 'copy.label.FEATURES.JOBTITLE.label' | copy | async }}</mat-label>
            <input matInput name="JobTitle" id="JobTitle" [attr.aria-label]="JobTitle" type="text" class="form-control" maxlength="75" pattern="^[\w\s!()&+/@\-\_\\:',.\/?]*" [(ngModel)]="attendee.JobTitle" #JobTitle="ngModel" required />
            <app-validation  [object]="JobTitle" isRequired=true pattern="alphanum"></app-validation>
          </mat-form-field>

          <div class="w-100" *ngIf="attendee.PAXTypeNo === 6 || attendee.PAXTypeNo === 10 || attendee.PAXTypeNo === 11 || attendee.PAXTypeNo === 12 || attendee.PAXTypeNo === 13">
            <mat-form-field class="w-100">
              <mat-label for="Company" aria-labelledby="Company">{{ 'copy.label.FEATURES.COMPANY.label' | copy | async }}</mat-label>
              <mat-select name="Company" [(ngModel)]="attendee.$mtcBusiness" required>
                <mat-option *ngFor="let obj of businessList" [value]="obj.value">{{ obj.display }}</mat-option>
              </mat-select>
            </mat-form-field>
            
            <mat-form-field class="w-100" *ngIf="attendee.$mtcBusiness === 'Other'">
              <mat-label for="BusinessName" aria-labelledby="BusinessName" [ngClass]="{'active' : attendee.BusinessName}">{{ 'copy.label.FEATURES.COMPANY.label' | copy | async }}</mat-label>
              <input matInput name="BusinessName" id="BusinessName" [attr.aria-label]="BusinessName" type="text" class="form-control" maxlength="50" pattern="^[\w\s!&@\-\_\\:',.\/?]*" [(ngModel)]="attendee.BusinessName" #BusinessName="ngModel" required />
              <app-validation  [object]="BusinessName" isRequired=true pattern="alphanum"></app-validation>
            </mat-form-field>
          </div>


        </div>

      </article>
    </section>
    <!--app-fordattendee></app-fordattendee-->
    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.ATTENDEE.CONTACT.header' | copy | async }}
        </div>
      </div>
      <article>
        <div class="card-body w-100">
          <!--div class="w-100 required">
            <label>{{ 'copy.label.FEATURES.ADDRESS.mailtolabel' | copy | async }}</label>
             <div>
              <mat-radio-group [(ngModel)]="mtcMailToAddress" name="mailTo" #mailTo="ngModel" required (ngModelChange)="setMailTo()">
                <mat-radio-button value="WORK">Business</mat-radio-button>
                <mat-radio-button value="HOME">Home</mat-radio-button>
                <div class="w-100">
                  <small class="text-danger"><app-validation  [object]="mailTo" isRequired=true></app-validation></small>
                </div>
              </mat-radio-group>
            </div>
          </div-->
        <div [innerHTML]="'copy.label.ATTENDEE.CONTACT.warning' |copy |async"></div>
        <div class="md-form">
          <label>{{ 'copy.label.FEATURES.ADDRESS.business' | copy | async }}</label>
        </div>
        <!-- Business Address -->
        <app-address class="w-100 mt-3"  [isRequired]=true [resident]=true [attendee]="attendee" [address]="workAddress" groupname="workAddress" name="workAddress"></app-address>
        <!--div class="md-form mb-3">
         // Home Address
          <label class="float-left w-100">{{ 'copy.label.FEATURES.ADDRESS.home' | copy | async }}</label>
        </div>
        <div class="md-form">
          <mat-checkbox name="copyAddress" (change)="FieldsChange($event)">{{ 'copy.label.ATTENDEE.same' | copy | async }}</mat-checkbox>
        </div>
        <app-address class="w-100" [isRequired]="homeAddress.MailTo" [resident]=false [attendee]="attendee" [address]="homeAddress" groupname="homeAddress" name="homeAddress"></app-address-->

        <app-phone [phone]="workPhone"  isRequired=true groupname="workPhone" label="copy.label.ATTENDEE.CONTACT.bizphonelabel"></app-phone>
        <app-phone [phone]="mobilePhone"  isRequired=true groupname="mobilePhone" label="copy.label.ATTENDEE.CONTACT.cellphonelabel"></app-phone>
        <!--app-phone [phone]="homePhone"  isRequired=false groupname="homePhone"
         label="copy.label.ATTENDEE.CONTACT.homephonelabel"></app-phone-->
        <!-- Email -->
        <app-email class="w-100" [model]="emailAddress" verify></app-email>

      </div>
  </article>
</section>
<!-- Emergency Contact Section -->
<section class="card">
  <div class="card-header">
    <div class="card-title">
      {{ 'copy.label.ATTENDEE.EMERGENCY.header' | copy | async }}
    </div>
  </div>
  <article>
    <div class="card-body w-100">
      <p>Please enter contact information for the person we should contact in case of an emergency while you are traveling. Please do not enter the name of a person who is traveling with you.</p>
      <mat-form-field class="w-100">
        <mat-label for="attendeeemailverify" [ngClass]="{'active' : attendee.EmergencyContact.EmergencyContactName}">
          {{ 'copy.label.FEATURES.EMERGENCY.namelabel' | copy | async }}
        </mat-label>
        <input matInput name="EmergencyContactName" id="EmergencyContactName" aria-label="EmergencyContactName"  type="text" maxlength="30" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="attendee.EmergencyContact.EmergencyContactName" #EmergencyContactName="ngModel" required />
        <mat-error><app-validation  [object]="EmergencyContactName" isRequired=true pattern="alpha"></app-validation></mat-error>
      </mat-form-field>

      <mat-form-field class="w-100">
        <mat-label for="EmergencyContactRelation" [ngClass]="{'active' : attendee.EmergencyContact.EmergencyContactRelation}">{{ 'copy.label.FEATURES.EMERGENCY.relationshiplabel' | copy | async }}</mat-label>
        <input matInput name="EmergencyContactRelation" id="EmergencyContactRelation" aria-label="EmergencyContactRelation"  type="text" maxlength="30" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="attendee.EmergencyContact.EmergencyContactRelation" #EmergencyContactRelation="ngModel" required />
        <mat-error><app-validation  [object]="EmergencyContactRelation" isRequired=true pattern="alpha"></app-validation></mat-error>
      </mat-form-field>

      <app-phone [phone]="emerContactMainPhone"  isRequired=true groupname="emerContactMainPhone" label="copy.label.FEATURES.EMERGENCY.phonelabel"></app-phone>
      <app-phone [phone]="emerContactAltPhone"  isRequired=false groupname="emerContactAltPhone" label="copy.label.FEATURES.EMERGENCY.altphonelabel"></app-phone>

    </div>
  </article>
</section>
<!-- Admin Section -->
<section class="card">
  <div class="card-header">
    <div class="card-title">
      {{ 'copy.label.ATTENDEE.ADMIN.header' | copy | async }}
    </div>
  </div>
  <article>
    <div class="card-body w-100">
      <div class="w-100 required">
        <label class="w-100 required">{{'copy.label.ATTENDEE.ADMIN.label' |copy | async}}</label>
        <mat-radio-group class="w-100" [(ngModel)]="enrollment.$mtcHasAdminContact" required #admin="ngModel" name="hasAdmin">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
          <div class="w-100">
            <small class="text-danger"><app-validation  [object]="admin" isRequired=true></app-validation></small>
          </div>
        </mat-radio-group>
      </div>
      <mat-form-field class="w-100" *ngIf="enrollment.$mtcHasAdminContact === true">
        <mat-label for="AdminContactName" [ngClass]="{'active' : adminContact.AdminContactName}">{{
          'copy.label.FEATURES.ADMIN.namelabel' | copy | async
          }}</mat-label>
        <input matInput name="AdminContactName" id="AdminContactName" aria-label="AdminContactName"  type="text" maxlength="60" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="adminContact.AdminContactName" #AdminContactName="ngModel" required />
        <mat-error><app-validation  [object]="AdminContactName" isRequired=true pattern="alphanum"></app-validation></mat-error>
      </mat-form-field>
      <app-phone [phone]="adminPhone"  isRequired=true groupname="adminContactPhone" label="copy.label.FEATURES.ADMIN.phonelabel" *ngIf="enrollment.$mtcHasAdminContact === true"></app-phone>
      <mat-form-field class="w-100" *ngIf="enrollment.$mtcHasAdminContact === true">
        <mat-label for="adminemail" [ngClass]="{'active' : adminContact.AdminContactEmailAddress}">{{ 'copy.label.FEATURES.ADMIN.email' | copy | async }}</mat-label>
        <input matInput type="text" name="adminemail" id="adminemail" aria-label="adminemail"  maxlength="100" [(ngModel)]="adminContact.AdminContactEmailAddress" pattern="[a-zA-Z0-9._'%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}" #adminemail="ngModel" required />

        <mat-error><app-validation  [object]="adminemail" isRequired=true pattern="email"></app-validation></mat-error>
      </mat-form-field>
    </div>
  </article>
</section>
<!-- Dietary Section -->
<section class="card">
  <div class="card-header">
    <div class="card-title">
      {{ 'copy.label.ATTENDEE.DIETARY.header' | copy | async }}
    </div>
  </div>
  <article>
    <div class="card-body w-100">
      <!-- <div class="w-100 required">
        <label class="w-100 floatLeft">{{ 'copy.label.ATTENDEE.DIETARY.label' | copy | async }}</label>
        <mat-radio-group class="w-100" [(ngModel)]="attendee.$mtcHasConsiderations" required #dietary="ngModel" name="hasConsiderations">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
          <div class="w-100">
            <mat-error><app-validation  [object]="dietary" isRequired=true></app-validation></mat-error>
          </div>
        </mat-radio-group>
      </div> -->
      <!-- Dietary -->
      <div class="w-100" >


        <div   [innerHTML]='"copy.html.washHealth"|copy|async'></div> &nbsp;
&nbsp;

        <mat-form-field class="w-100">
          <mat-label for="DietaryNeed">{{ 'copy.label.FEATURES.DIETARY.label' | copy | async }}</mat-label>
          <mat-select name="DietaryNeed" [(ngModel)]="attendee.DietaryNeed">
            <mat-option *ngFor="let obj of mealList" [value]="obj.code">{{ obj.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="w-100">
        <div class="d-flex justify-content-start">
          <mat-form-field class="w-100">
            <mat-label for="DietaryConsiderations">{{ 'copy.label.FEATURES.DIETARY.otherlabel' | copy | async }}</mat-label>
            <textarea matInput class="w-100" name="DietaryConsiderations" id="DietaryConsiderations" aria-label="DietaryConsiderations" [(ngModel)]="attendee.DietaryConsiderations" #DietaryConsiderations="ngModel" maxlength="225"  [required]="attendee.DietaryNeed === 'OTHER' || attendee.DietaryNeed === 'FOODALLE'" pattern="^[\w\s!@\-\_:',.\/?]*"></textarea>
            <mat-error><app-validation  [object]="DietaryConsiderations" isRequired=true pattern="alphanum"></app-validation></mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- Personal -->
      <div class="w-100" >
        {{ 'copy.label.FEATURES.PERSONAL.heading' | copy | async }}
        <div *ngFor="let considerations of personalConsiderationsList">
          <div class="agreeCheckbox my-2">
            <div class="checkboxFlexContainer">
              <mat-checkbox name="considerations_{{ considerations.name }}" [ngModel]="includes(attendee.DisabilityType,considerations.code)" (change)="considerationUpdate(considerations)" aria-labelledby="{{ considerations.code }}_label"></mat-checkbox>
              <label id="{{ considerations.code }}_label">{{ considerations.name }}</label>
          <!-- {{personalConsiderations.invalid}} -->
            </div>
          </div>
        </div>
      </div>
      <div class="w-100" >
        <div class="d-flex justify-content-start">
          <mat-form-field class="w-100">
            <mat-label for="PersonalConsiderations">{{ 'copy.label.FEATURES.PERSONAL.otherlabel' | copy | async }}</mat-label>
            <textarea matInput class="w-100" name="PersonalConsiderations" id="PersonalConsiderations" aria-label="PersonalConsiderations" [(ngModel)]="attendee.PersonalConsiderations" #PersonalConsiderations="ngModel" maxlength="225"  [required]="includes(attendee.DisabilityType, 'OTHER')"  pattern="^[\w\s!@\-\_:',.\/?]*"></textarea>
            <mat-error><app-validation  [object]="PersonalConsiderations" isRequired=true pattern="alphanum"></app-validation></mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </article>
</section>
<div class="button_group d-flex">
  <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()" *ngIf="!isSubmitting">
    {{ 'copy.label.GLOBAL.goback' | copy | async }}
  </button>
  <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
    {{ 'copy.label.GLOBAL.continue' | copy | async }}
  </button>
  <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
    <i class="fas fa-spinner fa-spin"></i>
  </button>
</div>
</form>
</div>
