import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { info } from 'console';
import { AuthenticationService } from './authentication.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }


  error(message, methodName:String){
    return this.logmessage(message, methodName, 'error');
  }
  info(message, methodName:String){
    return this.logmessage(message, methodName, 'info');
  }

  private logmessage(message, methodName:String, severity:String){
    let url = this.environmentService.getEnvironmentUrl("service/log/");


    let logobject = {
      userSessionToken: this.authService.authtoken,
      applicationName: this.environmentService.sitename,
      userAgentName: window.navigator.userAgent,
      severityLevel: severity,
      moduleName: this.environmentService.sitename,
      // viewName: viewName,
      // viewURL: this.environmentService.sitename,
      applicationContext: this.environmentService.getProgramKey(),
      logMessage: message,
      methodName: methodName
    }

    return this.http.put(url, logobject);

  }

  errorcc(message, methodName:String){
    return this.logmessage(message, methodName, 'error');
  }
}
