import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'src/app/services/registration.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-replacement',
  templateUrl: './replacement.component.html',
  styleUrls: ['./replacement.component.scss']
})
export class ReplacementComponent implements OnInit {

  attendee: any;
  EmailAddress;
  programInfo;
  replacementOptionRadios;
  replacementHotelRadios;
  isFormCompleted;
  isSubmitting;
  error;
  Cancel = { firstName: '', lastName: '', email: '', payment: '' , hotel: '', reason: '' };

  constructor(private registrationService: RegistrationService,
    private constantsService: ConstantsService,
    private configurationService: ConfigurationService,
    private environmentService: EnvironmentService,
    private router: Router,
    private http: HttpClient) {
    this.attendee = this.registrationService.getAttendee({});
    this.EmailAddress = this.registrationService.getEmail(this.attendee, this.constantsService.EMAILS.BUSINESS, {}).EmailAddress;


  }

  ngOnInit(): void {
    this.replacementOptionRadios = [
      {
        value: "Please credit my credit card for the total amount of my registration",
        display: "Please credit my credit card for the total amount of my registration"
      },
      {
        value: "Transfer my registration payment to the participant listed above",
        display: "Transfer my registration payment to the participant listed above"
      }
    ];
    this.configurationService.fetchConfig().then((data: any) => {
      this.programInfo = data;

      this.replacementHotelRadios = [
        { value: "I would like to cancel my hotel", display: "I would like to cancel my hotel" },
        { value: "Please transfer my hotel to the new participant replacement", display: "Please transfer my hotel to the new participant replacement" }
      ];
    });
  }


  /**
    * @function ReplacementController.back
    * @description Uses [NavigationService.back()](./app.NavigationService.html) to go back to previous page.
    */
  back() {
    this.router.navigate(['/summary']);
  };

  /**
    * @function ReplacementController.forward
    * @description Checks if form is valid then uses [NavigationService.forward()](./app.NavigationService.html) to go forward to next page.
    */
  forward(isFormValid) {
    this.isFormCompleted = true;
    if (isFormValid) {
      this.isSubmitting = true;
        this.http.post(this.environmentService.getEnvironmentUrl("service/enrollment/cancel"), this.Cancel).subscribe(
          () => {
            this.registrationService.getEnrollment({}).$mtcUpdateSuccess = true;
            this.router.navigate(['/summary']);

          },
          (err) => {
            this.error = true;
            this.isSubmitting = false;
          }
        );
    }
  };
}
