import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {
  constructor() {}

  test = 'test';

  VARIABLE = {
    varDealerCode: { ID: 1 },
    varArea: { ID: 2 },
    varDistrict: { ID: 3 },
    varGuestSubPAXType: { ID: 4 },
    varMailCode: { ID: 5 },
    varEliteBadge: { ID: 6 },
    varGuestAllowed: { ID: 7 },
    varHotelPackage: {ID: 8},  
    varHotelGuarantee:{ID: 9},
    varNAAB: { ID: 10 },
    varFeeExempt: { ID: 11 },
    varCityState: { ID: 12 }, 
    varDAP: { ID: 13 }, 
    varSpinID: { ID: 14 },
    varTypeOfLogin: { ID: 15}, 
    varVehicle: {ID: 16}, 
    varArrAirportTransportation:{ID: 17},
    varArrAirportSelection: {ID: 18},
    varHotelDatesMatch:{ID: 19}, 
    //varRedressTraveler:{ID: 20},
    OTHER_NUMBER: {ID:20},
    varArrivalTransferCode : {ID: 21},
    varPE:{ID: 22},
    varBADGE:{ID: 23},
    varDeskLocation: {ID: 24},
    varDepartAirportTransportation:{ID: 26},
    varDepartAirportSelection: {ID: 27},
    varDepartTransferCode : {ID: 28},  
 
    // NO_HOTEL_REASON: {ID: 2, summaryDisplayLabel: "nohotellabel", displayOnSummary:true, page:"hotel"}
  };


  toCheckBoxModel(actual, keys, delimiters?) {
    if (!keys) {
      return undefined;
    }
    const model = {};
    if (!actual) {
      return model;
    }
    const split = actual.split(delimiters ? delimiters : /,+ */);
    return _.values(keys).reduce((a, c) => {
      a[c] = split.indexOf(c) >= 0;
      return a;
    }, model);
  }

  toDelimitedKeysString(c, delimiters?) {
    if (!c) {
      return '';
    }
    return _.keys(c)
      .filter(k => {
        return c[k];
      })
      .join(delimiters ? delimiters : ', ');
  }
}
