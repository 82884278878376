import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, ValidatorFn, NG_VALIDATORS } from '@angular/forms';
import { DateUtilityServiceService } from '../services/date-utility-service.service';

@Directive({
  selector: '[appDateValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: DateValidatorDirective, multi: true}]
})
export class DateValidatorDirective implements Validator {

  @Input('appDateValidator') options;

  constructor(
    private dateService: DateUtilityServiceService
  ) {}

  validate(control: AbstractControl): {[key: string]: any} | null {
    return this.dateValidator()(control);
  }

  dateValidator(): ValidatorFn{
    return (control: AbstractControl): {[key: string]: any} | null => {

      const textdate = control.value;
      if (textdate) {
        const dateObj = new Date(textdate);
        if (isNaN(dateObj.getTime()) || textdate.indexOf('/00') >= 0) {
          return {invalidDate: {value: control.value}};
        } else {
          if(this.options.minDate){
            if(dateObj.getTime() < this.options.minDate){
              return {minDate: {value: control.value}};
            }
          }
          if(this.options.maxDate){
            if(dateObj.getTime() > this.options.maxDate){
              return {maxDate: {value: control.value}};
            }
          }else{
            return null;
          }
        }
      } else {
        return null;
      }
    };
  }
}
