import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { CopyService } from 'src/app/services/copy.service';
import { DateUtilityServiceService } from 'src/app/services/date-utility-service.service';
import { RegistrationService } from 'src/app/services/registration.service';

@Component({
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.scss']
})
export class HotelComponent implements OnInit {


  constructor(private navigationService: NavigationService,
    private copyService: CopyService,
    private registrationService: RegistrationService,
    private dateService: DateUtilityServiceService
) {
  this.enrollment = this.registrationService.getEnrollment({});
  this.attendee = this.registrationService.getAttendee({});

  this.attendee.HotelSmoking = false;
  this.declineRadios = [
    {
      value: 'Business Conflict',
      display: this.copyService.getCopyByKey(
        'copy.label.INVITATION.declineReason1'
      )
    },
    {
      value: 'Personal Conflict',
      display: this.copyService.getCopyByKey(
        'copy.label.INVITATION.declineReason2'
      )
    },
    {
      value: 'other',
      display: this.copyService.getCopyByKey(
        'copy.label.INVITATION.declineReason3'
      )
    }
  ];

}
  enrollment;
  attendee;
  declineRadios;
  isSubmitting;
  HoldSpecialRequests;
  isFormCompleted=false;
  noHotelRadios = [
    { display: "Staying at local residence", value: "Staying at local residence" },
   // { display: "Sharing a room reserved by someone else", value: "Sharing a room reserved by someone else" },
    { display: "Arranged my own accommodations", value: "Arranged my own accommodations" },
   // { display: "I will add my hotel later", value: "I will add my hotel later" }
  ];

  ngOnInit(): void {
  }

  clearRequests() {
    if (this.enrollment.HotelNeeded === true) {
      if (this.enrollment.HotelSpecialRequests === "Staying at local residence" || this.enrollment.HotelSpecialRequests === "Sharing a room reserved by someone else"
        || this.enrollment.HotelSpecialRequests === "Arranged my own accommodations" || this.enrollment.HotelSpecialRequests === "I will add my hotel later") {
        this.HoldSpecialRequests = this.enrollment.HotelSpecialRequests;
        this.enrollment.HotelSpecialRequests = "";
      }
    } else if (this.HoldSpecialRequests) {
      this.enrollment.HotelSpecialRequests = this.HoldSpecialRequests;
    }
  }

  forward(form) {
    this.isFormCompleted=true;
    if (this.enrollment.HotelNeeded !== undefined && form.valid) {
      if (this.enrollment.HotelNeeded) {
        // User must have selected hotels on contiguous dates
          this.isSubmitting = true;
          this.navigationService.forward();
      } else {

        this.isSubmitting = true;
        this.navigationService.forward();
      }
    } else {
      this.isSubmitting = false;
    }
  }

  back() {
    this.navigationService.back();
  }

}
