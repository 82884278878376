import { Component, OnInit, Input } from '@angular/core';
import { RegistrationService } from 'src/app/services/registration.service';
import { CreditcardService } from 'src/app/services/creditcard.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EventEmitter } from '@angular/core';
import { PaymentService } from 'src/app/services/payment.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-collect-payment',
  templateUrl: './collect-payment.component.html',
  styleUrls: ['./collect-payment.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CollectPaymentComponent implements OnInit {
  @Input() cardType: string;
  @Input() ccOnly: boolean;

  // Temporary cards
  collectOtherPayment:boolean;
  payment;
  mediatypes;
  subscription;
  constructor(private registrationService: RegistrationService,
    private configurationService: ConfigurationService,
    private paymentService: PaymentService) {
      //this will default all payments to a mediatype once the first one is selected on the screen
      this.subscription = this.paymentService.emitter.subscribe({
        next: (event) => {
            if(!this.payment.MediaType && event.MediaType){
              this.payment.MediaType = event.MediaType;
            }
        }
    })
  }

  ngOnInit(): void {
    this.payment = this.registrationService.getCreditCard(this.registrationService.getPayment([]), this.cardType, {Category:this.cardType});
    if(!this.ccOnly){
      this.configurationService.fetchConfig().then((data: any) => {
        this.collectOtherPayment = data.collectOtherPayment;
        if(this.collectOtherPayment){
          this.mediatypes = data.mediatypes;
        }else{
          this.payment.MediaType = 'CREDITCARD';
        }
      });
    }else{
      this.payment.MediaType = 'CREDITCARD';
    }

  }

  selected(){
    this.paymentService.mediaTypeSelected(this.payment.MediaType)
  }



}
