import { Component, OnInit, SkipSelf } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { CopyService } from '../../services/copy.service';
import { RegistrationService } from '../../services/registration.service';
import { HotelService } from '../../services/hotel.service';
import { DateUtilityServiceService } from '../../services/date-utility-service.service';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HotelDetailsComponent } from 'src/app/reusable-components/hotel-details/hotel-details.component';


@Component({
  templateUrl: './hotel-booking.component.html'
})
export class HotelBookingComponent implements OnInit {
  enrollment;
  isSubmitting = false;
  hotelList;
  dateRange;
  groupDays;
  preextend;
  postextend;
  hotelStay;
  submitted;
  attendee;
  HoldSpecialRequests;
  isError = false;
  noHotel = false;
  isFormCompleted = false;
  isRegComplete: any;
  constructor(
    private navigationService: NavigationService,
    private copyService: CopyService,
    private registrationService: RegistrationService,
    private hotelService: HotelService,
    private dateService: DateUtilityServiceService,
    public dialog: MatDialog
  ) {

    this.enrollment = this.registrationService.getEnrollment({});
    this.attendee = this.registrationService.getAttendee({});
    this.hotelStay = this.registrationService.getHotel([]);
    this.isRegComplete = this.registrationService.isRegistrationComplete();
    this.hotelService.getHotelList().then(list => {
      this.hotelList = list;
      if (this.hotelList) {
        this.dateRange = this.dateService.calculateDateRange(this.hotelList.firstNight, this.hotelList.lastNight);
        this.preextend = this.dateService.dateDiff(moment.utc(this.hotelList.firstNight, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago"), moment.utc(this.hotelList.groupStartDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago"));

        this.groupDays = dateService.dateDiff(moment.utc(this.hotelList.groupStartDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago"), moment.utc(this.hotelList.groupEndDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago"));
        this.postextend = dateService.dateDiff(moment.utc(this.hotelList.groupEndDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago"), moment.utc(this.hotelList.lastNight, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago"));


        if (!this.hotelStay.length) {
          //look for booked stays.
          this.hotelList.roomTypeList.forEach((roomtype) => {
            var groupid = roomtype.webHotelGroupID;
            var pending = _.filter(roomtype.roomNightsListWithRate, { availabilityCode: 2 });
            var reserved = _.filter(roomtype.roomNightsListWithRate, { availabilityCode: 3 });
            reserved = _.union(pending, reserved);
            reserved.forEach((res) => {
              this.hotelStay.push({ Date: res.blockDate, Hotel: groupid });
            });
          });
        }

        if (this.isRegComplete) {
          if (this.enrollment.WebHotelBooking.length) {
            //look for booked stays.
            this.hotelList.roomTypeList.forEach((roomtype) => {
              var groupid = roomtype.webHotelGroupID;
              var pending = _.filter(roomtype.roomNightsListWithRate, { availabilityCode: 2 });
              var reserved = _.filter(roomtype.roomNightsListWithRate, { availabilityCode: 3 });
              reserved = _.union(pending, reserved);
              reserved.forEach((res) => {
                this.hotelStay.push({ Date: res.blockDate, Hotel: groupid });
              });
            });
          }

          let filter = [];
          this.hotelStay.forEach((stay) => {
            if (stay.Date.indexOf("0000000") < 0) {
              filter.push(stay);
            }
          });
          filter = _.uniqBy(filter, 'Date');
          this.hotelStay = filter;
          this.enrollment.WebHotelBooking = filter;
        }

      }
    });
  }
  ngOnInit(): void {
  }
  noHotelRadios = [
    { display: "Staying at local residence", value: "Staying at local residence" },
    { display: "Sharing a room reserved by someone else", value: "Sharing a room reserved by someone else" },
    { display: "Arranged my own accommodations", value: "Arranged my own accommodations" },
    { display: "I will add my hotel later", value: "I will add my hotel later" }
  ];
  isHotelAvailableOnDate(hotellist, date) {
    let hotel = this.findDate(hotellist, date);
    if (hotel) {
      if (hotel.availabilityCode === 1 || hotel.availabilityCode === 2 || hotel.availabilityCode === 3) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  addNight(date, hotel) {
    this.noHotel = false;
    var tobe = { Date: date, Hotel: hotel };
    var search = _.filter(this.hotelStay, { Date: date });
    if (search.length) {
      _.remove(this.hotelStay, search[0]);
      if (search[0].Hotel !== hotel) {
        this.hotelStay.push(tobe);
      }
    } else {
      this.hotelStay.push(tobe);
    }

    var isExtension = false;
    this.hotelStay.forEach((stay) => {

      var date = this.dateService.createMoment(stay.Date).toDate();

      if (this.outsideGroupDates(date)) {
        isExtension = true;
      }
    });
    if (isExtension) {
      this.enrollment.$mtcExtension = true;
    } else {
      this.enrollment.$mtcExtension = undefined;
    }
  };


  clearRequests() {
    if (this.enrollment.HotelNeeded === true) {
      if (this.enrollment.HotelSpecialRequests === "Staying at local residence" || this.enrollment.HotelSpecialRequests === "Sharing a room reserved by someone else"
        || this.enrollment.HotelSpecialRequests === "Arranged my own accommodations" || this.enrollment.HotelSpecialRequests === "I will add my hotel later") {
        this.HoldSpecialRequests = this.enrollment.HotelSpecialRequests;
        this.enrollment.HotelSpecialRequests = "";
      }
    } else if (this.HoldSpecialRequests) {
      this.enrollment.HotelSpecialRequests = this.HoldSpecialRequests;
    }
  }

  outsideGroupDates(date) {
    console.log(date + " < " + this.hotelList.groupStartDate);
    return date < moment.utc(this.hotelList.groupStartDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago").toDate()
      || date > moment.utc(this.hotelList.groupEndDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago").toDate();
  };

  findDate(hotelList, searchDate) {
    let newDate = moment.utc(searchDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").toDate();
    var match;
    if (typeof newDate !== "undefined") {
      match = _.find(hotelList.roomNightsListWithRate, (night) => {
        var blockDate = moment.utc(night.blockDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago").toDate();

        return newDate.getDate() === blockDate.getDate() && newDate.getMonth() === blockDate.getMonth();
      });
    }
    return match;
  };

  declineRadios = [
    {
      value: 'Business Conflict',
      display: this.copyService.getCopyByKey(
        'copy.label.INVITATION.declineReason1'
      )
    },
    {
      value: 'Personal Conflict',
      display: this.copyService.getCopyByKey(
        'copy.label.INVITATION.declineReason2'
      )
    },
    {
      value: 'other',
      display: this.copyService.getCopyByKey(
        'copy.label.INVITATION.declineReason3'
      )
    }
  ];
  getNonContiguousHotels() {
    var hotels = [];
    if (typeof this.hotelList !== "undefined") {
      hotels = this.hotelService.getNonContiguousHotelNights(this.hotelList.roomTypeList, this.hotelStay);
    }
    return hotels;
  };
  isChecked(date, hotel) {
    //console.log("in isChecked");
    var search = _.filter(this.hotelStay, { Date: date });
    if (search.length) {
      if (search[0].Hotel === hotel) {
        //console.log("return true");
        return true;
      }
    }
    return false;
  };
  getColor(night) {
    if (night.availabilityCode === 0) {
      return '#fcb1ae'
    }
    if (night.isBookedAsPending === true) {
      return '#f8fab1';
    }
    if (night.isBookedAsPending === false) {
      return '#bcffb5'
    }
    return 'white';
  }

  forward(form) {
    this.isFormCompleted = true;
    if (this.enrollment.HotelNeeded !== undefined && form.valid) {
      if (this.enrollment.HotelNeeded) {
        // User must have selected hotels on contiguous dates
        if (this.hotelStay.length > 0 && this.hotelService.getNonContiguousHotelNights(this.hotelList.roomTypeList, this.hotelStay).length <= 0) {
          this.isSubmitting = true;
          this.navigationService.forward();
        } else {
          this.noHotel = true;
          this.isError = true;
          this.isSubmitting = false;
        }
      } else {
        this.enrollment.WebHotelBooking = [];
        this.isSubmitting = true;
        this.navigationService.forward();
      }
    } else {
      this.isSubmitting = false;
    }
  }

  back() {
    this.navigationService.back();
  }

  showModal(hotel) {
    // if (!hotel.displaySection) {
        // new ModalService("Hotel" + hotel.webHotelGroupDesc.replace(/[\s]/g, ""), "Hotel Details");
    let dialogRef = this.dialog.open(HotelDetailsComponent, { panelClass: 'hotel-container', data: hotel});
    hotel.displaySection = false;
  }
}
