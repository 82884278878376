import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { CopyService } from '../../services/copy.service';
import { RegistrationService } from '../../services/registration.service';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import * as _moment from 'moment';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ConstantsService } from '../../services/constants.service';
import { ListManagerService } from 'src/app/services/list-manager.service';
import { GuestService } from 'src/app/services/guest.service';
import { ModalService } from '../../services/modal.service';
import { AirportService } from '../../services/airport.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbDateStruct, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-travel',
  templateUrl: './travel.component.html',
  styleUrls: ['./travel.component.scss']
})
export class TravelComponent implements OnInit {
  isSubmitting;
  travelTypes;
  programInfo: any;
  attendee: any;
  enrollment: any;
  guests: any;
  passport: any;
  ownAir: any;
  isRegComplete: any;
  countryList: any;
  isError: any;
  prefixList: any;
  suffixList: any;
  genderRadios: any;
  eventDates: any;

  //For flight preference
  travelTimes: any;
  timesRadios: any;
  groupArrivalDate: any;
  groupDepartureDate: any;
  calendarready: any;
  options: any;
  airlineList: any;
  frequentFlyer = [];
  flightPrefRadios;
  participant;
  locations;
  startDateOutbound;
  endDateOutbound;
  startDateReturn;
  endDateReturn;
  search;
  startDateOutboundYear: number;
  startDateOutboundMonth: number;
  startDateOutboundDay: number;
  formatter = (result: string) => result.toUpperCase();
  startDate;
  startDateRet;
  endDate;
  endDateRet;
  outboundDateTime;
  returnDateTime;
  isFormCompleted=false;
  otherNumber:any;
  otherNumberBox=false;


  airQues;
  yesNoArrival;
  yesNoDepart;

  transportList;
  matchList;
  varRedressTraveler

  varArrAirportTransportation;
  varArrAirportSelection;

  varDepartAirportTransportation;
  varDepartAirportSelection;

  varHotelDatesMatch;
  varArrivalTransferCode;
  varDepartTransferCode;

  constructor(private navigationService: NavigationService,
    private copyService: CopyService,
    private registrationService: RegistrationService,
    public Constants: ConstantsService,
    private configurationService: ConfigurationService,
    private listService: ListManagerService,
    private guestService: GuestService,
    public modalService: ModalService,
    private matDialog: MatDialog,
    private airportService: AirportService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private dateAdapter: NgbDateAdapter<string>,
    private variablesService: VariablesService) {

    this.attendee = this.registrationService.getAttendee({});
    this.otherNumber = this.registrationService.getVariable(this.attendee,variablesService.VARIABLE.OTHER_NUMBER.ID,{});
    if(this.otherNumber.Value==="Yes"){
      this.otherNumberBox = true;
    }
    this.varArrAirportTransportation = this.registrationService.getVariable(this.registrationService.getAttendee({}), this.variablesService.VARIABLE.varArrAirportTransportation.ID, {});
    this.varArrAirportSelection = this.registrationService.getVariable(this.registrationService.getAttendee({}), this.variablesService.VARIABLE.varArrAirportSelection.ID, {});
    
    this.varDepartAirportTransportation = this.registrationService.getVariable(this.registrationService.getAttendee({}), this.variablesService.VARIABLE.varDepartAirportTransportation.ID, {});
    this.varDepartAirportSelection = this.registrationService.getVariable(this.registrationService.getAttendee({}), this.variablesService.VARIABLE.varDepartAirportSelection.ID, {});
    
    this.varDepartTransferCode =  this.registrationService.getVariable(this.registrationService.getAttendee({}), this.variablesService.VARIABLE.varDepartTransferCode.ID, {});
    this.varArrivalTransferCode =  this.registrationService.getVariable(this.registrationService.getAttendee({}), this.variablesService.VARIABLE.varArrivalTransferCode.ID, {});
    

    this.varHotelDatesMatch = this.registrationService.getVariable(this.registrationService.getAttendee({}), this.variablesService.VARIABLE.varHotelDatesMatch.ID, {});
   // this.varRedressTraveler =  this.registrationService.getVariable(this.registrationService.getAttendee({}), this.variablesService.VARIABLE.varRedressTraveler.ID, {});
  


    if(this.attendee.PAXTypeNo===1||this.attendee.PAXTypeNo===2||this.attendee.PAXTypeNo===3||this.attendee.PAXTypeNo===4 || 
      this.attendee.PAXTypeNo===8   || this.attendee.PAXTypeNo===14||this.attendee.PAXTypeNo===15||this.attendee.PAXTypeNo===16 || 
      this.attendee.PAXTypeNo===17 ||this.attendee.PAXTypeNo===18) {
       
       this.airQues = true;
      }else{
        this.airQues = false;
        this.attendee.TravelType = 'OWNAIR'; 
    }




    this.enrollment = this.registrationService.getEnrollment({});
    this.guests = this.registrationService.getGuests({});
    this.passport = this.registrationService.getTravelDocument(this.attendee, Constants.DOCUMENT_TYPES.PASSPORT, {});
    this.attendee.$mtcOutbound = this.registrationService.getPAXAirPreference(this.attendee, Constants.AIRPREF_DIRECTIONS.OUTBOUND, {});
    this.attendee.$mtcReturn = this.registrationService.getPAXAirPreference(this.attendee, Constants.AIRPREF_DIRECTIONS.RETURN, {});
    this.ownAir = this.registrationService.getOwnAir(this.attendee);
    if (this.attendee.FrequentFlyer === undefined) {
      this.attendee.FrequentFlyer = [];
    }

    this.listService.getCountryList().then(data => {
      this.countryList = data;
    });

    this.listService.getCommonPrefixList().then(list => {
      this.prefixList = list;
    });

    this.listService.getSuffixList().then(list => {
      this.suffixList = list;
    });

    this.listService.getFrequentFlyerList().then(data => {
      this.airlineList = data;
    });

    this.listService.getTravelTimeList().then(data => {
      this.travelTimes = data;
    });

    this.search = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(term => term === '' ? []
          : this.airportService.getAirportLocations(term).then(data => {
            this.locations = data;
            this.locations.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
          })
        ))

    copyService.getCopyByKey('arrivalDate').then((data) => {
      if (!this.attendee.$mtcOutbound.DepartureDateTime) {
        this.attendee.$mtcOutbound.DepartureDateTime = data;
      }
      this.outboundDateTime = this.convertToDatePicker(this.attendee.$mtcOutbound.DepartureDateTime);
    });
    copyService.getCopyByKey('departureDate').then((data) => {
      if (!this.attendee.$mtcReturn.DepartureDateTime) {
        this.attendee.$mtcReturn.DepartureDateTime = data;
      }
      this.returnDateTime = this.convertToDatePicker(this.attendee.$mtcReturn.DepartureDateTime);
    });
  }

  otherNumChange(){
    console.log(this.otherNumberBox);
    if(this.otherNumberBox){
      this.otherNumber.Value = "Yes";
    }else{
      this.otherNumber.Value = "";
    }
  }

  convertToDatePicker(datestring) {
    let dateobj = moment.utc(datestring, 'YYYY-MM-DD HH:mm:ss+Z').toDate();
    return {
      year: dateobj.getFullYear(),
      month: dateobj.getMonth() + 1,
      day: dateobj.getDate(),
    };

    // return this.ngbDateParserFormatter.parse(datestring);
  }

  ngOnInit(): void {
    this.calendarready = false;
    this.options = {
      showWeeks: false,
      startingDay: "0"
    };
    this.configurationService.fetchConfig().then((data: any) => {
      this.programInfo = data;

      this.startDateOutbound = moment.utc(this.programInfo.airOutboundDateBegin, "YYYY-MM-DD HH:mm:ss+Z").toDate();
      this.startDate = { year: this.startDateOutbound.getFullYear(), month: (this.startDateOutbound.getMonth()) + 1, day: this.startDateOutbound.getDate() };
      this.endDateOutbound = moment.utc(this.programInfo.airOutboundDateEnd, "YYYY-MM-DD HH:mm:ss+Z").toDate();
      this.endDate = { year: this.endDateOutbound.getFullYear(), month: (this.endDateOutbound.getMonth()) + 1, day: this.endDateOutbound.getDate() };

      this.startDateReturn = moment.utc(this.getMinimumDepartureDate(), "YYYY-MM-DD HH:mm:ss+Z").toDate();
      this.startDateRet = { year: this.startDateReturn.getFullYear(), month: (this.startDateReturn.getMonth()) + 1, day: this.startDateReturn .getDate() };
      this.endDateReturn = moment.utc(this.programInfo.airReturnDateEnd, "YYYY-MM-DD HH:mm:ss+Z").toDate();

      this.endDateRet = { year: this.endDateReturn.getFullYear(), month: (this.endDateReturn.getMonth()) + 1, day: this.endDateReturn.getDate() };
      this.transportList = [ 
        { display: "Nashville International Airport (BNA)", value: "Nashville International Airport (BNA)" },   
        { display: "Private - FBO", value: "Private - FBO" }, 
        { display: "Unsure at this time", value: "Unsure at this time" }
      ];

      this.yesNoArrival = [ 
        { display: "Yes, please arrange transportation from the airport to the hotel.", value: "Yes" },
        { display: "No, I will arrange my own transportation to the hotel.", value: "No" }
      ];


 this.yesNoDepart = [ 
        { display: "Yes, please arrange transportation from the hotel to the airport.", value: "Yes" },
        { display: "No, I will arrange my own transportation to the airport.", value: "No" }
      ];
      this.matchList =[
        { display: "Yes, my travel dates and my dealer meeting hotel dates will match. ", value: "Yes, my travel dates and my conference hotel dates will match." },
        { display: "No, my dates will not match. I will be making my own arrangements for dates not included in my dealer meeting hotel dates request.", value: "No, my dates will not match. I will be making my own arrangements for dates not included in my conference hotel dates request." }
      ];

      this.travelTypes = [
        {
          display: this.copyService.getCopyByKey(
            'copy.label.TRAVEL.fly'
          ),
          value: this.Constants.TRAVEL_TYPES.FLY
        }, 
        {
          display: this.copyService.getCopyByKey(
            'copy.label.TRAVEL.ownair'
          ),
          value: this.Constants.TRAVEL_TYPES.OWN_AIR
        },
        {
          display: this.copyService.getCopyByKey(
            'copy.label.TRAVEL.drive'
          ),
          value: this.Constants.TRAVEL_TYPES.DRIVE
        }];


      this.genderRadios = [
        {
          display: this.copyService.getCopyByKey(
            'copy.label.FEATURES.GENDER.male'
          ),
          value: "MALE"
        },
        {
          display: this.copyService.getCopyByKey(
            'copy.label.FEATURES.GENDER.female'
          ),
          value: "FEMALE"
        }];

      this.flightPrefRadios = [
        {
          display: this.copyService.getCopyByKey(
            'copy.label.FEATURES.SEATPREF.window'
          ),
          value: this.Constants.REQUESTED_SEAT_TYPES.WINDOW
        },
        {
          display: this.copyService.getCopyByKey(
            'copy.label.FEATURES.SEATPREF.aisle'
          ),
          value: this.Constants.REQUESTED_SEAT_TYPES.AISLE
        },
        {
          display: this.copyService.getCopyByKey(
            'copy.label.FEATURES.SEATPREF.nopref'
          ),
          value: this.Constants.REQUESTED_SEAT_TYPES.NOPREF
        }
      ];

      // For flight preference
      this.groupArrivalDate = moment.utc(this.programInfo.arrivalDate, "YYYY-MM-DD HH:mm:ss+Z").toDate();
      this.groupDepartureDate = moment.utc(this.programInfo.departureDate, "YYYY-MM-DD HH:mm:ss+Z").toDate();
      this.calendarready = true;

      if (!this.attendee.$mtcReturn.DepartureCityLocation && this.programInfo.programDepartureCityAirport) {
        this.attendee.$mtcReturn.DepartureCityLocation = this.programInfo.programDepartureCityAirport;
      }

      if (!this.attendee.$mtcReturn.DepartureAirportCode && this.programInfo.programDepartureCityAirportCode) {
        this.attendee.$mtcReturn.DepartureAirportCode = this.programInfo.programDepartureCityAirportCode;
      }

    });

    this.populateGender();

  }

  /**
    * @function back
    * @description Uses [navigation.service.back()] to go back to previous page.
    */
  back() {
    this.cleanAirTravelInfo();
    this.navigationService.back();
  }

  /**
     * @function forward
     * @description Checks if form is valid then uses [NavigationService.forward()](./app.NavigationService.html) to go forward to next page.
     */
  forward(form) {
    this.isFormCompleted = true;
    if (this.attendee.TravelType !== undefined && form.valid) {
       if (this.attendee.NoMiddleNameFlag) {
        this.attendee.MiddleName = "";
       }
       this.isSubmitting = true;
       this.cleanAirTravelInfo();
       this.navigationService.forward();
     }
  }

  convertToRealDate(d) {
    return this.ngbDateParserFormatter.format(d) + 'T12:00:00-0500';;
  }

  updateModel(){
    this.attendee.$mtcOutbound.DepartureDateTime = this.convertToRealDate(this.outboundDateTime);
    this.attendee.$mtcReturn.DepartureDateTime = this.convertToRealDate(this.returnDateTime);

    let departdate = moment.utc(this.attendee.$mtcOutbound.DepartureDateTime, "YYYY-MM-DD HH:mm:ss+Z").toDate();
    let retdate = moment.utc(this.attendee.$mtcReturn.DepartureDateTime, "YYYY-MM-DD HH:mm:ss+Z").toDate();

    if(departdate < this.groupArrivalDate){
      this.attendee.TravelPlan = 'EXT';
    }else if(this.groupDepartureDate < retdate){
      this.attendee.TravelPlan = 'EXT';
    }else{
      this.attendee.TravelPlan = 'PGMDATE';
    }

  }

  format(date){
    return this.convertToRealDate(date);
  }

  /**
     * @function TravelController.getMinimumDepartureDate
     * @returns {Window.programInfo.airReturnDateBegin|this.programInfo.airReturnDateBegin|Window.attendee.$mtcOutbound.DepartureDateTime|this.attendee.$mtcOutbound.DepartureDateTime}
     */
  getMinimumDepartureDate() {
    if (this.programInfo && this.programInfo.airReturnDateBegin) {
      if (this.attendee.$mtcOutbound.DepartureDateTime > this.programInfo.airReturnDateBegin) {
        return this.attendee.$mtcOutbound.DepartureDateTime;
      } else {
        return this.programInfo.airReturnDateBegin;
      }
    } else {
      return undefined;
    }
  }


  clearRequests(){
    if(this.attendee.TravelType===this.Constants.TRAVEL_TYPES.DRIVE){
      this.varArrAirportSelection.Value = '';   
      this.varArrAirportTransportation.Value = '';

      this.varDepartAirportSelection.Value = ''; 
      this.varDepartAirportTransportation.Value = '';

      this.varDepartTransferCode.Value ='';
      this.varArrivalTransferCode.Value ='';

      this.varHotelDatesMatch.Value="";
    }else if(this.attendee.TravelType===this.Constants.TRAVEL_TYPES.OWN_AIR) {    
      this.varDepartTransferCode.Value ='';
      this.varArrivalTransferCode.Value =''; 
    }else if(this.attendee.TravelType===this.Constants.TRAVEL_TYPES.FLY) {    
      this.varDepartTransferCode.Value ='';
      this.varArrivalTransferCode.Value =''; 
      this.varArrAirportTransportation.Value = '';
      this.varDepartAirportTransportation.Value = '';
    }
}

  /**
   * @function TravelController.populateGender
   */
  populateGender() {
    if (this.attendee.Prefix && !this.attendee.Gender) {
      if (this.attendee.Prefix === "MR" || this.attendee.Prefix === "MSTR") {
        this.attendee.Gender = "MALE";
      } else if (this.attendee.Prefix === "MRS" || this.attendee.Prefix === "MS" || this.attendee.Prefix === "MISS") {
        this.attendee.Gender = "FEMALE";
      } else {
        this.attendee.Gender = undefined;
      }
    }
  }

  /**
    * @function cleanAirTravelInfo
    */
  cleanAirTravelInfo() {
    if (this.attendee.TravelType === this.Constants.TRAVEL_TYPES.RAIL) {
      this.attendee.PAXAirPreference.forEach( (airpref) => {
        airpref.DepartureAirportCode = undefined;
      });
    }

    if(this.varArrAirportTransportation.Value === 'No'){
      this.varArrAirportSelection.Value = ''; 
      this.varArrivalTransferCode.Value ='';  
    }

    if(this.varDepartAirportTransportation.Value === 'No'){
      this.varDepartAirportSelection.Value = '';  
      this.varDepartTransferCode.Value =''; 
    }

   if(this.attendee.TravelType===this.Constants.TRAVEL_TYPES.DRIVE){
     this.varArrAirportSelection.Value = '';   
     this.varArrAirportTransportation.Value = '';
     this.varDepartTransferCode.Value ='';
      this.varArrivalTransferCode.Value ='';
   }


    if (this.attendee.TravelType && this.attendee.TravelType !== this.Constants.TRAVEL_TYPES.FLY && this.attendee.TravelType !== this.Constants.TRAVEL_TYPES.RAIL) {
      this.enrollment.AirSpecialRequests = undefined;
      this.attendee.MiddleName = undefined;
      this.attendee.Gender = undefined;
      //this.attendee.BirthDate = undefined;
      this.attendee.RedressNumber = undefined;
      this.attendee.KnownTravelerNumber = undefined;
      this.attendee.InterRowPosition = undefined;
      this.attendee.IssuingCountryISOCode = undefined;
      this.attendee.$mtcProvidePassport = undefined;
      this.attendee.FrequentFlyer = [];
      this.attendee.TravelDocument = [];
      this.attendee.PAXAirPreference = [];
      this.attendee.$mtcOutbound = {};
      this.attendee.$mtcReturn = {};
      if (this.guests.length > 0) {
        _.each(this.guests, (guest) => {
          guest.TravelType = this.attendee.TravelType;
          guest.MiddleName = undefined;
          guest.Gender = undefined;
          if (!this.guestService.isChild(guest)) {
            guest.BirthDate = undefined;
          }
          guest.RedressNumber = undefined;
          guest.KnownTravelerNumber = undefined;
          guest.InterRowPosition = undefined;
          guest.IssuingCountryISOCode = undefined;
          guest.FrequentFlyer = [];
          guest.PAXAirPreference = [];
          guest.$mtcSameItinerary = undefined;
          guest.$mtcOutbound = {};
          guest.$mtcReturn = {};
        });
      }
    }
  }


  /**
    * @function FrequentFlyerDirective.addFrequentFlyer
    * @description Adds frequent flyer information.
    */
  addFrequentFlyer() {
    this.attendee.FrequentFlyer.push({});
  }

  /**
  * @function FrequentFlyerDirective.removeFrequentFlyer
  * @description Removes frequent flyer information.
  */
  removeFrequentFlyer(flyer) {
    _.remove(this.attendee.FrequentFlyer, flyer);
  }

  openModal(copy, title, actionButtonText) {
    this.modalService.openModal(copy, title, actionButtonText);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = "modal-component-terms";
  }

  filterAirlines(airlinelist, code) {
    let selected = [];
    if(this.attendee.FrequentFlyer) {
      this.attendee.FrequentFlyer.forEach(flier => {
        if(flier.AirlineCode && flier.AirlineCode !== code) {
          selected.push(flier.AirlineCode);
        }
      });
    }
    const filteredlist = _.filter(airlinelist, o => {
      if(selected.indexOf(o.code) >= 0) {
        return false;
      } else {
        return true;
      }
    });
    return filteredlist;
  }
}
