<div class="form-summary">
    <label class="col-md-6 col-xs-12"><ng-content></ng-content></label>
    <span class=" form-summary-item col-md-6 col-xs-12">
        <span class="form-summary-line" *ngIf="address.AddressLine1 !== undefined">{{address.AddressLine1}}</span>

        <span class="form-summary-line" *ngIf="address.AddressLine2 !== undefined">{{address.AddressLine2}}</span>
        <span class="form-summary-line" *ngIf="address.City !== undefined">{{address.City}}
            <span *ngIf="address.StateProvince !== undefined">, {{address.StateProvince}}</span>
            <span *ngIf="address.Zip !== undefined"> {{address.Zip}}</span>
        </span>
        <span class="form-summary-line">{{selectedCountry}}</span>
    </span>
</div>
