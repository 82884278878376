import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { VariablesService } from 'src/app/services/variables.service';
import { Router } from '@angular/router';
import { PersistenceService } from 'src/app/services/persistence.service';

@Component({
  templateUrl: './load.component.html'
})
export class LoadComponent implements OnInit {

  dealerCode;
  spinID;
  regCode;
  emailAddr;
  isSubmitting;
  errorMsg;
  savedSpinID;

  constructor(private route: ActivatedRoute,
    private config: ConfigurationService,
    private nav: NavigationService,
    private auth:AuthenticationService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private persistenceService: PersistenceService, 
    private registrationService: RegistrationService,
    private variablesService: VariablesService,
    private enrollmentService: EnrollmentService) { }
  ngOnInit(): void {


    this.route.queryParams.subscribe((params) => {
      if (params && params.dealercode) {
        console.log(params.dealercode);
        this.dealerCode = params.dealercode;
      }else{
      this.persistenceService.clear();
      }
      if(params && params.spinid){
        this.spinID = params.spinid;
      }
      this.forward(this.dealerCode, this.spinID);

    });
  }


    forward(dealerCode, spinID) {
      console.log("in forward");
      console.log(dealerCode);
      console.log(spinID);
      this.persistenceService.testStorage();
      this.persistenceService.clear();
      const creds: any = {};
      creds.sso = true;
      //this.savedSpinID = this.registrationService.getVariable(this.registrationService.getAttendee({}), this.variablesService.VARIABLE.varSpinID.ID, {});
  
  
      
      if (dealerCode) {
        console.log("in dealercode condition");
        creds.username = spinID;
        creds.password = 'password';
        this.authenticationService.oauthlogin(creds, "").then((route) => {
          // promise resolved
          console.log('logging in with spin id');
          this.savedSpinID = this.registrationService.getVariable(this.registrationService.getAttendee({}), this.variablesService.VARIABLE.varSpinID.ID, {});
  
          this.savedSpinID.Value = spinID;
          // this.router.navigate([route]);
          this.nav.forward();
        }, (err) => {
          console.log("err");
          creds.username = dealerCode;
          console.log(creds);
          this.authenticationService.oauthlogin(creds,"").then((route) => {
            // promise resolved
            this.savedSpinID = this.registrationService.getVariable(this.registrationService.getAttendee({}), this.variablesService.VARIABLE.varSpinID.ID, {});
  
            this.savedSpinID.Value = spinID;
            console.log(this.savedSpinID.Value)
            console.log('logging in with dealer code');
           // this.router.navigate([route]);
           this.nav.forward();
  
          }, (err) => {
            this.isSubmitting =false;
            this.errorMsg = err;
            this.router.navigate(['/login']);
  
          });
        });
      }else if(spinID){
        console.log("in spindID condition");
        creds.username = spinID;
        creds.password = 'password';
        this.authenticationService.oauthlogin(creds, "").then((route) => {
          // promise resolved
          this.savedSpinID = this.registrationService.getVariable(this.registrationService.getAttendee({}), this.variablesService.VARIABLE.varSpinID.ID, {});
  
          this.savedSpinID.Value = spinID;
  
          console.log('logging in with spin id 2');
          // this.router.navigate([route]);
          this.nav.forward();
        }, (err) => {
          console.log("err 01");
            this.isSubmitting =false;
            this.errorMsg = err;
            this.router.navigate(['/login']);
  
        });
  
      } else {
        console.log("else condition")
        this.isSubmitting =false;
        this.errorMsg = 'Please enter a Login ID or Confirmation Code.';
        this.router.navigate(['/login']);
  
      } 
    }

  
    
  

    /*this.route.queryParams.subscribe(data=>{
      //http://localhost:9000/#/load?confcode=TX5VBMXS&email=asdf@asdf.com
      if(data.confcode && data.email){
        let creds = {
          "confirmationCode": data.confcode,
          "emailAddress": data.email
        };
        this.auth.oauthlogin(creds,undefined).then((data:any)=>{
          this.enrollmentService.rvsGetEnrollment().then(() => {
            this.nav.forwardrvs();
          });
        });
      }
    });*/
    
  

}
