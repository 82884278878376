import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  templateUrl: './decline.component.html'
})
export class DeclineComponent implements OnInit {
  programInfo: any;
  contact: any;
  phone: number;
  email: string;
  programName: string;
  travelHQName: string;

  constructor(private configurationService: ConfigurationService) { }

  ngOnInit(): void {
    this.configurationService.fetchConfig().then((data: any) => {
      this.programInfo = data;
      if (this.programInfo !== undefined) {
        this.programName = this.programInfo.programName;
        this.travelHQName = this.programInfo.travelHQName;
        this.phone = this.programInfo.travelHQ800Num;
        this.email = this.programInfo.travelHQEmail;
      }

    });
  }
}