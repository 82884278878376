import { Injectable } from '@angular/core';
import { RegistrationService } from './registration.service';
import { EnvironmentService } from './environment.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { ConstantsService } from './constants.service';
import { PersistenceService } from './persistence.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentService {
  constructor(
    private registrationService: RegistrationService,
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private authService: AuthenticationService,
    private constantsService: ConstantsService,
    private persistenceService: PersistenceService,
    private authenticationService: AuthenticationService,
    private logService: LogService
  ) {}

  serviceUrl = new EnvironmentService().getEnvironmentUrl('service/enrollment');
  getHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        ProgramKey: '450282A8-FD21-4D41-B2D5-4DEEC4C75DD7',
        'MTC-Session-Token': this.authService.authtoken,
      }),
    };
  }

  createEnrollment(data) {
    const promise = new Promise((resolve, reject) => {
      this.logService.info(JSON.stringify(this.registrationService.getEnrollment({})), 'initial submit').subscribe();
      const cleanedData = this.registrationService.cleanse(data);
      const url = this.serviceUrl;
      this.http.post(url, cleanedData, this.getHeaders()).subscribe(
        (data: any) => {
          this.registrationService.update(
            this.registrationService.getEnrollment({}),
            {
              $mtcOriginalHotelNeeded: this.registrationService.getEnrollment(
                {}
              ).HotelNeeded,
              $mtcConfirmationCode: data.confirmationCode,
            }
          );
          let creds = {confirmationCode : data.confirmationCode, emailAddress : this.registrationService.getEmail(this.registrationService.getAttendee({}), "BUSINESS", {}).EmailAddress};
          this.authenticationService.rvsLogin(creds).then(() => {
            this.rvsGetEnrollment().then(()=>{
              resolve(data);
            })
          });
        },
        (err) => {
          this.logService.error(JSON.stringify(this.registrationService.getEnrollment({})), 'initial submit error').subscribe();
          reject(err);
        }
      );
    });
    return promise;
  }

  updateEnrollment(data) {
    const promise = new Promise((resolve, reject) => {
      this.logService.info(JSON.stringify(this.registrationService.getEnrollment({})), 'rvs submit').subscribe();
      const cleanedData = this.registrationService.cleanse(data);
      const url = this.serviceUrl;
      console.log(cleanedData)
      this.http
        .put(url, cleanedData, this.getHeaders())
        .subscribe((data: any) => {
          this.registrationService.update(
            this.registrationService.getEnrollment({}),
            {
              $mtcOriginalHotelNeeded: this.registrationService.getEnrollment(
                {}
              ).HotelNeeded,
            }
          );
          resolve(data);
        },
        (err) => {
          this.logService.error(JSON.stringify(this.registrationService.getEnrollment({})), 'rvs submit error').subscribe();
          reject(err);
        });
    });
    return promise;
  }

  // getEnrollment() {
  //   const url = this.environmentService.getEnvironmentUrl('service/enrollment');
  //   return this.http.get(url, this.httpOptions);
  // }

  rvsGetEnrollment() {
    const promise = new Promise((resolve, reject) => {
      const url = this.serviceUrl;
      this.http.get(url, this.getHeaders()).subscribe((data) => {
        this.registrationService.update(
          this.registrationService.getEnrollment({}),
          data
        );
        let enroll = this.registrationService.getEnrollment({});
        let temp = null;
        if (enroll.$mtcConfirmationCode) {
          temp = enroll.$mtcConfirmationCode;
        }
        enroll = this.registrationService.cleanse(enroll);
        if (temp) {
          enroll.$mtcConfirmationCode = temp;
        }
        this.registrationService.Enrollment = enroll;

        /* rvs prefill service */
        this.rvsPrefill();
        /* config service */
        this.persistenceService.store();
        resolve({});
      });
    });
    return promise;
  }

  rvsPrefill() {
    const enrollment = this.registrationService.getEnrollment({});
    this.registrationService.update(enrollment, { $mtcRegistrationComplete: true });
    this.registrationService.update(enrollment, {
      $mtcOriginalHotelNeeded: enrollment.HotelNeeded,
    });

    const attendee = this.registrationService.getAttendee({});

    if (this.registrationService.getGuests([]).length > 0) {
      attendee.$mtcHasGuestsAttending = true;
    } else {
      attendee.$mtcHasGuestsAttending = false;
    }

    const emailAddress = this.registrationService.getEmail(attendee, "BUSINESS", {});
    if(emailAddress.EmailAddress){
      emailAddress.$mtcverifyEmailAddress = emailAddress.EmailAddress;
    }
    
    // mtcHasAdminContact
    if (
      this.registrationService.getAdminContact(undefined) &&
      this.registrationService.getAdminContact(undefined).AdminContactPhone &&
      this.registrationService.getAdminContact(undefined).AdminContactPhone.length >
        0
    ) {
      enrollment.$mtcHasAdminContact = true;
    } else {
      enrollment.$mtcHasAdminContact = false;
    }

    enrollment.$mtcHasGuestAgeAcknowledged = true;

    enrollment.AgreedToTermsAndConditions = true;
    enrollment.ResponsibilityStatement = true;

    this.registrationService.getParticipant({}).forEach((pax) => {
      // mtcHasConsiderations
      if (!pax.DietaryNeed && !pax.DisabilityType) {
        pax.$mtcHasConsiderations = false;
      } else {
        pax.$mtcHasConsiderations = true;
      }

      // mtcHasNoMiddleName
      if (!pax.MiddleName) {
        pax.NoMiddleNameFlag = true;
      } else {
        pax.NoMiddleNameFlag = false;
      }

      // mtcProvidePassport
      if (
        this.registrationService.getTravelDocument(
          pax,
          this.constantsService.DOCUMENT_TYPES.PASSPORT,
          undefined
        )
      ) {
        pax.$mtcProvidePassport = true;
      } else {
        pax.$mtcProvidePassport = false;
      }

      pax.$mtcOutbound = this.registrationService.getPAXAirPreference(
        pax,
        this.constantsService.AIRPREF_DIRECTIONS.OUTBOUND,
        {}
      );
      pax.$mtcReturn = this.registrationService.getPAXAirPreference(
        pax,
        this.constantsService.AIRPREF_DIRECTIONS.RETURN,
        {}
      );


    });
  }
}
