<div id="selection" class="registration container">
  <form ng-class="{'form-complete': invitationCtrl.isFormCompleted}" name="invitationForm" (ngSubmit)="forward()" novalidate autocomplete="off" appScrollToInvalid>
    <h1>Trip Selection</h1>
    <section class="card">
      <div class="card-header">
        <div class="card-title">
          Please select: <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <div class="card-body w-100">
          <div class="radio-group w-100">
            <div class="w-100 required">
              <mat-radio-group class="w-100" name="attendingLabel" required (change)="radioChange($event)">
                <mat-radio-button *ngFor="let item of programOptionRadios1" [disabled]="item.disabled"  [value]="item">{{item.display}}</mat-radio-button>
                <mat-radio-button *ngFor="let item of programOptionRadios2" [disabled]="item.disabled"  [value]="item">{{item.display}}</mat-radio-button>
                <mat-radio-button *ngFor="let item of programOptionRadios3" [disabled]="item.disabled"  [value]="item">{{item.display}}</mat-radio-button>
                <div class="w-100">
                  <!-- <app-validation  [object]="attendingLabel" isRequired=true pattern="alphanum"></app-validation> -->
                </div>
              </mat-radio-group>
            </div>
          </div>
        </div>
      </article>
    </section>

    <div class="button_group d-flex flex-row justify-content-between">
      <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.goback' | copy | async }}
      </button>
      <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.continue' | copy | async }}
      </button>
      <button class="inputSubmit" *ngIf="isSubmitting">
        <i class="fas fa-spinner fa-spin"></i>
      </button>
    </div>
  </form>
</div>
