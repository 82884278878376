import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { EnvironmentService } from './environment.service';
import { RegistrationService } from './registration.service';

@Injectable({
  providedIn: 'root'
})
export class LineitemService {

  constructor(  private registrationService: RegistrationService,
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private constantsService: ConstantsService) { }

    getAllLineItems(){
      let cleanedData = this.registrationService.cleanse(this.registrationService.getEnrollment({}));

      const lineitemUrl = this.environmentService.getEnvironmentUrl(
        'service/lineitem'
      );

      return this.http.post<any>(lineitemUrl, cleanedData);
    }

    getLineItems(key){
      let cleanedData = this.registrationService.cleanse(this.registrationService.getEnrollment({}));

      const lineitemUrl = this.environmentService.getEnvironmentUrl(
        'service/lineitem/' + key
      );
      return this.http.post<any>(lineitemUrl, cleanedData);
    }

    invoiceLookup(){
      const lineitemUrl = this.environmentService.getEnvironmentUrl(
        'service/invoicelookup'
      );
      return this.http.get<any>(lineitemUrl);
    }
}
