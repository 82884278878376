import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class BackguardService  {

  constructor(private router: Router) {

  }

  canActivate(): boolean {
    const env = window.location.hostname;
    this.router.events.subscribe((event: NavigationStart) => {
      if (env.indexOf('local') < 0 && (env.indexOf('dev') < 0)) {
        if (event instanceof NavigationStart && event.navigationTrigger === 'popstate' && this.router.url.indexOf('pretrip') < 0) {
          this.router.navigate([this.router.url]);
          return false;
        }
      }
    });
    return true;
    // or false if you want to cancel the navigation;
  }
}
