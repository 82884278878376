import { Component, OnInit } from '@angular/core';
import { DateUtilityServiceService } from 'src/app/services/date-utility-service.service';
import { HotelService } from 'src/app/services/hotel.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { RegistrationService } from 'src/app/services/registration.service';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { CopyService } from 'src/app/services/copy.service';

@Component({
  templateUrl: './hotel-package.component.html',
  styleUrls: ['./hotel-package.component.scss']
})
export class HotelPackageComponent implements OnInit {
  enrollment;
  isSubmitting = false;
  hotelList;
  dateRange;
  groupDays;
  preextend;
  postextend;
  hotelStay;
  submitted;
  attendee;
  HoldSpecialRequests;
  isError = false;
  noHotel = false;
  isFormCompleted=false;
  noHotelRadios = [
    { display: "Staying at local residence", value: "Staying at local residence" },
   // { display: "Sharing a room reserved by someone else", value: "Sharing a room reserved by someone else" },
    { display: "Arranged my own accommodations", value: "Arranged my own accommodations" },
   // { display: "I will add my hotel later", value: "I will add my hotel later" }
  ];
  constructor(
    private navigationService: NavigationService,
    private registrationService: RegistrationService,
    private hotelService: HotelService,
    private dateService: DateUtilityServiceService,
    private copyService: CopyService
  ) {
    this.enrollment = this.registrationService.getEnrollment({});
    this.attendee = this.registrationService.getAttendee({});
    this.hotelStay = this.registrationService.getHotel([]);
    this.attendee.HotelSmoking = false;


    this.hotelService.getHotelList().then(list => {
      this.hotelList = list; 

      if(this.hotelList.firstNight !== undefined){
      this.dateRange = this.dateService.calculateDateRange(this.hotelList.firstNight, this.hotelList.lastNight);
      this.preextend = this.dateService.dateDiff(moment.utc(this.hotelList.firstNight, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago"), moment.utc(this.hotelList.groupStartDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago"));
      }
      this.groupDays = dateService.dateDiff(moment.utc(this.hotelList.groupStartDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago"), moment.utc(this.hotelList.groupEndDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago"));
      this.postextend = dateService.dateDiff(moment.utc(this.hotelList.groupEndDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago"), moment.utc(this.hotelList.lastNight, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago"));

      //if (!this.hotelStay.length || this.hotelStay.length >0) {


        //sort packages by display order
        this.hotelList.roomTypeList.sort(function (a, b) {
          return a.displaySeqNo - b.displaySeqNo;
        });


       
        var fourSeasonsBool = false;
        var oneHotelBool = false;

        this.hotelList.roomTypeList.forEach((roomtype) => {
            if(!fourSeasonsBool){
              if(roomtype.supplierName ==='Four Seasons Hotel Nashville'){
                roomtype['firstFourSeasons'] = true; 
                fourSeasonsBool = true;
              };  
            }
         });

         
        this.hotelList.roomTypeList.forEach((roomtype) => {  
          if(!oneHotelBool){
            if(roomtype.supplierName ==='1 Hotel Nashville'){
              roomtype['firstOneHotel'] = true; 
              oneHotelBool = true;
            };  
          }
       });

        // console.log(this.hotelList.roomTypeList)

        //look for booked stays.
        this.hotelList.roomTypeList.forEach((roomtype) => {
         // console.log(roomtype);
          this.getHotelPackageDescriptionUrl(roomtype.webHotelGroupDesc).then(text=>{
            roomtype.verbiage = text;
          });


          var groupid = roomtype.webHotelGroupID;
          var pending = _.filter(roomtype.roomNightsListWithRate, { availabilityCode: 2 });
          var reserved = _.filter(roomtype.roomNightsListWithRate, { availabilityCode: 3 });
          reserved = _.union(pending, reserved);
          reserved.forEach( (res)=> {
            this.hotelStay.push({ Date: res.blockDate, Hotel: groupid });
          });
        });
        
      //}

    });
  }


  ngOnInit(): void {
  }

  isselected(hotelPackage, hotel) {
    var isselect = true;
    var startDate = this.dateService.createMTCZoneDateTime(hotelPackage.startDate);
    var endDate = this.dateService.createMTCZoneDateTime(hotelPackage.endDate);
    this.hotelStay.forEach((hotelnight) => {
        //check all nights in hotelstay are in hotel
        if (hotel !== hotelnight.Hotel) {
            isselect = false;
        }

        var momentdate = this.dateService.createMTCZoneDateTime(hotelnight.Date);

        if (!momentdate.isBetween(startDate, endDate) && !momentdate.isSame(startDate) && !momentdate.isSame(endDate)) {
            isselect = false;
        }

    });
    if (isselect === true) {
      //make an array of dates
      var dates = this.enumerateDaysBetweenDates(startDate, endDate);
      //check to make sure the hotel stay isn't longer the package dates
      if(this.hotelStay.length !== dates.length) {
          isselect = false;
      }
    }
    return isselect;
  };

  addNight(date, hotel) {
    this.noHotel = false;
    var tobe = { Date: date, Hotel: hotel };
    var search = _.filter(this.hotelStay, { Date: date });
    if (search.length) {
      _.remove(this.hotelStay, search[0]);
      if (search[0].Hotel !== hotel) {
        this.hotelStay.push(tobe);
      }
    } else {
      this.hotelStay.push(tobe);
    }

    var isExtension = false;
    this.hotelStay.forEach((stay) => {

      var date = this.dateService.createMoment(stay.Date).toDate();

      if (this.outsideGroupDates(date)) {
        isExtension = true;
      }
    });
    if (isExtension) {
      this.enrollment.$mtcExtension = true;
    } else {
      this.enrollment.$mtcExtension = undefined;
    }
  };

  addNights(hotelPackage, hotel) {
    while (this.hotelStay.length) {
        this.hotelStay.pop();
    }

    var startDate = this.dateService.createMTCZoneDateTime(hotelPackage.startDate);
    var endDate = this.dateService.createMTCZoneDateTime(hotelPackage.endDate);
    //make an array of dates
    var dates = this.enumerateDaysBetweenDates(startDate, endDate);
    dates.forEach((date) => {
        this.addNight(date.toISOString(), hotel);
    });
  };
  enumerateDaysBetweenDates(startDate, endDate) {
    var dates = [];

    var currDate = startDate.clone();
    var lastDate = endDate.clone();

    while (currDate.diff(lastDate) < 0) {
        dates.push(currDate.clone());
        currDate.add(1, "days");
    }

    return dates;
  };

  findDate(hotelList, searchDate) {
    var match;
    if (typeof searchDate !== "undefined") {
        match = _.find(hotelList.roomNightsListWithRate, (night) => {
          var blockDate = this.dateService.createMTCZoneDateTime(night.blockDate);
            return searchDate.date() === blockDate.date() && searchDate.month() === blockDate.month();
        });
    }
    return match;
  };

  isHotelAvailableOnDate(hotellist, date) {
    var hotel = this.findDate(hotellist, date);
   // console.log("Udoka")
    //console.log(hotel)
    if (hotel) {
        if (hotel.availabilityCode === 1 || hotel.availabilityCode === 2 || hotel.availabilityCode === 3) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
  };

  isHotelAvailableOnDates(hotellist, hotelPackage) {
    var available = true;
    var incrementDate = this.dateService.createMTCZoneDateTime(hotelPackage.startDate);
    var endDate = this.dateService.createMTCZoneDateTime(hotelPackage.endDate);

    while (incrementDate < endDate && available) {

      if (!this.isHotelAvailableOnDate(hotellist, incrementDate)) {
            available = false;
        }
        incrementDate.add(1, "d");
    }
    return available;
  };

  getPackageLength(hotelPackage) {
    var startDate = this.dateService.createMTCZoneDateTime(hotelPackage.startDate);
    var endDate = this.dateService.createMTCZoneDateTime(hotelPackage.endDate);
    return endDate.diff(startDate, "days") + " Night Package";
  };

  outsideGroupDates(date) {
    return date < moment.utc(this.hotelList.groupStartDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago").toDate()
    || date > moment.utc(this.hotelList.groupEndDate, "YYYY-MM-DD HH:mm:ss.SSS+Z").tz("America/Chicago").toDate();
  };

  getHotelPackageDescriptionUrl(webHotelGroupDesc){
    return this.copyService.getCopyByKey("copy.html.Hotel" + webHotelGroupDesc.replace(/[\s]/g, "") + "Package")
  }

  clearRequests() {
    if (this.enrollment.HotelNeeded === true) {
      if (this.enrollment.HotelSpecialRequests === "Staying at local residence" || this.enrollment.HotelSpecialRequests === "Sharing a room reserved by someone else"
        || this.enrollment.HotelSpecialRequests === "Arranged my own accommodations" || this.enrollment.HotelSpecialRequests === "I will add my hotel later") {
        this.HoldSpecialRequests = this.enrollment.HotelSpecialRequests;
        this.enrollment.HotelSpecialRequests = "";
      }
    } else if (this.HoldSpecialRequests) {
      this.enrollment.HotelSpecialRequests = this.HoldSpecialRequests;
    }
  }

  forward(form) {
    this.isFormCompleted=true;
    if (this.enrollment.HotelNeeded !== undefined && form.valid) {
      if (this.enrollment.HotelNeeded) {
        // User must have selected hotels on contiguous dates
        if (this.hotelStay.length > 0 && this.hotelService.getNonContiguousHotelNights(this.hotelList.roomTypeList, this.hotelStay).length <= 0) {
          this.isSubmitting = true;
          this.navigationService.forward();
        } else {
          this.noHotel = true;
          this.isError = true;
          this.isSubmitting = false;
        }
      } else {
        this.enrollment.WebHotelBooking = [];
        this.isSubmitting = true;
        this.navigationService.forward();
      }
    } else {
      this.isSubmitting = false;
    }
  }
  back() {
    this.navigationService.back();
  }
}
