import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-phonesummary',
  templateUrl: './phonesummary.component.html'
})
export class PhonesummaryComponent implements OnInit {

  @Input() phone: any;

  phoneDisplay: any;

  constructor() { }

  ngOnInit(): void {
    if (this.phone.CountryCode !== "" && this.phone.CountryCode !== undefined && this.phone.CountryCode !== '01') {
      this.phoneDisplay = this.phone.CountryCode + " " + this.phone.AreaCityCode + " " + this.phone.PhoneNo;
    } else {
      this.phoneDisplay = "(" + this.phone.AreaCityCode + ") " + this.phone.PhoneNo.slice(0, 3) + "-" + this.phone.PhoneNo.slice(3, 8);
    }
  }

}
