<div id="acknowledgment" class="registration container">
  <form novalidate autocomplete="off">
    <h1>{{ 'copy.label.ACKNOWLEDGEMENT.confirmation' | copy | async }}</h1>

    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.ACKNOWLEDGEMENT.success' | copy | async }}
        </div> 
      </div>
      <article class="p-4">
        <h3 class="w-100">{{'returnLogin' | copy | async}}: <span class="number" id="confCode">{{enrollment.$mtcConfirmationCode}}</span></h3>
        <p class="w-100">
          {{ 'copy.label.ACKNOWLEDGEMENT.message' | copy | async }}
        </p>
      </article>
    </section>

    <section class="card" *ngIf="attendee.PAXTypeNo === 1 || attendee.PAXTypeNo === 2 || attendee.PAXTypeNo === 3 || attendee.PAXTypeNo === 4 || 
        attendee.PAXTypeNo === 6 || attendee.PAXTypeNo === 7 || attendee.PAXTypeNo === 8 || attendee.PAXTypeNo === 9 || attendee.PAXTypeNo === 10 || attendee.PAXTypeNo === 11 || 
        attendee.PAXTypeNo === 12 || attendee.PAXTypeNo === 13  || attendee.PAXTypeNo === 16 || attendee.PAXTypeNo === 17 || attendee.PAXTypeNo === 20">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.ACKNOWLEDGEMENT.travel' | copy | async }}
        </div>
      </div>
      <div class="travelLinksPrimaryAndGuest">
        <article class="p-4">
         <!--US Dealer Principle, US Dealer Manager, US Public Entities, US Dealer Other, LNDC, NAAB -->
          <div *ngIf="attendee.PAXTypeNo === 1 || attendee.PAXTypeNo === 2 || attendee.PAXTypeNo === 3 || attendee.PAXTypeNo === 4 || 
        attendee.PAXTypeNo === 16 || attendee.PAXTypeNo === 17" [innerHTML]='"copy.html.acktravelnotes1"|copy|async'></div>

         <!--Global-->
        <div *ngIf="attendee.PAXTypeNo === 9" [innerHTML]='"copy.html.acktravelnotes2"|copy|async'></div> 

        <!--LFS, TMC, TMC Exec, TMNA GVP & Above, TMNA, TMNA Invited Attendee-->
        <div *ngIf="attendee.PAXTypeNo === 6 || attendee.PAXTypeNo === 8 || attendee.PAXTypeNo === 10 || 
        attendee.PAXTypeNo === 11 || attendee.PAXTypeNo === 12 || attendee.PAXTypeNo === 13 "
         [innerHTML]='"copy.html.acktravelnotes3"|copy|async'></div>

         <!--Area-->
         <div *ngIf="attendee.PAXTypeNo === 7"
         [innerHTML]='"copy.html.acktravelnotes4"|copy|async'></div>

        </article>
      </div>
    </section>

    <section class="card">
      <div class="card-header">
          <div class="card-title">
              {{ 'copy.label.CANCELLATION.policy' | copy | async }}
          </div>
      </div>
      <article class="p-4">
          <div [innerHTML]='"copy.html.ackCancellation" | copy | async'></div>
      </article>
  </section>

    <section class="card" *ngIf="enrollment.HotelNeeded && (attendee.PAXTypeNo === 1 || attendee.PAXTypeNo === 2 || attendee.PAXTypeNo === 3 || attendee.PAXTypeNo === 4 || 
    attendee.PAXTypeNo === 16 || attendee.PAXTypeNo === 17)">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.ACKNOWLEDGEMENT.hotel' | copy | async }}
        </div>
      </div>
      <article class="p-4">
        <!-- <app-contactus>{{'copy.label.ACKNOWLEDGEMENT.question' | copy | async }}</app-contactus> -->
        <div [innerHTML]='"copy.html.ackhotelnotes1"|copy|async'></div>
      </article>
    </section> 

    
    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.LOGOUT.contact' | copy | async }}
        </div>
      </div>
      <div class="travelLinksPrimaryAndGuest">
        <article class="p-4">
          <!-- <app-contactus>{{'copy.label.ACKNOWLEDGEMENT.question' | copy | async }}</app-contactus> -->
          <a (click)="openContactModal()">{{'copy.label.ACKNOWLEDGEMENT.question'|copy|async}}</a>
        </article>
      </div>
    </section>
  </form>


</div>
