import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../../../services/registration.service';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html'
})
export class BadgeComponent implements OnInit {
  attendee: any;
  config: any;
  showbadgeInfo: any;
  showbadge: any;
  isRegComplete: any;
  dealer = false;

  constructor(private regService: RegistrationService, private configService: ConfigurationService) {
    this.isRegComplete = this.regService.isRegistrationComplete();
   }

  ngOnInit(): void {
    this.attendee = this.regService.getAttendee({});



    if(this.attendee.PAXTypeNo === 1 || this.attendee.PAXTypeNo === 2 || 
      this.attendee.PAXTypeNo === 3 || this.attendee.PAXTypeNo === 4){

        this.dealer = true;
    }

    this.configService.fetchConfig().then(data => {
      this.config = data;
      this.showbadge = this.config.showbadge;
    });
  }
}
