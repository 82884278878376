import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../../services/registration.service';
import { ConfigurationService } from '../../services/configuration.service';
import { VariablesService } from '../../services/variables.service';

@Component({
  selector: 'app-fordattendee',
  templateUrl: './fordattendee.component.html'
})
export class FordattendeeComponent implements OnInit {
  attendee: any;
  config: any;
  showbadgeInfo: any;
  varPartsAccountNum;
  multiDealership;
  multiDealershipName1;
  multiDealershipPA1;
  multiDealershipName2;
  multiDealershipPA2;
  multiDealershipName3;
  multiDealershipPA3;
  multiDealershipName4;
  multiDealershipPA4;
  multiDealershipName5;
  multiDealershipPA5;
  multiDealershipRadios: any;


  constructor(private regService: RegistrationService, private configService: ConfigurationService,  private variablesService: VariablesService, 
    ) { 
      this.attendee = this.regService.getAttendee({});
      //UPDATE THIS VARIABLE TO THE NAME YOU GAVE PARTS ACCOUNT AND MULTI DEALERSHIP VARIABLES ON YOUR VARIABLE SERVICE
      //this.varPartsAccountNum = regService.getVariable(this.attendee, variablesService.VARIABLE.FIRST_TIME.ID,{});
  
    }

  ngOnInit(): void {
    this.attendee = this.regService.getAttendee({});
    this.configService.fetchConfig().then(data => {
      this.config = data;
    });
  }
}
