import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html'
})
export class ValidationComponent {

  @Input() object: any;
  @Input() isRequired: any;
  @Input() pattern: any;
  @Input() minDate: any;
  @Input() maxDate: any;

  constructor() {
  }

  log(){
    console.log(this.object);
  }
}
