import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { OnetimepasswordService } from 'src/app/services/onetimepassword.service';
import { PersistenceService } from 'src/app/services/persistence.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent {  
  
  /*
    get to login page
    enter email address
    make call to get censored SMS and Email
    select one, service call to send OTP
    box for entering OTP, service call to confirm OTP
    Once verified, set auth token
  */
  emailAddress;
  errorMsg;
  isFormCompleted=false;
  isFormCompleted2=false;
  header;
  emailEntryMode=true;
  //{{"copy.label.OTP.emailEntry"|copy|async}}
  methodSelectMode=false;
  //{{"copy.label.OTP.methodSelect"|copy|async}}
  confirmMode=false;
  //copy.label.OTP.confirmationCodeHeader
  method;
  smsnumber;
  email;
  confirmcode='';
  otpSessionID;
  guid;
  constructor(private auth:AuthenticationService, private otpService: OnetimepasswordService,private router: Router, private persistence: PersistenceService, private navigation:NavigationService, private enrollmentService: EnrollmentService) { }

  ngOnInit(): void {
    
  }

  emailEnter(){
    this.otpService.registerOTP(this.emailAddress).subscribe((data:any)=>{
      // this.otpKey = data.key;
      this.guid = data.guid;
      this.email = data.email;
      this.smsnumber = data.phone;
      this.emailEntryMode =false;
      this.methodSelectMode = true;
    });    
  }

  backToSelect(){
    this.emailEntryMode =true;
    this.methodSelectMode = false;
  }

  methodSelect(){
    this.otpService.selectMethod(this.method, this.guid).subscribe((data:any)=>{
      this.otpSessionID = data.sessionID;
      this.methodSelectMode = false;
      this.confirmMode = true;
    });
  }

  confirm(){
    this.errorMsg = false;
    this.otpService.validateOTP(this.confirmcode, this.otpSessionID,this.method,this.guid).subscribe((data:any)=>{
      this.auth.authtoken = data;
      this.enrollmentService.rvsGetEnrollment().then(() => {        
        this.navigation.forwardrvs();
      });
      
    },()=>{
      this.errorMsg = true;
    });
  }

  resend(){
    this.errorMsg = false;
    this.methodSelectMode = true;
    this.confirmMode = false;
  }

  clearError(){
    this.errorMsg = false;
  }


}
