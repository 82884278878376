<div class="footer-container">
  <footer id="footer" class="footer">
    <div class="container">
      <div class="text-center">
        <a tabindex="0" (click)="openModal('termsOfUse', 'About This Site', 'Close');"  (keydown.enter)="openModal('termsOfUse', 'About This Site', 'Close');">
          {{"copy.label.FOOTER.termsofuse" | copy | async}}
        </a>
        <a routerLink="privacy-notice" target="_blank">
          {{"copy.label.FOOTER.privacy" | copy | async}}
        </a>
        <!-- <a tabindex="0" (click)="openModalExternal('https://mge-cdn.prodtravelhq.com/policies/mge_cookie_policy.html', 'Cookie Policy', 'Close')" (keydown.enter)="openModalExternal('https://mge-cdn.prodtravelhq.com/policies/mge_cookie_policy.html', 'Cookie Policy', 'Close')">
          Cookie Policy
        </a> -->
        <!-- OneTrust Cookies Settings button start -->
        <a tabindex="0" class="ot-sdk-show-settings" id="cookieSettings" tabindex="0" (keyup.enter)="cookieEnter()">Cookie Settings</a>
        <!-- OneTrust Cookies Settings button end -->
      </div>
      <div class="text-center">
        <a href="https://maritz.com" target="_blank"
          >Powered by <span class="sr-only">Maritz</span> <svg class="svg-fill mtlogo" aria-hidden="true">
            <use xlink:href="assets/images/maritz-logo.svg#maritz"></use>
          </svg></a>
      </div>
      <!-- <div class="col-sm-4 text-center">
                <div id="google_translate_element"></div>
            </div>
            <div class="col-sm-4 text-right">
            <div class="">
                <a ng-click="footerCtrl.changeLanguage('en_US')">English</a>
                <a ng-click="footerCtrl.changeLanguage('es_US')">Español</a>
                <a ng-click="footerCtrl.changeLanguage('ja')">日本語</a>
            </div> -->
    </div>
  </footer>
</div>
