import { Component, OnInit, Input } from '@angular/core';
import { ListManagerService } from 'src/app/services/list-manager.service';
import * as _ from 'lodash';
import { ConstantsService } from 'src/app/services/constants.service';
import { VariablesService } from 'src/app/services/variables.service';
import { RegistrationService } from 'src/app/services/registration.service';


@Component({
  selector: 'app-flightsummary',
  templateUrl: './flightsummary.component.html'
})
export class FlightsummaryComponent implements OnInit {

  @Input() attendee: any;
  @Input() enrollment: any;
  @Input() traveltype: any;

  travelTimes: any;
  countryList: any;
  outboundtimepref: any;
  returntimepref: any;
  displaygender: any;
  displayissuingcountry: any;
  displaybirthcountry: any;
  seatposition: any;
  airlineList: any;
  otherknowntraveler;

  constructor(
    private listService: ListManagerService,
    public Constants: ConstantsService,
    private variables: VariablesService,
    private registrationService: RegistrationService
  ) {
    this.listService.getTravelTimeList().then(data => {
      this.travelTimes = data;
      if (this.attendee.$mtcOutbound.DepartureTimePreference)
        this.outboundtimepref = _.filter(this.travelTimes, { code: this.attendee.$mtcOutbound.DepartureTimePreference })[0].name;
      if (this.attendee.$mtcReturn.DepartureTimePreference)
        this.returntimepref = _.filter(this.travelTimes, { code: this.attendee.$mtcReturn.DepartureTimePreference })[0].name;
    });
    this.listService.getCountryList().then(data => {
      this.countryList = data;
      // console.log(this.attendee)
      if(this.attendee.TravelDocument && this.attendee.TravelDocument.length>0){
        if (this.attendee.TravelDocument[0].IssuingCountryISOCode)
          this.displayissuingcountry = _.filter(this.countryList, { code: this.attendee.TravelDocument[0].IssuingCountryISOCode })[0].name;
        if (this.attendee.TravelDocument[0].BirthCountryISOCode)
          this.displaybirthcountry = _.filter(this.countryList, { code: this.attendee.TravelDocument[0].BirthCountryISOCode })[0].name;
      }
    });
    this.listService.getFrequentFlyerList().then(data => {
      this.airlineList = data;
    });


  }

  ngOnInit(): void {
    if (this.attendee.Gender === "MALE") {
      this.displaygender = "Male";
    } else {
      this.displaygender = "Female";
    }

    switch (this.attendee.InterRowPosition) {
      case this.Constants.REQUESTED_SEAT_TYPES.WINDOW: {
        this.seatposition = "Window";
        break;
      }
      case this.Constants.REQUESTED_SEAT_TYPES.AISLE: {
        this.seatposition = "Aisle";
        break;
      }
      case this.Constants.REQUESTED_SEAT_TYPES.NOPREF: {
        this.seatposition = "No Preference";
        break;
      }
      default: {
        this.seatposition = "N/A";
        break;
      }

    }

    this.otherknowntraveler = this.registrationService.getVariable(this.attendee,this.variables.VARIABLE.OTHER_NUMBER.ID,{}).Value;
  }

  filterairlinecode(airlinecode) {
    if (this.airlineList !== undefined)
      return _.filter(this.airlineList, { code: airlinecode })[0].name;
    else
      return airlinecode;
  }

}
