<header class="modal-header">
  <h1 class="modal-title">Forgot {{"returnLogin"|copy|async}} </h1>
  <div tabindex="0" class="close-container" (click)="onNoClick()" (keydown.enter)="onNoClick()">
    <div class="leftright"></div>
    <div class="rightleft"></div>
  </div>
</header>
<form name="forgotConfForm" #forgotConfForm="ngForm" [ngClass]="{'form-complete': isFormCompleted}" *ngIf="!success">
  <mat-dialog-content class="modal-body overflow-auto">
    <mat-card *ngIf="isError">
      <mat-card-title><span class="material-icons">
          error_outline
        </span> {{"returnLogin"|copy|async}} Request Failed.</mat-card-title>
      <p>Your request failed because you did not supply a combination of last name and email address that matched our records.</p>
      <p>Please check your spelling and try again.</p>
    </mat-card>
    <mat-card>
      <mat-card-title>Contact Information</mat-card-title>
      <p class="w-100">
        <strong>{{'travelHQName' |copy |async}}</strong>
      </p>
      <p *ngIf="'travelHQ800Num'|copy|async">
        {{"copy.label.CONTACTUS.phone"|copy |async}} {{'travelHQ800Num' |copy |async}}
      </p>
      <p *ngIf="'travelHQIntlNum'|copy|async">
        {{"copy.label.CONTACTUS.phone"|copy|async}} {{'travelHQIntlNum'|copy|async}}
      </p>
      <p>
        {{"copy.label.CONTACTUS.email2"|copy|async}} <a href="mailto:{{'travelHQEmail'|copy|async}}">{{'travelHQEmail'|copy|async}}</a>
      </p>
      <p>{{"copy.label.CONTACTUS.hours"|copy|async}} {{'programSupportHours'|copy|async}}</p>
    </mat-card>
    <mat-form-field class="w-100">
      <mat-label for="lastName" aria-labelledby="lastName" [ngClass]="{'active' : confirmationLookUp.lastName}">
        {{"copy.label.CONTACTUS.last"|copy|async}}</mat-label>
      <input matInput name="lastName" id="lastName" aria-label="lastName" type="text"  maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="confirmationLookUp.lastName" #lastName="ngModel" required />
      <mat-error><app-validation [object]="lastName" isRequired=true pattern="alpha"></app-validation></mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label for="email" aria-labelledby="email" [ngClass]="{'active' : confirmationLookUp.email}">{{ 'copy.label.FEATURES.EMAIL.label' | copy | async }}</mat-label>
      <input matInput type="text" name="email" id="email" aria-label="email"  maxlength="100" [(ngModel)]="confirmationLookUp.email" #email="ngModel" required pattern="[a-zA-Z0-9._'%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}" />
      <mat-error><app-validation [object]="email" isRequired=true pattern="email"></app-validation></mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <footer id="modal-footer">
    <div class="button_group d-flex flex-row justify-content-between">
      <button class="btn btn-outline-primary" data-dismiss="modal" (click)="onNoClick()">{{"copy.label.GLOBAL.cancel"|copy|async}}</button>
      <button class="btn btn-primary" cdkfocusinitial (click)="ok(forgotConfForm)">Get {{'returnLogin'|copy|async}}</button>
    </div>
  </footer>
</form>
<div *ngIf="success">
  <mat-dialog-content class="modal-body overflow-auto">
    <mat-card>
      <mat-card-title>Your Lookup Request Has Been Completed!</mat-card-title>
      <p>Your information has been emailed to the email address you provided.</p>
    </mat-card>
  </mat-dialog-content>
  <footer id="modal-footer">
    <div class="button_group d-flex flex-row justify-content-between">
      <button class="btn btn-primary" data-dismiss="modal" (click)="onNoClick()">Close</button>
    </div>
  </footer>
</div>
