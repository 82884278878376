import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html'
})
export class RadioGroupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // get g(){
  //   return this.form.controls;
  // }  

}
