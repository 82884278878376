<span *ngIf="this.object.hasError('required')&&this.object.touched">{{'copy.label.ERRORS.required'|copy|async}}</span>
<span *ngIf="pattern === 'alpha' && this.object.hasError('pattern')">{{'copy.label.ERRORS.alpha'|copy|async}}</span>
<span *ngIf="pattern === 'alphanum' && this.object.hasError('pattern')">{{'copy.label.ERRORS.alphanum'|copy|async}}</span>
<span *ngIf="pattern === 'integer' && this.object.hasError('pattern')">{{'copy.label.ERRORS.integer'|copy|async}}</span>
<span *ngIf="pattern === 'uszip' && this.object.hasError('pattern')">{{'copy.label.ERRORS.usZip'|copy|async}}</span>
<span *ngIf="pattern === 'cazip' && this.object.hasError('pattern')">{{'copy.label.ERRORS.caZip'|copy|async}}</span>
<span *ngIf="pattern === 'email' && this.object.hasError('pattern')">{{'copy.label.ERRORS.emailx'|copy|async }}</span>
<span *ngIf="pattern === 'phonenum' && this.object.hasError('inputMask')">{{'copy.label.ERRORS.phonenum'|copy|async }}</span>
<span *ngIf="this.object.hasError('uniqueemail')">{{'copy.label.ERRORS.uniqueEmail'|copy|async }}</span>
<span *ngIf="this.object.hasError('verify')">{{'copy.label.ERRORS.verify'|copy|async }}</span>
<span *ngIf="this.object.hasError('invalidDate')">{{'copy.label.ERRORS.date'|copy|async }}</span>
<span *ngIf="this.object.hasError('luhncheck')">{{'copy.label.ERRORS.mtcLuhnCheck'|copy|async}}</span>
<span *ngIf="this.object.hasError('expired') && this.object.name !== 'year'">This Card is Expired</span>
<span *ngIf="this.object.hasError('minDate')">{{"copy.label.ERRORS.minDate" | copy | async}}
  <span *ngIf="minDate === 'today'">today</span>
  <span *ngIf="minDate !== 'today'">{{minDate | date: "longDate": "-0500"}}</span>
</span>
<span *ngIf="this.object.hasError('maxDate')">{{"copy.label.ERRORS.maxDate" | copy | async}}
  <span *ngIf="maxDate === 'today'">today</span>
  <span *ngIf="maxDate !== 'today'">{{maxDate | date: "longDate": "-0500"}}</span>
</span>

<!-- <button type="button" (click)="log()">log</button> -->