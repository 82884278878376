import { Directive, HostListener, ElementRef, ViewChild, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@Directive({
  selector: '[appScrollToInvalid]',
})
export class ScrollToInvalidDirective {
  constructor(private el: ElementRef) {}
  @Input() panels;

  @HostListener('ngSubmit') onSubmit() {
    if(this.panels) {
      this.panels.forEach(panel => {
        if(document.getElementById(panel.id).querySelector('.ng-invalid')) {
          panel.open();
        }
      });
      setTimeout(() => {
        this.scrollToFirstInvalidControl();
      }, 250);
    } else {
      this.scrollToFirstInvalidControl();
    }
  }

  private scrollToFirstInvalidControl() {
    let firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      '.ng-invalid'
    );
    if(firstInvalidControl){
      firstInvalidControl = firstInvalidControl.parentElement;
      if (firstInvalidControl) {
        window.scroll({
          top: this.getTopOffset(firstInvalidControl),
          left: 0,
          behavior: 'smooth',
        });

        fromEvent(window, 'scroll')
          .pipe(debounceTime(100), take(1))
          .subscribe(() => firstInvalidControl.focus());
      }
    }
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
}
