<div class="creditcards">
    <img src="assets/images/visa.png"><img src="assets/images/amex.png"><img src="assets/images/mastercard.png"><img
        src="assets/images/discover.png">
</div>
<div *ngIf="!cards || cards.length === 0">
    <a (click)="open()"><i class="fas fa-plus-circle addCC"></i> {{"copy.label.FEATURES.CREDITCARD.add"|copy|async}}</a>
</div>
<div *ngIf="cards && cards.length > 0">
    <div>
      <a (click)="open()"><i class="fas fa-plus-circle addCC"></i>
        {{"copy.label.FEATURES.CREDITCARD.adddiff"|copy|async}}</a>
    </div>
    <mat-form-field class="w-100">
      <mat-label for="paymentType">Credit Card</mat-label>
      <mat-select name="paymentType" id="payment" aria-label="payment" [(ngModel)]="payment.ReferenceKey"
        #MediaType="ngModel" required matNativeControl>
        <mat-option *ngFor="let card of cards" [value]="card.ReferenceKey">
            {{ card.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
</div>
