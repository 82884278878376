<header class="modal-header">
  <h1 class="modal-title">{{hotel.supplierName}}</h1>
  <div class="close-container" (click)="onNoClick('&nbsp;')">
      <div class="leftright"></div>
      <div class="rightleft"></div>
    </div>
  <!-- <button class="btn btn-outline-light closeButton"  (click)="onNoClick()">X</button> -->
</header>

<mat-dialog-content class="modal-body" >
  <div [innerHTML]="hoteldesc">

  </div>
</mat-dialog-content>

<footer id="modal-footer">
  <div class="button_group d-flex">
    <button data-dismiss="modal" (click)="onNoClick('&nbsp;')" class="btn btn-outline-primary cancelBtn">{{"copy.label.GLOBAL.cancel"|copy|async}}</button>
  </div>
</footer>
