import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './page-components/login/login.component';
import { LoadComponent } from './page-components/load/load.component';
import { DeclineComponent } from './page-components/decline/decline.component';
import { SelectionComponent } from './page-components/selection/selection.component';
import { AttendeeComponent } from './page-components/attendee/attendee.component';
import { DemographicsComponent } from './page-components/demographics/demographics.component';
import { HotelBookingComponent } from './page-components/hotel-booking/hotel-booking.component';
import { TravelComponent } from './page-components/travel/travel.component';
import { SummaryComponent } from './page-components/summary/summary.component';
import { AcknowledgementComponent } from './page-components/acknowledgement/acknowledgement.component';
import { CancellationComponent } from './page-components/cancellation/cancellation.component';
import { InvitationComponent } from './page-components/invitation/invitation.component';
import { TimeoutComponent } from './page-components/timeout/timeout.component';
import { HomeComponent } from './page-components/pretrip/home/home.component';
import { GuestComponent } from './page-components/guest/guest.component';
import { HotelComponent } from './page-components/hotel/hotel.component';
import { GuestTravelComponent } from './page-components/guest-travel/guest-travel.component';
import { ReplacementComponent } from './page-components/replacement/replacement.component';
import { PaymentComponent } from './page-components/payment/payment.component';
import { HotelPackageComponent } from './page-components/hotel-package/hotel-package.component';
import { HotelPretripComponent } from './page-components/pretrip/hotel-pretrip/hotel-pretrip.component';
import { TravelPretripComponent } from './page-components/pretrip/travel-pretrip/travel-pretrip.component';
import { DestinationComponent } from './page-components/pretrip/destination/destination.component';
import { ActivitiesPretripComponent } from './page-components/pretrip/activities-pretrip/activities-pretrip.component';
import { AgendaComponent } from './page-components/pretrip/agenda/agenda.component';
import { SpeakersComponent } from './page-components/pretrip/speakers/speakers.component';
import { FaqComponent } from './page-components/pretrip/faq/faq.component';
import { SponsorsComponent } from './page-components/pretrip/sponsors/sponsors.component';
import { OnePageComponent } from './page-components/pretrip/one-page/one-page.component';
import { BackguardService } from './services/backguard.service';
import { ProfileComponent } from './page-components/profile/profile.component';
import { ActivityComponent } from './page-components/activity/activity.component';
import { TestPageComponent } from './page-components/testpage/testpage.component';
import { OauthComponent } from './page-components/oauth/oauth.component';
import { InstructionsComponent } from './page-components/instructions/instructions.component';
import { FordattendeeComponent } from './reusable-components/fordattendee/fordattendee.component';
import { GeneralComponent } from './page-components/general/general.component';
import { ConfigComponent } from './page-components/config/config.component';
import { OnsiteComponent } from './page-components/onsite/onsite.component';
import { PrivacyNoticeComponent } from './page-components/privacy-notice/privacy-notice.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'privacy-notice', component: PrivacyNoticeComponent},
  { path: 'onsite', component: OnsiteComponent },
  { path: 'load', component: LoadComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'testpage', component: TestPageComponent },
  { path: 'instructions', component: InstructionsComponent },
  { path: 'oauth', component: OauthComponent },
  { path: 'invitation', component: InvitationComponent, canActivate : [BackguardService]  },
  { path: 'decline', component: DeclineComponent, canActivate : [BackguardService] },
  { path: 'selection', component: SelectionComponent, canActivate : [BackguardService] },
  { path: 'general', component: GeneralComponent },
  { path: 'attendee', component: AttendeeComponent, canActivate : [BackguardService] },
  { path: 'guest', component: GuestComponent, canActivate : [BackguardService] },
  { path: 'activity', component: ActivityComponent },
  { path: 'demographics information', component: DemographicsComponent, canActivate : [BackguardService] },
  { path: 'hotelbooking', component: HotelBookingComponent, canActivate : [BackguardService] },
  { path: 'hotelpackage', component: HotelPackageComponent, canActivate : [BackguardService] },
  { path: 'hotel', component: HotelComponent, pathMatch: 'full', canActivate : [BackguardService] },
  { path: 'travel', component: TravelComponent, canActivate : [BackguardService] },
  { path: 'guest-travel', component: GuestTravelComponent, canActivate : [BackguardService] },
  { path: 'payment', component: PaymentComponent, canActivate : [BackguardService] },
  { path: 'summary', component: SummaryComponent, canActivate : [BackguardService] },
  { path: 'acknowledgement', component: AcknowledgementComponent, canActivate : [BackguardService] },
  { path: 'cancellation', component: CancellationComponent, canActivate : [BackguardService] },
  { path: 'replacement', component: ReplacementComponent, canActivate : [BackguardService] },
  { path: 'timeout', component: TimeoutComponent, canActivate : [BackguardService] },

  { path: 'pretrip', component: HomeComponent, canActivate : [BackguardService]  },
  // { path: 'pretrip/one-page', component: OnePageComponent, canActivate : [BackguardService] },
  { path: 'pretrip/hotels', component: HotelPretripComponent, canActivate : [BackguardService] },
  { path: 'pretrip/travel', component: TravelPretripComponent, canActivate : [BackguardService] },
  { path: 'pretrip/destination', component: DestinationComponent, canActivate : [BackguardService] },
  { path: 'pretrip/activities', component: ActivitiesPretripComponent, canActivate : [BackguardService] },
  { path: 'pretrip/agenda', component: AgendaComponent, canActivate : [BackguardService] },
  { path: 'pretrip/speakers', component: SpeakersComponent, canActivate : [BackguardService] },
  { path: 'pretrip/faq', component: FaqComponent, canActivate : [BackguardService] },
  { path: 'pretrip/sponsors', component: SponsorsComponent, canActivate : [BackguardService] },

  { path: 'config', component: ConfigComponent},
];

// Hubb
// Forgot Confirmation
// Contact Us Pop Up

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    paramsInheritanceStrategy: 'always',
    enableTracing: false,
    useHash: true
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
