<div id="acknowledgment" class="registration container">
  <form novalidate autocomplete="off" (ngSubmit)="forward()">

    <section class="card">
      <div class="card-header">
        <div class="card-title">
          <h3>Welcome to the {{'programName' | copy | async}} Enrollment Website!</h3>
        </div>
      </div>
      <article class="p-4">
        <p class="w-100"><strong>On the next page, select one of the two options:</strong></p>
        <ul class="w-100">
            <li class="w-100">
                <strong>Dealership Employees:</strong> Please select "Dealer, Supplier, Other Login". 
                <br>
                <span>
                    <img class="instructions" alt="securelogon" src="assets/images/dealer.png" />
                </span>
                Dealer, Supplier, Other Login
            </li>
            <br/>
            <li class="w-100">
                <strong>Ford Company Personnel: </strong> Please select "Active Directory".
                <br>
                <span>
                    <img class="instructions" alt="securelogon" src="assets/images/securelogon.png" />
                </span>
                <span></span>
                Active Directory
            </li>
        </ul>
        <strong class="w-100"> Login help:</strong> <a href="mailto:{{'travelHQEmail' | copy | async}}">{{'travelHQEmail' | copy | async}}</a>
  </article>
    </section>
    <section>
      <div class="button_group d-flex">
        <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
          Continue to Login Page
        </button>
        <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
          <i class="fas fa-spinner fa-spin"></i>
        </button>
      </div>
      
    </section>
  </form>
</div>
