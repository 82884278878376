import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { RegistrationService } from './registration.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private environmentService: EnvironmentService, private http: HttpClient, private registrationService: RegistrationService) { }

  checkUniqueEmail(email){
    console.log(email);
    let url = this.environmentService.getEnvironmentUrl("service/emailcheck");
    let attendee = this.registrationService.getAttendee({});
    return this.http.post(url,{email:email, key: attendee.ExternalAttendeeKey});
  }
}
