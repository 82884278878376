import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import * as l33t from 'l33teral';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private http: HttpClient,
    private environmentService: EnvironmentService
    ) { }

  content;

  getContentByValue(value:any,refresh?:boolean){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const contentstatus = urlParams.get('contentstatus')?urlParams.get('contentstatus'):'published';
    const version =  urlParams.get('version')?urlParams.get('version'):'active';
    const promise = new Promise((resolve, reject)=>{
      let url = this.environmentService.getContentUrl(contentstatus,version)
      if(refresh || !this.content){
        this.http.get(url).subscribe((data)=>{
          this.content = data;
          resolve(this.parseValue(value));
        });
      }else{
        resolve(this.parseValue(value));
      }
    });
    return promise;
  }

  parseValue(value){
    const myLeet = l33t(this.content);
    console.log(myLeet);
    return myLeet.tap(value, 'couldnt find ' + value);
  }
}
