import { Component, EventEmitter } from '@angular/core';
import { RegistrationService } from '../../services/registration.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { PersistenceService } from '../../services/persistence.service';
import { timer } from 'rxjs';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ModalService } from '../../services/modal.service';


@Component({
  selector: 'app-sessionmodal',
  templateUrl: './sessionmodal.component.html'
})
export class SessionmodalComponent {

  isRegComplete: any;
  timesUp: any;
  onExtend  = new EventEmitter();
  timeoutpromise;
  subscription;
  constructor(
    private registrationService: RegistrationService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private persistenceService: PersistenceService,
    public dialogRef: MatDialogRef<SessionmodalComponent>,
    public dialogRefTimeout: MatDialogRef<SessionmodalComponent>

  ) {
    this.isRegComplete = this.registrationService.isRegistrationComplete();
    this.timesUp = false;

    this.timeoutpromise = timer(300000);
    this.subscription = this.timeoutpromise.subscribe(x => {
      //logged out
      this.timesUp = true;
      this.logout();
    });
  }



  refresh() {
    if (this.timesUp) {
      this.logout();
      this.dialogRefTimeout.close();
    } else {
      this.onExtend.emit();
      this.subscription.unsubscribe();
      //this.timeoutService.setSessionTimeout();
      this.dialogRefTimeout.close();
    }
  }

  logout() {
    this.authenticationService.invalidateSessionToken().then(() => {
      this.persistenceService.clear();
      this.dialogRefTimeout.close();
      this.router.navigate(['/timeout']);
    });
  }

}
