<div id="replacement" class="registration container">
    <form #replacementForm="ngForm" [ngClass]="{'form-complete': replacementForm.valid }"
        (ngSubmit)="forward(replacementForm.valid)" novalidate autocomplete="off" appScrollToInvalid
        [ngClass]="{'form-complete': isFormCompleted}">
        <h1>{{'copy.label.REPLACEMENT.title' | copy | async}}</h1>

        <p><strong>{{"copy.label.CANCELLATION.pleasenote"|copy|async}}</strong>
            {{"copy.label.CANCELLATION.note"|copy|async}}</p>

        <section class="card">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.CANCELLATION.attendeeinfo' | copy | async }}
                </div>
            </div>

            <article>
                <div class="card-body w-100">
                    <div class="w-100">
                        <label><strong>{{"copy.label.FEATURES.NAME.first"|copy|async}}:</strong></label>
                        <span>{{attendee.FirstName}}</span>
                    </div>
                    <div class="w-100">
                        <label><strong>{{"copy.label.FEATURES.NAME.last"|copy|async}}:</strong></label>
                        <span>{{attendee.LastName}}</span>
                    </div>
                    <div class="w-100">
                        <label
                            class="pull-left"><strong>{{"copy.label.FEATURES.EMAIL.label"|copy|async}}:</strong></label>
                        <span>{{EmailAddress}}</span>
                    </div>
                </div>
            </article>
        </section>

        <section class="card">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.REPLACEMENT.replacement' | copy | async }}
                </div>
            </div>

            <article>
                <div class="card-body w-100">
                    <mat-form-field class="w-100 mt-0">
                        <mat-label for="firstName" aria-labelledby="firstName"
                            [ngClass]="{'active' : Cancel.firstName}">
                            {{ 'copy.label.FEATURES.NAME.first' | copy | async }}</mat-label>
                        <input matInput name="firstName" id="firstName" type="text"  maxlength="25"
                            pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="Cancel.firstName" #firstName="ngModel"
                            required />
                        <mat-error><app-validation [object]="firstName" isRequired=true pattern="alpha"></app-validation></mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-100 mt-0">
                        <mat-label for="lastName" aria-labelledby="lastName" [ngClass]="{'active' : Cancel.lastName}">
                            {{ 'copy.label.FEATURES.NAME.last' | copy | async }}</mat-label>
                        <input matInput name="lastName" id="lastName" type="text"  maxlength="25"
                            pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="Cancel.lastName" #lastName="ngModel" required />
                        <mat-error><app-validation [object]="lastName" isRequired=true pattern="alpha"></app-validation></mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100 mt-0  mb-0">
                        <mat-label for="email" aria-labelledby="email">
                            {{ 'copy.label.FEATURES.EMAIL.label' | copy | async }}</mat-label>
                        <input matInput type="text" name="email" id="email" aria-label="email" 
                            maxlength="100" [(ngModel)]="Cancel.email"
                            pattern="[a-zA-Z0-9._'%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}"
                            #email="ngModel" required />
                        <mat-error><app-validation [object]="email" isRequired=true pattern="email"></app-validation></mat-error>
                    </mat-form-field>

                </div>
            </article>
        </section>

        <section class="card">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.CANCELLATION.policy' | copy | async }}
                </div>
            </div>

            <article>
                <div class="card-body w-100">
                    <div [innerHTML]='"copy.html.CancelPolicy" | copy | async'></div>
                </div>
            </article>
        </section>

        <section class="card">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.REPLACEMENT.paymentinfo' | copy | async }}
                </div>
            </div>

            <article>
                <div class="card-body w-100">
                    <div class="w-100 required">
                        <label class="font-weight-bold">{{ 'copy.label.REPLACEMENT.option' | copy | async }}</label>
                        <div>
                            <mat-radio-group class="w-100" name="payment" [(ngModel)]="Cancel.payment" #payment="ngModel" required>
                                <mat-radio-button *ngFor="let o of replacementOptionRadios" [value]="o.value">
                                    {{ o.display }}
                                </mat-radio-button>
                                <div class="w-100">
                                    <mat-error><app-validation [object]="payment" isRequired=true></app-validation></mat-error>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </article>
        </section>

        <section class="card">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.CANCELLATION.hotelinfo' | copy | async }}
                </div>
            </div>

            <article>
                <div class="card-body w-100">
                    <div class="w-100">
                        <label
                            class="font-weight-bold">{{ 'copy.label.REPLACEMENT.hoteldiscussed' | copy | async }}</label>
                        <div>
                            <mat-radio-group class="w-100" name="hotel" [(ngModel)]="Cancel.hotel" #hotel="ngModel"
                                required>
                                <mat-radio-button *ngFor="let o of replacementHotelRadios" [value]="o.value">
                                    {{ o.display }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div>
                            <mat-error><app-validation [object]="hotel" isRequired=true></app-validation></mat-error>
                        </div>
                    </div>
                </div>
            </article>

        </section>

        <section class="card">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.CANCELLATION.addinfo' | copy | async }}
                </div>
            </div>

            <article>
                <div class="card-body w-100">
                    <mat-form-field class="w-100 mt-0">
                        <mat-label for="reason" aria-labelledby="reason">
                            {{ 'copy.label.CANCELLATION.reason' | copy | async }}</mat-label>
                        <textarea matInput name="reason" id="reason" aria-label="reason" [(ngModel)]="Cancel.reason"
                            maxlength="225" required></textarea>
                    </mat-form-field>
                </div>
            </article>
        </section>
        <div class="button_group d-flex flex-row justify-content-between">
            <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()"
                *ngIf="!isSubmitting">
                {{ 'copy.label.GLOBAL.goback' | copy | async }}
            </button>
            <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
                {{ 'copy.label.GLOBAL.continue' | copy | async }}
            </button>
            <button class="inputSubmit btn btn-primary" *ngIf="isSubmitting">
                <i class="fas fa-spinner fa-spin"></i>
            </button>
        </div>
    </form>
</div>
