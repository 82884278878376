import { Component, Input, OnInit } from '@angular/core';
import { RegistrationService } from 'src/app/services/registration.service';
import * as _ from 'lodash';
import { AddressService } from 'src/app/services/address.service';
import { ListManagerService } from 'src/app/services/list-manager.service';
import { PhoneService } from 'src/app/services/phone.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-credit-card-address',
  templateUrl: './credit-card-address.component.html',
  styleUrls: ['./credit-card-address.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CreditCardAddressComponent implements OnInit {
  @Input() card:any;
  addresslist;
  prefill;
  prefilladd = false;
  stateList;
  countryList;
  provinceList;
  constructor(private registration: RegistrationService,
    private listService: ListManagerService,
    private addressService: AddressService,
    private phoneService: PhoneService) {
      this.listService.getStateList().then(list => {
        this.stateList = list;
      });
      this.listService.getCountryList().then(list => {
        this.countryList = list;
      });
      this.listService.getProvinceList().then(list => {
        this.provinceList = list;
      });
    console.log(registration.getAttendee({}).Address);
    this.addresslist = _.filter(registration.getAttendee({}).Address, this.hasAllParts);
    if(this.addresslist.length > 0){
      this.prefilladd = true;
      this.addresslist.push("new")
    }else{
      this.prefill = "new";
    }
  }

  ngOnInit(): void { 
  }

  hasAllParts(address){
    return (address && address.AddressLine1 && address.City && address.CountryISOCode && address.StateProvince && address.Zip);
  }

  getDisplay(address){
    if(address === 'new'){
      return 'Enter New Billing Address';
    }else{
      return address.AddressLine1 +' '+ address.City +' '+ address.StateProvince +' '+ address.Zip;
    }
  }

  prefillAddress(){
    console.log(this.prefill);
    if(this.prefill === 'new'){
      this.card.address="";
      this.card.zip="";
      this.card.city="";
      this.card.state="";
      this.card.countryCode="";
    }else if(this.prefill){
      this.card.address=this.prefill.AddressLine1;
      this.card.zip=this.prefill.Zip;
      this.card.city=this.prefill.City;
      this.card.state=this.prefill.StateProvince;
      this.card.countryCode=this.prefill.CountryISOCode;

    }
   // console.log(this.card);
  }
  zipChange(zip) {
    if (this.isZipValidForUSA(zip.viewModel)) {
      this.addressService.getCityState(zip.viewModel).subscribe(data => {
        this.card.city = data.city;
        this.card.state = data.state;
      });
    }
  }

  isZipValidForUSA(zipCode) {
    if (zipCode) {
      return /^[0-9]{5}$/.test(zipCode);
    }
    return false;
  }


}
