import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-summary-display',
  templateUrl: './summary-display.component.html'
})
export class SummaryDisplayComponent implements OnInit {

  @Input() ngmodel: any;

  constructor() { }

  ngOnInit(): void {
  }

}
