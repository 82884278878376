import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { RegistrationService } from '../../services/registration.service';
import { ControlContainer, NgForm } from '@angular/forms';
import { AddressService } from 'src/app/services/address.service';
import { ListManagerService } from 'src/app/services/list-manager.service';
import { PhoneService } from 'src/app/services/phone.service';
import { createMask } from '@ngneat/input-mask';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class PhoneComponent {

  @Input() phone: any;
  @Input() label: any;
  @Input() isRequired: any;
  @Input() isError: any;
  @Input() groupname: any;

  @ViewChild('natPhone') phoneelement: any;

  internationalStatus:boolean;
  breakpoint;
  phonesize;
  subscription;
  // phoneMask: any = createMask(
  //   {
  //     formatter: '(999)[]999-9999',
  //     parser: (value:string)=>{
  //       console.log(value);
  //       this.phone.$mtcPhoneNoMasked =value;
  //       this.maskConvert();
  //       return value;
  //     }
  //   });  
  phoneMask: any = createMask('(999)[ ]999-9999');
      
  constructor(
    private registrationService: RegistrationService,
    private addressService: AddressService,
    private listService: ListManagerService,
    private phoneService: PhoneService
  ) {

      //this will default all payments to a mediatype once the first one is selected on the screen
      this.subscription = this.phoneService.emitter.subscribe({
        next: (event) => {
          console.log('in phone component, from phone service', event);
            if(event){
              this.phone.CountryCode = event;
            }
        }
    })

   }

   ngOnInit(): void {
     this.calculateSize(undefined);
     if(this.phoneService.phoneCountryCode){
      this.phone.CountryCode = this.phoneService.phoneCountryCode;
    }
   }

   isinternational(){
    let intStatus = this.addressService.areAnyCountriesInternational();
    if(intStatus!==this.internationalStatus){
      this.internationalStatus = intStatus;
      this.calculateMask();
    }
     return this.addressService.areAnyCountriesInternational();
   }

   maskConvert() {      
      this.phone.$mtcPhoneNoMasked = this.phoneelement.nativeElement.value;      
     if (this.phone.$mtcPhoneNoMasked && this.phone.$mtcPhoneNoMasked.indexOf('_') === -1) {
      let temp = this.phone.$mtcPhoneNoMasked.replace(" ","");
      temp = temp.split(")");
      console.log(temp);
      if(temp.length>0){
        this.phone.AreaCityCode = temp[0].replace('(',"");
        this.phone.PhoneNo = temp[1].replace('-',"")
        this.phone.CountryCode = "01";
      }
     }
   }

   calculateMask(){
     if(this.phone.AreaCityCode && this.phone.PhoneNo){
        this.phone.$mtcPhoneNoMasked = '(' + this.phone.AreaCityCode + ')' + this.phone.PhoneNo.slice(0,3) + '-' +  this.phone.PhoneNo.slice(3,7);
     }

   }

   calculateSize(event){
     let size = window.innerWidth;
     if(event){
       size = event.target.innerWidth;
     }

    this.breakpoint = (size <= 600) ? 1 : 4;
    this.phonesize = (size <= 600) ? 1 : 2;
   }

   setCountryCode(){
    this.listService.getPhoneCode
   }


}
