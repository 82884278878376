<h1>Config</h1>

<mat-form-field class="w-100">
  <mat-label for="prog" aria-labelledby="prog" >Program Key</mat-label>
  <input matInput name="prog" id="prog" aria-label="prog" type="text" [(ngModel)]="progkey" (change)="overrideProgramKey()"/>
</mat-form-field>
<mat-form-field class="w-100">
  <mat-label for="token" aria-labelledby="token" >Token</mat-label>
  <input matInput name="token" id="token" aria-label="token" type="text"   [(ngModel)]="token" (change)="overrideToken()"/>
</mat-form-field>
<mat-form-field id="config" appearance="fill">
  <!-- <mat-label>Leave a comment</mat-label> -->
  <textarea matInput (change)="setModel($event)" (keyup)="clear()">{{enrollment|json}}</textarea>
</mat-form-field>

<div class="error" *ngIf="error">Invalid JSON.</div>
<div *ngIf="success">Mapped to enrollment.</div>
<div class="error" *ngIf="submitError">Error Submitting Enrollment.</div>
<div *ngIf="submitSuccess">Submitted Enrollment.</div>

<div *ngIf="!submitting">
  <button (click)="log()">log</button>
  <button (click)="logCleanse()">log cleanse</button>
  <button (click)="submit()">submit enrollment</button>
</div>
<div *ngIf="submitting">
  <button class="inputSubmit btn btn-primary ml-auto">
    <i class="fas fa-spinner fa-spin"></i>
  </button>
</div>
