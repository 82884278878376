import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PersistenceService } from 'src/app/services/persistence.service';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html'
})
export class TimeoutComponent implements OnInit {

  constructor(    private router: Router,
    private persistenceService: PersistenceService) { }

  ngOnInit(): void {
  }

  forward(){
    this.persistenceService.clear();
    this.router.navigate(["/login"]);
  }

}
