import { Directive } from '@angular/core';
import { AbstractControl, FormGroup, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appEmailmatch]',
  providers: [{provide: NG_VALIDATORS, useExisting: EmailmatchDirective, multi: true}]
})
export class EmailmatchDirective implements Validator  {

  constructor() { }

  validate(group: AbstractControl): any {
    // console.log(group);
    const attendeeemail = group.get('attendeeemail');
    const verify = group.get('attendeeemailverify');

    if(attendeeemail && verify){

      console.log(verify.errors);
      if(attendeeemail.value && verify.value){
        if(!verify.errors || !verify.errors.pattern){
          if(attendeeemail.value.toLowerCase() === verify.value.toLowerCase()){
            group.get('attendeeemailverify')?.updateValueAndValidity({onlySelf: true, emitEvent: false});
            return null;
          }else{
            group.get('attendeeemailverify')?.setErrors({verify:true});
            return {'verify':'Does not Match'};
          }
        }
      }
    }
    group.get('attendeeemailverify')?.updateValueAndValidity({onlySelf: true, emitEvent: false});
    return null;
  }

}
