import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { RegistrationService } from './registration.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

serviceurl = this.environmentService.getEnvironmentUrl('service/zipcode/');

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private authService: AuthenticationService,
    private registrationService: RegistrationService
  ) {}



  getCityState(zipCode): Observable<any> {
    return this.http.get(this.serviceurl + zipCode);
  }

  isCountryInternational(country) {
    return country !== "US" && country !== "CA";
}


areAnyCountriesInternational() {
    var addresses = this.registrationService.getAttendee({}).Address;
    let international = false;
    addresses.forEach(address=>{
      if(address.CountryISOCode && this.isCountryInternational(address.CountryISOCode)){
        international = true;
      }
    })

    return international;

}

}
