import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import * as _ from 'lodash';
import { promise } from 'protractor';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ListManagerService {
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private authService: AuthenticationService
  ) { }

  airportLocations = [];

  urlPrefix = this.environmentService.getEnvironmentUrl(
    'service/lists/COMMON_PREFIX'
  );
  urlSuffix = this.environmentService.getEnvironmentUrl(
    'service/lists/COMMON_SUFFIX'
  );
  urlMealPreference = this.environmentService.getEnvironmentUrl(
    'service/lists/ALL_MEALCODE'
  );
  urlAllDisability = this.environmentService.getEnvironmentUrl(
    'service/lists/ALL_ADACODE'
  );

  urlCounty = this.environmentService.getEnvironmentUrl(
    'service/lists/REGISTRATION_COUNTRY'
  );

  urlState = this.environmentService.getEnvironmentUrl(
    'service/lists/US_STATES'
  );
  urlProvince = this.environmentService.getEnvironmentUrl(
    'service/lists/CANADIAN_STATES'
  );

  allTravelTime = this.environmentService.getEnvironmentUrl(
    'service/lists/ALL_TRAVEL_TIME'
  );

  urlFreqFlyerList = this.environmentService.getEnvironmentUrl(
    'service/lists/COMMON_FREQUENT_FLYER'
  );

  urlPrivacyPolicyCountry = this.environmentService.getEnvironmentUrl(
    'service/lists/PRIVACY_POLICY_COUNTRY'
  );

  getAirportLocations(currentLocation) {
    var airUrl = this.environmentService.getEnvironmentUrl(
      "service/airports/" + currentLocation
    );

    const promise = new Observable((observer) => {
      this.http.get(airUrl).subscribe((data: any[]) => {
        this.airportLocations = data;
        if (currentLocation) {
          var crscode = currentLocation.toUpperCase();
          var airport = _.find(this.airportLocations, { "crscode": crscode });
          if (airport) {
            this.airportLocations = _.filter(this.airportLocations, (airport) => {
              return airport.crscode !== crscode;
            });
            this.airportLocations.unshift(airport);
          }
        }
        observer.next(this.airportLocations);
      });
      return {unsubscribe() {}};
    });
    return promise;
  }

  getListManagerPromise(url) {
    const promise = new Promise((resolve, reject) => {
      this.http.get(url).subscribe((data: any[]) => {
        const mapped = data.map(item => {
          return { name: item.description, code: item.key };
        });
        resolve(mapped);
      });
    });
    return promise;
  }
  getAltListManagerPromise(url) {
    const promise = new Promise((resolve, reject) => {
      this.http.get(url).subscribe((data: any[]) => {
        const mapped = data.map(item => {
          return { name: item.description, code: item.altKey, value: item.key };
        });
        resolve(mapped);
      });
    });
    return promise;
  }

  addNoneToTop(data) {
    data.unshift({ name: "None" });
    return data;
  }

  addUSAToTop(data) {
    data.forEach(function(item,i){ 
      if(item.code === "US"){
        data.splice(i, 1);
        data.unshift(item);
      } 

    });
    return data;
  }

  moveOtherToBottomOfList(data) {
    var index = _.findIndex(data, {
      "code": "OTHER"
    });
    if (index >= 0) {
      var item = data[index];
      data.splice(index, 1);
      data.push(item);
    }
    return data;
  }
  alphabetize(data) {
    return _.sortBy(data, "name");
  }

  getCurrentValue(input, list){
    var item = _.find(list, {code: input}) || {};
    return item;
  }

  getPrefixValue(input){
    return this.getCommonPrefixList().then(list=>{
       return this.getCurrentValue(input, list);
    });
  }

  getSuffixValue(input){
    return this.getSuffixList().then(list=>{
       return this.getCurrentValue(input, list);
    });
  }

  getCommonPrefixList() {
    const url = this.urlPrefix
    return this.getListManagerPromise(url).then(this.addNoneToTop);

  }

  moveVSuffix(data) {
    const item = data;
    item.splice(4, 0, data.splice(6, 1)[0]);
    return item;
  }

  /**
   * @function ListManagerService.getSuffixList
   * @description Gets the suffix list.
   */
  getSuffixList() {
    const url = this.urlSuffix;
    return this.getListManagerPromise(url).then(this.moveVSuffix);
  }

  getMealPreferenceList() {
    const url = this.urlMealPreference;
    return this.getListManagerPromise(url).then(this.moveOtherToBottomOfList).then(this.addNoneToTop);
  }
  getDisabilityList() {
    const url = this.urlAllDisability;
    return this.getListManagerPromise(url).then(this.alphabetize).then(this.moveOtherToBottomOfList);
  }

  getCountryList() {
    const url = this.urlCounty;
    return this.getAltListManagerPromise(url).then(this.removeNullCodes).then(this.remove3CharIsoCodes).then(this.addUSAToTop);;
  }

  getStateList() {
    const url = this.urlState
    return this.getListManagerPromise(url);
  }

  getProvinceList() {
    const url = this.urlProvince
    return this.getListManagerPromise(url);
  }

  /**
   * For flight prefererence
   * @function ListManagerService.getTravelTimeList
   * @description Gets the travel time list.
   */
  getTravelTimeList() {
    const url = this.allTravelTime;
    return this.getListManagerPromise(url);
  }

  /**
    * @function ListManagerService.getFrequentFlyerList
    * @description Gets the frequent flyer list.
    */
  getFrequentFlyerList() {
    const url = this.urlFreqFlyerList;
    return this.getAltListManagerPromise(url);
  }

  removeNullCodes(data){
    return _.filter(data, "code");
  }


  getPhoneCode(country) {
    var url = this.environmentService.getEnvironmentUrl("service/lists/PHONE_COUNTRY_CODE/altkeys/" + country);
    return this.getListManagerPromise(url);
  }

  getPrivacyPolicyCountryList() {
    return this.getAltListManagerPromise(this.urlPrivacyPolicyCountry).then(this.remove3CharIsoCodes);
  }

/**
* @function ListManagerService.remove3CharIsoCodes
* @description Removes country codes with 3 characters.
*/
  remove3CharIsoCodes(data){
    return _.filter(data, (item) => {
        // The XSD will allow a maximum of 2 char but some countries
        // are configured with the 3 character iso code. We need to
        // remove those countries from the list.
        return item.code.length <= 2;
    });
}
}
