import { EventEmitter, Injectable } from '@angular/core';
import { ListManagerService } from './list-manager.service';
import { RegistrationService } from './registration.service';

@Injectable({
  providedIn: 'root'
})
export class PhoneService {
  phoneCountryCode;
  public emitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private listService:ListManagerService,
    private registrationService: RegistrationService
  ) { }

  triggeredChange(){
    this.getCountryCode().then((code:any)=>{
      this.phoneCountryCode = code[0].name;
      this.emitter.emit(this.phoneCountryCode);
    })

  }


  getCountryCode(){
    let countrycode = "US";
    let pax = this.registrationService.getAttendee({});
    pax.Address.forEach(add => {
      if(add.MailTo){
        countrycode = add.CountryISOCode;
      }
    });
    return this.listService.getPhoneCode(countrycode);
  }



}
