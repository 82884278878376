<div class="content">
    <div class="card">
        <div class="card-header">
            <div class="card-title">{{"copy.label.OTP.emailEntry"|copy|async}}</div>
        </div>
        <div class="card-body w-100">
            <form class="w-100" *ngIf="emailEntryMode" name="loginForm" novalidate x (ngSubmit)="emailEnter()" autocomplete="off" [ngClass]="{'form-complete': isFormCompleted}">
                <mat-form-field class="w-100">
                    <mat-label class="control-label">
                        {{"copy.label.LOGIN.oldlogin.email"|copy|async}}
                    </mat-label>
                    <a class="helpiconInTextbox" tabindex="0" matTooltip="{{'copy.label.LOGIN.oldlogin.emailHelp'|copy|async}}">
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                    </a>
                    <input matInput required type="text" name="login-old-email" [(ngModel)]="emailAddress" alphanum />
                </mat-form-field>

                <div class="button_group d-flex flex-row-reverse justify-content-between">
                    <button type="submit" aria-label="Submit"
                        class="forward btn btn-primary">{{'copy.label.LOGIN.oldlogin.button'|copy|async}}</button>
                </div> 
            </form>

            <form class="w-100" *ngIf="methodSelectMode" name="loginForm" novalidate (ngSubmit)="methodSelect()" autocomplete="off"
                [ngClass]="{'form-complete': isFormCompleted}">
                <!-- <mtc-alert></mtc-alert> -->
                <div *ngIf="errorMsg" type="danger">
                    <strong>
                        <span class="fas fa-stack">
                            <i class="fas fa-exclamation fa-stack-1x"></i>
                            <i class="far fa-circle fa-stack-2x"></i>
                        </span>
                        {{"copy.label.OTP.conferror"|copy|async}}
                    </strong>
                </div>                
                <div class="w-100 required">
                    <label>{{"copy.label.OTP.methodSelect"|copy|async}}</label>
                    <div>
                        <mat-radio-group [(ngModel)]="method" name="method" #mailTo="ngModel" required>
                            <mat-radio-button *ngIf="smsnumber" value="SMS">{{"copy.label.OTP.SMS"|copy|async}}
                                {{smsnumber}}</mat-radio-button>
                            <mat-radio-button *ngIf="email" value="EMAIL">{{"copy.label.OTP.Email"|copy|async}}
                                {{email}}</mat-radio-button>
                            <div class="w-100">
                                <app-validation [object]="mailTo" isRequired=true></app-validation>
                            </div>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="button_group d-flex flex-row-reverse justify-content-between">
                    <button type="submit" aria-label="Submit"
                        class="forward btn btn-primary">{{'copy.label.LOGIN.oldlogin.button'|copy|async}}</button>
                        <button type="button" aria-label="Resend" class="forward btn btn-secondary"
                        (click)="backToSelect()">Back</button>
                </div>                        
            </form>
            <form class="w-100" *ngIf="confirmMode" name="loginForm2" novalidate (ngSubmit)="confirm()" autocomplete="off"
                [ngClass]="{'form-complete': isFormCompleted2}">                
                <div *ngIf="errorMsg" type="danger">
                    <strong>
                        <span class="fas fa-stack">
                            <i class="fas fa-exclamation fa-stack-1x"></i>
                            <i class="far fa-circle fa-stack-2x"></i>
                        </span>
                        {{"copy.label.OTP.conferror"|copy|async}}
                    </strong>
                </div>
                <mat-form-field class="w-100">
                    <mat-label for="login-new-input" aria-labelledby="login-new-input"
                        [ngClass]="{'active' : confirmcode}">{{"copy.label.OTP.confirmationCode"|copy|async}}</mat-label>
                    <input matInput class="form-control" type="text" name="login-new-input" id="login-new-input"
                        aria-label="login-new-input" [(ngModel)]="confirmcode" alphanum (keyup)="clearError()" />
                </mat-form-field>
                <div class="button_group d-flex flex-row-reverse justify-content-between">
                    <button type="submit" aria-label="Submit"
                        class="forward btn btn-primary">{{'copy.label.LOGIN.oldlogin.button'|copy|async}}</button>
                    <button type="button" aria-label="Resend" class="forward btn btn-secondary"
                        (click)="resend()">Resend</button>
                </div>            
            </form>
        </div>
    </div>
</div>