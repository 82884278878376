import { Directive } from '@angular/core';
import { Validator, AbstractControl, ValidatorFn, NG_VALIDATORS } from '@angular/forms';
import * as _ from 'lodash';

@Directive({
  selector: '[appLuhnCheck]',
  providers: [{provide: NG_VALIDATORS, useExisting: LuhnCheckDirective, multi: true}]
})
export class LuhnCheckDirective implements Validator{

  luhnarray = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  constructor() { }

  validate(control: AbstractControl): {[key: string]: any} | null {
    return this.luhnValidator()(control);
  }

  luhnValidator(): ValidatorFn{
    return (control: AbstractControl): {[key: string]: any} | null => {
      if(!control.value){
        return null;
      }

      if(!this.luhnCheck(control.value)){
        return {luhncheck: {value: control.value}};
      }

      if(!this.lengthCheck(control.value)){
        return {luhncheck: {value: control.value}};
      }

      return null;
    }
  };

  lengthCheck( ccnum ) {
    // AMEX
    if( _.startsWith( ccnum, "34" ) || _.startsWith( ccnum, "37" ) ) {
        return ccnum.length === 15;
    }

    // Diners Club
    if( _.startsWith( ccnum, "300" ) ||
        _.startsWith( ccnum, "301" ) ||
        _.startsWith( ccnum, "302" ) ||
        _.startsWith( ccnum, "303" ) ||
        _.startsWith( ccnum, "304" ) ||
        _.startsWith( ccnum, "305" ) ||
        _.startsWith( ccnum, "36" ) ||
        _.startsWith( ccnum, "38" ) ) {
        //return ccnum.length === 14;
        //We do not support diners club
        return false;
    }

    // Discover
    if( _.startsWith( ccnum, "6011" ) ) {
        return ccnum.length === 16;
    }

    // Mastercard
    if( _.startsWith( ccnum, "51" ) ||
        _.startsWith( ccnum, "52" ) ||
        _.startsWith( ccnum, "53" ) ||
        _.startsWith( ccnum, "54" ) ||
        _.startsWith( ccnum, "55" ) ) {
        return ccnum.length === 16;
    }
    //new 2X mastercard
    if( _.startsWith( ccnum, "22" ) ||
        _.startsWith( ccnum, "23" ) ||
        _.startsWith( ccnum, "24" ) ||
        _.startsWith( ccnum, "25" ) ||
        _.startsWith( ccnum, "26" ) ||
        _.startsWith( ccnum, "27" ) ) {
        return ccnum.length === 16;
    }

    // Visa
    if( _.startsWith( ccnum, "4" ) ) {
        return ccnum.length === 13 || ccnum.length === 16;
    }

    // Assume any unknown card types are invalid.
    return false;
  }

  luhnCheck(ccnum){
        if( !ccnum ) {
            return false;
        }

        var
            len = ccnum.length,
            bit = 1,
            sum = 0,
            val;

        while( len ) {
            val = parseInt( ccnum.charAt( --len ), 10 );
            sum += ( bit ^= 1 ) ? this.luhnarray[val] : val;
        }

        return sum && sum % 10 === 0;
    }


}
