<div id="general" class="registration container">
  <form #generalForm="ngForm" name="generalForm" (ngSubmit)="forward(generalForm)" novalidate appScrollToInvalid [ngClass]="{'form-complete': isFormCompleted}">
    <h1>Information </h1>

    <section class="card">
      <div class="card-header">
        <div class="card-title">
          Instructions
        </div>
      </div>
      <article class="p-4">
        <div [innerHTML]='"copy.html.GeneralInstructions" | copy | async'></div>
      </article>
    </section>
    <section class="card">
      <div class="card-header">
        <div class="card-title">
          Cancellation Policy:


        </div>
      </div>
      <article class="p-4">
        <div [innerHTML]='"copy.html.GeneralCancelPolicy" | copy | async'></div>
      </article>
    </section>
    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.ACKNOWLEDGEMENT.traveldesk' | copy | async }}
        </div>
      </div>
      <article class="p-4">
        <p class="w-100" *ngIf="phone0">
          {{"copy.label.CONTACTUS.phone"|copy |async}} {{'travelHQ800Num' | copy | async}}
        <br/>{{"copy.label.CONTACTUS.email2"|copy|async}} <a
            href="mailto:{{'travelHQEmail' | copy | async}}">{{'travelHQEmail' | copy | async}}</a>
        <br/>{{"copy.label.CONTACTUS.hours"|copy|async}} {{'programSupportHours' | copy | async}}</p>
      </article>
    </section>

    <div class="button_group d-flex">
      <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.goback' | copy | async }}
      </button>
      <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.continue' | copy | async }}
      </button>
      <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
        <i class="fas fa-spinner fa-spin"></i>
      </button>
    </div>
      </form>
</div>
