import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CopyService } from 'src/app/services/copy.service';
import { HotelService } from 'src/app/services/hotel.service';

@Component({
  selector: 'app-hotel-details',
  templateUrl: './hotel-details.component.html',
  styleUrls: ['./hotel-details.component.scss']
})
export class HotelDetailsComponent implements OnInit {

  hotel;
  hoteldesc;
  constructor(
    public dialogRef: MatDialogRef<HotelDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private hotelService: HotelService,
    private copyService: CopyService
  ) {
    this.hotel = modalData;
    let copykey = this.hotelService.getHotelDescriptionUrl(this.hotel.webHotelGroupDesc);
    this.copyService.getCopyByKey(copykey).then(copy=>{
      this.hoteldesc = copy;
    });
  }

  ngOnInit(): void {
  }
  onNoClick(action): void {
    this.dialogRef.close(action);
  }

}
