import { Component, OnInit} from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { RegistrationService } from '../../services/registration.service';
import { ListManagerService } from '../../services/list-manager.service';
import { EnrollmentService } from '../../services/enrollment.service';
import * as _ from 'lodash';
import { PhoneService } from 'src/app/services/phone.service';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  templateUrl: './attendee.component.html'
})
export class AttendeeComponent implements OnInit {
  isSubmitting = false;
  isError = false;
  attendee;
  prefixList;
  suffixList;
  workPhone;
  homePhone;
  mobilePhone;
  workAddress;
  homeAddress;
  emailAddress;
  adminContact;
  adminPhone;
  emerContact;
  emerContactMainPhone;
  emerContactAltPhone;
  DisabilityType;
  mealList;
  personalConsiderationsList;
  $mtcverifyEmailAddress;
  $mtcMailToAddress;
  isRegComplete;
  enrollment;
  answer; 
  isFormCompleted = false;

  whichEventList = [
    { display: "Elite and LNDM", value: 'Elite and LNDM' },
    { display: "Elite Only", value: 'Elite Only' },
    { display: "LNDM Only", value: 'LNDM Only' }
  ];

  
  yesNoRadio = [ 
    { display: "Yes", value: "Yes" },
    { display: "No", value: "No" }
  ];
  
  attendeetypeList = [
    { display: "US Dealer Principal", value: 3 },
    { display: "US Dealer General Manager", value: 2 },
    { display: "US Public Entity", value: 1 },
    { display: "US Responsible Executive", value:  99},
    { display: "US Dealer Other", value: 4 }
  ];
  businessList = [
    { display: "Lexus", value: "Lexus" },
    { display: "NAPO", value: "NAPO" },
    { display: "Toyota Motor North America", value: "Toyota Motor North America" },
    { display: "Toyota Motor Corporation", value: "Toyota Motor Corporation" },
    { display: "TEMA", value: "TEMA" },
    { display: "Lexus Financial Services", value: "Lexus Financial Services" },
    { display: "Toyota Logistics Services", value: "Toyota Logistics Services" },
    { display: "Lexus College", value: "Lexus College" },
    { display: "Lexus International", value: "Lexus International" },
    { display: "Other", value: "Other" }
  ];
  $mtcBusiness;
  area;


  clickedCountry;
  childEventClicked(data) {
    this.clickedCountry = data;
  }


  constructor(
    private navigationService: NavigationService,
    private registrationService: RegistrationService,
    private listService: ListManagerService,
    private enrollmentService: EnrollmentService,
    private phoneService: PhoneService,
    private variablesService: VariablesService

  ) {

    this.enrollment = this.registrationService.getEnrollment({});
    this.isRegComplete = this.registrationService.isRegistrationComplete();
    /* this.enrollment.HotelNeeded = "false"; */

    this.attendee = this.registrationService.getAttendee({});
    this.workPhone = this.registrationService.getPhone(this.attendee, "WORK", {});
    this.homePhone = this.registrationService.getPhone(this.attendee, "HOME", {});
    this.mobilePhone = this.registrationService.getPhone(this.attendee, "MOBILE", {});
    this.workAddress = this.registrationService.getAddress(this.attendee, "WORK", {});
    this.homeAddress = this.registrationService.getAddress(this.attendee, "HOME", {});
    this.emailAddress = this.registrationService.getEmail(this.attendee, "BUSINESS", {});
 

    this.adminContact = this.registrationService.getAdminContact({});
    this.adminPhone = this.adminContact.AdminContactPhone[0];
    this.emerContactMainPhone = this.registrationService.getEmergencyContactPhone(this.attendee, "DTPHONE", {});
    this.emerContactAltPhone = this.registrationService.getEmergencyContactPhone(this.attendee, "ALTPHONE", {});
    this.DisabilityType = this.registrationService.getDisabilities(this.attendee, "");
    if(this.workAddress.CountryISOCode === "" || this.workAddress.CountryISOCode === " " || this.workAddress.CountryISOCode === undefined){
      this.workAddress.CountryISOCode = "US";
    }

    if(this.homeAddress.CountryISOCode === "" || this.homeAddress.CountryISOCode === " " || this.homeAddress.CountryISOCode === undefined){
      this.homeAddress.CountryISOCode = "US";
    }




    if(this.attendee.ResidenceCountryISOCode && this.workAddress.CountryISOCode){
      if(this.attendee.ResidenceCountryISOCode == this.workAddress.CountryISOCode){
        this.attendee.$mtcvarCountryOfRes = 'Yes';
      }else{
        this.attendee.$mtcvarCountryOfRes = 'No';
      }
    }

    if(this.isRegComplete && (this.attendee.PAXTypeNo===6||this.attendee.PAXTypeNo===10||this.attendee.PAXTypeNo===11 
      || this.attendee.PAXTypeNo===12 || this.attendee.PAXTypeNo===13)){
     if(this.workAddress.AddressLine1 === "6565 Headquarters Drive" && this.workAddress.Zip === "75024"){
       this.attendee.$mtcPrefMailing = 'Yes'; 
       this.attendee.$mtcAddyDisabled = true;
     }else{
       this.attendee.$mtcPrefMailing = 'No';
       this.attendee.$mtcAddyDisabled = false;
     }
   }



   if (this.attendee.PAXTypeNo === 6 || this.attendee.PAXTypeNo === 10 || this.attendee.PAXTypeNo === 11 || this.attendee.PAXTypeNo === 12 || this.attendee.PAXTypeNo === 13) {
     var found = false;
     for(var i = 0; i < this.businessList.length; i++) {
         if (this.businessList[i].value === this.attendee.BusinessName) {
           this.attendee.$mtcBusiness = this.attendee.BusinessName;
           found = true;
           break;
         }
     }
     if (found === false && this.attendee.BusinessName) {
       this.attendee.$mtcBusiness = 'Other';
     }
   }

   this.area = registrationService.getVariable(this.attendee,variablesService.VARIABLE.varArea.ID,{}).Value;
    
   if(this.attendee.PAXTypeNo === 7){
     switch (this.area) {
       case "31": {
         this.attendee.BusinessName = "Lexus Western Area"; 
         break;
       }
       case "32": {
         this.attendee.BusinessName = "Lexus Central Area"; 
         break;
       }
       case "33": {
         this.attendee.BusinessName = "Lexus Eastern Area"; 
         break;
       }
       case "34": {
         this.attendee.BusinessName = "Lexus Southern Area"; 
         break;
       }
       default: {
         break;
       }

     }
 }



    this.listService.getCommonPrefixList().then(list => {
      this.prefixList = list;
    });
    this.listService.getSuffixList().then(list => {
      this.suffixList = list;
    });
    this.listService.getMealPreferenceList().then(list => {
      this.mealList = list;
    });
    this.listService.getDisabilityList().then(list => {
      this.personalConsiderationsList = list;
    });
    this.detectMailTo();
    this.setMailTo();

  }

  ngOnInit(): void { }

  includes(arr, val){
    return _.includes(arr, val);
  }

  considerationUpdate(consideration) {
    let justadded = false;

    if (!_.includes(this.attendee.DisabilityType, consideration.code)) {
      this.attendee.DisabilityType.push(consideration.code);
      justadded = true;
    }
    if (justadded === false && _.includes(this.attendee.DisabilityType, consideration.code)) {
      var index = this.attendee.DisabilityType.indexOf(consideration.code);
      if (index !== -1) {
        this.attendee.DisabilityType.splice(index, 1);
      }
    }
  }

  FieldsChange(values:any) {
    if (values.checked) {
      this.homeAddress.AddressLine1 = this.workAddress.AddressLine1;
      this.homeAddress.AddressLine2 = this.workAddress.AddressLine2;
      this.homeAddress.Zip = this.workAddress.Zip;
      this.homeAddress.City = this.workAddress.City;
      this.homeAddress.StateProvince = this.workAddress.StateProvince;
      this.homeAddress.CountryISOCode = this.workAddress.CountryISOCode;
    } else {
      this.homeAddress.AddressLine1 = "";
      this.homeAddress.AddressLine2 = "";
      this.homeAddress.Zip = "";
      this.homeAddress.City = "";
      this.homeAddress.StateProvince = "";
      this.homeAddress.CountryISOCode = "";
    }
  }
  cleanAdminContact() {
    if (!this.enrollment.$mtcHasAdminContact) {
        this.adminContact.AdminContactName = undefined;
        this.adminContact.AdminContactEmailAddress = undefined;
        this.adminContact.AdminContactPhone = undefined;
    }
  }
  cleanConsiderations() {
    if (!(this.attendee.DietaryNeed === "FOODALLE") && !(this.attendee.DietaryNeed === "OTHER")) {
      this.attendee.DietaryConsiderations = undefined;
    }
    if (this.attendee.DisabilityType) {

      if (!_.includes(this.attendee.DisabilityType, 'OTHER')) {
        this.attendee.PersonalConsiderations = undefined;
      }
    } 
  }

  mtcMailToAddress;

  detectMailTo(){
    this.mtcMailToAddress = "WORK"; 


  }

  setMailTo() {
    if(this.attendee){
      this.attendee.Address.forEach(add => {
        if(add.AddressType === this.mtcMailToAddress){
          add.MailTo = true;
        }else{
          add.MailTo = false;
        }
      });
    }
    this.phoneService.triggeredChange();
  }

  setPaxJobBiz(){
    if(!this.isRegComplete && this.attendee.$mtcPAXTypeNo !== undefined && this.attendee.$mtcPAXTypeNo !== ''){
      this.attendee.PAXTypeNo = this.attendee.$mtcPAXTypeNo;
    }
    if (this.attendee.PAXTypeNo === 6 || this.attendee.PAXTypeNo === 10 || this.attendee.PAXTypeNo === 11 || this.attendee.PAXTypeNo === 12 || this.attendee.PAXTypeNo === 13) {
      if (this.attendee.$mtcBusiness && this.attendee.$mtcBusiness !== 'Other') {
        this.attendee.BusinessName = this.attendee.$mtcBusiness;
      }
    }
    if(this.attendee.PAXTypeNo === 99){
      this.attendee.PAXTypeNo = 3;
      this.attendee.JobTitle = 'US Responsible Executive';
    }

   /* if(this.attendee.PAXTypeNo === 2 || this.attendee.PAXTypeNo === 3 ||this.attendee.PAXTypeNo === 4 
      || this.attendee.PAXTypeNo === 99){
        this.attendee.BusinessName ="";
    }*/
  }


  forward(form) {
    this.isSubmitting = true;
    this.isFormCompleted = true;
   // console.log(form);
    if (form.valid) {

      this.setPaxJobBiz();
      this.cleanAdminContact();
      //this.cleanConsiderations();
      this.navigationService.forward();
    } else {
      this.isError = true;
      this.isSubmitting = false;

    }
  }

  back() {
    this.navigationService.back();
  }
}
